import React, {
  ChangeEvent,
  Dispatch,
  SetStateAction,
  useEffect,
  useState,
} from 'react';
import Grid from '@mui/material/Unstable_Grid2';
import {
  Autocomplete,
  Button,
  Checkbox,
  FormControl,
  FormControlLabel,
  IconButton,
  InputAdornment,
  InputLabel,
  MenuItem,
  Paper,
  Select,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Typography,
  useTheme,
} from '@mui/material';
import InputMask from 'react-input-mask';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import dayjs, { Dayjs } from 'dayjs';
import { toast } from 'react-toastify';
import ClearIcon from '@mui/icons-material/Clear';
import { CloudUpload as CloudUploadIcon } from '@mui/icons-material';
import SearchOutlinedIcon from '@mui/icons-material/SearchOutlined';
import { alpha, styled } from '@mui/system';
import FileOpenRoundedIcon from '@mui/icons-material/FileOpenRounded';
import {
  Customer,
  Address,
  City,
  State,
  Project, Associate,
} from '../../contexts/@types';
import style from './projectcustomer.module.scss';
import { api } from '../../services/api';
import {
  defaultAddress,
  defaultAssociate,
  defaultCity,
  defaultState,
} from '../../contexts/@defaultValues';
import { FieldError } from '../../pages/NovoProjeto';
import {
  applyStrNumberMask,
  calcKWPCost, convertToNumber, handleFileName, isValidUrl, openPdf,
} from '../../utils/util';
import Ph3aDataSearch from './ph3aDataSearch';

interface ProjectCustomerProps {
  customer: Customer
  handleChangeCustomer: Function
  address: Address
  setAddress: Function
  city: City | undefined
  setCity: Function
  state: State | undefined
  setState: Dispatch<SetStateAction<State>>
  project: Project
  handleChangeProject: Function
  associates: Array<Associate>
  handleChangeAssociates: Function
  isDisabled: boolean
  handleToggleTerms: Function
  fieldsError: FieldError
  setCustomer: Function
  validateCustomerForm: () => string
}

interface AssociateErr {
  name: boolean,
  cpf: boolean,
  motherName: boolean,
  phone: boolean,
}

const ProjectCustomer = (props: ProjectCustomerProps) => {
  const theme = useTheme();
  const {
    customer,
    handleChangeCustomer,
    address,
    setAddress,
    city,
    setCity,
    state,
    setState,
    project,
    handleChangeProject,
    associates,
    handleChangeAssociates,
    isDisabled,
    handleToggleTerms,
    fieldsError,
    setCustomer,
    validateCustomerForm,
  }: ProjectCustomerProps = props;
  const [states, setStates] = useState<Array<State>>([defaultState]);
  const [cities, setCities] = useState<Array<City>>([defaultCity]);
  const [associate, setAssociate] = useState<Associate>(defaultAssociate);
  const [associateErr, setAssociateErr] = useState<AssociateErr>({
    name: false,
    cpf: false,
    motherName: false,
    phone: false,
  });
  const [openCPFSearch, setOpenCPFSearch] = useState<boolean>(false);

  const handleChangeAssociate = (prop: keyof Associate) => (e:
    ChangeEvent<HTMLInputElement>) => {
    const { value } = e.target;
    setAssociate({ ...associate, [prop]: value });
  };

  const validateAssociate = () => {
    const errors = {
      name: !associate.name,
      cpf: !associate.cpf || associate.cpf.includes('_'),
      motherName: !associate.motherName,
      phone: !associate.phone || associate.phone.includes('_'),
    };

    setAssociateErr(errors);

    const fieldNames = {
      name: 'Nome do sócio',
      cpf: 'CPF do sócio',
      motherName: 'Nome da mãe do sócio',
      phone: 'Telefone do sócio',
    };

    const emptyFields = Object.keys(errors)
      .filter((key) => errors[key as keyof typeof errors])
      .map((key) => fieldNames[key as keyof typeof fieldNames])
      .join(';; ');

    if (emptyFields.length > 0) {
      toast.error(
        <>
          O(s) campo(s) a seguir devem ser preenchidos:
          {emptyFields.split('; ').map((e) => (
            <React.Fragment key={e}>
              <br />
              {e}
            </React.Fragment>
          ))}
        </>,
      );

      return 'nok';
    }

    handleChangeAssociates(associate, 'add');
    setAssociate(defaultAssociate);
    return 'ok';
  };

  const getCities = async (stateId: number) => {
    const response = await api.post('/address/city/get', { stateId });
    setCities(response.data);
  };

  useEffect(() => {
    const getStates = async () => {
      const response = await api.post('/address/state/get', {}, {});
      setStates(response.data);
    };

    getStates();
    getCities(1);
  }, []);

  const handleDefaultAddress = () => {
    setAddress({ ...defaultAddress });
    setState({ ...defaultState });
    setCity({ ...defaultCity });
  };

  const zipSearch = async (zip: string) => {
    try {
      const getByZipResponse = await api.post('/address/cep/get', { data: { ref: zip.replace(/\D/g, '') } });
      return getByZipResponse.data;
    } catch {
      handleDefaultAddress();
      toast.error(`CEP ${zip} não encontrado`);
      return false;
    }
  };

  useEffect(() => {
    const fetchZip = async () => {
      if (!!address.zip && !address.zip.includes('_')) {
        const data = await zipSearch(address.zip);
        if (!data || !states) return;

        const newAddress: Address = {
          ...address,
          district: data.bairro,
          street1: data.rua,
          cityId: data.cidadeId,
        };
        setAddress(newAddress);

        const newCity: City = {
          id: data.cidadeId,
          name: data.cidade,
          stateId: data.estadoId,
        };
        setCity(newCity);

        const newState = states.find((e) => e.id === data.estadoId);
        if (newState) {
          setState(newState);
        }
      }
    };
    fetchZip().then(() => {});
    // If Use-effect is changed run without the disabled eslint to check
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [states, address.zip]);

  const handleDateChange = (key: keyof Customer) => (value: Dayjs | null) => {
    const fakeEvent = {
      target: {
        value: value?.format('DD/MM/YYYY'),
      },
    } as React.ChangeEvent<HTMLInputElement>;

    handleChangeCustomer(key)(fakeEvent);
  };

  const handleChangeAddress = (key: keyof Address) => (e: ChangeEvent<HTMLInputElement>) => {
    const { value } = e.target;
    setAddress({ ...address, [key]: value });
  };

  const handleChangeCity = (event: React.ChangeEvent<{}>, value: City) => {
    setCity({ id: value.id, name: value.name });
  };

  const handleChangeState = (event: React.ChangeEvent<{}>, value: State) => {
    const stateId = value.id;
    setState({ id: value.id, acronym: value.acronym, name: value.name });
    getCities(stateId);
  };

  const StyledLink = styled('a')({
    color: 'blue',
    textDecoration: 'none',
    '&:hover': {
      textDecoration: 'underline',
    },
  });

  const handleCPFsearch = () => {
    setOpenCPFSearch(!openCPFSearch);
  };

  return (
    <>
      <Ph3aDataSearch
        open={openCPFSearch}
        setOpen={setOpenCPFSearch}
        customer={customer}
        handleChangeCustomer={handleChangeCustomer}
        setCustomer={setCustomer}
        address={address}
        setAddress={setAddress}
        validateCustomerForm={validateCustomerForm}
      />
      <Grid container spacing={2} rowSpacing={2}>
        <Grid xs={12}>
          <Typography sx={{
            fontSize: {
              xs: '16px',
              md: '24px',
            },
            fontFamily: 'Poppins',
            color: theme.palette.primary.main,
            fontWeight: '700',
          }}
          >
            Cadastro do cliente
          </Typography>
          <hr className={style.titleDivisor} />
        </Grid>
        <Grid xs={12} lg={2.4}>
          <TextField
            error={fieldsError.customerName}
            fullWidth
            required
            label="Nome completo"
            variant="outlined"
            value={customer.nome}
            onChange={handleChangeCustomer('nome')}
            InputLabelProps={{
              shrink: !!customer.nome,
            }}
            disabled={isDisabled}
          />
        </Grid>
        <Grid xs={12} lg={2.4}>
          <InputMask
            mask="999.999.999-99"
            maskPlaceholder="_"
            disabled={isDisabled}
            value={customer.cpf}
            onChange={handleChangeCustomer('cpf')}
          >
            <TextField
              error={fieldsError.cpf}
              fullWidth
              required
              label="CPF"
              variant="outlined"
              InputLabelProps={{
                shrink: !!customer.cpf,
              }}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton onClick={handleCPFsearch}>
                      <SearchOutlinedIcon />
                    </IconButton>
                  </InputAdornment>
                ),
              }}
            />
          </InputMask>
        </Grid>
        <Grid xs={12} lg={2.4}>
          <TextField
            error={fieldsError.rg}
            fullWidth
            required
            label="RG"
            variant="outlined"
            InputLabelProps={{
              shrink: !!customer.rg,
            }}
            disabled={isDisabled}
            value={customer.rg}
            onChange={handleChangeCustomer('rg')}
          />
        </Grid>
        <Grid xs={12} lg={2.4}>
          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <DatePicker
              disabled={isDisabled}
              label="Data de nascimento *"
              openTo="month"
              views={['year', 'month', 'day']}
              format="DD/MM/YYYY"
              value={customer.dataNascimento && dayjs(customer.dataNascimento, 'DD/MM/YYYY').isValid() ? dayjs(customer.dataNascimento, 'DD/MM/YYYY') : null}
              sx={{ width: '100%' }}
              onChange={handleDateChange('dataNascimento')}
            />
          </LocalizationProvider>
        </Grid>
        <Grid xs={12} lg={2.4}>
          <InputMask
            mask="(99) 9 9999-9999"
            maskPlaceholder="_"
            disabled={isDisabled}
            value={customer.telefone}
            onChange={handleChangeCustomer('telefone')}
          >
            <TextField
              error={fieldsError.phone}
              fullWidth
              required
              label="Telefone"
              variant="outlined"
              InputLabelProps={{
                shrink: !!customer.telefone,
              }}
            />
          </InputMask>
        </Grid>
        <Grid xs={12} lg={3}>
          <TextField
            error={fieldsError.motherName}
            fullWidth
            required
            label="Nome da mãe"
            variant="outlined"
            InputLabelProps={{
              shrink: !!customer.nomeMae,
            }}
            disabled={isDisabled}
            value={customer.nomeMae}
            onChange={handleChangeCustomer('nomeMae')}
          />
        </Grid>
        <Grid xs={12} lg={3}>
          <FormControl fullWidth required error={fieldsError.maritalStatus}>
            <InputLabel id="select-marital-status">Estado civil</InputLabel>
            <Select
              disabled={isDisabled}
              label="Estado civil"
              value={customer.estadoCivil}
              labelId="select-marital-status"
              onChange={handleChangeCustomer('estadoCivil')}
            >
              <MenuItem value="Casado">Casado</MenuItem>
              <MenuItem value="Solteiro">Solteiro</MenuItem>
              <MenuItem value="Viúvo">Viúvo</MenuItem>
              <MenuItem value="União Estável">União Estável</MenuItem>
              <MenuItem value="Separado">Separado</MenuItem>
            </Select>
          </FormControl>
        </Grid>
        <Grid xs={12} lg={3}>
          <TextField
            error={fieldsError.occupation}
            fullWidth
            required
            label="Profissão"
            variant="outlined"
            InputLabelProps={{
              shrink: !!customer.profissao,
            }}
            disabled={isDisabled}
            value={customer.profissao}
            onChange={handleChangeCustomer('profissao')}
          />
        </Grid>
        <Grid xs={12} lg={3}>
          <TextField
            error={fieldsError.monthlyIncome}
            fullWidth
            required
            label="Renda/Faturamento mensal (líquida)"
            variant="outlined"
            InputLabelProps={{
              shrink: true,
            }}
            InputProps={{
              startAdornment: <InputAdornment position="start">R$</InputAdornment>,
            }}
            disabled={isDisabled}
            value={customer.faturamentoMensal}
            onChange={handleChangeCustomer('faturamentoMensal')}
          />
        </Grid>
        <Grid xs={12} lg={2.2}>
          <TextField
            error={fieldsError.occupationTime}
            fullWidth
            required
            label="Tempo de profissão (Máx. 50 anos)"
            variant="outlined"
            InputLabelProps={{
              shrink: true,
            }}
            InputProps={{
              endAdornment: <InputAdornment position="end">ano(s)</InputAdornment>,
            }}
            disabled={isDisabled}
            value={customer.tempoProfissao}
            onChange={handleChangeCustomer('tempoProfissao')}
          />
        </Grid>
        <Grid xs={12} lg={2.6}>
          <TextField
            error={fieldsError.assetValue}
            fullWidth
            required
            label="Valor do patrimônio"
            variant="outlined"
            InputLabelProps={{
              shrink: true,
            }}
            InputProps={{
              startAdornment: <InputAdornment position="start">R$</InputAdornment>,
            }}
            disabled={isDisabled}
            value={customer.valorPatrimonio}
            onChange={handleChangeCustomer('valorPatrimonio')}
          />
        </Grid>
        <Grid xs={12} lg={3}>
          <TextField
            error={fieldsError.customerEmail}
            fullWidth
            required
            label="E-mail do cliente"
            variant="outlined"
            InputLabelProps={{
              shrink: !!customer.email,
            }}
            disabled={isDisabled}
            value={customer.email}
            onChange={handleChangeCustomer('email')}
          />
        </Grid>
        <Grid xs={12} lg={2.6}>
          <TextField
            error={fieldsError.averageElectricityBill}
            fullWidth
            required
            label="Média da conta de luz (últimos 3 meses)"
            variant="outlined"
            InputLabelProps={{
              shrink: true,
            }}
            InputProps={{
              startAdornment: <InputAdornment position="start">R$</InputAdornment>,
            }}
            disabled={isDisabled}
            value={customer.mediaContaLuz}
            onChange={handleChangeCustomer('mediaContaLuz')}
          />
        </Grid>
        <Grid xs={12} lg={1.6}>
          <FormControl fullWidth required error={fieldsError.maritalStatus}>
            <InputLabel id="select-project-type">Tipo do projeto</InputLabel>
            <Select
              disabled={isDisabled}
              label="Tipo do projeto"
              value={project.type.toString()}
              labelId="select-project-type"
              onChange={handleChangeProject('type')}
            >
              <MenuItem value="Comercial">Comercial</MenuItem>
              <MenuItem value="Rural">Rural</MenuItem>
              <MenuItem value="Residencial">Residencial</MenuItem>
              <MenuItem value="Industrial">Industrial</MenuItem>
            </Select>
          </FormControl>
        </Grid>
        <Grid xs={12}>
          <FormControlLabel
            control={(
              <Checkbox
                disabled={isDisabled}
                defaultChecked={customer.company_link}
                onChange={handleChangeCustomer('company_link')}
                sx={{
                  color: theme.palette.primary.main,
                  '&.Mui-checked': {
                    color: theme.palette.primary.main,
                  },
                }}
              />
            )}
            label="Projeto vinculado à empresa"
            sx={{ '& .MuiFormControlLabel-label': { fontSize: '16px', userSelect: 'none' } }}
          />
        </Grid>
        <Grid xs={12} container spacing={2} rowSpacing={2} alignItems="center">
          {customer.company_link && (
            <>
              <Grid xs={12}>
                <Typography sx={{
                  fontSize: {
                    xs: '16px',
                    md: '24px',
                  },
                  fontFamily: 'Poppins',
                  color: theme.palette.primary.main,
                  fontWeight: '700',
                }}
                >
                  Dados da empresa
                </Typography>
                <hr className={style.titleDivisor} />
              </Grid>
              <Grid xs={12} lg={1.8}>
                <InputMask
                  mask="99.999.999/9999-99"
                  maskPlaceholder="_"
                  disabled={isDisabled}
                  value={customer.cnpj}
                  onChange={handleChangeCustomer('cnpj')}
                >
                  <TextField
                    error={fieldsError.cnpj}
                    fullWidth
                    required
                    label="CNPJ"
                    variant="outlined"
                    InputLabelProps={{
                      shrink: !!customer.cnpj,
                    }}
                  />
                </InputMask>
              </Grid>
              <Grid xs={12} lg={2.2}>
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                  <DatePicker
                    disabled={isDisabled}
                    label="Data de fundação *"
                    openTo="month"
                    views={['year', 'month', 'day']}
                    format="DD/MM/YYYY"
                    value={customer.dataAbertura && dayjs(customer.dataAbertura, 'DD/MM/YYYY').isValid() ? dayjs(customer.dataAbertura, 'DD/MM/YYYY') : null}
                    sx={{ width: '100%' }}
                    onChange={handleDateChange('dataAbertura')}
                  />
                </LocalizationProvider>
              </Grid>
              <Grid xs={12} lg={4}>
                <TextField
                  error={fieldsError.socialReason}
                  fullWidth
                  required
                  label="Razão social"
                  variant="outlined"
                  value={customer.razaoSocial}
                  onChange={handleChangeCustomer('razaoSocial')}
                  InputLabelProps={{
                    shrink: !!customer.razaoSocial,
                  }}
                  disabled={isDisabled}
                />
              </Grid>
              <Grid xs={12} lg={4}>
                <TextField
                  error={fieldsError.fantasyName}
                  fullWidth
                  required
                  label="Nome fantasia"
                  variant="outlined"
                  value={customer.nomeFantasia}
                  onChange={handleChangeCustomer('nomeFantasia')}
                  InputLabelProps={{
                    shrink: !!customer.nomeFantasia,
                  }}
                  disabled={isDisabled}
                />
              </Grid>
              <Grid xs={12} lg={4}>
                <TextField
                  error={fieldsError.companyEmail}
                  fullWidth
                  required
                  label="E-mail da empresa"
                  variant="outlined"
                  value={customer.emailEmpresa}
                  onChange={handleChangeCustomer('emailEmpresa')}
                  InputLabelProps={{
                    shrink: !!customer.emailEmpresa,
                  }}
                  disabled={isDisabled}
                />
              </Grid>
              <Grid xs={12} lg={2.4}>
                <InputMask
                  mask="(99) 9 9999-9999"
                  maskPlaceholder="_"
                  disabled={isDisabled}
                  value={customer.telefoneComercial}
                  onChange={handleChangeCustomer('telefoneComercial')}
                >
                  <TextField
                    error={fieldsError.companyPhone}
                    fullWidth
                    required
                    label="telefone da empresa"
                    variant="outlined"
                    InputLabelProps={{
                      shrink: !!customer.telefoneComercial,
                    }}
                  />
                </InputMask>
              </Grid>
              <Grid xs={12} lg={2.8}>
                <TextField
                  error={fieldsError.monthlyIncomeCompany}
                  fullWidth
                  required
                  label="Renda/Faturamento mensal da empresa"
                  variant="outlined"
                  InputLabelProps={{
                    shrink: true,
                  }}
                  InputProps={{
                    startAdornment: <InputAdornment position="start">R$</InputAdornment>,
                  }}
                  disabled={isDisabled}
                  value={customer.faturamentoMensalEmpresa}
                  onChange={handleChangeCustomer('faturamentoMensalEmpresa')}
                />
              </Grid>
              <Grid xs={12} lg={2.8}>
                <TextField
                  error={fieldsError.shareCapital}
                  fullWidth
                  required
                  label="Capital social"
                  variant="outlined"
                  InputLabelProps={{
                    shrink: true,
                  }}
                  InputProps={{
                    startAdornment: <InputAdornment position="start">R$</InputAdornment>,
                  }}
                  disabled={isDisabled}
                  value={customer.capitalSocial}
                  onChange={handleChangeCustomer('capitalSocial')}
                />
              </Grid>
              {((!associates || associates.length === 0) && isDisabled) || (
                <Grid xs={12}>
                  <Typography sx={{
                    fontSize: {
                      xs: '16px',
                      md: '24px',
                    },
                    fontFamily: 'Poppins',
                    color: theme.palette.primary.main,
                    fontWeight: '700',
                  }}
                  >
                    Adicionar sócios
                  </Typography>
                  <hr className={style.titleDivisor} />
                </Grid>
              )}
              {!isDisabled && (
                <>
                  <Grid xs={12} lg={2.8}>
                    <TextField
                      error={associateErr.name}
                      fullWidth
                      required
                      label="Nome completo"
                      variant="outlined"
                      disabled={isDisabled}
                      InputLabelProps={{
                        shrink: !!associate.name,
                      }}
                      value={associate.name}
                      onChange={handleChangeAssociate('name')}
                    />
                  </Grid>
                  <Grid xs={12} lg={2.4}>
                    <InputMask
                      mask="999.999.999-99"
                      maskPlaceholder="_"
                      disabled={isDisabled}
                      value={associate.cpf}
                      onChange={handleChangeAssociate('cpf')}
                    >
                      <TextField
                        error={associateErr.cpf}
                        fullWidth
                        required
                        label="CPF"
                        variant="outlined"
                        InputLabelProps={{
                          shrink: !!associate.cpf,
                        }}
                      />
                    </InputMask>
                  </Grid>
                  <Grid xs={12} lg={2.8}>
                    <TextField
                      error={associateErr.motherName}
                      fullWidth
                      required
                      label="Nome da mãe"
                      variant="outlined"
                      disabled={isDisabled}
                      InputLabelProps={{
                        shrink: !!associate.motherName,
                      }}
                      value={associate.motherName}
                      onChange={handleChangeAssociate('motherName')}
                    />
                  </Grid>
                  <Grid xs={12} lg={2.4}>
                    <InputMask
                      mask="(99) 9 9999-9999"
                      maskPlaceholder="_"
                      disabled={isDisabled}
                      value={associate.phone}
                      onChange={handleChangeAssociate('phone')}
                    >
                      <TextField
                        error={associateErr.phone}
                        fullWidth
                        required
                        label="Telefone"
                        variant="outlined"
                        InputLabelProps={{
                          shrink: !!associate.phone,
                        }}
                      />
                    </InputMask>
                  </Grid>
                  <Grid xs={12} lg={1.6}>
                    <Button
                      variant="contained"
                      fullWidth
                      sx={{
                        borderRadius: '2rem',
                        backgroundColor: theme.palette.primary.main,
                        textTransform: 'none',
                        '&:hover': {
                          backgroundColor: alpha(theme.palette.primary.main, 0.8),
                        },
                      }}
                      onClick={() => {
                        validateAssociate();
                      }}
                    >
                      Adicionar
                    </Button>
                  </Grid>
                </>
              )}
              {associates && associates.length > 0 && (
                <Grid xs={12}>
                  <TableContainer component={Paper}>
                    <Table sx={{ minWidth: 650 }} aria-label="simple table">
                      <TableHead>
                        <TableRow>
                          <TableCell>Nome do sócio</TableCell>
                          <TableCell>CPF</TableCell>
                          <TableCell>Nome da mãe</TableCell>
                          <TableCell>Telefone</TableCell>
                          <TableCell />
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {associates.map((row) => (
                          <TableRow
                            key={row.name}
                            sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                          >
                            <TableCell>{row.name}</TableCell>
                            <TableCell>{row.cpf}</TableCell>
                            <TableCell>{row.motherName}</TableCell>
                            <TableCell>{row.phone}</TableCell>
                            <TableCell>
                              {!isDisabled && (
                                <Button
                                  variant="outlined"
                                  fullWidth
                                  sx={{
                                    borderRadius: '2rem',
                                    borderColor: theme.palette.primary.main,
                                    color: theme.palette.primary.main,
                                    textTransform: 'none',
                                    '&:hover': {
                                      borderColor: theme.palette.primary.main,
                                      backgroundColor: 'rgba(255,127,80,0.2)',
                                    },
                                  }}
                                  onClick={() => {
                                    handleChangeAssociates(row, 'remove');
                                  }}
                                >
                                  Remover
                                </Button>
                              )}
                            </TableCell>
                          </TableRow>
                        ))}
                      </TableBody>
                    </Table>
                  </TableContainer>
                </Grid>
              )}
            </>
          )}
        </Grid>
        <Grid xs={12}>
          <Typography sx={{
            fontSize: {
              xs: '16px',
              md: '24px',
            },
            fontFamily: 'Poppins',
            color: theme.palette.primary.main,
            fontWeight: '700',
          }}
          >
            Endereço da instalação
          </Typography>
          <hr className={style.titleDivisor} />
        </Grid>
        <Grid xs={12} lg={2}>
          <InputMask
            mask="99.999-999"
            maskPlaceholder="_"
            disabled={isDisabled}
            value={address.zip}
            onChange={handleChangeAddress('zip')}
          >
            <TextField
              error={fieldsError.zipCode}
              fullWidth
              required
              label="CEP"
              variant="outlined"
              InputLabelProps={{
                shrink: !!address.zip,
              }}
            />
          </InputMask>
        </Grid>
        <Grid xs={12} lg={3}>
          <TextField
            error={fieldsError.street}
            fullWidth
            required
            label="Logradouro"
            variant="outlined"
            InputLabelProps={{
              shrink: !!address.street1,
            }}
            disabled={isDisabled}
            value={address.street1}
            onChange={handleChangeAddress('street1')}
          />
        </Grid>
        <Grid xs={12} lg={1}>
          <TextField
            error={fieldsError.companyPhone}
            fullWidth
            required
            label="Nº"
            variant="outlined"
            InputLabelProps={{
              shrink: true,
            }}
            disabled={isDisabled}
            value={address.number}
            onChange={handleChangeAddress('number')}
          />
        </Grid>
        <Grid xs={12} lg={1.5}>
          <TextField
            fullWidth
            label="Complemento"
            variant="outlined"
            InputLabelProps={{
              shrink: !!address.street2,
            }}
            disabled={isDisabled}
            value={address.street2}
            onChange={handleChangeAddress('street2')}
          />
        </Grid>
        <Grid xs={12} lg={1.5}>
          <TextField
            error={fieldsError.district}
            fullWidth
            label="Bairro"
            required
            variant="outlined"
            InputLabelProps={{
              shrink: !!address.district,
            }}
            disabled={isDisabled}
            value={address.district}
            onChange={handleChangeAddress('district')}
          />
        </Grid>
        <Grid xs={12} lg={2}>
          {!isDisabled && (
            <Autocomplete
              disabled={isDisabled}
              id="state"
              disableClearable
              options={states}
              getOptionLabel={(option: State) => option.acronym}
              value={state}
              onChange={handleChangeState}
              renderInput={(params) => (
                <TextField
                  error={fieldsError.state}
                  {...params}
                  label="Estado"
                  required
                  fullWidth
                />
              )}
            />
          )}
          {isDisabled && (
            <TextField
              disabled
              required
              label="Estado"
              value={state?.acronym}
              InputLabelProps={{
                shrink: !!state,
              }}
            />
          )}
        </Grid>
        <Grid xs={12} lg={2}>
          <Autocomplete
            id="city"
            disabled={isDisabled}
            disableClearable
            options={cities}
            getOptionLabel={(option: City) => option.name}
            value={city}
            renderInput={(params) => (
              <TextField
                error={fieldsError.city}
                {...params}
                label="Cidade"
                required
                fullWidth
              />
            )}
            onChange={handleChangeCity}
          />
        </Grid>
        <Grid xs={12} lg={6} container spacing={2} rowSpacing={1}>
          <Grid xs={12}>
            <Typography sx={{
              fontSize: {
                xs: '16px',
                md: '24px',
              },
              fontFamily: 'Poppins',
              color: theme.palette.primary.main,
              fontWeight: '700',
            }}
            >
              Outras informações
            </Typography>
            <hr className={style.titleDivisor} />
          </Grid>
          <Grid xs={12} lg={6}>
            <TextField
              fullWidth
              disabled
              required
              error={fieldsError.fproposal}
              label="Potência a ser instalada (kW)"
              variant="outlined"
              value={applyStrNumberMask(project.potencia)}
              InputProps={{
                endAdornment: <InputAdornment position="end">kW</InputAdornment>,
              }}
              InputLabelProps={{
                shrink: true,
              }}
            />
          </Grid>
          <Grid xs={12} lg={6}>
            <TextField
              disabled
              fullWidth
              required
              label="Custo do kWp"
              variant="outlined"
              value={calcKWPCost(convertToNumber(project.valorTotal), project.potencia).brl}
              InputProps={{
                startAdornment: <InputAdornment position="start">R$</InputAdornment>,
              }}
              InputLabelProps={{
                shrink: true,
              }}
            />
          </Grid>
        </Grid>
        <Grid xs={12} lg={6} container spacing={2} rowSpacing={1}>
          <Grid xs={12}>
            <Typography sx={{
              fontSize: {
                xs: '16px',
                md: '24px',
              },
              fontFamily: 'Poppins',
              color: '#EB5A12',
              fontWeight: '700',
            }}
            >
              Documentação
            </Typography>
            <hr className={style.titleDivisor} />
          </Grid>
          <Grid xs={12} lg={6}>
            <TextField
              label="Proposta comercial feita pelo cliente"
              fullWidth
              required
              error={fieldsError.fproposal}
              value={isValidUrl(customer.proposal) ? 'proposta.pdf' : handleFileName(customer.proposal)}
              InputProps={{
                readOnly: true,
                endAdornment: (
                  <InputAdornment position="end">
                    {!isValidUrl(customer.proposal) && !isDisabled && (
                      <>
                        {
                          customer.proposal && (
                            <IconButton component="label" onClick={handleChangeCustomer('proposal')}>
                              <ClearIcon />
                            </IconButton>
                          )
                        }
                        <IconButton component="label" disabled={isDisabled}>
                          <CloudUploadIcon sx={{ color: '#EB5A12' }} />
                          <input
                            type="file"
                            accept=".pdf"
                            hidden
                            onChange={handleChangeCustomer('proposal')}
                          />
                        </IconButton>
                      </>
                    )}
                    {isValidUrl(customer.proposal) && (
                      <IconButton
                        onClick={() => (
                          typeof customer.proposal === 'string' ? openPdf(customer.proposal) : {})}
                      >
                        <FileOpenRoundedIcon sx={{ color: '#EB5A12' }} />
                      </IconButton>
                    )}
                  </InputAdornment>
                ),
              }}
              InputLabelProps={{
                shrink: !!customer.proposal,
              }}
              variant="outlined"
              disabled
            />
          </Grid>
          <Grid xs={12} lg={6}>
            <TextField
              required
              error={fieldsError.fidentidade}
              label={isValidUrl(customer.documentoIdentificacao) ? '' : 'CNH/RG'}
              fullWidth
              value={isValidUrl(customer.documentoIdentificacao)
                ? 'documento_de_identidade.pdf' : handleFileName(customer.documentoIdentificacao)}
              InputProps={{
                readOnly: true,
                endAdornment: (
                  <InputAdornment position="end">
                    {((!isValidUrl(customer.documentoIdentificacao) && !isDisabled)
                        || project.status === 'Documentos recusados')
                      && (
                        <>
                          {customer.documentoIdentificacao && customer.documentoIdentificacao !== 'Sem documentos' && (
                            <IconButton component="label" onClick={handleChangeCustomer('documentoIdentificacao')}>
                              <ClearIcon />
                            </IconButton>
                          )}
                          <IconButton component="label">
                            <CloudUploadIcon sx={{ color: '#EB5A12' }} />
                            <input
                              type="file"
                              accept=".pdf"
                              hidden
                              onChange={handleChangeCustomer('documentoIdentificacao')}
                            />
                          </IconButton>
                        </>
                      )}
                    {isValidUrl(customer.documentoIdentificacao) && (
                      <IconButton
                        onClick={() => (
                          typeof customer.documentoIdentificacao === 'string'
                            ? openPdf(customer.documentoIdentificacao) : {})}
                      >
                        <FileOpenRoundedIcon sx={{ color: '#EB5A12' }} />
                      </IconButton>
                    )}
                  </InputAdornment>
                ),
              }}
              InputLabelProps={{
                shrink: !!handleFileName(customer.documentoIdentificacao),
              }}
              variant="outlined"
              disabled
            />
          </Grid>
        </Grid>
        <Grid xs={12}>
          <FormControlLabel
            control={(
              <Checkbox
                disabled={isDisabled}
                defaultChecked={isDisabled}
                onChange={handleToggleTerms('declaration')}
                sx={{
                  color: theme.palette.primary.main,
                  '&.Mui-checked': {
                    color: theme.palette.primary.main,
                  },
                }}
              />
            )}
            label="Declaro para os devidos fins que as informações do meu cliente inseridas por mim estão corretas."
            sx={{ '& .MuiFormControlLabel-label': { fontSize: '16px', userSelect: 'none' } }}
          />
          <FormControlLabel
            control={(
              <Checkbox
                disabled={isDisabled}
                defaultChecked={isDisabled}
                onChange={handleToggleTerms('authorization')}
                sx={{
                  color: theme.palette.primary.main,
                  '&.Mui-checked': {
                    color: theme.palette.primary.main,
                  },
                }}
              />
            )}
            label={(
              <>
                Meu cliente autoriza que seus dados sejam consultados pela Soltech,
                {' '}
                inclusive para fins de validação junto a órgãos de proteção de crédito
                {' '}
                conforme consta em nossa
                {' '}
                <StyledLink href="/termos">Ficha de autorização do uso de dados</StyledLink>
                .
              </>
            )}
            sx={{ '& .MuiFormControlLabel-label': { fontSize: '16px', userSelect: 'none' } }}
          />
          <FormControlLabel
            control={(
              <Checkbox
                disabled={isDisabled}
                defaultChecked={isDisabled}
                onChange={handleToggleTerms('terms')}
                sx={{
                  color: theme.palette.primary.main,
                  '&.Mui-checked': {
                    color: theme.palette.primary.main,
                  },
                }}
              />
            )}
            label={(
              <>
                Eu e meu cliente concordamos com a
                {' '}
                <StyledLink href="/termos">Política de privacidade</StyledLink>
                {' '}
                e com os
                {' '}
                <StyledLink href="/termos">Termos de uso da Soltech</StyledLink>
                .
              </>
            )}
            sx={{ '& .MuiFormControlLabel-label': { fontSize: '16px', userSelect: 'none' } }}
          />
        </Grid>
      </Grid>
    </>
  );
};
export default ProjectCustomer;
