import LoadingBar, { LoadingBarRef } from 'react-top-loading-bar';
import {
  SyntheticEvent, useContext, useRef, useState,
} from 'react';
import { ToastContainer, toast } from 'react-toastify';
import { Link, useHistory } from 'react-router-dom';
import { parseCookies } from 'nookies';
import { VisibilityOutlined, VisibilityOffOutlined, EmailOutlined } from '@mui/icons-material';
import Grid from '@mui/material/Unstable_Grid2';
import {
  alpha,
  Button,
  Checkbox,
  FormControl,
  FormControlLabel,
  FormHelperText,
  IconButton,
  InputAdornment,
  InputLabel,
  OutlinedInput,
  TextField,
  Typography,
  useTheme,
} from '@mui/material';
import style from './main.module.scss';

import { AuthContext } from '../../hooks/useAuth';
import MediaCard from '../../components/MediaCard';

export function Login() {
  const ref = useRef<LoadingBarRef>(null);

  const history = useHistory();
  const theme = useTheme();

  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [passwordShown, setPasswordShown] = useState(false);
  const [errorField, setErrorField] = useState(false);
  const emailRegex = /^[a-zA-Z0-9.!#$%&´*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*\.[a-zA-Z]{2,}$/;
  const { signIn } = useContext(AuthContext);

  // user authenticated already, redirect to respective main page
  const { 'nextauth.roles': accessToken } = parseCookies();

  if (accessToken) {
    if (accessToken.split(',').indexOf('Operador') > -1) {
      history.push('/DashboardOperador');
    } else if (accessToken.split(',').indexOf('Gerente') > -1) {
      history.push('/DashboardGerente');
    } else if (accessToken.split(',').indexOf('Integradora') > -1) {
      history.push('/DashboardIntegrator');
    } else if (accessToken.split(',').indexOf('Vendedor') > -1) {
      history.push('/DashboardVendedor');
    } else if (accessToken.split(',').indexOf('Admin') > -1) {
      history.push('/ListaOperador');
    } else if (accessToken.split(',').indexOf('Operador da Distribuidora') > -1) {
      history.push('/DashboardBoldOperator');
    }
  }

  const handleSubmit = async (event: SyntheticEvent) => {
    event.preventDefault();

    ref!.current!.continuousStart(0, 200);

    const validateEmail = (e: string) => e.length > 8;

    const validatePassword = (p: string) => p.length >= 6;

    const validateForm = () => {
      if (!validatePassword(password)) {
        toast.error('A senha precisa ter ao menos 6 caracters.');
        setErrorField(true);
        return false;
      }

      if (!validateEmail(email) || !emailRegex.test(email)) {
        toast.error('O campo "e-mail" está formatado incorretamente');
        setErrorField(true);
        return false;
      }

      return true;
    };

    if (validateForm()) {
      const response = await signIn({ email, password });
      const { 'nextauth.roles': loginAccessToken } = parseCookies();

      if (response === 'OK' && loginAccessToken) {
        setErrorField(false);
        if (loginAccessToken.split(',')
          .indexOf('Operador') > -1) {
          history.push('/DashboardOperador');
        } else if (loginAccessToken.split(',')
          .indexOf('Integradora') > -1) {
          history.push('/DashboardIntegrator');
        } else if (loginAccessToken.split(',')
          .indexOf('Gerente') > -1) {
          history.push('/DashboardGerente');
        } else if (loginAccessToken.split(',')
          .indexOf('Admin') > -1) {
          history.push('/ListaOperador');
        } else if (loginAccessToken.split(',')
          .indexOf('Vendedor') > -1) {
          history.push('/DashboardVendedor');
        } else if (loginAccessToken.split(',')
          .indexOf('Operador da Distribuidora') > -1) {
          history.push('/DashboardBoldOperator');
        }
      } else if (response === 'Sem documentos' || response === 'Pendente') {
        // history.push('/DocumentosIntegradora');
        history.push('/DashboardIntegrator');
      } else if (response === 'Recusado') {
        // history.push('/ReenvioDocumentosIntegradora');
        history.push('/DashboardIntegrator');
      } else {
        toast.error('E-mail não cadastrado ou senha incorreta!');
        setErrorField(true);
      }
    } else {
      toast.error('Alguns campos não estão preenchidos corretamente.');
      setErrorField(true);
    }
  };
  // Password toggle handler
  const togglePassword = () => {
    // When the handler is invoked
    // inverse the boolean state of passwordShown
    setPasswordShown(!passwordShown);
  };

  return (
    <div>
      <LoadingBar color={theme.palette.primary.main} ref={ref} />
      <MediaCard smallCard>
        <div>
          <form onSubmit={handleSubmit}>
            <h5>Entre na sua conta</h5>
            <Grid container spacing={2} rowSpacing={2}>
              <Grid xs={12} sm={12} md={12} lg={12}>
                <TextField
                  id="email"
                  label="E-mail"
                  fullWidth
                  margin="normal"
                  error={errorField}
                  onChange={(e) => setEmail(e.target.value)}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <IconButton>
                          <EmailOutlined />
                        </IconButton>
                      </InputAdornment>
                    ),
                  }}
                />
              </Grid>
              <Grid xs={12} sm={12} md={12} lg={12}>
                <FormControl variant="outlined" fullWidth error={errorField}>
                  <InputLabel htmlFor="outlined-adornment-password">Senha</InputLabel>
                  <OutlinedInput
                    id="outlined-adornment-password"
                    type={passwordShown ? 'text' : 'password'}
                    onChange={(e) => setPassword(e.target.value)}
                    endAdornment={(
                      <InputAdornment position="end">
                        <IconButton
                          aria-label="toggle password visibility"
                          onClick={togglePassword}
                        >
                          {passwordShown ? <VisibilityOutlined /> : <VisibilityOffOutlined />}
                        </IconButton>
                      </InputAdornment>
                    )}
                    label="Senha"
                  />
                  {errorField && <FormHelperText>E-mail ou senha incorretos!</FormHelperText>}
                </FormControl>
              </Grid>
              <Grid xs={12} sm={12} md={12} lg={12}>
                <div className={style.forgotWrapper}>
                  <FormControlLabel
                    control={(
                      <Checkbox
                        defaultChecked
                        sx={{
                          color: theme.palette.primary.main,
                          '&.Mui-checked': {
                            color: theme.palette.primary.main,
                          },
                        }}
                      />
                    )}
                    label="Lembrar-me"
                    sx={{ '& .MuiFormControlLabel-label': { fontSize: '12px' } }}
                  />
                  <Link
                    className={style.link}
                    to="/ChecaEmail"
                  >
                    Esqueceu a senha?
                  </Link>
                </div>
              </Grid>
              <Grid xs={12} sm={12} md={12} lg={12}>
                <Button
                  variant="contained"
                  fullWidth
                  type="submit"
                  sx={{
                    borderRadius: '2rem',
                    backgroundColor: theme.palette.primary.main,
                    marginBottom: '0.5rem',
                    '&:hover': {
                      backgroundColor: alpha(theme.palette.primary.main, 0.8),
                    },
                  }}
                >
                  Entrar
                </Button>
                {/* <Typography sx={{ */}
                {/*   fontSize: { xs: '12px', md: '16px' }, */}
                {/*   fontFamily: 'Poppins', */}
                {/*   textAlign: 'center', */}
                {/* }} */}
                {/* > */}
                {/*   Não possui uma conta? */}
                {/*   {' '} */}
                {/*   <Link className={style.link} to="/CadastroIntegradora"> */}
                {/*     Criar conta */}
                {/*   </Link> */}
                {/* </Typography> */}
              </Grid>
            </Grid>
          </form>
        </div>
      </MediaCard>
    </div>
  );
}
