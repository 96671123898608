import './meusprojetos.module.scss';

import { parseCookies, setCookie } from 'nookies';
import React, { useEffect, useRef, useState } from 'react';
import LoadingBar, { LoadingBarRef } from 'react-top-loading-bar';
import { AxiosResponse } from 'axios';
import { Box, Typography, useTheme } from '@mui/material';
import { api } from '../../services/api';
import style from '../MeusProjetos/meusprojetos.module.scss';
import ProjectsTable from '../../components/ProjectsTable';
import { DialogComponent } from '../../components/DialogComponent';

interface ProjetoData {
  id: number;
  name: string;
  costumer: string;
  status: string;
}

interface ProjetoStatus {
  cadastro: number;
  inativo: number;
  analiseSoltech: number;
  analiseBancos: number;
  escolhaCondicoes: number;
  condicoesEscolhidas: number;
  aprovados: number;
  reprovados: number;
  analiseDocs: number;
  pagamentoNF: number;
  pagamentoDocs: number;
  docRecusados: number;
  contratados: number;
}

export function MeusProjetosIntegradora() {
  const { 'nextauth.tag': tag } = parseCookies();
  const [isOpen, setIsOpen] = useState(false);
  const [loading, setLoading] = useState<Boolean>(false);
  const [loadingText, setLoadingText] = useState('');
  const [projetos, setProjetos] = useState<ProjetoData[]>([]);
  const [projetoQuant, setprojetoQuant] = useState<ProjetoStatus>({
    cadastro: 0,
    inativo: 0,
    analiseSoltech: 0,
    analiseBancos: 0,
    escolhaCondicoes: 0,
    condicoesEscolhidas: 0,
    aprovados: 0,
    reprovados: 0,
    pagamentoNF: 0,
    analiseDocs: 0,
    pagamentoDocs: 0,
    docRecusados: 0,
    contratados: 0,
  });

  const theme = useTheme();
  const ref = useRef<LoadingBarRef>(null);

  useEffect(() => {
    const getProjetos = async () => {
      setLoading(true);
      const data = {
        tag: parseInt(tag, 10),
      };
      setCookie(undefined, 'nextauth.p', '0', {
        maxAge: 60 * 60 * 24 * 30, // 30 days,
        path: '/',
        sameSite: 'none',
        secure: true,
      });
      const response: Promise<AxiosResponse<any, any>> = api.post('project/integrator/get', data);
      response.then((resolved) => {
        const items: ProjetoData[] = resolved.data;
        setProjetos(items);
        setLoading(false);
        return items;
      });
    };

    const getProjectNumbers = async () => {
      const data = {
        tag: parseInt(tag, 10),
      };
      const response: Promise<AxiosResponse<any, any>> = api.post(
        'project/integrator/numbers/get',
        data,
      );
      response.then((resolved) => {
        setprojetoQuant(resolved.data);
      });
    };

    getProjectNumbers();
    getProjetos();
  }, [tag]);

  const cardStyle = {
    backgroundColor: '#fff',
    boxShadow: '0px 2px 2px 0px #0000001A',
    borderRadius: '8px',
    boxSizing: 'border-box',
    padding: '1rem',
    minHeight: '82vh',
  };

  return (
    <Box sx={cardStyle}>
      <LoadingBar color={theme.palette.primary.main} ref={ref} />
      <Typography sx={{
        fontSize: { xs: '16px', md: '24px' },
        fontFamily: 'Poppins',
        color: theme.palette.primary.main,
        fontWeight: '700',
      }}
      >
        Projetos da Integradora
      </Typography>
      <hr className={style.divisor} />
      <ProjectsTable projects={projetos} projectsQt={projetoQuant} />
      <DialogComponent isOpen={isOpen} labelText={loadingText} />
    </Box>
  );
}
