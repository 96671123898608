import React, { useEffect, useState } from 'react';
import {
  Box,
  Typography,
  useMediaQuery,
  useTheme,
} from '@mui/material';
import Grid from '@mui/material/Unstable_Grid2';
import { AxiosResponse } from 'axios';
import { parseCookies } from 'nookies';
import EmailRoundedIcon from '@mui/icons-material/EmailRounded';
import { api } from '../../services/api';
import Donut, { defaultDonutData, DonutData } from '../../components/DashboardCharts/Donut';
import { DialogComponent } from '../../components/DialogComponent';
import { applyCurrencyMask } from '../../utils/util';
import OpBarCharts, {
  defaultOpBarChartsData,
  OpBarChartsData,
} from '../../components/DashboardCharts/OpBarCharts';
import BottomCharts, {
  BottomCardsProps,
  defaultBottomCardsProps,
} from '../../components/DashboardCharts/BottomCharts';
import Divider from '../../components/elements/Divider';

interface OperadorData {
  name: string;
  email: string
}

function DashboardBoldOperator() {
  const [operatorData, setOperatorData] = useState<OperadorData>();
  const [donutData, setDonutData] = useState<DonutData>(defaultDonutData);
  const [barChartsData, setBarChartsData] = useState<OpBarChartsData>(defaultOpBarChartsData);
  const [bottomCardsData, setBottomCardsData] = useState<BottomCardsProps>(defaultBottomCardsProps);
  const [isOpen, setIsOpen] = useState(false);
  const loadingText = 'Carregando dados...';
  const { 'nextauth.tag': tag } = parseCookies();

  useEffect(() => {
    const getOperatorData = async () => {
      const response: Promise<AxiosResponse<any, any>> = api.post(
        '/user/get',
        { userId: Number(tag) },
      );
      response.then((resolved) => {
        const { data } = resolved;
        setOperatorData(data);
      });
    };
    getOperatorData();
    // If Use-effect is changed run without the disabled eslint to check
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [tag]);

  useEffect(() => {
    const getProjectData = async () => {
      setIsOpen(true);
      const response: Promise<AxiosResponse<any, any>> = api.post(
        'user/operator/data/get',
        { opId: tag },
      );
      response.then((e) => {
        const { data } = e;

        const donutDataRecover: DonutData = {
          projectValue: data.projectsValue,
          totalValueMonth: applyCurrencyMask(data.totalValueMonth),
          totalProjectsMonth: data.totalProjectsMonth,
          monthProjectsDifference: data.monthProjectsDifference,
          monthDifference: applyCurrencyMask(data.monthDifference),
        };
        setDonutData(donutDataRecover);

        const barChartsDataRecover: OpBarChartsData = {
          lastProjects: data.lastProjects,
          projectsComparation: data.projectsComparation.projectsPass,
          projectsByUserMonth: data.projectsByUserMonth,
          projectsByUserMonthMinor: data.projectsByUserMonthMinor,
          actived: data.integrators.actived,
          noDocumentation: data.integrators.noDocumentation,
        };
        setBarChartsData(barChartsDataRecover);

        const cardsDataRecover = {
          totalProjects: data.totalProjects,
          approvedFinancialProjectsMonth: data.approvedFinancialProjectsMonth,
          totalProjectsValue: data.totalProjectsValue,
          totalAprovedProjectsValue: data.projectsValue.contratados,
          totalPower: data.totalPower,
          totalAprovePower: data.totalAprovePower,
        };
        setBottomCardsData(cardsDataRecover);

        setIsOpen(false);
      });
    };
    getProjectData();
  }, [tag]);

  const theme = useTheme();
  const isMobileScreen = useMediaQuery(theme.breakpoints.down('sm'));

  return (
    <>
      <Grid
        container
        spacing={2}
        rowSpacing={2}
      >
        <Grid xs={12}>
          <Box sx={{
            display: 'flex',
            justifyContent: 'space-between',
            flexDirection: { xs: 'column', md: 'row' },
            gap: { md: '4rem' },
          }}
          >
            <h5>
              {operatorData?.name}
            </h5>
            <Box sx={{ display: 'flex', gap: '0.5rem' }}>
              <EmailRoundedIcon sx={{ color: theme.palette.primary.main }} />
              <Typography sx={{
                fontFamily: 'Poppins', fontSize: { xs: '12px', md: '16px' }, color: '#6F7481',
              }}
              >
                {
                  operatorData?.email
                }
              </Typography>
            </Box>
          </Box>
          {!isMobileScreen && <Divider variant="primary" />}
        </Grid>
        <Grid container sx={{ gap: { xs: '1rem', lg: '0' } }}>
          <Grid
            container
            xs={12}
            lg={7.99}
            sx={{
              display: 'flex',
              flexDirection: 'column',
              alignContent: 'space-between',
            }}
            order={{ xs: 2, lg: 1 }}
          >
            <Grid
              xs={12}
              order={{ xs: 2, lg: 1 }}
              sx={{
                height: '100%',
                pt: 0,
                pb: 0,
              }}
            >
              <OpBarCharts opBarChartsData={barChartsData} />
            </Grid>
          </Grid>
          <Grid container xs={12} lg={4} order={{ xs: 1, lg: 2 }}>
            <Donut donutData={donutData} />
          </Grid>
          <Grid xs={12} order={{ xs: 3, lg: 3 }}>
            <BottomCharts bottomCardsData={bottomCardsData} />
          </Grid>
        </Grid>
      </Grid>
      <DialogComponent isOpen={isOpen} labelText={loadingText} />
    </>
  );
}
export default DashboardBoldOperator;
