import {
  BrowserRouter, Redirect, Route, Switch,
} from 'react-router-dom';
import React from 'react';
import { Login } from './pages/Login';
import { CadastroIntegradora } from './pages/CadastroIntegradora';
import { MeusProjetos } from './pages/MeusProjetos';
import { CadastroVendedor } from './pages/CadastroVendedor';
import { CadastroOperador } from './pages/CadastroOperador';
import { IntegratorsList } from './pages/ListaIntegradoras';
import './styles/global.scss';
import { PrivateRoute } from './components/PrivateRoute';
import { CadastroCliente } from './pages/CadastroCliente';
import { NovoProjeto } from './pages/NovoProjeto';
import { AnaliseCredito } from './pages/AnaliseCredito';
import { MeusProjetosIntegradora } from './pages/MeusProjetosIntegradora';
import { DetalheCadastroOld } from './pages/DetalheCadastroOld';
import { Debito } from './pages/Debito';
import { TrocaSenha } from './pages/TrocaSenha';
import { ListaEnvioCotacoes } from './pages/ListaEnvioCotacoes';
import { DetalheCotacaoOld } from './pages/DetalheCotacaoOld';
import { ChecaEmail } from './pages/ChecaEmail';
import { ListaVendedor } from './pages/ListaVendedor';
import { DocumentosIntegradora } from './pages/DocumentosIntegradora';
import { ListaOperador } from './pages/ListaOperador';
import { ListaGerente } from './pages/ListaGerente';
import { CadastroGerente } from './pages/CadastroGerente';
import { LogsSistema } from './pages/LogsSistema';
import { CadastroBanco } from './pages/CadastroBanco';
import { ListaBancos } from './pages/ListaBancos';
import { ListaChamados } from './pages/ListaChamados';
import { AbrirChamado } from './pages/AbrirChamado';
import { AvaliarChamado } from './pages/AvaliarChamado';
import { EditarBanco } from './pages/EditarBanco';
import { DetalheChamado } from './pages/DetalheChamado';
import { ReenvioDocumentosIntegradora } from './pages/ReenvioDocumentosIntegradora';
import DashboardVendedor from './pages/DashboardVendedor';
import DashboardIntegrator from './pages/DashboardIntegrator';
import DashboardOperador from './pages/DashboardOperador';
import DashboardGerente from './pages/DashboardGerente';
import { Contrato } from './pages/Contrato';
import UserProfile from './pages/UserProfile';
import { CadastroIntegradoraFinalizado } from './pages/CadastroIntegradoraFinalizado';
import { CobrancaEmConta } from './pages/CobrançaEmConta';
import { RelatorioVendas } from './pages/RelatorioVendas';
import { ListaCobrancas } from './pages/ListaCobrancas';
import { EditarCobranca } from './pages/EditarCobranca';
import { RelatorioVendasAnual } from './pages/RelatorioVendasAnual';
import { MainComponent } from './components/MainComponent';
import { DetalheCadastro } from './pages/DetalheCadastro';
import { DetalheCotacao } from './pages/DetalheCotacao';
import DashboardBoldOperator from './pages/DashboardBoldOperator';

// {loggedIn ? <Redirect to="/dashboard" /> : <PublicHomePage />}

function App() {
  return (
    <BrowserRouter>
      <Switch>
        <Route
          path="/Login"
          exact
          component={Login}
        />
        <Route
          exact
          path="/"
        >
          <Redirect to="/Login" />
        </Route>
        <Route
          path="/ChecaEmail"
          exact
          component={ChecaEmail}
        />
        <Route
          path="/TrocaSenha"
          exact
          component={TrocaSenha}
        />
        <MainComponent>
          <PrivateRoute
            path="/CadastroIntegradora"
            exact
            component={CadastroIntegradora}
          />
          <PrivateRoute
            path="/CadastroOperador"
            exact
            component={CadastroOperador}
          />
          <PrivateRoute
            path="/CadastroGerente"
            exact
            component={CadastroGerente}
          />
          <PrivateRoute
            path="/MeusProjetos"
            exact
            component={MeusProjetos}
          />
          <PrivateRoute
            path="/NovoProjeto"
            exact
            component={NovoProjeto}
          />
          <PrivateRoute
            path="/DetalheCotacaoOld"
            exact
            component={DetalheCotacaoOld}
          />
          <PrivateRoute
            path="/DetalheCotacao"
            exact
            component={DetalheCotacao}
          />
          <PrivateRoute
            path="/CadastroCliente"
            exact
            component={CadastroCliente}
          />
          <PrivateRoute
            path="/AnaliseCredito"
            exact
            component={AnaliseCredito}
          />
          <PrivateRoute
            path="/CadastroVendedor"
            exact
            component={CadastroVendedor}
          />
          <PrivateRoute
            path="/ListaIntegradoras"
            exact
            component={IntegratorsList}
          />
          <PrivateRoute
            path="/DetalheCadastroOld"
            exact
            component={DetalheCadastroOld}
          />
          <PrivateRoute
            path="/DetalheCadastro"
            exact
            component={DetalheCadastro}
          />
          <PrivateRoute
            path="/MeusProjetosIntegradora"
            exact
            component={MeusProjetosIntegradora}
          />
          <PrivateRoute
            path="/ListaEnvioCotacoes"
            exact
            component={ListaEnvioCotacoes}
          />
          <PrivateRoute
            path="/ListaVendedor"
            exact
            component={ListaVendedor}
          />
          <PrivateRoute
            path="/ListaOperador"
            exact
            component={ListaOperador}
          />
          <PrivateRoute
            path="/ListaGerente"
            exact
            component={ListaGerente}
          />
          <PrivateRoute
            path="/DocumentosIntegradora"
            exact
            component={DocumentosIntegradora}
          />
          <PrivateRoute
            path="/ReenvioDocumentosIntegradora"
            exact
            component={ReenvioDocumentosIntegradora}
          />
          <PrivateRoute
            path="/LogsSistema"
            exact
            component={LogsSistema}
          />
          <PrivateRoute
            path="/CadastroBanco"
            exact
            component={CadastroBanco}
          />
          <PrivateRoute
            path="/ListaBancos"
            exact
            component={ListaBancos}
          />
          <PrivateRoute
            path="/ListaChamados"
            exact
            component={ListaChamados}
          />
          <PrivateRoute
            path="/AbrirChamado"
            exact
            component={AbrirChamado}
          />
          <PrivateRoute
            path="/AvaliarChamado"
            exact
            component={AvaliarChamado}
          />
          <PrivateRoute
            path="/DetalheChamado"
            exact
            component={DetalheChamado}
          />
          <PrivateRoute
            path="/EditarBanco"
            exact
            component={EditarBanco}
          />
          <PrivateRoute
            path="/DashboardVendedor"
            exact
            component={DashboardVendedor}
          />
          <PrivateRoute
            path="/DashboardIntegrator"
            exact
            component={DashboardIntegrator}
          />
          <PrivateRoute
            path="/DashboardOperador"
            exact
            component={DashboardOperador}
          />
          <PrivateRoute
            path="/DashboardBoldOperator"
            exact
            component={DashboardBoldOperator}
          />
          <PrivateRoute
            path="/DashboardGerente"
            exact
            component={DashboardGerente}
          />
          <PrivateRoute
            path="/Debito"
            exact
            component={Debito}
          />
          <PrivateRoute
            path="/Contrato"
            exact
            component={Contrato}
          />
          <PrivateRoute
            path="/UserProfile"
            exact
            component={UserProfile}
          />
          <PrivateRoute
            path="/Debito"
            exact
            component={Debito}
          />
          <PrivateRoute
            path="/CobrancaEmConta"
            exact
            component={CobrancaEmConta}
          />
          <PrivateRoute
            path="/RelatorioVendas"
            exact
            component={RelatorioVendas}
          />
          <PrivateRoute
            path="/RelatorioVendasAnual"
            exact
            component={RelatorioVendasAnual}
          />
          <PrivateRoute
            path="/ListaCobrancas"
            exact
            component={ListaCobrancas}
          />
          <PrivateRoute
            path="/EditarCobranca"
            exact
            component={EditarCobranca}
          />
          <Route
            path="/CadastroIntegradoraFinalizado"
            exact
            component={CadastroIntegradoraFinalizado}
          />
        </MainComponent>
        <Route path="/TrocaSenha" exact component={TrocaSenha} />
      </Switch>
    </BrowserRouter>
  );
}

export default App;
