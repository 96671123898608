import { parseCookies } from 'nookies';
import {
  Box, IconButton, Link, Stack, Typography, useTheme,
} from '@mui/material';
import MenuRoundedIcon from '@mui/icons-material/MenuRounded';
import style from './header.module.scss';
import logo from '../../../assets/images/bold_financiamentos_blue_logo.png';
import { BotaoUsuario } from '../../BotaoUsuario';

interface HeaderProps {
  mobileMenuOpen: boolean
  setMobileMenuOpen: Function
  isMobileScreen: boolean
}

const Header = (props: HeaderProps) => {
  const {
    mobileMenuOpen,
    setMobileMenuOpen,
    isMobileScreen,
  }: HeaderProps = props;

  const theme = useTheme();

  const { 'nextauth.roles': cookieRoles } = parseCookies();
  const { 'nextauth.corpStatus': corpStatus } = parseCookies();
  const linkStyle = {
    color: '#000',
    '&:hover': {
      color: '#1976d2',
    },
  };

  return (
    <div className={style.header}>
      <Link href="/">
        <img className={style.logo} src={logo} alt="bold logo" />
      </Link>
      { !isMobileScreen && (
        <Box sx={{
          display: 'flex',
          gap: '2rem',
        }}
        >
          {!(corpStatus !== 'Sem documentos' && corpStatus !== 'Pendente')
            ? (
              <Link href="/DocumentosIntegradora" underline="hover" sx={linkStyle}>
                Início
              </Link>
            )
            : (
              <Link href="/" underline="hover" sx={linkStyle}>
                Início
              </Link>
            )}
          <Link
            href="https://www.boldenergy.com.br/"
            underline="hover"
            rel="noopener"
            target="_blank"
            sx={linkStyle}
          >
            Institucional
          </Link>
          {/* <Link */}
          {/*   href="https://usesoltech.com.br/sou-integrador/" */}
          {/*   underline="hover" */}
          {/*   rel="noopener" */}
          {/*   target="_blank" */}
          {/*   sx={linkStyle} */}
          {/* > */}
          {/*   Integradoras */}
          {/* </Link> */}
        </Box>
      )}
      {cookieRoles && !isMobileScreen && <BotaoUsuario />}
      {isMobileScreen && (
      <IconButton>
        <MenuRoundedIcon onClick={() => setMobileMenuOpen(!mobileMenuOpen)} />
      </IconButton>
      )}
    </div>
  );
};

export { Header };
