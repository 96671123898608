import { ReactNode, useState } from 'react';
import { useMediaQuery, useTheme } from '@mui/material';
import { parseCookies } from 'nookies';
import { Header } from './Header';
import style from './mainComponent.module.scss';
import { Menu } from './Menu';

interface Props {
  children: ReactNode;
}

const MainComponent = ({ children }: Props) => {
  const { 'nextauth.corpStatus': corpStatus } = parseCookies();
  const theme = useTheme();
  const isMobileScreen = useMediaQuery(theme.breakpoints.down('lg'));
  const [mobileMenuOpen, setMobileMenuOpen] = useState(false);

  let menuDisplayStyle;
  if (isMobileScreen) {
    menuDisplayStyle = mobileMenuOpen ? 'inline-flex' : 'none';
  } else {
    menuDisplayStyle = 'inline-flex';
  }

  return (
    <div className={style.mainContainer}>
      <div className={style.header}>
        <Header
          isMobileScreen={isMobileScreen}
          mobileMenuOpen={mobileMenuOpen}
          setMobileMenuOpen={setMobileMenuOpen}
        />
      </div>
      {/* {corpStatus !== 'Sem documentos' && corpStatus !== 'Pendente' && ( */}

      <div className={style.menu} style={{ display: menuDisplayStyle }}>
        <Menu
          isMobileScreen={isMobileScreen}
          mobileMenuOpen={mobileMenuOpen}
          setMobileMenuOpen={setMobileMenuOpen}
        />
      </div>

      {/* )} */}
      <div id="ref" className={style.page} style={{ display: mobileMenuOpen && isMobileScreen ? 'none' : '' }}>
        {children}
      </div>
    </div>
  );
};
export { MainComponent };
