import React, {
  ChangeEvent, useRef, useState,
} from 'react';
import Grid from '@mui/material/Unstable_Grid2';
import {
  TableCell,
  TableHead,
  Paper,
  TableContainer,
  TableRow,
  TableBody,
  Table,
  TablePagination,
  Typography,
  Box, useTheme, alpha,
} from '@mui/material';
import { parseCookies, setCookie } from 'nookies';
import { useHistory } from 'react-router-dom';
import LoadingBar, { LoadingBarRef } from 'react-top-loading-bar';
import Button from '@mui/material/Button';
import style from '../../pages/MeusProjetos/meusprojetos.module.scss';
import { ProjetoStatus } from '../../pages/MeusProjetos';
import { projectStatusList } from '../../utils/Status';

interface Project {
  id: number,
  costumer: string,
  name: string,
  status: string,
}

interface ProjectsTableProps {
  projects: Project[]
  projectsQt: ProjetoStatus
}
interface Column {
  id: 'code' | 'project' | 'client' | 'status'| 'actions'
  label: string
  minWidth?: number
  align?: 'right' | 'center' | 'left'
  format?: (value: number) => string
}

const ProjectsTable = (props: ProjectsTableProps) => {
  const {
    projects,
    projectsQt,
  }: ProjectsTableProps = props;
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const history = useHistory();
  const theme = useTheme();
  const { 'nextauth.roles': cookieRoles } = parseCookies();

  const removeInativo = (value: string, index: number, arr: Array<any>) => {
    if (value === 'Inativo') {
      arr.splice(index, 1);
      return true;
    }
    return false;
  };
  const getActiveProjects = (arr: Array<string>) => {
    const arrayCopy = JSON.parse(JSON.stringify(arr));
    arrayCopy.filter(removeInativo);
    return arrayCopy;
  };
  const [filterText, setFilterText] = React
    .useState<string[]>(getActiveProjects(projectStatusList));
  const filteredItems = projects.filter((item) => item.status && filterText?.includes(item.status));

  const handleChangePage = (event: unknown, newPage: number) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event: ChangeEvent<HTMLInputElement>) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  const columns: readonly Column[] = [
    { id: 'code', label: 'Código', align: 'left' },
    { id: 'project', label: 'Projeto', align: 'left' },
    { id: 'client', label: 'Cliente', align: 'left' },
    { id: 'status', label: 'Status', align: 'left' },
    { id: 'actions', label: '', align: 'left' },
  ];

  const handleClickDetails = (row: Project) => {
    setCookie(undefined, 'nextauth.p', String(row.id), {
      maxAge: 60 * 60 * 24 * 30, // 30 days,
      secure: true,
    });

    history.push({
      pathname: '/NovoProjeto',
    });
  };

  const ref = useRef<LoadingBarRef>(null);
  const handleNew = async () => {
    ref!.current!.continuousStart(0, 200);
    history.push('/NovoProjeto');
    ref!.current!.complete();
  };
  const activeProjectsNumber = () => {
    if (projects && projects.length > 0) {
      const arrayCopy = JSON.parse(JSON.stringify(projects));
      if (cookieRoles === 'Vendedor') {
        arrayCopy.pop();
      }
      const numbers = arrayCopy.filter((item: { status: string; }) => item.status !== 'Inativo');
      return numbers.length;
    }
    return 0;
  };
  const pagamentoStatusNumber = () => {
    let count = 0;
    if (projectsQt?.pagamentoNF) {
      count += projectsQt.pagamentoNF;
    }
    if (projectsQt?.pagamentoDocs) {
      count += projectsQt.pagamentoDocs;
    }
    if (projectsQt?.analiseDocs) {
      count += projectsQt.analiseDocs;
    }

    return count;
  };

  const filterCardStyle = {
    padding: '0.5rem 2rem 0.5rem 1rem',
    height: '4rem',
    backgroundColor: '#fff',
    color: '#000',
    cursor: 'pointer',
    transitionDuration: '0.1s',
    justifyContent: 'space-between',
  };

  const cardTextStyle = {
    fontSize: '12px',
    textAlign: 'left',
    fontWeight: '700',
    fontFamily: 'Poppins',
  };

  const counterStyle = {
    display: 'flex',
    height: '100%',
    gap: '1rem',
    alignItems: 'center',
  };
  const filterCard = (title: string, filter: string[], counter: Function | number) => {
    const clickedCardTest = (filterText[0] === filter[0] && filterText.length < 3 && title !== 'Ativos') || (filterText.length > 2 && title === 'Ativos');

    return (
      <Button
        sx={{
          ...filterCardStyle,
          boxShadow: clickedCardTest ? 'black 0.75px 0.75px 3px' : 'lightgray 0.25px 0.25px 3px',
        }}
        fullWidth
        type="button"
        onClick={() => setFilterText(filter)}
      >
        <Typography sx={cardTextStyle}>{title}</Typography>
        <Box sx={counterStyle}>
          <div
            style={{ border: clickedCardTest ? `solid ${theme.palette.primary.main} 1px` : 'solid lightgray 1px' }}
            className={style.filterButtonDivider}
          />
          <Typography sx={cardTextStyle}>{counter}</Typography>
        </Box>
      </Button>
    );
  };

  return (
    <div>
      <LoadingBar color={theme.palette.primary.main} ref={ref} />
      <Box
        sx={{
          backgroundColor: '#F2F5F9',
          padding: '1rem',
          borderRadius: '0.5rem',
        }}
      >
        <Grid
          container
          spacing={2}
          rowSpacing={2}
        >
          <Grid lg={2}>
            {filterCard('Ativos', getActiveProjects(projectStatusList), activeProjectsNumber())}
          </Grid>
          <Grid lg={2}>
            {filterCard('Cadastro', ['Cadastro'], projectsQt?.cadastro)}
          </Grid>
          <Grid lg={2}>
            {filterCard('Inativo', ['Inativo'], projectsQt?.inativo)}
          </Grid>
          <Grid lg={2}>
            {filterCard('Análise - Soltech', ['Análise - Soltech'], projectsQt?.analiseSoltech)}
          </Grid>
          <Grid lg={2}>
            {filterCard('Análise - Bancos', ['Análise - Bancos'], projectsQt?.analiseBancos)}
          </Grid>
          <Grid lg={2}>
            {filterCard('Financiamento recusado', ['Financiamento recusado'], projectsQt?.reprovados)}
          </Grid>
          <Grid lg={2}>
            {filterCard('Financiamento aprovado', ['Financiamento aprovado'], projectsQt?.aprovados)}
          </Grid>
          <Grid lg={2}>
            {filterCard('Aguardando escolha das condições', ['Aguardando escolha das condições'], projectsQt?.escolhaCondicoes)}
          </Grid>
          <Grid lg={2}>
            {filterCard('Processo de pagamento', ['Aguardando documentos', 'Aguardando NF', 'Análise - Documentos'], pagamentoStatusNumber())}
          </Grid>
          <Grid lg={2}>
            {filterCard('Condições escolhidas', ['Condições escolhidas'], projectsQt?.condicoesEscolhidas)}
          </Grid>
          <Grid lg={2}>
            {filterCard('Documentos recusados', ['Documentos recusados'], projectsQt?.docRecusados)}
          </Grid>
          <Grid lg={2}>
            {filterCard('Projeto contratado', ['Projeto contratado'], projectsQt?.contratados)}
          </Grid>
        </Grid>
      </Box>
      <Paper sx={{
        width: '100%',
        overflow: 'hidden',
        boxSizing: 'border-box',
        boxShadow: 'none',
      }}
      >
        <TableContainer sx={{ maxHeight: 490 }}>
          <Table>
            <TableHead>
              <TableRow>
                {columns.map((column) => (
                  <TableCell
                    key={column.id}
                    align={column.align}
                  >
                    {column.label}
                  </TableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              {filteredItems && (
                filteredItems.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                  .map((row: Project) => (
                    <TableRow
                      hover
                      role="checkbox"
                      tabIndex={-1}
                      key={row.id}
                    >
                      <TableCell>
                        {row.id}
                      </TableCell>
                      <TableCell>
                        {row.name}
                      </TableCell>
                      <TableCell>
                        {row.costumer}
                      </TableCell>
                      <TableCell>
                        {row.status}
                      </TableCell>
                      <TableCell align="right" onClick={() => handleClickDetails(row)}>
                        <Typography sx={{
                          fontSize: '12px',
                          fontFamily: 'Poppins',
                          color: theme.palette.primary.main,
                          fontWeight: '700',
                          cursor: 'pointer',
                          userSelect: 'none',
                        }}
                        >
                          Detalhes
                        </Typography>
                      </TableCell>
                    </TableRow>
                  ))
              )}
              {(!filteredItems || filteredItems.length === 0) && (
                <TableRow
                  hover
                  role="checkbox"
                >
                  <TableCell>
                    Nenhum projeto cadastrado!
                  </TableCell>
                  <TableCell />
                  <TableCell />
                  <TableCell />
                  <TableCell />
                </TableRow>
              )}
            </TableBody>
          </Table>
        </TableContainer>
        <Box sx={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
        }}
        >
          <Button
            variant="contained"
            sx={{
              borderRadius: '2rem',
              backgroundColor: theme.palette.primary.main,
              marginBottom: '0.5rem',
              marginTop: '1rem',
              textTransform: 'none',
              '&:hover': {
                backgroundColor: alpha(theme.palette.primary.main, 0.8),
              },
            }}
            onClick={handleNew}
          >
            Adicionar Projeto
          </Button>
          <TablePagination
            rowsPerPageOptions={[10, 25, 100]}
            component="div"
            count={filteredItems.length}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
            labelRowsPerPage="Linhas por página"
          />
        </Box>
      </Paper>
    </div>
  );
};
export default ProjectsTable;
