import React, {
  ChangeEvent, useEffect, useRef, useState,
} from 'react';
import { pdfjs } from 'react-pdf';
import LoadingBar, { LoadingBarRef } from 'react-top-loading-bar';
import { AxiosResponse } from 'axios';
import { useHistory } from 'react-router-dom';
import { ToastContainer, toast } from 'react-toastify';
import { parseCookies } from 'nookies';
import { useTheme } from '@mui/material';
import pdfIcon from '../../assets/images/downloadPDF.png';
import style from './detalhecadastroold.module.scss';
import { FormPage } from '../../components/FormPage';
import { api } from '../../services/api';
import { Baloon } from '../../components/Baloon';
import { LoadingIcon } from '../../components/LoadingIcon';
import { DialogComponent } from '../../components/DialogComponent';

interface GetState {
  id: number;
}

interface IntegratorEval {
  id: number;
  datahora: Date;

  homologaContrato: Boolean;
  homologaCrea: Boolean;
  homologaBalancete: Boolean;
  homologaCrp: Boolean;
  homologaCat: Boolean;

  userHomologadorId: number;

  justificativa: String;
}

interface IntegratorDetalheData {
  id: number;
  name: String;
  cnpj: String;
  phoneNumber: String;
  potencyW: String;
  numWorkers: number;
  dataFundacao: String;
  docCrea: String;
  docBalanco: String;
  docCrp: String;
  docContrato: String;
  docCat: String;
  status: String;
  user: {
    email: String;
  };
  address: {
    street1: String;
    number: number;
    street2: String;
    zip: String;
    city: {
      name: String;
      state: {
        name: String;
      };
    };
  };
}

async function loadPdfToCanvas(canvasRef: any, file: String, pageNumber : number) {
  const pdf = await pdfjs.getDocument(file).promise;

  // Prepare canvas using PDF page dimensions.
  const canvas = canvasRef.current;
  const canvasContext = canvas.getContext('2d');

  const page = await pdf.getPage(pageNumber);
  const viewport = await page.getViewport({ scale: 2.0 });

  canvas.height = viewport.height;
  canvas.width = viewport.width;

  // Render PDF page into canvas context.
  const renderContext = { canvasContext, viewport };
  page.render(renderContext);

  return pdf.numPages;
}

export function DetalheCadastroOld() {
  pdfjs.GlobalWorkerOptions.workerSrc = `https://cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.min.js`;

  const canvasRef = useRef(null);

  enum TipoDocumento {
    Contrato,
    Crea,
    Balancete,
    Crp,
    Cat,
  }

  const [integrator, setIntegrator] = useState<IntegratorDetalheData | null>(
    null,
  );
  const [documentUrl, setDocumentUrl] = useState<string>('');
  const [pageNumber, setPageNumber] = useState<number>(1);
  const [numPages, setNumPages] = useState<number>(0);
  const history = useHistory();
  const theme = useTheme();
  const getIntegratorEvalId = () => {
    const { 'nextauth.p': integratorEvalId } = parseCookies();
    return parseInt(integratorEvalId, 10);
  };
  const [homoContrato, setHomoContrato] = useState<Boolean>(false);
  const [homoCrea, setHomoCrea] = useState<Boolean>(false);
  const [homoBalancete, setHomoBalancete] = useState<Boolean>(false);
  const [homoCrp, setHomoCrp] = useState<Boolean>(false);
  const [homoCat, setHomoCat] = useState<Boolean>(false);
  const [homojustificativa, setHomoJustificativa] = useState<any>('');
  const [isOpen, setIsOpen] = useState(false);
  const [loadingText, setLoadingText] = useState('');
  const validateJustificativa = (m: string) => {
    if (!homoBalancete
    || !homoContrato
    || !homoCat
    || !homoCrea
    || !homoCrp) {
      if (m.length > 0) {
        return true;
      } return false;
    }
    return true;
  };
  const setAttribute = (id: string, attrib: string, value: any) => {
    document.getElementById(id)?.setAttribute(attrib, value);
    document.getElementById(id)?.setAttribute('disabled', 'disabled');
  };
  const ref = useRef<LoadingBarRef>(null);
  const { 'nextauth.tag': accessToken } = parseCookies();

  const state: GetState = history.location.state as GetState;

  useEffect(() => {
    const getIntegrator = async () => {
      const resp: Promise<AxiosResponse<any, any>> = api.post('integrator/get', {
        id: getIntegratorEvalId(),
      });

      resp.then((resolved) => {
        const item: IntegratorDetalheData = resolved.data;
        setIntegrator(item);
        return item;
      });
    };

    const getIntegratorEvaluation = async () => {
      const resp: Promise<AxiosResponse<any, any>> = api.post('integrator/eval/get', {
        id: getIntegratorEvalId(),
      });

      resp.then((resolved) => {
        const item: IntegratorEval = resolved.data;

        const {
          homologaContrato,
          homologaCrea,
          homologaBalancete,
          homologaCrp,
          homologaCat,
          justificativa,
        } = item;

        setHomoContrato(homologaContrato);
        setHomoCrea(homologaCrea);
        setHomoBalancete(homologaBalancete);
        setHomoCrp(homologaCrp);
        setHomoCat(homologaCat);
        setHomoJustificativa(justificativa);
        setAttribute('f_justify', 'value', justificativa);
      });
    };

    getIntegrator();
    getIntegratorEvaluation();
  }, []);

  const getFile = async (urlx: String) => {
    const respo = await api.post('file/get', { data: urlx });

    if (respo.status === 403) {
      toast.error('Não foi possível acessar o arquivo.');
    }
    return respo.data;
  };

  const handleVoltarClick = () => {
    history.goBack();
  };
  const handleJustificativa = (e: ChangeEvent<HTMLInputElement>) => {
    setHomoJustificativa(e.target.value);
  };
  const handleAtualizarClick = async () => {
    if (validateJustificativa(homojustificativa)) {
      setLoadingText('Atualizando integradora...');
      setIsOpen(true);

      const urlv = window.location.href.split('/');
      const urlc = `${urlv[0]}//${urlv[2]}`;

      toast.info('Cadastro enviado para atualização! Por favor, aguarde...');
      const respo = await api.post('integrator/eval/put', {
        data: {
          id: getIntegratorEvalId(),
          homologaBalancete: homoBalancete,
          homologaContrato: homoContrato,
          homologaCrp: homoCrp,
          homologaCrea: homoCrea,
          homologaCat: homoCat,
          justificativa: homojustificativa,
          user: accessToken,
          returnUrl: urlc,
        },
      });

      if (respo.data === 'CREATED_OK') {
        toast.success('Status do cadastro atualizado com sucesso.');
        setIsOpen(false);
        await api.post('user/integrator/put', {
          data: {
            integratorId: integrator?.id,
          },
        });
        ref?.current?.complete();
        history.push({
          pathname: '/ListaIntegradoras',
          // search: `?id=${data.id}`,
        });
      } else {
        setIsOpen(false);
        toast.error(
          `Não foi possível atualizar o status do cadastro.${respo.data}`,

        );
        ref?.current?.complete();
      }
    } else {
      toast.warning('Ne existência de documentos não homologados, é necessário preencher a justificativa!');
    }

    // ref?.current?.complete();
  };

  const handleNextPageClick = async () => {
    if (pageNumber < numPages) {
      setPageNumber(pageNumber + 1);
      loadPdfToCanvas(canvasRef, documentUrl, pageNumber + 1);
    }
  };

  const handlePreviousPageClick = async () => {
    if (pageNumber > 1) {
      setPageNumber(pageNumber - 1);
      loadPdfToCanvas(canvasRef, documentUrl, pageNumber - 1);
    }
  };
  const handleHomoJustificativa = (e: ChangeEvent<HTMLInputElement>) => {
    setHomoJustificativa(e.target.value);
  };

  const handlePdfButtonClick = async (t: TipoDocumento) => {
    ref?.current?.continuousStart(0, 500);

    if (integrator) {
      let docUrl: String;

      switch (t) {
        case TipoDocumento.Contrato:
          docUrl = integrator.docContrato;
          break;
        case TipoDocumento.Cat:
          docUrl = integrator.docCat;
          break;
        case TipoDocumento.Balancete:
          docUrl = integrator.docBalanco;
          break;
        case TipoDocumento.Crea:
          docUrl = integrator.docCrea;
          break;
        case TipoDocumento.Crp:
          docUrl = integrator.docCrp;
          break;
        default:
          docUrl = '';
          break;
      }

      const promise = getFile(docUrl);

      promise.then((resolved) => {
        setPageNumber(1);
        setDocumentUrl(resolved as string);
        const nPages = loadPdfToCanvas(canvasRef, resolved, pageNumber);

        nPages.then((num) => {
          setNumPages(num);
        });

        const element = document.getElementById('canvasPdf');
        element?.scrollIntoView({
          behavior: 'smooth',
          block: 'center',
          inline: 'center',
        });
        ref?.current?.complete();
      });
    }
  };

  // @ts-ignore
  // @ts-ignore
  return (
    <div>
      <LoadingBar color={theme.palette.primary.main} ref={ref} />
      <FormPage>
        <br />
        <form className={style.detalheCadastroSplitter}>
          <span className={style.detalheCadastroSplitterSection}>
            <h1>
              Dados da Integradora #
              {integrator?.id || '--'}
            </h1>
            <div>
              <label>
                Razão Social
                <br />
                {integrator?.name || <LoadingIcon active={!integrator} />}
              </label>
              <label>
                CNPJ
                <br />
                <span className={style.formarea_readonlydata}>
                  {integrator?.cnpj || '--'}
                </span>
              </label>
            </div>
            <hr />
            <div>
              <label>
                Potência instalada
                <br />
                {`${integrator?.potencyW || '--'} KW`}
              </label>
              <label>
                Qtde. de funcionários
                <br />
                {integrator?.numWorkers || '--'}
              </label>
              <label>
                Data da Fundação
                <br />
                {integrator?.dataFundacao || '--'}
              </label>
            </div>
            <h1>Endereço</h1>
            <div>
              <label>
                Rua
                <br />
                {integrator?.address.street1 || '--'}
              </label>
              <label>
                Número
                <br />
                {integrator?.address.number || '--'}
              </label>
              <label>
                Complemento
                <br />
                {integrator?.address.street2 || '--'}
              </label>
              <br />
              <label>
                <b>CEP</b>
                <br />
                {integrator?.address.zip || '--'}
              </label>
              <label>
                Estado
                <br />
                {integrator?.address.city.state.name || '--'}
              </label>
              <label>
                Cidade
                <br />
                {integrator?.address.city.name || '--'}
              </label>
            </div>
            <h1>Contato</h1>
            <div>
              <label>
                Telefone
                <br />
                {integrator?.phoneNumber || '--'}
              </label>
              <br />
              <label>
                E-mail
                <br />
                <a href={`mailto:${integrator?.user.email}`}>
                  {integrator?.user.email || '--'}
                </a>
              </label>
            </div>
            <br />
          </span>
          <span className={style.detalheCadastroSplitterSection}>
            <h1>Documentação</h1>
            <table>
              <tbody>
                <tr>
                  <td className={style.detalheCadastroDescrCelula}>
                    Contrato Social
                  </td>
                  <td className={style.detalheCadastroBtnAbrir}>
                    <button
                      className={style.detalheCadastroBtn}
                      type="button"
                      onClick={() => {
                        handlePdfButtonClick(TipoDocumento.Contrato);
                      }}
                    >
                      <img
                        className={style.detalheCadastroIcon}
                        src={pdfIcon}
                        alt="Abrir documento: Contrato"
                      />
                    </button>
                  </td>
                  <td>
                    <label>
                      <input
                        type="radio"
                        name="radioContrato"
                        checked={homoContrato as boolean}
                        onChange={() => setHomoContrato(true)}
                      />
                      Homologar
                    </label>
                    <label>
                      <input
                        type="radio"
                        checked={!homoContrato as boolean}
                        onChange={() => setHomoContrato(false)}
                        name="radioContrato"
                      />
                      Não homologar
                    </label>
                  </td>
                </tr>
                <tr>
                  <td>Registro no CREA</td>
                  <td className={style.detalheCadastroBtnAbrir}>
                    <button
                      className={style.detalheCadastroBtn}
                      type="button"
                      onClick={() => {
                        handlePdfButtonClick(TipoDocumento.Crea);
                      }}
                    >
                      <img
                        className={style.detalheCadastroIcon}
                        src={pdfIcon}
                        alt="Abrir documento: CREA"
                      />
                    </button>
                  </td>
                  <td>
                    <label>
                      <input
                        onChange={() => setHomoCrea(true)}
                        checked={homoCrea as boolean}
                        type="radio"
                        name="radioCrea"
                      />
                      Homologar
                    </label>
                    <label>
                      <input
                        onChange={() => setHomoCrea(false)}
                        checked={!homoCrea as boolean}
                        type="radio"
                        name="radioCrea"
                      />
                      Não homologar
                    </label>
                  </td>
                </tr>
                <tr>
                  <td>Balanço/Balancete</td>
                  <td className={style.detalheCadastroBtnAbrir}>
                    <button
                      className={style.detalheCadastroBtn}
                      type="button"
                      onClick={() => {
                        handlePdfButtonClick(TipoDocumento.Balancete);
                      }}
                    >
                      <img
                        className={style.detalheCadastroIcon}
                        src={pdfIcon}
                        alt="Abrir documento: Balancete"
                      />
                    </button>
                  </td>
                  <td>
                    <label>
                      <input
                        type="radio"
                        onChange={() => setHomoBalancete(true)}
                        checked={homoBalancete as boolean}
                        name="radioBalancete"
                      />
                      Homologar
                    </label>
                    <label>
                      <input
                        type="radio"
                        onChange={() => setHomoBalancete(false)}
                        checked={!homoBalancete as boolean}
                        name="radioBalancete"
                      />
                      Não homologar
                    </label>
                  </td>
                </tr>
                <tr>
                  <td>CAT da Empresa</td>
                  <td className={style.detalheCadastroBtnAbrir}>
                    <button
                      className={style.detalheCadastroBtn}
                      type="button"
                      onClick={() => {
                        handlePdfButtonClick(TipoDocumento.Cat);
                      }}
                    >
                      <img
                        className={style.detalheCadastroIcon}
                        src={pdfIcon}
                        alt="Abrir documento: CAT"
                      />
                    </button>
                  </td>
                  <td>
                    <label>
                      <input
                        type="radio"
                        onChange={() => setHomoCat(true)}
                        checked={homoCat as boolean}
                        name="radioCAT"
                      />
                      Homologar
                    </label>
                    <label>
                      <input
                        type="radio"
                        onChange={() => setHomoCat(false)}
                        checked={!homoCat as boolean}
                        name="radioCAT"
                      />
                      Não homologar
                    </label>
                  </td>
                </tr>
                <tr>
                  <td>Certidão de Reg. Profissional</td>
                  <td className={style.detalheCadastroBtnAbrir}>
                    <button
                      className={style.detalheCadastroBtn}
                      type="button"
                      onClick={() => {
                        handlePdfButtonClick(TipoDocumento.Crp);
                      }}
                    >
                      <img
                        className={style.detalheCadastroIcon}
                        src={pdfIcon}
                        alt="Abrir documento: CRP"
                      />
                    </button>
                  </td>
                  <td>
                    <label>
                      <input
                        type="radio"
                        onChange={() => setHomoCrp(true)}
                        checked={homoCrp as boolean}
                        name="radioCRP"
                      />
                      Homologar
                    </label>
                    <label>
                      <input
                        type="radio"
                        onChange={() => setHomoCrp(false)}
                        checked={!homoCrp as boolean}
                        name="radioCRP"
                      />
                      Não homologar
                    </label>
                  </td>
                </tr>
              </tbody>
            </table>
            {!(
              homoBalancete
              && homoContrato
              && homoCat
              && homoCrea
              && homoCrp
            ) && (
              <div>
                <h2>Justificativa  </h2>
                <div>
                  <Baloon mode="any">
                    Utilize a caixa de texto abaixo para justificar a alteração de
                    status. Em seguida, pressione &quot;Atualizar&quot;.
                  </Baloon>
                </div>
                <textarea
                  disabled={integrator?.status !== 'Pendente'}
                  value={homojustificativa}
                  className={style.detalheCadastroJustificativa}
                  // @ts-ignore
                  onChange={handleJustificativa}
                />
              </div>
            )}
            <br />
            <br />
            <button
              className={style.detalheCadastroConfirm}
              type="button"
              onClick={handleVoltarClick}
            >
              Voltar
            </button>
            <button
              className={style.detalheCadastroConfirm}
              disabled={integrator?.status !== 'Pendente'}
              type="button"
              onClick={handleAtualizarClick}
            >
              Atualizar
            </button>
          </span>
        </form>
        <form
          style={{ marginTop: '20px' }}
          className={
            documentUrl === ''
              ? style.detalheCadastroSecondFrameDisabled
              : style.detalheCadastroSecondFrame
          }
        >
          <div style={{
            background: '#eee',
            width: '100%',
            padding: '3px',
            color: '#666',
            fontSize: '11px',
          }}
          >
            <button type="button" onClick={handlePreviousPageClick}>Anter.</button>
&nbsp;
            <button type="button" onClick={handleNextPageClick}>Próx.</button>
&nbsp;&nbsp;
            {pageNumber}
            &nbsp;/&nbsp;
            {numPages}
          </div>
          <br />
          <canvas
            ref={canvasRef}
            id="canvasPdf"
            style={{ border: '1px solid #eee', width: '100%' }}
          />
        </form>
        <br />
      </FormPage>
      <DialogComponent isOpen={isOpen} labelText={loadingText} />
    </div>
  );
}
