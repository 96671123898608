import { useHistory } from 'react-router-dom';
import React, { useEffect, useState } from 'react';
import { ToastContainer } from 'react-toastify';
import { AxiosResponse } from 'axios';
import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import TableCell from '@mui/material/TableCell';
import TableBody from '@mui/material/TableBody';
import TableContainer from '@mui/material/TableContainer';
import {
  Autocomplete,
  Box,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  Stack,
  TextField,
  useMediaQuery,
  useTheme,
} from '@mui/material';
import { DialogComponent } from '../../components/DialogComponent';
import { FormPage } from '../../components/FormPage';
import style from './relatoriovendas.module.scss';
import RelatorioVendasTabela from '../../components/RelatorioVendasTabela';
import { api } from '../../services/api';
import { SectionTitle } from '../../components/elements/SectionTitle';

interface Sellers {
  integrator: string,
  countcontratado: number,
  countrecusado: number,
  id: number,
  integratorId: number,
  name: string,
  projects: number,
}

interface Integrator {
  name: string,
  id: number
  sellers: [Sellers]
}

export function RelatorioVendas() {
  const theme = useTheme();
  const isXs = useMediaQuery(theme.breakpoints.only('xs'));
  const [integrator, setIntegrator] = useState<Integrator[]>([]);
  const [interval, setInterval] = useState('1');
  const [isOpen, setIsOpen] = useState(false);
  const [loadingText, setLoadingText] = useState('');

  useEffect(() => {
    setIsOpen(true);
    setLoadingText('Carregando dados...');
    const getTableData = async () => {
      const resp = await api.post('/user/vendor/sales/get', {
        interval,
      });
      const resolved = resp.data;
      setIntegrator(resolved);
      setIsOpen(false);
    };

    getTableData();
  }, [interval]);

  const periodOptions = [
    { value: '1', label: 'Mensal' },
    { value: '6', label: 'Semestral' },
    { value: '12', label: 'Anual' },
  ];

  const handleTipoChange = (e: any) => {
    const newInterval = e.target.value;
    if (newInterval === 0) return;
    setInterval(newInterval);
  };

  // @ts-ignore
  return (
    <>
      <Stack
        direction={isXs ? 'column' : 'row'}
        alignItems="flex-end"
        flexGrow={1}
      >
        <Box
          width="100%"
        >
          <SectionTitle
            title="Relatório de vendas por período"
          />
        </Box>
        <FormControl sx={{ m: 1, minWidth: 300 }}>
          <InputLabel id="demo-simple-select-helper-label">Período</InputLabel>
          <Select
            labelId="demo-simple-select-helper-label"
            id="demo-simple-select-helper"
            value={interval}
            label="Período"
            onChange={handleTipoChange}
          >
            {periodOptions
              .map(({ value, label }) => (
                <MenuItem
                  key={value}
                  value={value}
                >
                  {label}
                </MenuItem>
              ))}
          </Select>
        </FormControl>
      </Stack>
      {integrator.length > 0
        ? <RelatorioVendasTabela integrator={integrator} />
        : (
          <TableContainer sx={{ marginBottom: '45vh' }} component={Paper}>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell sx={{ bgcolor: 'lightsalmon' }} />
                  <TableCell sx={{ bgcolor: 'lightsalmon' }}>Integradoras</TableCell>
                </TableRow>
              </TableHead>
              <TableBody />
            </Table>
          </TableContainer>
        )}
      <DialogComponent isOpen={isOpen} labelText={loadingText} />
    </>
  );
}
