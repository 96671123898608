import './styles/global.scss';
import ReactDOM from 'react-dom';
import { StrictMode } from 'react';
import Pusher from 'pusher-js';
import { ToastContainer } from 'react-toastify';
import App from './App';
import { PusherProvider } from './hooks/PusherContext';
import { AuthProvider } from './hooks/useAuth';
import SystemColors from './themes/SystemColors';

const pusher = new Pusher('71ee94ec2b6be41a3315', {
  cluster: 'sa1',
});

ReactDOM.render(
  <PusherProvider pusher={pusher}>
    <StrictMode>
      <AuthProvider>
        <SystemColors>
          <App />
          <ToastContainer />
        </SystemColors>
      </AuthProvider>
    </StrictMode>
  </PusherProvider>,
  document.getElementById('root'),
);
