import { ReactNode } from 'react';
import {
  alpha,
  Button as MuiButton, ButtonProps as MuiButtonProps, createTheme, ThemeProvider, useTheme,
} from '@mui/material';

interface ButtonProps extends MuiButtonProps {
  children: ReactNode,
  variant: 'contained' | 'outlined',
}

export default function Button(props: ButtonProps) {
  const theme = useTheme();

  const buttonThemes = createTheme({
    components: {
      MuiButton: {
        variants: [
          {
            props: { variant: 'contained' },
            style: {
              width: '10rem',
              borderRadius: '2rem',
              backgroundColor: theme.palette.primary.main,
              '&:hover': {
                backgroundColor: alpha(theme.palette.primary.main, 0.8),
              },
              fontFamily: 'Poppins',
              textTransform: 'none',
              fontWeight: 700,
            },
          },
          {
            props: { variant: 'outlined' },
            style: {
              width: '10rem',
              color: theme.palette.primary.main,
              border: `1px ${theme.palette.primary.main} solid`,
              borderRadius: '2rem',
              backgroundColor: '#fff',
              '&:hover': {
                borderColor: theme.palette.primary.main,
                backgroundColor: alpha(theme.palette.primary.main, 0.2),
              },
              fontFamily: 'Poppins',
              textTransform: 'none',
              fontWeight: 700,
            },
          },
        ],
      },
    },
  });

  const {
    children,
    variant,
    ...other
  }: ButtonProps = props;

  return (
    <ThemeProvider theme={buttonThemes}>
      <MuiButton variant={variant} {...other}>{children}</MuiButton>
    </ThemeProvider>
  );
}
