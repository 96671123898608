import { toast, ToastContainer } from 'react-toastify';
import React, {
  ChangeEvent, SyntheticEvent, useEffect, useState,
} from 'react';
import { useHistory } from 'react-router-dom';
import { parseCookies } from 'nookies';
import { AxiosResponse } from 'axios';
import { Header } from '../../components/Header';
import { Navigator } from '../../components/Navigator';
import { FormPage } from '../../components/FormPage';
import style from './editarcobranca.module.scss';
import { DialogComponent } from '../../components/DialogComponent';
import { Footer } from '../../components/Footer';
import { RequiredFieldMark } from '../../components/RequiredFieldMark';
import { Baloon } from '../../components/Baloon';
import {
  applyCEPMask,
  applyCPFMask,
  applyCurrencyMask,
  applyDateMask, applyTelefoneMask, checkValidDate, emailMask,
  onlyLetters,
  removeNonNumeric_2, searchCEP,
} from '../../utils/util';
import { api } from '../../services/api';

interface GetState {
  id: number;
  empresa: string;
}
interface Cliente {
  id: number
  nome: string,
  cpf: string,
  dataCriacao: Date
  dataNascimento: Date,
  telefone: string,
  email: string,
  enderecoInstalacao: {
    city : {
      name : string,
      state : {
        name: string
      }
    },
    number: number,
    street1: string,
    street2: string,
    zip: string
  },
}
interface Anexos {
  tipoAnexo: string,
  conteudoBase64: string,
  nomeArquivo: string,
  extensao: string
}
interface OrderSale {
  Nome: string,
  Sobrenome: string,
  dtNascimento: any,
  Telefone: string,
  Email: string,
  Endereco: string,
  EnderecoNumero: string,
  Bairro: string,
  Cidade: string,
  CEP: string,
  empresa: string,
  numeroCliente: string,
  CPF: string,
  numParcelas: number,
  valorParcela: number,
  codProduto: string,
  dataOcorrencia: any,
  codCanalVenda: string,
  numeroClienteEmpPar: string,
  anexos: Anexos[]
}

export function EditarCobranca() {
  const history = useHistory();
  const [isOpen, setIsOpen] = useState(false);
  const [loadingText, setLoadingText] = useState('');
  const [isLoading, setIsLoading] = useState(false);

  const [state, setState] = useState<GetState | null>(null);
  const sstate: GetState = history.location.state as GetState;

  const [errorMessage, setErrorMessage] = useState<string[]>([]);
  const [changeCep, setChangeCep] = useState<boolean>(false);
  const [isCepOpen, setIsCepOpen] = useState(false);
  const [orderSale, setOrderSale] = useState<Partial<OrderSale>>({});
  const [anexos, setAnexos] = useState<Anexos[]>([]);

  useEffect(() => {
    setLoadingText('carregando dados...');
    setIsOpen(true);

    const getCliente = async () => {
      const resp: Promise<AxiosResponse<any, any>> = api.post('project/costumer/get', {
        projectId: parseInt(String(sstate.id), 10),
      });
      resp.then((resolved) => {
        if (resolved.data === 'COULD_NOT_FIND_COSTUMER') {
          setIsLoading(true);
        } else {
          const enelxResp: Promise<AxiosResponse<any, any>> = api.post('getSubscriptionHistoryEnelX', {
            userId: resolved.data.id,
          });
          enelxResp.then((resol) => {
          });
        }
        setState(state);
        setIsOpen(false);
        return state;
      });
    };

    getCliente();
  }, [sstate.id, state]);

  useEffect(() => {
    const updateCep = async () => {
      if (orderSale.CEP) {
        setIsCepOpen(true);
        setLoadingText('Procurando CEP');
        const value = await searchCEP(orderSale.CEP);
        if (value.data.erro) {
          toast.warning('Cep Não encontrado!');
          setOrderSale({
            ...orderSale,
            Endereco: '',
            Bairro: '',
            Cidade: '',
          });
        } else {
          setOrderSale({
            ...orderSale,
            Endereco: value.data.rua,
            Bairro: value.data.bairro,
            Cidade: value.data.cidade,
          });
        }
      }
      setIsCepOpen(false);
    };
    updateCep();
  }, [orderSale, changeCep]);

  const handleOrderChange = async (e: ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;

    let newValue = '';
    let nonNum:any;
    switch (name) {
      case 'Nome':
        newValue = onlyLetters(e);
        break;
      case 'Sobrenome':
        newValue = onlyLetters(e);
        break;
      case 'CPF':
        newValue = applyCPFMask(e);
        break;
      case 'valorParcela':
        nonNum = removeNonNumeric_2(value);
        newValue = applyCurrencyMask(nonNum);
        break;
      case 'dtNascimento':
        newValue = applyDateMask(e);
        break;
      case 'dataOcorrencia':
        newValue = applyDateMask(e);
        break;
      case 'CEP':
        newValue = applyCEPMask(e);
        if (newValue.length === 9) {
          setChangeCep(!changeCep);
        }
        break;
      case 'numParcelas':
        newValue = removeNonNumeric_2(value);
        break;
      case 'Telefone':
        newValue = applyTelefoneMask(value);
        break;
      case 'EnderecoNumero':
        newValue = removeNonNumeric_2(value);
        break;
      default:
        newValue = value;
    }

    setOrderSale({
      ...orderSale,
      [name]: newValue,
    });
  };

  const validateNome = (m: string | undefined) => m && m.length >= 5;
  const validateSobrenome = (m: string | undefined) => m && m.length >= 5;
  const validateDataNascimento = (m: string | undefined) => m && checkValidDate(m, false);
  const validateTelefone = (m: string | undefined) => m && (m.length > 13 && m.length < 16);
  const validateEmail = (m: string | undefined) => m && emailMask(m);
  const validateEndereco = (m: string | undefined) => m && m.length >= 5;
  const validateEnderecoNumero = (m: string | undefined) => m;
  const validateBairro = (m: string | undefined) => m;
  const validateCIdade = (m: string | undefined) => m;
  const validateCEP = (m: string | undefined) => m && m.length > 8;
  const validateEmpresa = (m: string | undefined) => m;
  const validateNumeroCliente = (m: string | undefined) => m;
  const validateCPF = (m: string | undefined) => m && m.length > 13;
  const validateNumParcelas = (m: number | undefined) => m && m > 0;
  const validateValorParcela = (m: number | undefined) => m && m.toString().length > 0;
  const validateCodProduto = (m: string | undefined) => m;
  // @ts-ignore
  const validateDataOcorrencia = (m: string | undefined) => checkValidDate(m, true);
  const validateCodCanalVenda = (m: string | undefined) => m;
  const validateNumeroClienteEmpPar = (m: string | undefined) => m;

  const validateForm = () => {
    const messages: any[] = [];

    if (!validateNome(orderSale.Nome)) {
      messages.push('O campo "Nome" está formatado incorretamente');
    }
    if (!validateSobrenome(orderSale.Sobrenome)) {
      messages.push('O campo "Sobrenome" está formatado incorretamente');
    }
    if (!validateDataNascimento(orderSale.dtNascimento)) {
      messages.push('O campo "Data de Nascimento" está formatado incorretamente');
    }
    if (!validateTelefone(orderSale.Telefone)) {
      messages.push('O campo "Telefone" está formatado incorretamente');
    }
    if (!validateEmail(orderSale.Email)) {
      messages.push('O campo "Email" está formatado incorretamente');
    }
    if (!validateEndereco(orderSale.Endereco)) {
      messages.push('O campo "Endereço" está formatado incorretamente');
    }
    if (!validateEnderecoNumero(orderSale.EnderecoNumero)) {
      messages.push('O campo "N°" está formatado incorretamente');
    }
    if (!validateBairro(orderSale.Bairro)) {
      messages.push('O campo "Bairro" está formatado incorretamente');
    }
    if (!validateCIdade(orderSale.Cidade)) {
      messages.push('O campo "Cidade" está formatado incorretamente');
    }
    if (!validateCEP(orderSale.CEP)) {
      messages.push('O campo "CEP" está formatado incorretamente');
    }
    if (!validateEmpresa(orderSale.empresa)) {
      messages.push('O campo "Empresa" está formatado incorretamente');
    }
    if (!validateNumeroCliente(orderSale.numeroCliente)) {
      messages.push('O campo "N° do Cliente" está formatado incorretamente');
    }
    if (!validateCPF(orderSale.CPF)) {
      messages.push('O campo "CPF" está formatado incorretamente');
    }
    if (!validateNumParcelas(orderSale.numParcelas)) {
      messages.push('O campo "N° de Parcelas" está formatado incorretamente');
    }
    if (!validateValorParcela(orderSale.valorParcela)) {
      messages.push('O campo "Valor da Parcela" está formatado incorretamente');
    }
    if (!validateCodProduto(orderSale.codProduto)) {
      messages.push('O campo "Código do Produto" está formatado incorretamente');
    }
    if (!validateDataOcorrencia(orderSale.dataOcorrencia)) {
      messages.push('O campo "Data da Ocorrência" está formatado incorretamente');
    }
    if (!validateCodCanalVenda(orderSale.codCanalVenda)) {
      messages.push('O campo "Código do Canal de Venda" está formatado incorretamente');
    }
    if (!validateNumeroClienteEmpPar(orderSale.numeroClienteEmpPar)) {
      messages.push('O campo "N° do Cliente da Empresa Parceira" está formatado incorretamente');
    }

    setErrorMessage(messages);
    return messages.length === 0;
  };

  // eslint-disable-next-line consistent-return
  const handleSubmit = async (event: SyntheticEvent) => {
    event.preventDefault();

    if (validateForm()) {
      return 1;
    }
  };

  const handleUnsubscribe = async (event: SyntheticEvent) => {
    event.preventDefault();
  };
  return (
    <div>
      <FormPage>
        <form
          style={{ marginTop: '2.5rem', marginBottom: '2.5rem' }}
          className={style.formCobranca}
        >
          <h1>
            Editar Cobrança em Conta do Projeto #
            { sstate.id }
          </h1>
          <div
            style={{ display: 'flex', justifyContent: 'end' }}
            className={style.buttonContainer}
          >
            <input type="submit" id="submit_btn" value="Cancelar cobrança" onClick={handleUnsubscribe} />
          </div>
          <h2>Dados Pessoais</h2>
          <div>
            <label>
              Nome
              <RequiredFieldMark />
              <input
                  // style={{ width: '100%' }}
                value={orderSale.Nome}
                name="Nome"
                type="text"
                onChange={handleOrderChange}
              />
            </label>
            <label>
              Sobrenome
              <RequiredFieldMark />
              <input
                className={style.biggerInput}
                value={orderSale.Sobrenome}
                name="Sobrenome"
                type="text"
                onChange={handleOrderChange}
              />
            </label>
            <label>
              Data de Nascimento
              <input
                value={orderSale.dtNascimento}
                name="dtNascimento"
                type="text"
                onChange={handleOrderChange}
              />
            </label>
            <label>
              CPF
              <RequiredFieldMark />
              <input
                value={orderSale.CPF}
                name="CPF"
                type="text"
                onChange={handleOrderChange}
              />
            </label>
            <label>
              Telefone
              <RequiredFieldMark />
              <input
                value={orderSale.Telefone}
                name="Telefone"
                type="text"
                onChange={handleOrderChange}
              />
            </label>
            <label>
              Email
              <input
                className={style.biggerInput}
                value={orderSale.Email}
                name="Email"
                type="text"
                onChange={handleOrderChange}
              />
            </label>
          </div>
          <h2>Endereço</h2>
          <div>
            <label>
              Cep
              <RequiredFieldMark />
              <input
                value={orderSale.CEP}
                name="CEP"
                type="text"
                onChange={handleOrderChange}
              />
            </label>
            <label>
              Endereco
              <RequiredFieldMark />
              <input
                className={style.biggerInput}
                value={orderSale.Endereco}
                name="Endereco"
                type="text"
                onChange={handleOrderChange}
              />
            </label>
            <label>
              N°
              <RequiredFieldMark />
              <input
                value={orderSale.EnderecoNumero}
                name="EnderecoNumero"
                type="text"
                onChange={handleOrderChange}
              />
            </label>
            <label>
              Bairro
              <RequiredFieldMark />
              <input
                className={style.biggerInput}
                value={orderSale.Bairro}
                name="Bairro"
                type="text"
                onChange={handleOrderChange}
              />
            </label>
            <label>
              Cidade
              <RequiredFieldMark />
              <input
                value={orderSale.Cidade}
                name="Cidade"
                type="text"
                onChange={handleOrderChange}
              />
            </label>
          </div>
          <h2>Dados da Empresa</h2>
          <div>
            <label>
              Empresa
              <RequiredFieldMark />
              <input
                value={orderSale.empresa}
                name="empresa"
                type="text"
                disabled
                onChange={handleOrderChange}
              />
            </label>
            <label>
              N° do cliente
              <RequiredFieldMark />
              <input
                className={style.biggerInput}
                value={orderSale.numeroCliente}
                name="numeroCliente"
                type="text"
                onChange={handleOrderChange}
              />
            </label>
            <label>
              N° de Parcelas
              <RequiredFieldMark />
              <input
                value={orderSale.numParcelas}
                name="numParcelas"
                type="text"
                onChange={handleOrderChange}
              />
            </label>
            <label>
              Valor da Parcela
              <RequiredFieldMark />
              <input
                className={style.biggerInput}
                value={orderSale.valorParcela}
                name="valorParcela"
                type="text"
                onChange={handleOrderChange}
              />
            </label>
            <label>
              Código do Produto
              <RequiredFieldMark />
              <input
                value={orderSale.codProduto}
                name="codProduto"
                type="text"
                onChange={handleOrderChange}
              />
            </label>
            <label>
              Data da ocorrência
              <input
                value={orderSale.dataOcorrencia}
                name="dataOcorrencia"
                type="text"
                onChange={handleOrderChange}
              />
            </label>
            <label>
              Código do Canal de Venda
              <input
                value={orderSale.codCanalVenda}
                name="codCanalVenda"
                type="text"
                onChange={handleOrderChange}
              />
            </label>
            <label>
              N° do Cliente da Empresa Parceira
              <input
                className={style.biggerInput}
                value={orderSale.numeroClienteEmpPar}
                name="numeroClienteEmpPar"
                type="text"
                onChange={handleOrderChange}
              />
            </label>
          </div>
          <br />
          {errorMessage.length > 0 && (
          <div style={{ marginTop: '1.5rem' }}>
            <Baloon mode="warning">
              <ul>
                {errorMessage.map((m) => (
                  <li key={m}>{m}</li>
                ))}
              </ul>
            </Baloon>
          </div>
          )}
          <div
            style={{ display: 'flex', justifyContent: 'end', marginTop: '10px' }}
            className={style.buttonContainer}
          >
            <input type="submit" id="submit_btn" value="Confirmar >" onClick={handleSubmit} />
          </div>
        </form>
      </FormPage>
      <DialogComponent isOpen={isOpen} labelText={loadingText} />
      <DialogComponent isOpen={isCepOpen} labelText={loadingText} />
    </div>
  );
}
