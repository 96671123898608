import { useTheme } from '@mui/material';
import { styled } from '@mui/system';
import React from 'react';

interface DividerProps {
  variant: 'primary' | 'secondary'
}

export default function Divider(props: DividerProps) {
  const theme = useTheme();

  const StyledDivider = styled('hr')(({ variant }: DividerProps) => ({
    border: variant === 'primary' ? `1px solid ${theme.palette.primary.main}` : '1px solid #DBDDE4',
    margin: '0.75rem 0 0.5rem 0',
  }));

  const {
    variant,
  }: DividerProps = props;
  return <StyledDivider variant={variant} />;
}
