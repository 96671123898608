import LoadingBar, { LoadingBarRef } from 'react-top-loading-bar';
import React, { SyntheticEvent, useRef, useState } from 'react';
import { toast, ToastContainer } from 'react-toastify';
import { AxiosResponse } from 'axios';
import {
  alpha,
  IconButton, InputAdornment, TextField, Typography, useTheme,
} from '@mui/material';
import Grid from '@mui/material/Unstable_Grid2';
import ReportGmailerrorredOutlinedIcon from '@mui/icons-material/ReportGmailerrorredOutlined';
import { EmailOutlined } from '@mui/icons-material';
import { Link, useHistory } from 'react-router-dom';
import style from './checaemail.module.scss';
import { api } from '../../services/api';
import { emailMask } from '../../utils/util';
import MediaCard from '../../components/MediaCard';
import Button from '../../components/elements/Button';

export function ChecaEmail() {
  const ref = useRef<LoadingBarRef>(null);
  const theme = useTheme();
  const history = useHistory();

  // Save the current url and format protocol+hostname
  const completeURL = window.location.href.split('/');
  const formatedURL = `${completeURL[0]}//${completeURL[2]}`;

  const [email, setEmail] = useState('');
  const [emailError, setEmailError] = useState(false);

  const handleSubmit = async (event: SyntheticEvent) => {
    event.preventDefault();

    ref!.current!.continuousStart(0, 200);

    const validateEmail = (e: string) => emailMask(e);

    const validateForm = () => {
      if (!validateEmail(email)) {
        setEmailError(true);
        toast.error(
          <div style={{ fontWeight: '8px' }}>
            <strong>Formato incorreto!</strong>
            {' '}
            <br />
            {' '}
            Insira um e-mail válido.
          </div>,
        );
        return false;
      }
      return true;
    };

    if (validateForm()) {
      const resp: Promise<AxiosResponse<any, any>> = api.post('/email/password/send', {
        email,
        returnURL: formatedURL,
      });
      resp.then((resolved) => {
        // eslint-disable-next-line default-case
        switch (resolved.data) {
          case 'COULD_NOT_FIND_EMAIL':
            setEmailError(true);
            toast.error(
              <div style={{ fontWeight: '8px' }}>
                <strong>E-mail não registrado!</strong>
                {' '}
                <br />
                {' '}
                Insira um e-mail válido.
              </div>,
            );
            break;

          case 'EMAIL_SENT':
            setEmailError(false);
            toast.success(
              <div style={{ fontWeight: '8px' }}>
                <strong>E-mail de recuperação enviado com sucesso!</strong>
                {' '}
                <br />
                {' '}
                Verifique o seu e-mail e siga os passos para redefinição.
              </div>,
            );
            break;
        }
      });
    }
    ref!.current!.complete();
  };

  const handleCancel = () => {
    history.goBack();
  };

  return (
    <MediaCard smallCard>
      <LoadingBar color={theme.palette.primary.main} ref={ref} />
      <form onSubmit={handleSubmit}>
        <Grid container spacing={2} rowSpacing={1}>
          <Grid xs={12} sm={12} md={12} lg={12}>
            <h5 style={{ marginBottom: '0.5rem' }}>Esqueceu a sua senha?</h5>
            <Typography sx={{ fontSize: { xs: '12px', md: '16px' }, fontFamily: 'Poppins' }}>Preencha o formulário para solicitar a inclusão de sua empresa como parceira da Soltech</Typography>
          </Grid>
          <Grid xs={12} sm={12} md={12} lg={12}>
            <TextField
              id="email"
              label="E-mail"
              required
              fullWidth
              margin="normal"
              error={emailError}
              onChange={(e) => setEmail(e.target.value)}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton disabled>
                      {emailError
                        ? <ReportGmailerrorredOutlinedIcon style={{ color: '#f44336' }} /> : <EmailOutlined />}
                    </IconButton>
                  </InputAdornment>
                ),
              }}
            />
          </Grid>
          <Grid
            xs={12}
            display="flex"
            gap="1rem"
            justifyContent="flex-end"
            mt="1rem"
          >
            <Button
              variant="outlined"
              fullWidth
              onClick={handleCancel}
            >
              Cancelar
            </Button>
            <Button
              variant="contained"
              fullWidth
              type="submit"
            >
              Enviar
            </Button>
            {/* <Typography sx={{ */}
            {/*   fontSize: { xs: '12px', md: '16px' }, */}
            {/*   fontFamily: 'Poppins', */}
            {/*   textAlign: 'center', */}
            {/* }} */}
            {/* > */}
            {/*   Não possui uma conta? */}
            {/*   {' '} */}
            {/*   <Link className={style.link} to="/CadastroIntegradora"> */}
            {/*     Criar conta */}
            {/*   </Link> */}
            {/* </Typography> */}
          </Grid>
        </Grid>
      </form>
    </MediaCard>
  );
}
