import {
  FormControl, FormControlLabel, FormLabel,
  IconButton, InputAdornment, Radio, RadioGroup, TextField, Typography, useTheme,
} from '@mui/material';
import React from 'react';
import Grid from '@mui/material/Unstable_Grid2';
import ClearIcon from '@mui/icons-material/Clear';
import { CloudUpload as CloudUploadIcon } from '@mui/icons-material';
import FileOpenRoundedIcon from '@mui/icons-material/FileOpenRounded';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import dayjs from 'dayjs';
import { handleFileName, isValidUrl, openPdf } from '../../utils/util';
import { Customer, Project } from '../../contexts/@types';
import style from './projectcontrat.module.scss';

interface ProjectContractProps {
  project: Project
  handleChangeProject: Function
  customer: Customer
  handleChangeCustomer: Function
  isDisabled: boolean
}

const ProjectContract = (props: ProjectContractProps) => {
  const {
    project,
    handleChangeProject,
    customer,
    handleChangeCustomer,
    isDisabled,
  }: ProjectContractProps = props;

  const theme = useTheme();

  return (
    <Grid container spacing={2} rowSpacing={2}>
      <Grid xs={12}>
        <Typography sx={{
          fontSize: {
            xs: '16px',
            md: '24px',
          },
          fontFamily: 'Poppins',
          color: theme.palette.primary.main,
          fontWeight: '700',
        }}
        >
          Documentação do cliente
        </Typography>
        <hr className={style.titleDivisor} />
        {(project.status === 'Documentos recusados') && (
          <Typography sx={{
            fontSize: { xs: '12px', md: '16px' },
            fontFamily: 'Poppins',
            fontWeight: '400',
          }}
          >
            Os documentos foram recusados pelo banco.
            Se desejar, pode enviar novamente para uma nova análise.
          </Typography>
        )}
      </Grid>
      <Grid xs={12} lg={3}>
        <TextField
          required
          label={isValidUrl(project.proposal) ? '' : 'Enviar proposta'}
          margin="normal"
          fullWidth
          value={isValidUrl(project.proposal) ? 'proposta.pdf' : handleFileName(project.proposal)}
          InputProps={{
            readOnly: true,
            endAdornment: (
              <InputAdornment position="end">
                {((!isValidUrl(project.proposal) && !isDisabled)
                    || project.status === 'Documentos recusados')
                  && (
                    <>
                      {project.proposal && project.proposal !== 'Sem documentos' && (
                        <IconButton component="label" onClick={handleChangeProject('proposal')}>
                          <ClearIcon />
                        </IconButton>
                      )}
                      <IconButton component="label">
                        <CloudUploadIcon sx={{ color: theme.palette.primary.main }} />
                        <input
                          type="file"
                          accept=".pdf"
                          hidden
                          onChange={handleChangeProject('proposal')}
                        />
                      </IconButton>
                    </>
                  )}
                {isValidUrl(project.proposal) && (
                  <IconButton
                    onClick={() => (
                      typeof project.proposal === 'string' ? openPdf(project.proposal) : {})}
                  >
                    <FileOpenRoundedIcon sx={{ color: theme.palette.primary.main }} />
                  </IconButton>
                )}
              </InputAdornment>
            ),
          }}
          InputLabelProps={{
            shrink: !!handleFileName(project.proposal),
          }}
          variant="outlined"
          disabled
        />
      </Grid>
      <Grid xs={12} lg={3}>
        <TextField
          required
          label={isValidUrl(customer.documentoIdentificacao) ? '' : 'Documento de identificação'}
          margin="normal"
          fullWidth
          value={isValidUrl(customer.documentoIdentificacao)
            ? 'documento_de_identidade.pdf' : handleFileName(customer.documentoIdentificacao)}
          InputProps={{
            readOnly: true,
            endAdornment: (
              <InputAdornment position="end">
                {((!isValidUrl(customer.documentoIdentificacao) && !isDisabled)
                    || project.status === 'Documentos recusados')
                  && (
                    <>
                      {customer.documentoIdentificacao && customer.documentoIdentificacao !== 'Sem documentos' && (
                        <IconButton component="label" onClick={handleChangeCustomer('documentoIdentificacao')}>
                          <ClearIcon />
                        </IconButton>
                      )}
                      <IconButton component="label">
                        <CloudUploadIcon sx={{ color: theme.palette.primary.main }} />
                        <input
                          type="file"
                          accept=".pdf"
                          hidden
                          onChange={handleChangeCustomer('documentoIdentificacao')}
                        />
                      </IconButton>
                    </>
                  )}
                {isValidUrl(customer.documentoIdentificacao) && (
                  <IconButton
                    onClick={() => (
                      typeof customer.documentoIdentificacao === 'string'
                        ? openPdf(customer.documentoIdentificacao) : {})}
                  >
                    <FileOpenRoundedIcon sx={{ color: theme.palette.primary.main }} />
                  </IconButton>
                )}
              </InputAdornment>
            ),
          }}
          InputLabelProps={{
            shrink: !!handleFileName(customer.documentoIdentificacao),
          }}
          variant="outlined"
          disabled
        />
      </Grid>
      <Grid xs={12} lg={3}>
        <TextField
          required
          label={isValidUrl(customer.comprovanteRenda) ? '' : 'Comprovante de renda'}
          margin="normal"
          fullWidth
          value={isValidUrl(customer.comprovanteRenda)
            ? 'comprovante_de_renda.pdf' : handleFileName(customer.comprovanteRenda)}
          InputProps={{
            readOnly: true,
            endAdornment: (
              <InputAdornment position="end">
                {((!isValidUrl(customer.comprovanteRenda) && !isDisabled)
                    || project.status === 'Documentos recusados')
                  && (
                    <>
                      {customer.comprovanteRenda && customer.comprovanteRenda !== 'Sem documentos' && (
                        <IconButton component="label" onClick={handleChangeCustomer('comprovanteRenda')}>
                          <ClearIcon />
                        </IconButton>
                      )}
                      <IconButton component="label">
                        <CloudUploadIcon sx={{ color: theme.palette.primary.main }} />
                        <input
                          type="file"
                          accept=".pdf"
                          hidden
                          onChange={handleChangeCustomer('comprovanteRenda')}
                        />
                      </IconButton>
                    </>
                  )}
                {isValidUrl(customer.comprovanteRenda) && (
                  <IconButton
                    onClick={() => (
                      typeof customer.comprovanteRenda === 'string'
                        ? openPdf(customer.comprovanteRenda) : {})}
                  >
                    <FileOpenRoundedIcon sx={{ color: theme.palette.primary.main }} />
                  </IconButton>
                )}
              </InputAdornment>
            ),
          }}
          InputLabelProps={{
            shrink: !!handleFileName(customer.comprovanteRenda),
          }}
          variant="outlined"
          disabled
        />
      </Grid>
      <Grid xs={12} lg={3}>
        <TextField
          label={isValidUrl(customer.iptu) ? '' : 'IPTU (em nome do cliente)'}
          margin="normal"
          fullWidth
          value={isValidUrl(customer.iptu) ? 'iptu.pdf' : handleFileName(customer.iptu)}
          InputProps={{
            readOnly: true,
            endAdornment: (
              <InputAdornment position="end">
                {((!isValidUrl(customer.iptu) && !isDisabled)
                    || project.status === 'Documentos recusados')
                  && (
                    <>
                      {customer.iptu && customer.iptu !== 'Sem documentos' && (
                        <IconButton component="label" onClick={handleChangeCustomer('iptu')}>
                          <ClearIcon />
                        </IconButton>
                      )}
                      <IconButton component="label">
                        <CloudUploadIcon sx={{ color: theme.palette.primary.main }} />
                        <input
                          type="file"
                          accept=".pdf"
                          hidden
                          onChange={handleChangeCustomer('iptu')}
                        />
                      </IconButton>
                    </>
                  )}
                {isValidUrl(customer.iptu) && (
                  <IconButton
                    onClick={() => (
                      typeof customer.iptu === 'string'
                        ? openPdf(customer.iptu) : {})}
                  >
                    <FileOpenRoundedIcon sx={{ color: theme.palette.primary.main }} />
                  </IconButton>
                )}
              </InputAdornment>
            ),
          }}
          InputLabelProps={{
            shrink: !!handleFileName(customer.iptu),
          }}
          variant="outlined"
          disabled
        />
      </Grid>
      <Grid xs={12} lg={3}>
        <TextField
          required
          label={isValidUrl(customer.contaLuzRecente) ? '' : 'Última conta de luz'}
          margin="normal"
          fullWidth
          value={isValidUrl(customer.contaLuzRecente) ? 'conta_de_luz.pdf' : handleFileName(customer.contaLuzRecente)}
          InputProps={{
            readOnly: true,
            endAdornment: (
              <InputAdornment position="end">
                {((!isValidUrl(customer.contaLuzRecente) && !isDisabled)
                    || project.status === 'Documentos recusados')
                  && (
                    <>
                      {customer.contaLuzRecente && customer.contaLuzRecente !== 'Sem documentos' && (
                        <IconButton component="label" onClick={handleChangeCustomer('contaLuzRecente')}>
                          <ClearIcon />
                        </IconButton>
                      )}
                      <IconButton component="label">
                        <CloudUploadIcon sx={{ color: theme.palette.primary.main }} />
                        <input
                          type="file"
                          accept=".pdf"
                          hidden
                          onChange={handleChangeCustomer('contaLuzRecente')}
                        />
                      </IconButton>
                    </>
                  )}
                {isValidUrl(customer.contaLuzRecente) && (
                  <IconButton
                    onClick={() => (
                      typeof customer.contaLuzRecente === 'string'
                        ? openPdf(customer.contaLuzRecente) : {})}
                  >
                    <FileOpenRoundedIcon sx={{ color: theme.palette.primary.main }} />
                  </IconButton>
                )}
              </InputAdornment>
            ),
          }}
          InputLabelProps={{
            shrink: !!handleFileName(customer.contaLuzRecente),
          }}
          variant="outlined"
          disabled
        />
      </Grid>
      {!!handleFileName(customer.contaLuzRecente) && (
        <Grid xs={12} lg={3.1}>
          <FormControl margin="normal" disabled={isValidUrl(customer.contaLuzRecente) || isDisabled}>
            <FormLabel id="demo-row-radio-buttons-group-label">A conta de luz está no nome do cliente?</FormLabel>
            <RadioGroup
              row
              aria-labelledby="demo-row-radio-buttons-group-label"
              name="row-radio-buttons-group"
              value={String(customer.electricityBillOwnership)}
              onChange={handleChangeCustomer('electricityBillOwnership')}
            >
              <FormControlLabel value="true" control={<Radio />} label="Sim" />
              <FormControlLabel value="false" control={<Radio />} label="Não" />
            </RadioGroup>
          </FormControl>
        </Grid>
      )}
      {!customer.electricityBillOwnership && (
        <Grid xs={12} lg={5.9}>
          <FormControl margin="normal" disabled={isValidUrl(customer.contaLuzRecente) || isDisabled}>
            <FormLabel id="demo-row-radio-buttons-group-label">A conta de luz está no nome de qual familiar?</FormLabel>
            <RadioGroup
              row
              aria-labelledby="demo-row-radio-buttons-group-label"
              name="row-radio-buttons-group"
              value={customer.electricityBillFamiliarType}
              onChange={handleChangeCustomer('electricityBillFamiliarType')}
            >
              <FormControlLabel value={0} control={<Radio />} label="Mãe" />
              <FormControlLabel value={1} control={<Radio />} label="Pai" />
              <FormControlLabel value={2} control={<Radio />} label="Esposo(a)" />
              <FormControlLabel value={3} control={<Radio />} label="Filho(a)" />
              <FormControlLabel value={4} control={<Radio />} label="Irmão/Irmã" />
            </RadioGroup>
          </FormControl>
        </Grid>
      )}
      <Grid xs={12}>
        <Typography sx={{
          fontSize: {
            xs: '16px',
            md: '24px',
          },
          fontFamily: 'Poppins',
          color: theme.palette.primary.main,
          fontWeight: '700',
        }}
        >
          Contratação do projeto
        </Typography>
        <hr className={style.titleDivisor} />
      </Grid>
      {(project.status === 'Aguardando NF' || project.status === 'Projeto contratado' || project.status === 'Condições escolhidas') && (
        <>
          <Grid xs={12} lg={3}>
            <TextField
              required
              label={isValidUrl(project.invoice) ? '' : 'Enviar nota fiscal'}
              fullWidth
              value={isValidUrl(project.invoice) ? 'nota_fiscal.pdf' : handleFileName(project.invoice)}
              InputProps={{
                readOnly: true,
                endAdornment: (
                  <InputAdornment position="end">
                    {!(isValidUrl(project.invoice)) && !isDisabled && (
                    <>
                      {project.invoice && project.invoice !== 'Sem documentos' && (
                      <IconButton component="label" onClick={handleChangeProject('invoice')}>
                        <ClearIcon />
                      </IconButton>
                      )}
                      <IconButton component="label">
                        <CloudUploadIcon sx={{ color: theme.palette.primary.main }} />
                        <input
                          type="file"
                          accept=".pdf"
                          hidden
                          onChange={handleChangeProject('invoice')}
                        />
                      </IconButton>
                    </>
                    )}
                    {isValidUrl(project.invoice) && (
                    <IconButton
                      onClick={() => (
                        typeof project.invoice === 'string' ? openPdf(project.invoice) : {})}
                    >
                      <FileOpenRoundedIcon sx={{ color: theme.palette.primary.main }} />
                    </IconButton>
                    )}
                  </InputAdornment>
                ),
              }}
              InputLabelProps={{
                shrink: !!project.invoice,
              }}
              variant="outlined"
              disabled
            />
          </Grid>
          <Grid xs={12} lg={3}>
            <TextField
              label={isValidUrl(customer.notaFiscalEquipamentos) ? '' : 'Enviar nota fiscal do equipamento'}
              fullWidth
              value={isValidUrl(customer.notaFiscalEquipamentos) ? 'nota_fiscal_equipamento.pdf' : handleFileName(customer.notaFiscalEquipamentos)}
              InputProps={{
                readOnly: true,
                endAdornment: (
                  <InputAdornment position="end">
                    {!(isValidUrl(customer.notaFiscalEquipamentos)) && !isDisabled && (
                    <>
                      {customer.notaFiscalEquipamentos && customer.notaFiscalEquipamentos !== 'Sem documentos' && (
                      <IconButton component="label" onClick={handleChangeCustomer('notaFiscalEquipamentos')}>
                        <ClearIcon />
                      </IconButton>
                      )}
                      <IconButton component="label">
                        <CloudUploadIcon sx={{ color: theme.palette.primary.main }} />
                        <input
                          type="file"
                          accept=".pdf"
                          hidden
                          onChange={handleChangeCustomer('notaFiscalEquipamentos')}
                        />
                      </IconButton>
                    </>
                    )}
                    {isValidUrl(customer.notaFiscalEquipamentos) && (
                    <IconButton
                      onClick={() => (
                        typeof customer.notaFiscalEquipamentos === 'string' ? openPdf(customer.notaFiscalEquipamentos) : {})}
                    >
                      <FileOpenRoundedIcon sx={{ color: theme.palette.primary.main }} />
                    </IconButton>
                    )}
                  </InputAdornment>
                ),
              }}
              InputLabelProps={{
                shrink: customer.notaFiscalEquipamentos !== 'Sem documentos' ? !!customer.notaFiscalEquipamentos : false,
              }}
              variant="outlined"
              disabled
            />
          </Grid>
        </>
      )}
      {(project.status !== 'Aguardando NF' && project.status !== 'Projeto contratado' && project.status !== 'Condições escolhidas') && (
        <Typography sx={{
          fontSize: { xs: '12px', md: '16px' },
          fontFamily: 'Poppins',
          fontWeight: '400',
        }}
        >
          Aguarde a análise dos documentos do cliente para emitir a nota fiscal!
        </Typography>
      )}
      {project.status === 'Projeto contratado' && (
        <>
          <Grid xs={12} sx={{ mt: '2rem' }}>
            <Typography sx={{
              fontSize: {
                xs: '16px',
                md: '24px',
              },
              fontFamily: 'Poppins',
              color: theme.palette.primary.main,
              fontWeight: '700',
            }}
            >
              Detalhes do depósito
            </Typography>
            <hr className={style.titleDivisor} />
            {(project.status === 'Projeto contratado') && (
              <Typography sx={{
                fontSize: {
                  xs: '12px',
                  md: '16px',
                },
                fontFamily: 'Poppins',
                fontWeight: '400',
              }}
              >
                O projeto foi contratado.
              </Typography>
            )}
          </Grid>
          <Grid xs={12} lg={3}>
            <TextField
              label={isValidUrl(project.receipt) ? '' : 'Comprovante de depósito não enviado'}
              fullWidth
              value={isValidUrl(project.receipt) ? 'comprovante_de_deposito.pdf' : handleFileName(project.receipt)}
              InputProps={{
                readOnly: true,
                endAdornment: (
                  <InputAdornment position="end">
                    {isValidUrl(project.receipt) && (
                      <IconButton
                        onClick={() => (
                          typeof project.receipt === 'string' ? openPdf(project.receipt) : {})}
                      >
                        <FileOpenRoundedIcon sx={{ color: theme.palette.primary.main }} />
                      </IconButton>
                    )}
                  </InputAdornment>
                ),
              }}
              InputLabelProps={{
                shrink: !!project.receipt,
              }}
              variant="outlined"
              disabled
            />
          </Grid>
          <Grid xs={12} lg={2.4}>
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <DatePicker
                disabled={isDisabled}
                label="Data do depósito"
                format="DD/MM/YYYY"
                value={project.dateDeposit
                  && dayjs(project.dateDeposit, 'DD/MM/YYYY').isValid()
                  ? dayjs(project.dateDeposit, 'DD/MM/YYYY') : null}
                sx={{ width: '100%' }}
              />
            </LocalizationProvider>
          </Grid>
        </>
      )}
    </Grid>
  );
};

export default ProjectContract;
