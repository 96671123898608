import { FormControl, MenuItem } from '@mui/material';
import TextField from '@mui/material/TextField';
import React, { ChangeEvent, useState } from 'react';
import { toast } from 'react-toastify';
import { useHistory } from 'react-router-dom';
import { DialogBodyComponent } from '../../DialogBodyComponent';
import { api } from '../../../services/api';

interface Props {
  isModalOpen: boolean
  localClose: Function
  localConfirm?: Function
  projectId: number
}

interface Consult {
  IdentityNumber: string,
  POS: string,
  LegalEntity: string
}
const CobrancaEmContaDialog = (props: Props) => {
  const {
    isModalOpen, localClose, localConfirm, projectId,
  } = props;

  const history = useHistory();
  const [consult, setConsult] = useState<Consult>({
    IdentityNumber: '',
    POS: '',
    LegalEntity: '',
  });
  const [formLoading, setFormLoading] = useState(false);

  const integrators = [
    {
      value: 'EXBRA_SA_Fortaleza_CE',
      label: 'EXBRA_SA_Fortaleza_CE',
    },
    {
      value: 'EXBRA_SA_Paulo_SP',
      label: 'EXBRA_SA_Paulo_SP',
    },
    {
      value: 'EXBRA_SA_Rio_de_Janeiro_RJ',
      label: 'EXBRA_SA_Rio_de_Janeiro_RJ',
    },
  ];

  const validateLegalEntity = (m: string) => m.length > 0;
  const validatePOS = (m: string) => m.length > 0;

  const validateConsultForm = () => {
    if (!validateLegalEntity(consult.POS)) {
      toast.warning('O código do cliente é necessário!');
      return false;
    }

    if (!validatePOS(consult.LegalEntity)) {
      toast.warning('A distribuidora é necessário!');
      return false;
    }

    return true;
  };

  const handleConsultConfirm = async () => {
    if (validateConsultForm()) {
      setFormLoading(true);
      const data = consult;
      const responseStatus = await api.post('/getContractEnelX', { data });

      const consultResponse = responseStatus.data;
      // setIsOpen(false);
      setFormLoading(false);
      if (consultResponse.Result.ErrorCode === '0') {
        if (consultResponse.Contract[0].Status === 'Activated') {
          toast.success('Cliente está ativo!');
          history.push({ pathname: '/CobrancaEmConta', state: { id: projectId, empresa: consult.LegalEntity } });
        }
      } else {
        toast.warning('Cliente não encontrado!');
      }
    }
  };

  const handleConsultChange = (e: ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    setConsult({
      ...consult,
      [name]: value,
    });
  };

  return (
    <DialogBodyComponent
      title="Cobrança em Conta"
      helperText="Pesquise o usuário"
      cancelButtonText="Cancelar"
      confirmButtonText="Consultar"
      isOpen={isModalOpen}
      close={localClose}
      confirm={localConfirm || handleConsultConfirm}
    >
      <FormControl style={{ display: 'flex' }}>
        <TextField
          autoFocus
          margin="dense"
          label="Código do Cliente"
          disabled={formLoading}
          value={formLoading ? 'Consultando...' : consult.POS}
          name="POS"
          type="text"
          fullWidth
          variant="standard"
          onChange={handleConsultChange}
        />

        {/* this Textfield is a select */}
        <TextField
          select={!formLoading}
          margin="dense"
          id="code"
          label="Distribuidora"
          disabled={formLoading}
          value={formLoading ? 'Consultando...' : consult.LegalEntity}
          name="LegalEntity"
          type="text"
          fullWidth
          variant="standard"
          onChange={handleConsultChange}
        >
          {integrators.map((integ) => (
            <MenuItem key={integ.value} value={integ.value}>
              {integ.label}
            </MenuItem>
          ))}
        </TextField>
      </FormControl>
    </DialogBodyComponent>
  );
};
export { CobrancaEmContaDialog };
