import React, {
  ChangeEvent, useRef, useState,
} from 'react';
import {
  TableCell,
  TableHead,
  Paper,
  TableContainer,
  TableRow,
  TableBody,
  Table,
  TablePagination,
  Typography,
  Box,
  IconButton,
  useTheme,
  alpha,
} from '@mui/material';
import DriveFileRenameOutlineOutlinedIcon from '@mui/icons-material/DriveFileRenameOutlineOutlined';
import { useHistory } from 'react-router-dom';
import LoadingBar, { LoadingBarRef } from 'react-top-loading-bar';
import { parseCookies } from 'nookies';
import { toast } from 'react-toastify';
import Button from '@mui/material/Button';
import { Bank } from '../../contexts/@types';
import { api } from '../../services/api';
import { DialogComponent } from '../DialogComponent';

interface BanksTableProps {
  banks: Array<Bank>
  sortBanks: Function
}
interface Column {
  id: 'id' | 'nome' | 'email' | 'cod' | 'active' | 'actions'
  label: string
  minWidth?: number
  align?: 'right' | 'center' | 'left'
  format?: (value: number) => string
}

const BanksTable = (props: BanksTableProps) => {
  const {
    banks,
    sortBanks,
  }: BanksTableProps = props;
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [loading, setLoading] = useState(false);
  const [loadingText, setLoadingText] = useState('');
  const history = useHistory();
  const theme = useTheme();

  const { 'nextauth.tag': tag } = parseCookies();

  const handleChangePage = (event: unknown, newPage: number) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event: ChangeEvent<HTMLInputElement>) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  const [columns, setColumns] = useState<readonly Column[]>([
    { id: 'id', label: 'Código', align: 'left' },
    { id: 'nome', label: 'Banco', align: 'left' },
    { id: 'email', label: 'E-mail', align: 'left' },
    { id: 'cod', label: 'Código nacional', align: 'left' },
    { id: 'active', label: 'Status', align: 'left' },
    { id: 'actions', label: '', align: 'left' },
  ]);

  const ref = useRef<LoadingBarRef>(null);

  const handleEdit = (bank: Bank) => {
    history.push({
      pathname: '/EditarBanco',
      state: bank.id,
    });
  };

  const handleToggleStatus = async (bank: Bank) => {
    const msg = 'Deseja alterar o status deste banco?';
    // eslint-disable-next-line no-alert

    let statusToSend = '';

    for (let i = 0; i < banks.length; i++) {
      if (banks[i].id === bank.id) {
        statusToSend = banks[i].active ? 'Ativo' : 'Inativo';
      }
    }
    // eslint-disable-next-line no-alert
    if (window.confirm(msg)) {
      if (statusToSend.toLowerCase() === 'Ativo'.toLowerCase()) {
        setLoadingText('Desativando banco...');
      } else {
        setLoadingText('Ativando banco...');
      }

      setLoading(true);
      const changeStatus = await api.post('bank/status/put', {
        data: {
          userId: bank.id,
          status: statusToSend,
        },

      });

      if (changeStatus.data === 'STATUS_CHANGED') {
        toast.success(statusToSend.toLowerCase() === 'Ativo'.toLowerCase()
          ? 'Banco desativado' : 'Banco ativado');
        setTimeout(() => {
          window.location.reload();
        }, 2000);
      } else {
        toast.error('Houve um erro com sua solicitação, tente mais tarde!');
      }
    }
  };

  const handleNewBank = () => {
    history.push('/CadastroBanco');
  };

  return (
    <div>
      <DialogComponent isOpen={loading} labelText={loadingText} />
      <LoadingBar color={theme.palette.primary.main} ref={ref} />
      <Paper sx={{
        width: '100%',
        overflow: 'hidden',
        boxSizing: 'border-box',
        boxShadow: 'none',
      }}
      >
        <TableContainer sx={{ maxHeight: 490 }}>
          <Table>
            <TableHead>
              <TableRow>
                {columns.map((column) => (
                  <TableCell
                    key={column.id}
                    align={column.align}
                    onClick={() => sortBanks(column.id)}
                    sx={{
                      userSelect: 'none',
                      cursor: (
                        column.id === 'id'
                        || column.id === 'nome'
                        || column.id === 'email'
                        || column.id === 'cod'
                        || column.id === 'active'
                      ) ? 'pointer' : 'arrow',
                      '&:hover': {
                        textShadow: '1px 1px 1px #0000005A',
                      },
                    }}
                  >
                    {column.label}
                  </TableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              {banks && (
                banks.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                  .map((row: Bank) => (
                    <TableRow
                      hover
                      role="checkbox"
                      tabIndex={-1}
                      key={row.id}
                    >
                      <TableCell>
                        {row.id}
                      </TableCell>
                      <TableCell>
                        {row.name}
                      </TableCell>
                      <TableCell>
                        {row.email}
                      </TableCell>
                      <TableCell>
                        {row.cod}
                      </TableCell>
                      <TableCell
                        sx={{
                          color: row.active ? 'green' : 'red',
                          cursor: 'pointer',
                        }}
                      >
                        {row.active ? 'Ativo' : 'Inativo'}
                      </TableCell>
                      <TableCell align="right">
                        <Box
                          display="flex"
                          justifyContent="flex-end"
                          alignItems="center"
                          gap="1rem"
                        >
                          <IconButton
                            onClick={() => handleEdit(row)}
                          >
                            <DriveFileRenameOutlineOutlinedIcon />
                          </IconButton>
                          <Typography
                            onClick={() => handleToggleStatus(row)}
                            sx={{
                              fontSize: '12px',
                              fontFamily: 'Poppins',
                              color: row.active ? 'red' : 'green',
                              fontWeight: '700',
                              cursor: 'pointer',
                              userSelect: 'none',
                              '&:hover': {
                                textShadow: '1px 1px 1px #0000005A',
                              },
                            }}
                          >
                            {row.active ? 'Desativar' : 'Ativar'}
                          </Typography>
                        </Box>
                      </TableCell>
                    </TableRow>
                  ))
              )}
              {(!banks || banks.length === 0) && (
                <TableRow
                  hover
                  role="checkbox"
                >
                  <TableCell>
                    Nenhum banco cadastrado!
                  </TableCell>
                  <TableCell />
                  <TableCell />
                  <TableCell />
                  <TableCell />
                  <TableCell />
                  <TableCell />
                </TableRow>
              )}
            </TableBody>
          </Table>
        </TableContainer>
        <Box sx={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
        }}
        >
          <Button
            variant="contained"
            sx={{
              borderRadius: '2rem',
              backgroundColor: theme.palette.primary.main,
              marginBottom: '0.5rem',
              marginTop: '1rem',
              textTransform: 'none',
              '&:hover': {
                backgroundColor: alpha(theme.palette.primary.main, 0.8),
              },
            }}
            onClick={handleNewBank}
          >
            Cadastrar novo banco
          </Button>
          <TablePagination
            rowsPerPageOptions={[10, 25, 100]}
            component="div"
            count={banks.length}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
            labelRowsPerPage="Linhas por página"
          />
        </Box>
      </Paper>
    </div>
  );
};
export default BanksTable;
