import {
  Box,
  Typography,
  useMediaQuery,
  useTheme,
} from '@mui/material';
import Grid from '@mui/material/Unstable_Grid2';
import React from 'react';
import { parseCookies } from 'nookies';
import ReactApexChart from 'react-apexcharts';
import { ApexOptions } from 'apexcharts';
import { responsiveChart } from '../../../utils/chartUtil';

interface ProjectsByUserMonth {
  name: string;
  userProjects: number;
  userDoneProjects: number;
  integrator: string;
}

interface ProjectsByUserMonthMinor {
  name: string;
  userProjects: number;
  userDoneProjects: number;
  integrator: string;
}

interface LastProjects {
  doneProjects: number;

}

interface ProjectsComparation {
  monthYear: string;
  newProjects: number;
  doneProjects: number;
  projectsPass: any;

}

export interface OpBarChartsData {
  lastProjects: Array<LastProjects>,
  projectsComparation: Array<ProjectsComparation>,
  projectsByUserMonth: Array<ProjectsByUserMonth>,
  projectsByUserMonthMinor: Array<ProjectsByUserMonthMinor>
  actived: number,
  noDocumentation: number,
}

export const defaultOpBarChartsData: OpBarChartsData = {
  lastProjects: [{ doneProjects: 0 }],
  projectsComparation: [{
    monthYear: '',
    newProjects: 0,
    doneProjects: 0,
    projectsPass: '',
  }],
  projectsByUserMonth: [{
    name: '',
    userProjects: 0,
    userDoneProjects: 0,
    integrator: '',
  }],
  projectsByUserMonthMinor: [{
    name: '',
    userProjects: 0,
    userDoneProjects: 0,
    integrator: '',
  }],
  actived: 0,
  noDocumentation: 0,
};

interface BarChartsProps {
  opBarChartsData: OpBarChartsData
}
function OpBarCharts(props: BarChartsProps) {
  const {
    opBarChartsData,
  }: BarChartsProps = props;
  const theme = useTheme();
  const { 'nextauth.roles': cookieRoles } = parseCookies();
  const isXs = useMediaQuery(theme.breakpoints.only('xs'));
  const isSm = useMediaQuery(theme.breakpoints.only('sm'));
  const isLg = useMediaQuery(theme.breakpoints.only('lg'));
  const isXl = useMediaQuery(theme.breakpoints.only('xl'));
  const statusProjectsChartWidth = () => {
    if (isXs || isSm) return 1000;

    return '100%';
  };
  const statusProjectsChartContainerWidth = () => {
    if (isXs) return '80vw';
    if (isSm) return '85vw';

    return '100%';
  };

  const statusProjectsChartHeight = () => {
    if (isLg && cookieRoles === 'Vendedor') return '12.5rem';
    if (isLg && cookieRoles === 'Integradora') return '12.5rem';
    if (isXl && cookieRoles === 'Vendedor') return '18rem';
    if (isXl && cookieRoles === 'Integradora') return '14rem';

    return '12rem';
  };

  const contractedProjectOp: ApexOptions = {
    chart: {
      type: 'bar',
    },
    plotOptions: {
      bar: {
        horizontal: false,
        columnWidth: '30%',
      },
    },
    dataLabels: {
      enabled: false,
    },
    stroke: {
      show: true,
      width: 2,
      colors: ['transparent'],
    },
    xaxis: {
      categories: ['Projetos efetivados'],
      labels: {
        rotate: 0,
        style: {
          fontSize: '12px',
        },
      },
    },
    yaxis: {
      title: {
        text: 'Projetos',
      },
    },
    fill: {
      colors: ['#3c3c3b', theme.palette.primary.main],
      opacity: 1,
    },
    legend: {
      labels: {
        useSeriesColors: true,
      },
      markers: {
        fillColors: ['#3c3c3b', theme.palette.primary.main],
      },
    },
  };

  const contractedProjectSeries = [{
    name: 'Mês anterior',
    data: [opBarChartsData.lastProjects[0]?.doneProjects],
    color: theme.palette.primary.light,
  }, {
    name: 'Mês atual',
    data: [opBarChartsData.lastProjects[1]?.doneProjects],
    color: theme.palette.primary.main,
  }];

  const activeIntegratorsOp: ApexOptions = {
    chart: {
      type: 'bar',
    },
    plotOptions: {
      bar: {
        horizontal: false,
        columnWidth: '30%',
      },
    },
    dataLabels: {
      enabled: false,
    },
    stroke: {
      show: true,
      width: 2,
      colors: ['transparent'],
    },
    xaxis: {
      categories: ['Integradoras'],
      labels: {
        rotate: 0,
        style: {
          fontSize: '12px',
        },
      },
    },
    yaxis: {
      title: {
        text: 'Integradoras',
      },
    },
    fill: {
      colors: ['#3c3c3b', theme.palette.primary.main],
      opacity: 1,
    },
    legend: {
      labels: {
        useSeriesColors: true,
      },
      markers: {
        fillColors: ['#3c3c3b', theme.palette.primary.main],
      },
    },
  };

  const activeIntegratorsSeries : ApexOptions['series'] = [{
    name: 'Ativas',
    data: [opBarChartsData.actived],
    color: theme.palette.primary.light,
  }, {
    name: 'Sem documentos',
    data: [opBarChartsData.noDocumentation],
    color: theme.palette.primary.main,

  }];

  const mostSimulatedOp: ApexOptions = {
    responsive: [...responsiveChart],
    chart: {
      type: 'bar',
    },
    plotOptions: {
      bar: {
        horizontal: false,
        columnWidth: '30%',
      },
    },
    dataLabels: {
      enabled: false,
    },
    stroke: {
      show: true,
      width: 1,
      colors: ['transparent'],
    },
    xaxis: {
      categories: [opBarChartsData.projectsByUserMonth[0]?.integrator],
      labels: {
        rotate: 0,
        style: {
          fontSize: '10px',
        },
      },
    },
    yaxis: {
      title: {
        text: 'Projetos',
      },
    },
    fill: {
      colors: ['#3c3c3b', theme.palette.primary.main],
      opacity: 1,
    },
    legend: {
      labels: {
        useSeriesColors: true,
      },
      markers: {
        fillColors: ['#3c3c3b', theme.palette.primary.main],
      },
    },
  };

  const mostSimulatedSeries = [{
    name: 'Projetos Criados',
    data: [opBarChartsData.projectsByUserMonth[0]?.userProjects],
    color: theme.palette.primary.light,
  }, {
    name: 'Projetos Contratados',
    data: [opBarChartsData.projectsByUserMonth[0]?.userDoneProjects],
    color: theme.palette.primary.main,
  }];

  const lessSimulatedOp: ApexOptions = {
    responsive: [...responsiveChart],
    chart: {
      type: 'bar',
    },
    plotOptions: {
      bar: {
        horizontal: false,
        columnWidth: '30%',
      },
    },
    dataLabels: {
      enabled: false,
    },
    stroke: {
      show: true,
      width: 2,
      colors: ['transparent'],
    },
    xaxis: {
      categories: [opBarChartsData.projectsByUserMonthMinor[0]?.integrator],
      labels: {
        rotate: 0,
        style: {
          fontSize: '10px',
        },
      },
    },
    yaxis: {
      title: {
        text: 'Projetos',
      },
    },
    fill: {
      colors: ['#3c3c3b', theme.palette.primary.main],
      opacity: 1,
    },
    legend: {
      labels: {
        useSeriesColors: true,
      },
      markers: {
        fillColors: ['#3c3c3b', theme.palette.primary.main],
      },
    },
  };

  const lessSimulatedSeries = [{
    name: 'Projetos Criados',
    data: [opBarChartsData.projectsByUserMonthMinor[0]?.userProjects],
    color: theme.palette.primary.light,
  }, {
    name: 'Projetos Contratados',
    data: [opBarChartsData.projectsByUserMonthMinor[0]?.userDoneProjects],
    color: theme.palette.primary.main,
  }];

  const projectsStatusSeries = [{
    name: 'Novos Projetos',
    data: [
      opBarChartsData.projectsComparation[0]?.newProjects,
      opBarChartsData.projectsComparation[1]?.newProjects,
      opBarChartsData.projectsComparation[2]?.newProjects,
      opBarChartsData.projectsComparation[3]?.newProjects,
      opBarChartsData.projectsComparation[4]?.newProjects,
      opBarChartsData.projectsComparation[5]?.newProjects,
      opBarChartsData.projectsComparation[6]?.newProjects,
      opBarChartsData.projectsComparation[7]?.newProjects,
      opBarChartsData.projectsComparation[8]?.newProjects,
      opBarChartsData.projectsComparation[9]?.newProjects,
      opBarChartsData.projectsComparation[10]?.newProjects,
      opBarChartsData.projectsComparation[11]?.newProjects,
    ],
    color: theme.palette.primary.light,
  }, {
    name: 'Projetos Contratados',
    data: [
      opBarChartsData.projectsComparation[0]?.doneProjects,
      opBarChartsData.projectsComparation[1]?.doneProjects,
      opBarChartsData.projectsComparation[2]?.doneProjects,
      opBarChartsData.projectsComparation[3]?.doneProjects,
      opBarChartsData.projectsComparation[4]?.doneProjects,
      opBarChartsData.projectsComparation[5]?.doneProjects,
      opBarChartsData.projectsComparation[6]?.doneProjects,
      opBarChartsData.projectsComparation[7]?.doneProjects,
      opBarChartsData.projectsComparation[8]?.doneProjects,
      opBarChartsData.projectsComparation[9]?.doneProjects,
      opBarChartsData.projectsComparation[10]?.doneProjects,
      opBarChartsData.projectsComparation[11]?.doneProjects,
    ],
    color: theme.palette.primary.main,
  }];

  const statusProjectsOptions: ApexOptions = {
    chart: {
      type: 'bar',
    },
    plotOptions: {
      bar: {
        horizontal: false,
        columnWidth: '55%',
      },
    },
    dataLabels: {
      enabled: false,
    },
    stroke: {
      show: true,
      width: 2,
      colors: ['transparent'],
    },
    legend: {
      labels: {
        useSeriesColors: true,
      },
      markers: {
        fillColors: ['#3c3c3b', theme.palette.primary.main],
      },
    },
    xaxis: {
      categories: [
        opBarChartsData.projectsComparation[0]?.monthYear,
        opBarChartsData.projectsComparation[1]?.monthYear,
        opBarChartsData.projectsComparation[2]?.monthYear,
        opBarChartsData.projectsComparation[3]?.monthYear,
        opBarChartsData.projectsComparation[4]?.monthYear,
        opBarChartsData.projectsComparation[5]?.monthYear,
        opBarChartsData.projectsComparation[6]?.monthYear,
        opBarChartsData.projectsComparation[7]?.monthYear,
        opBarChartsData.projectsComparation[8]?.monthYear,
        opBarChartsData.projectsComparation[9]?.monthYear,
        opBarChartsData.projectsComparation[10]?.monthYear,
        opBarChartsData.projectsComparation[11]?.monthYear,
      ],
    },
    yaxis: {
      title: {
        text: 'Projetos',
      },
    },
    fill: {
      colors: ['#3c3c3b', theme.palette.primary.main],
      opacity: 1,
    },
  };

  const cardStyle = {
    backgroundColor: '#fff',
    boxShadow: '0px 2px 2px 0px #0000001A',
    borderRadius: '8px',
    boxSizing: 'border-box',
    padding: '0.5rem',
  };

  return (
    <Grid
      container
      spacing={4}
      rowSpacing={2}
      sx={{
        justifyContent: 'space-between',
        height: '100%',
        alignItems: 'flex-end',
        m: 0,
      }}
    >
      <Grid
        xs={12}
        container
        spacing={2}
        rowSpacing={0}
        sx={{
          m: 0,
          p: 0,
          display: 'flex',
          alignItems: 'flex-end',
          alignSelf: 'flex-start',
        }}
      >
        <>
          <Grid xs={12} md={3} sx={{ height: '100%' }}>
            <Box sx={{ ...cardStyle }}>
              <Typography sx={{
                fontFamily: 'Poppins',
                fontSize: { xs: '12px', md: '16px' },
                textAlign: 'center',
                fontWeight: '700',
                minHeight: { xs: '18px', md: '48px' },
              }}
              >
                Status mensal de projetos
              </Typography>
              <ReactApexChart
                options={contractedProjectOp}
                series={contractedProjectSeries}
                type="bar"
                height="100%"
              />
            </Box>
          </Grid>
          <Grid xs={12} md={3} sx={{ height: '100%' }}>
            <Box sx={{ ...cardStyle }}>
              <Typography sx={{
                fontFamily: 'Poppins',
                fontSize: { xs: '12px', md: '16px' },
                textAlign: 'center',
                fontWeight: '700',
                minHeight: { xs: '18px', md: '48px' },
              }}
              >
                Status das integradoras
              </Typography>
              <ReactApexChart
                options={activeIntegratorsOp}
                series={activeIntegratorsSeries}
                type="bar"
                height="100%"
              />
            </Box>
          </Grid>
          <Grid xs={12} md={3} sx={{ height: '100%' }}>
            <Box sx={{ ...cardStyle }}>
              <Typography sx={{
                fontFamily: 'Poppins',
                fontSize: { xs: '12px', md: '16px' },
                textAlign: 'center',
                fontWeight: '700',
                minHeight: { xs: '18px', md: '48px' },
              }}
              >
                Integradora que mais simulou
              </Typography>
              <ReactApexChart
                options={mostSimulatedOp}
                series={mostSimulatedSeries}
                type="bar"
                height="100%"
              />
            </Box>
          </Grid>
          <Grid xs={12} md={3} sx={{ height: '100%' }}>
            <Box sx={{ ...cardStyle }}>
              <Typography sx={{
                fontFamily: 'Poppins',
                fontSize: { xs: '12px', md: '16px' },
                textAlign: 'center',
                fontWeight: '700',
                minHeight: { xs: '18px', md: '48px' },
              }}
              >
                Integradora que menos simulou
              </Typography>
              <ReactApexChart
                options={lessSimulatedOp}
                series={lessSimulatedSeries}
                type="bar"
                height="100%"
              />
            </Box>
          </Grid>
        </>
      </Grid>
      <Grid xs={12}>
        <Box sx={{ ...cardStyle }}>
          <Typography sx={{
            fontFamily: 'Poppins',
            fontSize: {
              xs: '12px',
              md: '16px',
            },
            textAlign: 'center',
            fontWeight: '700',
            height: 'fit-content',
          }}
          >
            Status de projetos simulados e efetivados
          </Typography>

          <Box
            sx={{
              minHeight: statusProjectsChartHeight(),
              maxWidth: statusProjectsChartContainerWidth(),
              overflowY: 'hidden',
              overflowX: { xs: 'auto', md: 'hidden' },
              margin: '0 auto',
              scrollbarColor: '#EA5B13 lightgrey',
            }}
          >
            <ReactApexChart
              options={statusProjectsOptions}
              series={projectsStatusSeries}
              type="bar"
              width={statusProjectsChartWidth()}
              height="100%"
            />
          </Box>
        </Box>
      </Grid>
    </Grid>
  );
}
export default OpBarCharts;
