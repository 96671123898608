import React, {
  useEffect, useRef, useState,
} from 'react';
import { AxiosResponse } from 'axios';
import { ToastContainer } from 'react-toastify';
import { Box, Typography, useTheme } from '@mui/material';
import LoadingBar, { LoadingBarRef } from 'react-top-loading-bar';
import { setCookie } from 'nookies';
import { api } from '../../services/api';
import style from './listaenviocotacoes.module.scss';
import { DialogComponent } from '../../components/DialogComponent';
import QuotationsTable from '../../components/QuotationsTable';

interface ProjectData{
  id: number;
  status: string;
}

export interface ProjectCostumerData {
  id: number;
  nome: string;
  cpf: string;
  telefone: string;
  projeto: ProjectData;
}

export interface ProjetoStatus {
  cadastro: number;
  inativo: number;
  analiseSoltech: number;
  analiseBancos: number;
  escolhaCondicoes: number;
  condicoesEscolhidas: number;
  aprovados: number;
  reprovados: number;
  analiseDocs: number;
  pagamentoNF: number;
  pagamentoDocs: number;
  docRecusados: number,
  contratados: number;
}

export function ListaEnvioCotacoes() {
  const [projects, setProjects] = useState<ProjectCostumerData[]>([]);
  const [projetoQuant, setprojetoQuant] = useState<ProjetoStatus>({
    cadastro: 0,
    inativo: 0,
    analiseSoltech: 0,
    analiseBancos: 0,
    escolhaCondicoes: 0,
    condicoesEscolhidas: 0,
    aprovados: 0,
    reprovados: 0,
    analiseDocs: 0,
    pagamentoNF: 0,
    pagamentoDocs: 0,
    docRecusados: 0,
    contratados: 0,
  });
  const [isOpen, setIsOpen] = useState(false);
  const theme = useTheme();

  useEffect(() => {
    const getProjectNumbers = async () => {
      setIsOpen(true);
      const response: Promise<AxiosResponse<any, any>> = api.post(
        'project/numbers/get',
        '',
      );
      response.then((resolved) => {
        setprojetoQuant(resolved.data);
      });
    };

    const getProjects = async () => {
      setIsOpen(true);
      const response: Promise<AxiosResponse<any, any>> = api.post(
        '/project/all/get',
        '',
      );
      response.then((resolved) => {
        const { data } = resolved;
        setProjects(data);
      });
    };
    getProjectNumbers();
    getProjects();
    setIsOpen(false);
  }, []);
  const ref = useRef<LoadingBarRef>(null);

  const cardStyle = {
    backgroundColor: '#fff',
    boxShadow: '0px 2px 2px 0px #0000001A',
    borderRadius: '8px',
    boxSizing: 'border-box',
    padding: '1rem',
    minHeight: '82vh',
  };
  return (
    <Box sx={cardStyle}>
      <LoadingBar color={theme.palette.primary.main} ref={ref} />
      <Typography sx={{
        fontSize: { xs: '16px', md: '24px' },
        fontFamily: 'Poppins',
        color: theme.palette.primary.main,
        fontWeight: '700',
      }}
      >
        Envio de cotações
      </Typography>
      <hr className={style.divisor} />
      <QuotationsTable projects={projects} projectsQt={projetoQuant} />
      <DialogComponent isOpen={isOpen} labelText="Carregando dados" />
    </Box>
  );
}
