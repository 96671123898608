import React, { useState } from 'react';
import { parseCookies } from 'nookies';
import Grid from '@mui/material/Unstable_Grid2';
import {
  Accordion, AccordionDetails, AccordionSummary, Box, Link,
} from '@mui/material';
import BarChartRoundedIcon from '@mui/icons-material/BarChartRounded';
import TaskRoundedIcon from '@mui/icons-material/TaskRounded';
import PeopleAltRoundedIcon from '@mui/icons-material/PeopleAltRounded';
import MarkChatReadRoundedIcon from '@mui/icons-material/MarkChatReadRounded';
import AssignmentRoundedIcon from '@mui/icons-material/AssignmentRounded';
import TextSnippetRoundedIcon from '@mui/icons-material/TextSnippetRounded';
import AccountBalanceRoundedIcon from '@mui/icons-material/AccountBalanceRounded';
import AssignmentTurnedInRoundedIcon from '@mui/icons-material/AssignmentTurnedInRounded';
import { useHistory } from 'react-router-dom';
import style from './menu.module.scss';
import { BotaoUsuario } from '../../BotaoUsuario';

interface MenuProps {
  mobileMenuOpen: boolean
  setMobileMenuOpen: Function
  isMobileScreen: boolean
}

const Menu = (props: MenuProps) => {
  const {
    mobileMenuOpen,
    setMobileMenuOpen,
    isMobileScreen,
  }: MenuProps = props;
  const { 'nextauth.roles': cookieRoles } = parseCookies();

  const decd: string = cookieRoles || '';
  const roles = decd.split(',') as string[];

  const history = useHistory();

  const [hovered, setHovered] = useState(false);
  const menuItemStyle = {
    height: '3rem',
    display: 'flex',
    gap: '1rem',
    alignItems: 'center',
    justifyContent: { xs: 'left', lg: !hovered ? 'center' : 'left' },
    color: '#fff',
    cursor: 'pointer',
    padding: '0.5rem 0 0.5rem 0',
    margin: '0 1rem 0 1rem',
    paddingLeft: { xs: 'initial', lg: hovered ? '1rem' : 'initial' },
    borderRadius: '0.5rem',
    '&:hover': {
      backgroundColor: '#FFFFFF4D',
    },
    '& h6': {
      opacity: { lg: hovered ? 1 : 0 },
      transition: hovered ? 'opacity 0.3s ease-in' : 'opacity 0.1s ease-out',
    },
  };

  const iconStyle = {
    position: { lg: !hovered ? 'absolute' : 'relative' },
  };

  return (
    <div
      className={style.menu}
      onMouseEnter={() => { setHovered(true); }}
      onMouseLeave={() => { setHovered(false); }}
    >
      <Grid
        container
        spacing={1}
        rowSpacing={1}
        sx={{
          margin: 0,
          top: '5.5rem',
          position: 'sticky',
        }}
      >
        {isMobileScreen && (
        <Grid xs={12}>
          <BotaoUsuario />
        </Grid>
        )}
        {!cookieRoles && (
          <Grid xs={12}>
            <Link href="/Login">
              Login
            </Link>
          </Grid>
        )}
        <Grid xs={12} sx={{ p: 0 }}>
          <Box>
            {roles.indexOf('Integradora') >= 0 && (
              <Grid
                container
                rowSpacing={1}
              >
                <Grid xs={12}>
                  <Box
                    sx={menuItemStyle}
                    onClick={() => {
                      setMobileMenuOpen(false);
                      history.push('/DashboardIntegrator');
                    }}
                  >
                    <BarChartRoundedIcon sx={iconStyle} />
                    <h6>Dashboard</h6>
                  </Box>
                </Grid>
                <Grid xs={12}>
                  <Box
                    sx={menuItemStyle}
                    onClick={() => {
                      setMobileMenuOpen(false);
                      history.push('/MeusProjetosIntegradora');
                    }}
                  >
                    <TaskRoundedIcon sx={iconStyle} />
                    <h6 style={{ maxWidth: '125px' }}>Projetos da integradora</h6>
                  </Box>
                </Grid>
                <Grid xs={12}>
                  <Box
                    sx={menuItemStyle}
                    onClick={() => {
                      setMobileMenuOpen(false);
                      history.push('/ListaVendedor');
                    }}
                  >
                    <PeopleAltRoundedIcon sx={iconStyle} />
                    <h6 style={{ maxWidth: '125px' }}>Gerenciar vendedores</h6>
                  </Box>
                </Grid>
              </Grid>
            )}
            {roles.indexOf('Gerente') >= 0 && (
              <Grid
                container
                rowSpacing={1}
              >
                <Grid xs={12}>
                  <Box
                    sx={menuItemStyle}
                    onClick={() => {
                      setMobileMenuOpen(false);
                      history.push('/DashboardGerente');
                    }}
                  >
                    <BarChartRoundedIcon sx={iconStyle} />
                    <h6>Dashboard</h6>
                  </Box>
                </Grid>
                {/* <Grid xs={12}> */}
                {/*   <Box */}
                {/*     sx={menuItemStyle} */}
                {/*     onClick={() => { */}
                {/*       setMobileMenuOpen(false); */}
                {/*       history.push('/ListaIntegradoras'); */}
                {/*     }} */}
                {/*   > */}
                {/*     <TaskRoundedIcon sx={iconStyle} /> */}
                {/*     <h6>Integradoras</h6> */}
                {/*   </Box> */}
                {/* </Grid> */}
                <Grid xs={12}>
                  <Box
                    sx={menuItemStyle}
                    onClick={() => {
                      setMobileMenuOpen(false);
                      history.push('/ListaEnvioCotacoes');
                    }}
                  >
                    <MarkChatReadRoundedIcon sx={iconStyle} />
                    <h6>Envios de cotações</h6>
                  </Box>
                </Grid>
                <Grid xs={12}>
                  <Box
                    sx={menuItemStyle}
                    onClick={() => {
                      setMobileMenuOpen(false);
                      history.push('/ListaOperador');
                    }}
                  >
                    <PeopleAltRoundedIcon sx={iconStyle} />
                    <h6>Lista de operadores</h6>
                  </Box>
                </Grid>
                {/* <Grid xs={12}> */}
                {/*   <Box sx={menuItemStyle}> */}
                {/*     <DropDownMenu */}
                {/*       buttonName="Cobrança em conta de energia" */}
                {/*       menuItems={menuItems} */}
                {/*     /> */}
                {/*   </Box> */}
                {/* </Grid> */}
                <Grid xs={12}>
                  <Box
                    sx={menuItemStyle}
                    onClick={() => {
                      setMobileMenuOpen(false);
                      history.push('/ListaChamados');
                    }}
                  >
                    <AssignmentRoundedIcon sx={iconStyle} />
                    <h6>Lista de chamados</h6>
                  </Box>
                </Grid>
                <Grid xs={12}>
                  <Accordion
                    sx={{
                      flexDirection: 'column',
                      backgroundColor: 'transparent',
                      boxShadow: 'none',
                      gap: '0',
                      alignItems: 'left',
                    }}
                  >
                    <AccordionSummary
                      sx={{ width: '100%', p: 0 }}
                    >
                      <Box
                        sx={{
                          ...menuItemStyle, width: '100%', height: '4rem', p: 0,
                        }}
                      >
                        <TextSnippetRoundedIcon sx={{ ...iconStyle, left: { lg: !hovered ? '34%' : '0' } }} />
                        <h6>Relatórios</h6>
                      </Box>
                    </AccordionSummary>
                    <AccordionDetails>
                      <Box
                        sx={menuItemStyle}
                        onClick={() => {
                          setMobileMenuOpen(false);
                          history.push('/RelatorioVendasAnual');
                        }}
                      >
                        <h6>Relatório de 12 meses</h6>
                      </Box>
                      <Box
                        sx={menuItemStyle}
                        onClick={() => {
                          setMobileMenuOpen(false);
                          history.push('/RelatorioVendas');
                        }}
                      >
                        <h6>Relatório de vendas</h6>
                      </Box>
                    </AccordionDetails>
                  </Accordion>
                </Grid>
                <Grid xs={12}>
                  <Box
                    sx={menuItemStyle}
                    onClick={() => {
                      setMobileMenuOpen(false);
                      history.push('/ListaBancos');
                    }}
                  >
                    <AccountBalanceRoundedIcon sx={iconStyle} />
                    <h6>Lista de bancos</h6>
                  </Box>
                </Grid>
              </Grid>
            )}
            {roles.indexOf('Operador') >= 0 && (
              <Grid
                container
                rowSpacing={1}
              >
                <Grid xs={12}>
                  <Box
                    sx={menuItemStyle}
                    onClick={() => {
                      setMobileMenuOpen(false);
                      history.push('/DashboardOperador');
                    }}
                  >
                    <BarChartRoundedIcon sx={iconStyle} />
                    <h6>Dashboard</h6>
                  </Box>
                </Grid>
                {/* <Grid xs={12}> */}
                {/*   <Box */}
                {/*     sx={menuItemStyle} */}
                {/*     onClick={() => { */}
                {/*       setMobileMenuOpen(false); */}
                {/*       history.push('/ListaIntegradoras'); */}
                {/*     }} */}
                {/*   > */}
                {/*     <TaskRoundedIcon sx={iconStyle} /> */}
                {/*     <h6>Integradoras</h6> */}
                {/*   </Box> */}
                {/* </Grid> */}
                <Grid xs={12}>
                  <Box
                    sx={menuItemStyle}
                    onClick={() => {
                      setMobileMenuOpen(false);
                      history.push('/ListaEnvioCotacoes');
                    }}
                  >
                    <MarkChatReadRoundedIcon sx={iconStyle} />
                    <h6>Envios de cotações</h6>
                  </Box>
                </Grid>
                <Grid xs={12}>
                  <Box
                    sx={menuItemStyle}
                    onClick={() => {
                      setMobileMenuOpen(false);
                      history.push('/ListaChamados');
                    }}
                  >
                    <AssignmentRoundedIcon sx={iconStyle} />
                    <h6>Lista de chamados</h6>
                  </Box>
                </Grid>
                {/* <Grid xs={12}> */}
                {/*   <Box sx={menuItemStyle}> */}
                {/*     <DropDownMenu */}
                {/*       buttonName="Cobrança em conta de energia" */}
                {/*       menuItems={menuItems} */}
                {/*     /> */}
                {/*   </Box> */}
                {/* </Grid> */}
              </Grid>
            )}
            {roles.indexOf('Vendedor') >= 0 && (
              <Grid
                container
                rowSpacing={1}
              >
                <Grid xs={12}>
                  <Box
                    sx={menuItemStyle}
                    onClick={() => {
                      setMobileMenuOpen(false);
                      history.push('/DashboardVendedor');
                    }}
                  >
                    <BarChartRoundedIcon sx={iconStyle} />
                    <h6>Dashboard</h6>
                  </Box>
                </Grid>
                <Grid xs={12}>
                  <Box
                    sx={menuItemStyle}
                    onClick={() => {
                      setMobileMenuOpen(false);
                      history.push('/MeusProjetos');
                    }}
                  >
                    <AssignmentTurnedInRoundedIcon sx={iconStyle} />
                    <h6>Meus projetos</h6>
                  </Box>
                </Grid>
              </Grid>
            )}
            {roles.indexOf('Operador da Distribuidora') >= 0 && (
              <Grid
                container
                rowSpacing={1}
              >
                <Grid xs={12}>
                  <Box
                    sx={menuItemStyle}
                    onClick={() => {
                      setMobileMenuOpen(false);
                      history.push('/DashboardOperador');
                    }}
                  >
                    <BarChartRoundedIcon sx={iconStyle} />
                    <h6>Dashboard</h6>
                  </Box>
                </Grid>
                <Grid xs={12}>
                  <Box
                    sx={menuItemStyle}
                    onClick={() => {
                      setMobileMenuOpen(false);
                      history.push('/ListaIntegradoras');
                    }}
                  >
                    <TaskRoundedIcon sx={iconStyle} />
                    <h6>Integradoras</h6>
                  </Box>
                </Grid>
              </Grid>
            )}
          </Box>
        </Grid>
      </Grid>
    </div>
  );
};

export { Menu };
