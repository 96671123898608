import { alpha, createTheme, useTheme } from '@mui/material/styles';

export function useButtonThemes() {
  const theme = useTheme();

  const primaryButton = createTheme({
    components: {
      MuiButton: {
        defaultProps: {
          variant: 'contained',
          fullWidth: true,
          disableElevation: true,
          type: 'submit',
        },
        styleOverrides: {
          root: {
            borderRadius: '2rem',
            backgroundColor: theme.palette.primary.main,
            marginBottom: '0.5rem',
            padding: '10px 24px',
            '&:hover': {
              backgroundColor: alpha(theme.palette.primary.main, 0.8),
            },
            fontFamily: 'Poppins',
            textTransform: 'none',
            fontWeight: 700,
          },
        },
      },
    },
  });

  const secondaryButton = createTheme({
    components: {
      MuiButton: {
        defaultProps: {
          variant: 'contained',
          fullWidth: true,
          disableElevation: true,
          type: 'submit',
        },
        styleOverrides: {
          root: {
            color: theme.palette.primary.main,
            border: `1px ${theme.palette.primary.main} solid`,
            borderRadius: '2rem',
            backgroundColor: '#fff',
            marginBottom: '0.5rem',
            padding: '5px 12px',
            '&:hover': {
              borderColor: theme.palette.primary.main,
              backgroundColor: alpha(theme.palette.primary.main, 0.2),
            },
            fontFamily: 'Poppins',
            textTransform: 'none',
            fontWeight: 700,
          },
        },
      },
    },
  });

  return { primaryButton, secondaryButton };
}
