import React, { ReactNode, useState } from 'react';
import {
  Stack, Box, IconButton,
} from '@mui/material';
import { ArrowBackIosNewRounded, ArrowForwardIosRounded } from '@mui/icons-material';

interface CarouselProps {
  children: ReactNode[]
}

const Carousel = (props: CarouselProps) => {
  const { children }: CarouselProps = props;
  const [startIndex, setStartIndex] = useState(0);

  const style = {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
  };

  const handleNext = () => {
    setStartIndex((prevIndex) => Math.min(prevIndex + 1, children.length - 4));
  };

  const handlePrev = () => {
    setStartIndex((prevIndex) => Math.max(prevIndex - 1, 0));
  };

  return (
    <Box sx={style}>
      <IconButton onClick={handlePrev} disabled={startIndex === 0}>
        <ArrowBackIosNewRounded />
      </IconButton>
      <Stack
        direction="row"
        spacing={3}
        alignItems="stretch"
      >
        {children.slice(startIndex, startIndex + 4)}
      </Stack>
      <IconButton onClick={handleNext} disabled={startIndex >= children.length - 4}>
        <ArrowForwardIosRounded />
      </IconButton>
    </Box>
  );
};

export default Carousel;
