import LoadingBar from 'react-top-loading-bar';
import DataTable from 'react-data-table-component';
import SortIcon from '@mui/icons-material/ArrowUpward';
import { ToastContainer } from 'react-toastify';
import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { parseCookies } from 'nookies';
import { AxiosResponse } from 'axios';
import { Header } from '../../components/Header';
import { Navigator } from '../../components/Navigator';
import { FormPage } from '../../components/FormPage';
import style from '../ListaBancos/listabanco.module.scss';
import { LoadingIcon } from '../../components/LoadingIcon';
import { DialogComponent } from '../../components/DialogComponent';
import { Footer } from '../../components/Footer';
import { api } from '../../services/api';

interface Cobranca {
  id: number,
  nome: string,
  sobrenome: string,
  dataDeSolicitacao: string,
  ultimaMovimentacao: string,
  status: string,
}

export function ListaCobrancas() {
  const [cobranca, setCobranca] = useState<Cobranca[]>([]);

  const history = useHistory();
  const { 'nextauth.tag': tag } = parseCookies();
  const [isOpen, setIsOpen] = useState(false);
  const [loadingText, setLoadingText] = useState('');

  useEffect(() => {
    const getCobrancas = async () => {
      const teste = [{
        id: 3, nome: 'nome', sobrenome: 'sobrenome', projeto: 'projeto', dataDeSolicitacao: 'data', ultimaMovimentacao: 'data', status: 'Ativo',
      }, {
        id: 2, nome: 'nome', sobrenome: 'sobrenome', projeto: 'projeto', dataDeSolicitacao: 'data', ultimaMovimentacao: 'data', status: 'Inativo',
      } as Cobranca];

      setCobranca(teste);
    };
    getCobrancas();
  }, []);

  const handleClienteName = (row: { [x: string]: any; }) => `${row['nome']} ${row['sobrenome']}`;

  // @ts-ignore
  const handleEvaluate = (state) => {
    history.push({ pathname: '/EditarCobranca', state: { id: state.target.id } });
  };

  const columns = [
    {
      name: 'Código',
      selector: (row: { [x: string]: any; }) => row['id'],
      sortable: true,
      width: '100px',
    },
    // },
    {
      name: 'Cliente',
      selector: (row: { [x: string]: any; }) => handleClienteName(row),
      sortable: true,
      right: false,
      center: false,
      minWidth: '200',
      width: '200',
    },
    {
      name: 'Projeto',
      selector: (row: { [x: string]: any; }) => row['projeto'],
      sortable: true,
      right: false,
      center: false,
      minWidth: '200',
      width: '200',
    },
    {
      name: 'Data da solicitação',
      selector: (row: { [x: string]: any; }) => row['dataDeSolicitacao'],
      sortable: true,
      right: false,
      center: false,
      minWidth: '200',
      width: '200',
    },
    {
      name: 'Última movimentação',
      selector: (row: { [x: string]: any; }) => row['ultimaMovimentacao'],
      sortable: true,
      right: false,
      center: false,
      minWidth: '200',
      width: '200',
    },
    {
      name: 'Status',
      selector: (row: { [x: string]: any; }) => row['status'],
      width: '100px',
      sortable: true,
      conditionalCellStyles: [
        {
          when: (row: { [x: string]: any; }) => row['status'] === 'Ativo',
          style: {
            color: 'green',
            '&:hover': {
              cursor: 'pointer',
            },
          },
        },
        {
          when: (row: { [x: string]: any; }) => row['status'] === 'Inativo',
          style: {
            color: 'red',
            '&:hover': {
              cursor: 'pointer',
            },
          },
        },
      ],
    },
    {
      button: true,
      // eslint-disable-next-line react/no-unstable-nested-components
      cell: (row: { [x: string]: any; }) => (
        // eslint-disable-next-line react/jsx-no-useless-fragment
        <>
          { row['status'] === 'Ativo' ? (
            <button
              className={style.button}
              type="button"
              id={row['id']}
              onClick={handleEvaluate}
            >
              Avaliar
            </button>
          )
            : ''}
        </>
      ),
    },
  ];
  const paginationComponentOptions = {
    selectAllRowsItem: true,
    selectAllRowsItemText: 'Todos',
    rowsPerPageText: 'Registros por página',
  };
  return (
    <div>
      <FormPage>
        <br />
        <div className={style.listaBancoContainer}>
          <h1>Cobranças</h1>
          {cobranca.length === 0 ? <LoadingIcon active />
            : (
              <DataTable
                title=""
                key="id"
                // @ts-ignore
                columns={columns}
                // @ts-ignore
                // paginationComponent={BootyPagination}
                paginationComponentOptions={paginationComponentOptions}
                data={cobranca}
                // paginationPerPage={30}
                // @ts-ignore
                defaultSortField="nome"
                noDataComponent="Nenhum banco cadastrado"
                sortIcon={<SortIcon />}
                pagination
              />
            )}
        </div>
        <br />
      </FormPage>
      <DialogComponent isOpen={isOpen} labelText={loadingText} />
    </div>
  );
}
