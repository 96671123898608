import React from 'react';
import Grid from '@mui/material/Unstable_Grid2';
import {
  FormControl,
  InputAdornment,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Typography,
  useTheme,
} from '@mui/material';
import style from './projectquotation.module.scss';
import { Project } from '../../contexts/@types';
import { FieldError } from '../../pages/NovoProjeto';
import { applyStrNumberMask } from '../../utils/util';

interface ProjectQuotationProps {
  project: Project
  handleChangeProject: Function
  isDisabled: boolean
  fieldsError: FieldError
}
const ProjectQuotation = (props: ProjectQuotationProps) => {
  const {
    project,
    handleChangeProject,
    isDisabled,
    fieldsError,
  }: ProjectQuotationProps = props;

  const theme = useTheme();

  return (
    <Grid container spacing={2} rowSpacing={2}>
      <Grid xs={12}>
        <Typography sx={{
          fontSize: {
            xs: '16px',
            md: '24px',
          },
          fontFamily: 'Poppins',
          color: theme.palette.primary.main,
          fontWeight: '700',
        }}
        >
          Cotação do projeto
        </Typography>
        <hr className={style.titleDivisor} />
      </Grid>
      <Grid xs={12}>
        <Typography sx={{
          fontSize: {
            xs: '12px',
            md: '16px',
          },
          fontFamily: 'Poppins',
          fontWeight: '400',
        }}
        >
          Instruções: Após continuar, você será redirecionado para o
          cadastro do cliente. A carência mínima é de 1 mês.
        </Typography>
      </Grid>
      <Grid xs={12} lg={2.4}>
        <TextField
          error={fieldsError.name}
          fullWidth
          required
          label="Nome do projeto"
          variant="outlined"
          value={project.nome}
          onChange={handleChangeProject('nome')}
          InputLabelProps={{
            shrink: !!project.nome,
          }}
          disabled={isDisabled}
        />
      </Grid>
      <Grid xs={12} lg={2.4}>
        <TextField
          error={fieldsError.power}
          fullWidth
          required
          label="Potência instalada (kW)"
          variant="outlined"
          value={applyStrNumberMask(project.potencia)}
          onChange={handleChangeProject('potencia')}
          InputProps={{
            endAdornment: <InputAdornment position="end">kW</InputAdornment>,
          }}
          InputLabelProps={{
            shrink: true,
          }}
          disabled={isDisabled}
        />
      </Grid>
      <Grid xs={12} lg={2.4}>
        <TextField
          error={fieldsError.totalValue}
          fullWidth
          required
          label="Valor total do projeto"
          variant="outlined"
          value={project.valorTotal}
          onChange={handleChangeProject('valorTotal')}
          InputProps={{
            startAdornment: <InputAdornment position="start">R$</InputAdornment>,
          }}
          InputLabelProps={{
            shrink: true,
          }}
          disabled={isDisabled}
        />
      </Grid>
      <Grid xs={12} lg={2.4}>
        <TextField
          error={fieldsError.entryValue}
          fullWidth
          required
          label="Valor de entrada do projeto"
          variant="outlined"
          value={project.valorEntrada}
          onChange={handleChangeProject('valorEntrada')}
          InputProps={{
            startAdornment: <InputAdornment position="start">R$</InputAdornment>,
          }}
          InputLabelProps={{
            shrink: true,
          }}
          disabled={isDisabled}
        />
      </Grid>
      <Grid xs={12} lg={2.4}>
        <FormControl fullWidth required error={fieldsError.lack}>
          <InputLabel id="demo-simple-select-label" shrink={isDisabled ? true : undefined}>Carência</InputLabel>
          <Select
            labelId="demo-simple-select-label"
            id="demo-simple-select"
            label="Carência"
            value={project.carencia > 0 ? project.carencia : null}
            onChange={handleChangeProject('carencia')}
            disabled={isDisabled}
          >
            <MenuItem value={1}>1 mês</MenuItem>
            <MenuItem value={2}>2 meses</MenuItem>
            <MenuItem value={3}>3 meses</MenuItem>
            <MenuItem value={4}>4 meses</MenuItem>
            <MenuItem value={5}>5 meses</MenuItem>
            <MenuItem value={6}>6 meses</MenuItem>
          </Select>
        </FormControl>
      </Grid>
    </Grid>
  );
};
export default ProjectQuotation;
