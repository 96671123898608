import React, {
  ChangeEvent,
  useEffect,
  useRef,
  useState,
} from 'react';
import LoadingBar, { LoadingBarRef } from 'react-top-loading-bar';
import { toast, ToastContainer } from 'react-toastify';
import {
  alpha,
  Autocomplete,
  Box,
  TextField,
  Typography, useTheme,
} from '@mui/material';
import Grid from '@mui/material/Unstable_Grid2';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import dayjs from 'dayjs';
import { useHistory } from 'react-router-dom';
import { parseCookies } from 'nookies';
import { api } from '../../services/api';
import { nonMonetaryDecimalValue } from '../../utils/util';
import { DialogComponent } from '../../components/DialogComponent';
import MediaCard from '../../components/MediaCard';
import Steps from '../../components/Steps';
import Card from '../../components/elements/Card';
import Button from '../../components/elements/Button';

interface Estado {
  id: number;
  name: string;
  acronym: string;
}

interface Cidade {
  id: number;
  name: string;
}

interface Bank {
  id: number,
  ShortName: String,
  LongName: String,
  COMPE: String,
}

interface ViaCepErro {
  erro: boolean;
}

export function CadastroIntegradora() {
  const theme = useTheme();

  const emailMask = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  const cnpjMask = /[0-9]{2}\.?[0-9]{3}\.?[0-9]{3}\/?[0-9]{4}-?[0-9]{2}/;
  const dateMaskAlt = /^(?:(?:31(\/|-|\.)(?:0?[13578]|1[02]))\1|(?:(?:29|30)(\/|-|\.)(?:0?[13-9]|1[0-2])\2))(?:(?:1[6-9]|[2-9]\d)?\d{2})$|^(?:29(\/|-|\.)0?2\3?:(?:(?:1[6-9]|[2-9]\d)?(?:0[48]|[2468][048]|[13579][26])|(?:(?:16|[2468][048]|[3579][26])00)))$|^(?:0?[1-9]|1\d|2[0-8])(\/|-|\.)(?:(?:0?[1-9])|(?:1[0-2]))\4(?:(?:1[6-9]|[2-9]\d)?\d{2})$/;
  const telCheck = (num = '') => num.replace(/\D/g, '');
  const potencyCheck = (str = '') => (str !== '' && str !== '0') && str.substring(str.length, str.length - 1) !== '.';

  const [errorMessageInput, setErrorMessageInput] = useState<string[]>([]);
  const [errorMessageUpload, setErrorMessageUpload] = useState<string[]>([]);
  const [estados, setEstados] = useState<Estado[]>();
  const [cidades, setCidades] = useState<Cidade[]>();

  const [razaoSocial, setRazaoSocial] = useState('');
  const [cnpj, setCnpj] = useState('');
  const [bank, setBank] = useState(0);
  const [banks, setBanks] = useState<Bank[]>();
  const [telefone, setTelefone] = useState('');
  const [rua, setRua] = useState('');
  const [district, setDistrict] = useState('');
  const [numero, setNumero] = useState('');
  const [complemento, setComplemento] = useState('');
  const [cep, setCep] = useState('');
  const [agency, setAgency] = useState('');
  const [accountNumber, setAccountNumber] = useState('');
  const [cidade, setCidade] = useState(0);
  const [estado, setEstado] = useState(0);
  const [potenciaInstalada, setPotenciaInstalada] = useState('');
  const [quantidadeFuncionarios, setQuantidadeFuncionarios] = useState('');
  const [dataFundacao, setDataFundacao] = useState('');
  const [email, setEmail] = useState('');
  const [isOpen, setIsOpen] = useState(false);
  const [loadingText, setLoadingText] = useState('');
  const [step, setStep] = useState(0);
  const history = useHistory();
  const { 'nextauth.tag': userId } = parseCookies();

  const getCidades = async (state: number) => {
    const response = await api.post('/address/city/get', { stateId: state });
    setCidades(response.data);
  };

  useEffect(() => {
    const getStates = async () => {
      const response = await api.post('/address/state/get', {}, {});
      setEstados(response.data);
    };
    const getBanks = async () => {
      const response = await api.post('/banks/bacen/get', {}, {});
      setBanks(response.data);
    };

    getStates();
    getBanks();
    getCidades(1);
  }, []);

  const getToday = (m: string) => {
    const date = new Date();
    const day = date.getDate()
      .toString();
    let month = ((date.getMonth() + 1).toString());
    if (month.length === 1) month = `0${month}`;
    const year = date.getFullYear()
      .toString();

    // check if date is after today
    return !(m.slice(6, 10) > year
      || (m.slice(6, 10) >= year
        && m.slice(3, 5) > month)
      || (m.slice(0, 2) > day
        && m.slice(3, 5) >= month
        && m.slice(6, 10) >= year));
  };

  const ref = useRef<LoadingBarRef>(null);

  const refreshPage = (interval: number) => setInterval(() => window.location.reload(), interval);

  /**
   * Field Validation
   */

  const validateRazaoSocial = (m: string) => m.length > 5;
  const validateCnpj = (m: string) => cnpjMask.test(m);
  const validateTelefone = (m: string) => telCheck(m).length > 10;
  const validateRua = (m: string) => m.length > 5;
  const validateNumero = (m: string) => m.length > 0;
  // const validateComplemento = (m: string) => m.length > 0;
  const validateCep = (m: string) => m.length >= 5;
  const validateDistrict = (m:string) => m.length > 0;
  const validateCidade = (m: number) => m > 0;
  const validateEstado = (m: number) => m > 0;
  const validatePotenciaInstalada = (m: string) => potencyCheck(m);
  const validateQuantidadeFuncionarios = (m: string) => m.length >= 1;
  const validateDataFundacao = (m: string) => {
    const leapDay = m.slice(0, 2);
    const leapMonth = m.slice(3, 5);
    const leapYear = m.slice(6, m.length);

    if (getToday(m) && dateMaskAlt.test(m)) {
      return true;
    }
    return (parseInt(leapYear, 10) % 4 === 0) && leapMonth === '02' && leapDay === '29';
  };
  const validateAgency = (m: string) => m.length >= 4;
  const validateAccount = (m: string) => m.length >= 1;
  const validateBank = (m: number) => m > 0;
  const validateEmail = (m: string) => emailMask.test(m);

  /** ONCHANGE HANDLERS ===================== */
  const handleRazaoSocialChange = (e: ChangeEvent<HTMLInputElement>) => {
    setRazaoSocial(e.target.value);
  };

  const handleCnpjChange = (e: ChangeEvent<HTMLInputElement>) => {
    let value = '';
    const mask = '0123456789';

    // remove unwanted characters (including recently pressed ones)
    for (let i = 0; i < e.target.value.length; ++i) {
      if (mask.includes(e.target.value[i])) {
        value += e.target.value[i];
      }
    }

    // apply the "__.___.___/____-__" mask
    let newValue = '';

    for (let i = 0; i < value.length; ++i) {
      newValue += value[i];
      if (i === 1 || i === 4) {
        newValue += '.';
      }
      if (i === 11) {
        newValue += '-';
      }
      if (i === 7) {
        newValue += '/';
      }
    }

    // forcefully limit characters count
    if (newValue.length > 18) {
      newValue = newValue.substring(0, 18);
    }

    e.target.value = newValue;
    setCnpj(newValue);
  };

  const handleTelefoneChange = (e: ChangeEvent<HTMLInputElement>) => {
    let value = '';
    const mask = '0123456789';

    // remove unwanted characters (including recently pressed ones)
    for (let i = 0; i < e.target.value.length; ++i) {
      if (mask.includes(e.target.value[i])) {
        value += e.target.value[i];
      }
    }

    // apply the "(__)_____-____" mask
    let newValue = '';

    for (let i = 0; i < value.length; ++i) {
      if (i === 0) {
        newValue += '(';
      }
      if (i === 2) {
        newValue += ') ';
      }
      if (i === 7) {
        newValue += '-';
      }

      newValue += value[i];
    }

    // forcefully limit characters count
    if (newValue.length > 15) {
      newValue = newValue.substring(0, 15);
    }

    e.target.value = newValue;
    setTelefone(e.target.value);
  };

  const handleRuaChange = (e: ChangeEvent<HTMLInputElement>) => {
    setRua(e.target.value);
  };

  const handleNumeroChange = (e: ChangeEvent<HTMLInputElement>) => {
    let value = '';
    const mask = '0123456789';

    // remove unwanted characters (including recently pressed ones)
    for (let i = 0; i < e.target.value.length; ++i) {
      if (mask.includes(e.target.value[i])) {
        value += e.target.value[i];
      }
    }

    e.target.value = value;
    setNumero(e.target.value);
  };

  const handleComplementoChange = (e: ChangeEvent<HTMLInputElement>) => {
    setComplemento(e.target.value);
  };

  const buscaCep = async (cepVal: string) => {
    // remove hyphenation
    const value = cepVal.replace('-', '');
    const request = {
      data: {
        ref: value,
      },
    };
    const response = await api.post('/address/cep/get', request);

    const error = response.data as ViaCepErro;
    if (error.erro !== true) {
      // update logradouro field
      const ruaElem = document.getElementById('ruaField');
      setRua(response.data.rua);
      ruaElem?.setAttribute('value', response.data.rua);

      const districtElem = document.getElementById('districtField');
      setDistrict(response.data.bairro);
      districtElem?.setAttribute('value', response.data.bairro);

      // update state field
      const estadoEle = document.getElementById('estadoField');
      setEstado(response.data.estadoId);
      estadoEle?.setAttribute('value', response.data.estadoId);

      const cidadeEle = document.getElementById('cidadeField');

      getCidades(response.data.estadoId);
      setCidade(response.data.cidadeId);

      cidadeEle?.setAttribute('value', response.data.cidadeId);
    }
    setIsOpen(false);
  };

  const handleCepChange = (e: ChangeEvent<HTMLInputElement>) => {
    let value = '';
    const mask = '0123456789';

    // remove unwanted characters (including recently pressed ones)
    for (let i = 0; i < e.target.value.length; ++i) {
      // prevent field to go over 9 characters
      if (i === 9) break;

      if (i === 5) {
        value += '-';
      }

      if (mask.includes(e.target.value[i])) {
        value += e.target.value[i];
      }
    }

    e.target.value = value;
    setCep(value);

    if (value.length === 9) {
      setIsOpen(true);
      buscaCep(value);
    }
  };

  const handleDistrictChange = (e: ChangeEvent<HTMLInputElement>) => {
    setDistrict(e.target.value);
  };

  const handleAgencyChange = (e: ChangeEvent<HTMLInputElement>) => {
    let value = '';
    const mask = '0123456789';

    // remove unwanted characters (including recently pressed ones)
    for (let i = 0; i < e.target.value.length; ++i) {
      // prevent field to go over 9 characters
      if (i === 4) break;

      if (mask.includes(e.target.value[i])) {
        value += e.target.value[i];
      }
    }

    e.target.value = value;
    setAgency(value);
  };
  const handleAccountChange = (e: ChangeEvent<HTMLInputElement>) => {
    let value = '';
    const mask = '0123456789';

    // remove unwanted characters (including recently pressed ones)
    for (let i = 0; i < e.target.value.length; ++i) {
      // prevent field to go over 9 characters
      if (i === 11) break;

      if (i === e.target.value.length - 1) {
        value += '-';
      }

      if (mask.includes(e.target.value[i])) {
        value += e.target.value[i];
      }
    }

    e.target.value = value;
    setAccountNumber(value);
  };

  const handlePotenciaInstaladaChange = (e: ChangeEvent<HTMLInputElement>) => {
    e.target.value = nonMonetaryDecimalValue(e);
    setPotenciaInstalada(e.target.value);
  };

  const handleQuantidadeFuncionariosChange = (
    e: ChangeEvent<HTMLInputElement>,
  ) => {
    let value = '';
    const mask = '0123456789';

    // remove unwanted characters (including recently pressed ones)
    for (let i = 0; i < e.target.value.length; ++i) {
      if (mask.includes(e.target.value[i])) {
        value += e.target.value[i];
      }
    }

    e.target.value = value;
    setQuantidadeFuncionarios(e.target.value);
  };

  const handleDataFundacaoChange = (date: Date | null | undefined, context: any) => {
    setDataFundacao(dayjs(date).format('DD/MM/YYYY'));
  };

  const handleEmailChange = (e: ChangeEvent<HTMLInputElement>) => {
    setEmail(e.target.value);
  };

  const handleCityChange = (event: React.ChangeEvent<{}>, value: any) => {
    const cidadeId = value.id;
    setCidade(cidadeId);
  };

  const handleStateChange = (event: React.ChangeEvent<{}>, value: any) => {
    const estadoId = value.id;
    setEstado(estadoId);
    getCidades(estadoId);
  };

  /**
   * handleSubmit
   * Submits form to the backend.
   * @param event
   */
  const handleSubmit = async () => {
    setLoadingText('Enviando cadastro...');
    setIsOpen(true);
    const urlv = window.location.href.split('/');
    const urlc = `${urlv[0]}//${urlv[2]}`;

    toast.info('Enviando Cadastro. Por favor, aguarde um instante.');

    const request = {
      headers: { 'Content-Type': 'multipart/form-data' },
      data: {
        razaoSocial,
        cnpj,
        telefone,
        rua,
        numero,
        district,
        complemento,
        cep,
        cidade,
        potenciaInstalada,
        quantidadeFuncionarios,
        dataFundacao,
        email,
        agency,
        accountNumber,
        bankId: bank,

        returnUrl: urlc,
        userId: Number(userId),
      },
    };

    const response = await api.post('integrator/insert', request);

    switch (response.data) {
      case 'INTEGRATOR_INSERTED_OK':
        setIsOpen(false);
        toast.success('Cadastro recebido. Verificar caixa de e-mail.');
        history.push('/ListaIntegradoras');
        break;
      case 'DUPLICATED_EMAIL':
        setIsOpen(false);
        toast.error('Email já cadastrado na base de dados.');
        break;
      case 'DUPLICATED_CNPJ':
        setIsOpen(false);
        toast.error('CNPJ já cadastrado na base de dados');
        break;
      default:
        setIsOpen(false);
        toast.error(`Falha ao inserir usuário no sistema.${response.data}`);
    }
  };

  const handleBankChange = (event: any, newValue: Bank | null) => {
    if (newValue) {
      setBank(newValue.id);
    }
  };

  const stateByCEP = (estados?.find(({ id }) => id === estado)) ?? { id: 0, name: '', acronym: '' };
  const cityByCEP = (cidades?.find(({ id }) => id === cidade)) ?? { id: 0, name: '' };
  const bankStringToDate = (banks?.find(({ id }) => id === bank)) ?? {
    id: 0, COMPE: '', LongName: '', ShortName: '',
  };

  const [stepError, setStepError] = useState(-1);
  const handleSubmitIntegratorData = () => {
    if (
      validateRazaoSocial(razaoSocial)
      && validateCnpj(cnpj)
      && validateTelefone(telefone)
      && validateQuantidadeFuncionarios(quantidadeFuncionarios)
      && validatePotenciaInstalada(potenciaInstalada)
      && validateDataFundacao(dataFundacao)
      && validateEmail(email)) {
      setStepError(-1);
      setStep(step + 1);
    } else {
      setStepError(0);
      toast.error(
        <div style={{ fontWeight: '8px' }}>
          <strong>Formato inválido!</strong>
          {' '}
          <br />
          {' '}
          Verifique os valores dos campos.
        </div>,
      );
    }
  };

  const integratorData = (
    <form onSubmit={handleSubmitIntegratorData}>
      <Grid container spacing={2} rowSpacing={2}>
        <Grid xs={6} md={6}>
          <TextField
            id="corporateReason"
            label="Razão Social"
            required
            fullWidth
            error={razaoSocial.length > 0 ? !validateRazaoSocial(razaoSocial) : stepError === 0}
            value={razaoSocial}
            onChange={handleRazaoSocialChange}
          />
        </Grid>
        <Grid xs={6} md={6}>
          <TextField
            id="cnpj"
            label="CNPJ"
            required
            fullWidth
            error={cnpj.length > 0 ? !validateCnpj(cnpj) : stepError === 0}
            value={cnpj}
            onChange={handleCnpjChange}
          />
        </Grid>
        <Grid xs={6} md={6}>
          <TextField
            id="phone"
            label="Telefone"
            required
            fullWidth
            error={telefone.length > 0 ? !validateTelefone(telefone) : stepError === 0}
            value={telefone}
            onChange={handleTelefoneChange}
          />
        </Grid>
        <Grid xs={6} md={6}>
          <TextField
            id="employeesNum"
            label="QTD Funcionários"
            required
            fullWidth
            error={
              quantidadeFuncionarios.length > 0
                ? !validateQuantidadeFuncionarios(quantidadeFuncionarios) : stepError === 0
            }
            value={quantidadeFuncionarios}
            onChange={handleQuantidadeFuncionariosChange}
          />
        </Grid>
        <Grid xs={6} md={6}>
          <TextField
            id="powerKWP"
            label="Potência instalada (kWp)"
            required
            fullWidth
            error={
            potenciaInstalada.length > 0
              ? !validatePotenciaInstalada(potenciaInstalada) : stepError === 0
          }
            value={potenciaInstalada}
            onChange={handlePotenciaInstaladaChange}
          />
        </Grid>
        <Grid xs={6} md={6}>
          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <DatePicker
              sx={{ width: '100%' }}
              openTo="month"
              views={['year', 'month', 'day']}
              label="Data da Fundação *"
              onChange={handleDataFundacaoChange}
            />
          </LocalizationProvider>
        </Grid>
        <Grid xs={12} md={12}>
          <TextField
            id="email"
            label="E-mail"
            required
            fullWidth
            error={email.length > 0 ? !validateEmail(email) : stepError === 0}
            value={email}
            onChange={handleEmailChange}
          />
        </Grid>
        <Grid xs={12} md={12}>
          <Box sx={{ display: 'flex', justifyContent: 'space-between', mt: '1rem' }}>
            <Button
              variant="outlined"
              onClick={() => { history.push('/ListaIntegradoras'); }}
              sx={{ width: '10rem' }}
            >
              Cancelar
            </Button>
            <Button
              variant="contained"
              onClick={() => handleSubmitIntegratorData()}
              sx={{ width: '10rem' }}
            >
              Próximo
            </Button>
          </Box>
        </Grid>
      </Grid>
    </form>
  );

  const handleSubmitAddressData = () => {
    if (
      validateCep(cep)
      && validateEstado(estado)
      && validateCidade(cidade)
      && validateDistrict(district)
      && validateRua(rua)
      && validateNumero(numero)) {
      setStepError(-1);
      setStep(step + 1);
    } else {
      setStepError(1);
      toast.error(
        <div style={{ fontWeight: '8px' }}>
          <strong>Formato inválido!</strong>
          {' '}
          <br />
          {' '}
          Verifique os valores dos campos.
        </div>,
      );
    }
  };

  const addressData = (
    <Grid container spacing={2} rowSpacing={2}>
      <Grid xs={7} md={7}>
        <TextField
          id="cep"
          label="CEP"
          required
          fullWidth
          error={cep.length > 0 ? !validateCep(cep) : stepError === 1}
          value={cep}
          onChange={handleCepChange}
        />
      </Grid>
      <Grid xs={5} md={5}>
        <Autocomplete
          id="state"
          disableClearable
          options={estados || [{ id: 0, name: '', acronym: '' }]}
          getOptionLabel={(option: Estado) => option.acronym}
          value={stateByCEP}
          renderInput={(params) => (
            <TextField
              {...params}
              label="Estado"
              required
              fullWidth
              error={estado !== 0 ? !validateEstado(estado) : stepError === 1}
            />
          )}
          onChange={handleStateChange}
        />
      </Grid>
      <Grid xs={6} md={6}>
        <Autocomplete
          id="city"
          disableClearable
          options={cidades || [{ id: 0, name: '' }]}
          getOptionLabel={(option: Cidade) => option.name}
          value={cityByCEP}
          renderInput={(params) => (
            <TextField
              {...params}
              label="Cidade"
              required
              fullWidth
              error={cidade !== 0 ? !validateCidade(cidade) : stepError === 1}
            />
          )}
          onChange={handleCityChange}
        />
      </Grid>
      <Grid xs={6} md={6}>
        <TextField
          id="district"
          label="Bairro"
          required
          fullWidth
          error={district.length > 0 ? !validateDistrict(district) : stepError === 1}
          InputLabelProps={{
            shrink: !!district,
          }}
          value={district}
          onChange={handleDistrictChange}
        />
      </Grid>
      <Grid xs={9} md={9}>
        <TextField
          id="logradouro"
          label="Logradouro"
          required
          fullWidth
          InputLabelProps={{
            shrink: !!rua,
          }}
          error={rua.length > 0 ? !validateRua(rua) : stepError === 1}
          value={rua}
          onChange={handleRuaChange}
        />
      </Grid>
      <Grid xs={3} md={3}>
        <TextField
          id="num"
          label="N°"
          required
          fullWidth
          error={numero.length > 0 ? !validateNumero(numero) : stepError === 1}
          value={numero}
          onChange={handleNumeroChange}
        />
      </Grid>
      <Grid xs={12} md={12}>
        <TextField
          id="complement"
          label="Complemento"
          fullWidth
          value={complemento}
          onChange={handleComplementoChange}
        />
      </Grid>
      <Grid xs={12} md={12}>
        <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
          <Button
            variant="outlined"
            onClick={() => { setStep(step - 1); }}
            sx={{ width: '10rem' }}
          >
            Voltar
          </Button>
          <Button
            variant="contained"
            onClick={() => handleSubmitAddressData()}
            sx={{ width: '10rem' }}
          >
            Próximo
          </Button>
        </Box>
      </Grid>
    </Grid>
  );

  const handleSubmitBankData = () => {
    if (
      validateAgency(agency)
      && validateAccount(accountNumber)
      && validateBank(bank)) {
      setStepError(-1);
      setStep(0);
      handleSubmit();
    } else {
      setStepError(2);
      toast.error(
        <div style={{ fontWeight: '8px' }}>
          <strong>Formato inválido!</strong>
          {' '}
          <br />
          {' '}
          Verifique os valores dos campos.
        </div>,
      );
    }
  };

  const bankData = (
    <Grid container spacing={2} rowSpacing={2}>
      <Grid xs={12} md={6}>
        <TextField
          id="agency"
          label="Agência"
          required
          fullWidth
          error={agency.length > 0 ? !validateAgency(agency) : stepError === 2}
          value={agency}
          onChange={handleAgencyChange}
        />
      </Grid>
      <Grid xs={12} md={6}>
        <TextField
          id="account"
          label="Conta"
          required
          fullWidth
          error={accountNumber.length > 0 ? !validateAccount(accountNumber) : stepError === 2}
          value={accountNumber}
          onChange={handleAccountChange}
        />
      </Grid>
      <Grid xs={12} md={12}>
        <Autocomplete
          id="bank"
          disableClearable
          options={banks || [{
            id: 0, COMPE: '', LongName: '', ShortName: '',
          }]}
          getOptionLabel={(option: Bank) => option.ShortName as string}
          renderInput={(params) => (
            <TextField
              {...params}
              label="Banco"
              required
              fullWidth
              error={bank > 0 ? !validateBank(bank) : stepError === 2}
            />
          )}
          value={bankStringToDate}
          onChange={handleBankChange}
        />
      </Grid>
      <Grid xs={12} sm={12} md={12} lg={12}>
        <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
          <Button
            variant="outlined"
            onClick={() => { setStep(step - 1); }}
            sx={{ width: '10rem' }}
          >
            Voltar
          </Button>
          <Button
            variant="contained"
            onClick={() => handleSubmitBankData()}
            sx={{ width: '10rem' }}
          >
            Enviar cadastro
          </Button>
        </Box>
      </Grid>
    </Grid>
  );

  return (
    <div>
      <LoadingBar color={theme.palette.primary.main} ref={ref} />
      <Card>
        <form onSubmit={handleSubmit} id="formIntegrator" encType="multipart/form-data">
          <h5 style={{ marginBottom: '1rem' }}>Cadastro de integradora</h5>
          <Typography sx={{ marginBottom: '1rem', fontSize: { xs: '12px', md: '16px' }, fontFamily: 'Poppins' }}>Preencha o formulário com os dados da nova integradora</Typography>
          <Steps step={step} stepError={stepError} />
          {step === 0 && integratorData}
          {step === 1 && addressData}
          {step === 2 && bankData}
        </form>
      </Card>
      <DialogComponent isOpen={isOpen} labelText={loadingText} />
    </div>
  );
}
