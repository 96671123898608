import {
  alpha,
  Backdrop,
  Box,
  Fade, FormControl, InputLabel, MenuItem,
  Modal, Select,
  Stack,
  TextField,
  Typography,
  useMediaQuery,
  useTheme,
} from '@mui/material';
import React, { useEffect, useState } from 'react';
import InputMask from 'react-input-mask';
import { toast } from 'react-toastify';
import { Address, Customer } from '../../contexts/@types';
import Button from '../elements/Button';
import { DataDisplay } from '../elements/DataDisplay';
import { applyCurrencyMask, applyZipMask } from '../../utils/util';
import Carousel from '../Carousel';
import { defaultAddress, defaultCustomer } from '../../contexts/@defaultValues';
import { api } from '../../services/api';

const style = {
  width: '70vw',
  height: '70vh',
  position: 'relative',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  overflow: 'auto',

  display: 'flex',
  flexDirection: 'column',
  p: '2rem',
  borderRadius: '1rem',
  gap: '2rem',

  bgcolor: 'background.paper',
  boxShadow: 24,
};

interface Ph3aDataSearchProps {
  open: boolean
  setOpen: Function
  customer: Customer
  handleChangeCustomer: Function
  setCustomer: Function
  address: Address
  setAddress: Function
  validateCustomerForm: () => string;
}

type PH3AAddressProps = {
  zip: string
  street: string
  number: number
  street2: string
  district: string
  cityName: string,
  stateName: string
};

type PH3AProps = {
    name: string | null
    rg: string | null
    birthDate: string | null
    phones: Array<string> | []
    motherName: string | null
    maritalStatus: string | null
    professions: Array<string> | []
    income: number | null
    emails: Array<string> | []
    addresses: Array<PH3AAddressProps> | []
}

const Ph3aDataSearch = (props: Ph3aDataSearchProps) => {
  const {
    open,
    setOpen,
    customer,
    handleChangeCustomer,
    setCustomer,
    address,
    setAddress,
    validateCustomerForm,
  }: Ph3aDataSearchProps = props;
  const [isConfirmed, setIsConfirmed] = useState<boolean>(false);
  const [newSearch, setNewSearch] = useState<boolean>(true);

  const [apiCustomer, setApiCustomer] = useState<Customer>(defaultCustomer);
  const [apiAddress, setApiAddress] = useState<Address>(defaultAddress);

  const [emailOps, setEmailOps] = useState<Array<string>>([]);
  const [phoneOps, setPhoneOps] = useState<Array<string>>([]);
  const [professionOps, setProfessionOps] = useState<Array<string>>([]);
  const [addressOps, setAddressOps] = useState<Array<PH3AAddressProps>>([]);

  const [selectedCardId, setSelectedCardId] = useState(0);
  const [isDataApplied, setDataApplied] = useState<boolean>();
  const [isFirstRender, setIsFirstRender] = useState(true);

  const theme = useTheme();
  const isXs = useMediaQuery(theme.breakpoints.only('xs'));

  const handleClose = () => setOpen(false);

  const getPH3AData = (data: PH3AProps) => {
    setDataApplied(false);

    setApiCustomer({
      ...customer,
      nome: data.name || customer.nome,
      rg: data.rg || customer.rg,
      dataNascimento: data.birthDate || customer.dataNascimento,
      telefone: data.phones[0] || customer.telefone,
      nomeMae: data.motherName || customer.nomeMae,
      estadoCivil: data.maritalStatus || customer.estadoCivil,
      profissao: data.professions[0] || customer.profissao,
      faturamentoMensal: String(data.income) || customer.faturamentoMensal,
      email: data.emails[0] || customer.email,
    });

    if (data && data.addresses[0]) {
      setApiAddress({
        ...address,
        zip: applyZipMask(data.addresses[0].zip) || applyZipMask(address.zip),
        street1: data.addresses[0].street || address.street1,
        number: data.addresses[0].number || address.number,
        street2: data.addresses[0].street2 || address.street2,
        district: data.addresses[0].district || address.district,
      });
    } else {
      setApiAddress({
        ...address,
        zip: applyZipMask(address.zip),
        street1: address.street1,
        number: address.number,
        street2: address.street2,
        district: address.district,
      });
    }

    setEmailOps(data.emails || []);
    setPhoneOps(data.phones || []);
    setProfessionOps(data.professions || []);
    setAddressOps(data.addresses || []);
  };

  const confirmPH3AUsage = async () => {
    const cpf = customer.cpf.replace(/\D/g, '');
    if (cpf.length !== 11) {
      toast.error(`Número de CPF ${customer.cpf} inválido.`);
      return;
    }
    const body = {
      cpf,
    };
    try {
      const response = await api.post('/ph3aUserData', body);
      getPH3AData(response.data);
      setIsConfirmed(true);
      setNewSearch(false);
    } catch (err: any) {
      toast.error(err);
      console.log(err);
    }
  };

  useEffect(() => {
    setNewSearch(true);
  }, [customer.cpf]);

  const selectAddress = (index: number) => {
    setSelectedCardId(index);
    if (addressOps && addressOps[index]) {
      const selectedAddress = addressOps[index];
      const newAddrs = {
        ...address,
        zip: applyZipMask(selectedAddress.zip),
        street1: selectedAddress.street,
        number: selectedAddress.number,
        street2: selectedAddress.street2,
        district: selectedAddress.district,
      };
      setApiAddress(newAddrs);
    }
  };

  function formatCurrency(value: string) {
    if (!value) return '';
    if (value === '0,0') return '';
    let input = value.replace(/\D/g, '');
    input = (parseInt(input, 10) / 100).toFixed(2).replace(/\./g, ',');
    return input;
  }

  // useEffect para garantir que o estado das consts Customer e Address
  // já estejam atualizados antes de executar a validação
  useEffect(() => {
    if (isFirstRender) {
      setIsFirstRender(false);
    } else if (isDataApplied === true) {
      validateCustomerForm();
    }
    // If Use-effect is changed run without the disabled eslint to check
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isDataApplied, customer]);

  const applyData = () => {
    // setCustomer(apiCustomer);
    if (apiCustomer && apiAddress) {
      const newCustomer = {
        ...customer,
        nome: apiCustomer.nome,
        rg: apiCustomer.rg,
        dataNascimento: apiCustomer.dataNascimento,
        telefone: apiCustomer.telefone,
        nomeMae: apiCustomer.nomeMae,
        estadoCivil: apiCustomer.estadoCivil,
        profissao: apiCustomer.profissao,
        faturamentoMensal: formatCurrency(apiCustomer.faturamentoMensal),
        tempoProfissao: apiCustomer.tempoProfissao,
        valorPatrimonio: apiCustomer.valorPatrimonio,
        email: apiCustomer.email,
        mediaContaLuz: apiCustomer.mediaContaLuz,
      };
      const newAddr = {
        ...address,
        zip: apiAddress.zip,
        district: apiAddress.district,
        number: apiAddress.number,
        street1: apiAddress.street1,
        street2: apiAddress.street2,
      };

      setCustomer(newCustomer);
      setAddress(newAddr);
      setDataApplied(true);

      handleClose();
    } else {
      toast.error('Nenhum dado a ser aplicado.');
    }
  };

  const cancelButton = (
    <Button
      fullWidth={isXs}
      variant="outlined"
      sx={{ minWidth: '10rem' }}
      onClick={handleClose}
    >
      Cancelar
    </Button>
  );
  const selectedShadowColor = `0px 3px 5px -1px ${alpha(theme.palette.primary.main, 0.2)}, 0px 5px 8px 0px ${alpha(theme.palette.primary.main, 0.14)},0px 1px 14px 0px ${alpha(theme.palette.primary.main, 0.12)}`;

  const addressCardStyle = {
    width: '25%',

    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    textAlign: 'center',
    justifyContent: 'center',

    borderRadius: '1rem',
    p: '0.5rem',

    userSelect: 'none',
    cursor: 'pointer',

    boxShadow: '0px 2px 1px -1px rgba(0,0,0,0.2),0px 1px 1px 0px rgba(0,0,0,0.14),0px 1px 3px 0px rgba(0,0,0,0.12)',

    '&:hover': {
      backgroundColor: 'rgba(0, 0, 0, 0.05)',
      boxShadow: selectedShadowColor,
    },
  };

  const selectedAddressCardStyle = {
    ...addressCardStyle,
    // backgroundColor: 'rgba(0, 0, 0, 0.05)',
    border: `0.2rem solid ${theme.palette.primary.main}`,
    borderTop: 'none',
    boxShadow: selectedShadowColor,
  };

  const dataList = (
    <>
      <Stack
        direction={isXs ? 'column' : 'row'}
        justifyContent="center"
        alignItems="center"
      >
        <DataDisplay title="Nome completo" data={apiCustomer.nome || '-----'} />
        <DataDisplay title="RG" data={apiCustomer.rg || '-----'} />
        <DataDisplay title="Data de nascimento" data={apiCustomer.dataNascimento || '-----'} />
        <FormControl fullWidth>
          <InputLabel id="select-phone">Telefone</InputLabel>
          <Select
            label="Telefone"
            value={apiCustomer.telefone}
            labelId="select-phone"
            onChange={(e) => setApiCustomer({ ...apiCustomer, telefone: e.target.value })}
          >
            {phoneOps.map((e) => <MenuItem value={e}>{e}</MenuItem>)}
          </Select>
        </FormControl>
      </Stack>
      <Stack
        direction={isXs ? 'column' : 'row'}
        justifyContent="center"
        alignItems="center"
      >
        <DataDisplay title="Nome da mãe" data={apiCustomer.nomeMae || '-----'} />
        <DataDisplay title="Estado civil" data={apiCustomer.estadoCivil || '-----'} />
        <DataDisplay title="Renda/Faturamento líquida mensal" data={applyCurrencyMask(apiCustomer.faturamentoMensal) || '-----'} />
        <Box width="100%">
          <FormControl sx={{ width: '90%' }}>
            <InputLabel id="select-profession">Profissão</InputLabel>
            <Select
              label="Profissão"
              value={apiCustomer.profissao}
              labelId="select-profession"
              onChange={(e) => setApiCustomer({ ...apiCustomer, profissao: e.target.value })}
            >
              {professionOps.map((e) => <MenuItem value={e}>{e}</MenuItem>)}
            </Select>
          </FormControl>
        </Box>
        <Box width="100%" display="flex" alignItems="flex-end">
          <FormControl sx={{ width: '90%' }}>
            <InputLabel id="select-email">E-mail do cliente</InputLabel>
            <Select
              label="E-mail do cliente"
              value={apiCustomer.email}
              labelId="select-email"
              onChange={(e) => setApiCustomer({ ...apiCustomer, email: e.target.value })}
            >
              {emailOps.map((e) => <MenuItem value={e}>{e}</MenuItem>)}
            </Select>
          </FormControl>
        </Box>
      </Stack>
      <Typography sx={{
        fontSize: { xs: '16px' },
        fontFamily: 'Poppins',
        color: '#19171C',
        fontWeight: '700',
      }}
      >
        Endereço
      </Typography>
      <Carousel>
        {addressOps.map((addrs, index) => (
          <Box
            sx={{
              ...index === selectedCardId ? selectedAddressCardStyle : addressCardStyle,
            }}
            onClick={() => selectAddress(index)}
          >
            <Typography>
              {applyZipMask(addrs.zip)}
            </Typography>
            <Typography>
              {addrs.street}
              ,
              {' '}
              {addrs.number}
            </Typography>
            <Typography>
              {addrs.street2}
            </Typography>
            <Typography>
              {addrs.district}
            </Typography>
            <Typography>
              {addrs.cityName}
            </Typography>
            <Typography>
              {addrs.stateName}
            </Typography>
          </Box>
        ))}
      </Carousel>
    </>
  );

  return (
    <Modal
      aria-labelledby="transition-modal-title"
      aria-describedby="transition-modal-description"
      open={open}
      onClose={handleClose}
      closeAfterTransition
      slots={{ backdrop: Backdrop }}
      slotProps={{
        backdrop: {
          timeout: 500,
        },
      }}
    >
      <Fade in={open}>
        <Box sx={style}>
          <Typography sx={{
            fontSize: {
              xs: '16px',
              md: '24px',
            },
            fontFamily: 'Poppins',
            color: theme.palette.primary.main,
            fontWeight: '700',
          }}
          >
            Buscar informações do cliente
          </Typography>
          <Stack direction={isXs ? 'column' : 'row'} gap="1rem">
            <InputMask
              mask="999.999.999-99"
              maskPlaceholder="_"
              value={customer.cpf}
              onChange={handleChangeCustomer('cpf')}
            >
              <TextField
                fullWidth={isXs}
                required
                label="CPF"
                variant="outlined"
                sx={{ minWidth: '10rem' }}
                InputLabelProps={{
                  shrink: !!customer.cpf,
                }}
              />
            </InputMask>
            {newSearch
              && (
              <Typography
                id="transition-modal-description"
                textAlign="justify"
                fontFamily="Poppins"
              >
                Confirma que deseja proceder com a busca deste CPF?
                Por favor, esteja ciente de que a pesquisa será registrada e o usuário
                atualmente autenticado no sistema assumirá a responsabilidade por esta ação.
              </Typography>
              )}
          </Stack>
          {newSearch && (
          <Stack
            direction={isXs ? 'column' : 'row'}
            justifyContent="flex-end"
            justifySelf="flex-start !important"
            spacing={2}
          >
            {isConfirmed || cancelButton}
            <Button
              fullWidth={isXs}
              variant="contained"
              sx={{ minWidth: '10rem' }}
              onClick={confirmPH3AUsage}
            >
              Confirmar
            </Button>
          </Stack>
          )}
          {isConfirmed && apiCustomer && (
            <>
              { dataList }
              <Stack direction={isXs ? 'column' : 'row'} justifyContent="flex-end" spacing={2}>
                {cancelButton}
                {!newSearch
                    && (
                    <Button
                      fullWidth={isXs}
                      variant="contained"
                      sx={{ minWidth: '10rem' }}
                      onClick={applyData}
                    >
                      Aplicar dados
                    </Button>
                    )}
              </Stack>
            </>
          )}
        </Box>
      </Fade>
    </Modal>
  );
};

export default Ph3aDataSearch;
