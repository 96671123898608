import * as React from 'react';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import { useTheme } from '@mui/material';
import { timeStampToDate } from '../../utils/util';
import { ProjectUpdate } from '../../contexts/@types';

interface extendedProjectUpdate extends ProjectUpdate {
  user?: {
    name: string
  };
}

interface Props {
  isHistoryOpen: boolean,
  close: Function,
  projectsUpdates: extendedProjectUpdate[],
}

const DialogHistoryComponent = (props: Props) => {
  const {
    isHistoryOpen, close, projectsUpdates,
  } = props;
  const theme = useTheme();

  const handleClose = () => {
    close(false);
  };

  return (
    <Dialog open={isHistoryOpen} onClose={handleClose} maxWidth="lg" fullWidth>
      <DialogTitle style={{ color: 'white', backgroundColor: theme.palette.primary.main }}>
        Histórico do projetos
        <span style={{ fontSize: '8pt' }}> (Movimentações registradas a partir do dia 23/04/2023) </span>
      </DialogTitle>

      <DialogContent>
        { projectsUpdates.length !== 0
          ? (
            <TableContainer component={Paper}>
              <Table sx={{ minWidth: 550 }} size="small" aria-label="a dense table">
                <TableHead>
                  <TableRow>
                    <TableCell>Responsável pela mudança</TableCell>
                    <TableCell align="left">Novo status</TableCell>
                    <TableCell align="left">Status Anterior</TableCell>
                    <TableCell align="left">Data da mudança</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  { projectsUpdates.map((row) => (
                    <TableRow
                      key={row.id}
                      sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                    >
                      <TableCell component="th" scope="row">
                        {row?.user?.name}
                      </TableCell>
                      <TableCell align="left">{row.new_Status}</TableCell>
                      <TableCell align="left">{row.old_Status}</TableCell>
                      <TableCell align="left">{timeStampToDate(row.created_at.toString())}</TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          )
          : 'Se este projeto foi criado antes de 23/04/2023 e não foi movimentado, não aparecerão movimentações.'}
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose}>{ handleClose }</Button>
      </DialogActions>
    </Dialog>
  );
};

export { DialogHistoryComponent };
