import React, { useEffect, useRef, useState } from 'react';
import LoadingBar, { LoadingBarRef } from 'react-top-loading-bar';
import { useHistory } from 'react-router-dom';
import { AxiosResponse } from 'axios';
import { ToastContainer } from 'react-toastify';
import { Box, Typography, useTheme } from '@mui/material';
import style from './listachamado.module.scss';
import { api } from '../../services/api';
import { DialogComponent } from '../../components/DialogComponent';
import { sortArray } from '../../utils/util';
import IssuesTable from '../../components/IssuesTable';

interface UserData {
  id: number;
  name: string;
  email: string
}

interface ModificationData {
  id?: number;
  name?: string;
  newValue?: string;
  issueId?: number;
  formFieldId?: number;
  codAttribute?: string;
}
export interface IssueData {
  id: number;
  assunto: string;
  descricao: string;
  status: string;
  resolvido: string
  ownerIssues: UserData
  modification: ModificationData[]
  created_at: string
}

export function ListaChamados() {
  const ref = useRef<LoadingBarRef>(null);
  const [issues, setIssues] = useState<IssueData[]>([]);
  const [loading, setLoading] = useState<boolean>(false);
  const history = useHistory();
  const theme = useTheme();

  useEffect(() => {
    const getIssues = async () => {
      setLoading(true);
      const response: Promise<AxiosResponse<any, any>> = api.post('/issue/all/get');
      response.then((resolved) => {
        const items: IssueData[] = resolved.data;
        setIssues(items);
        setLoading(false);
        return items;
      });
    };
    getIssues();
  }, []);

  const sortIssues = (attribute: keyof IssueData) => {
    setIssues(sortArray(issues, attribute));
  };

  const cardStyle = {
    backgroundColor: '#fff',
    boxShadow: '0px 2px 2px 0px #0000001A',
    borderRadius: '8px',
    boxSizing: 'border-box',
    padding: '1rem',
    minHeight: '82vh',
  };
  return (
    <Box sx={cardStyle}>
      <LoadingBar color={theme.palette.primary.main} ref={ref} />
      <Typography sx={{
        fontSize: { xs: '16px', md: '24px' },
        fontFamily: 'Poppins',
        color: theme.palette.primary.main,
        fontWeight: '700',
      }}
      >
        Chamados
      </Typography>
      <hr className={style.divisor} />
      <IssuesTable issues={issues} sortIssues={sortIssues} />
      <DialogComponent isOpen={loading} labelText="Carregando dados" />
    </Box>
  );
}
