import { Box, Typography, useTheme } from '@mui/material';
import React from 'react';
import Divider from '../Divider';

interface Props {
  title: string,
  status?: string,
  helperText?: string
}

const SectionTitle = (props: Props) => {
  const { status, title, helperText } = props;
  const theme = useTheme();

  return (
    <>
      <Box
        display="flex"
        flexDirection={{
          xs: 'column',
          md: 'row',
        }}
        justifyContent="space-between"
      >
        <Typography sx={{
          fontSize: {
            xs: '16px',
            md: '24px',
          },
          fontFamily: 'Poppins',
          color: theme.palette.primary.main,
          fontWeight: '700',
        }}
        >
          {`${title}`}
        </Typography>
        {status && (
          <Typography sx={{
            fontSize: {
              xs: '16px',
              md: '24px',
            },
            fontFamily: 'Poppins',
            color: theme.palette.primary.main,
            fontWeight: '700',
          }}
          >
            {status}
          </Typography>
        )}
      </Box>
      <Divider variant="primary" />
      {helperText && (
        <Typography sx={{
          fontSize: { xs: '12px', md: '16px' },
          fontFamily: 'Poppins',
          fontWeight: '400',
        }}
        >
          {helperText}
        </Typography>
      )}
    </>
  );
};

export { SectionTitle };
