/* eslint-disable */
import React, { ChangeEvent, useEffect, useRef, useState, } from 'react';
import 'react-chat-elements/dist/main.css';
import { pdfjs } from 'react-pdf';
import LoadingBar, { LoadingBarRef } from 'react-top-loading-bar';
import { AxiosResponse } from 'axios';
import { useHistory } from 'react-router-dom';
import { toast, ToastContainer } from 'react-toastify';
import { parseCookies } from 'nookies';
import Select from 'react-select';
import TextField from '@mui/material/TextField';
import pdfIcon from '../../assets/images/downloadPDF.png';
import style from './detalhecotacaoold.module.scss';
import { Footer } from '../../components/Footer';
import { FormPage } from '../../components/FormPage';
import { api } from '../../services/api';
import { LoadingIcon } from '../../components/LoadingIcon';
import { AnaliseCreditoEditor } from '../../components/AnaliseCreditoEditor';
import uploadIcon from '../../assets/images/upload.png';
import { nonMonetaryDecimalValue, percentageValue, timeStampToDate } from '../../utils/util';
import { Post } from '../../components/Posts';
import { DialogComponent } from '../../components/DialogComponent';
import { DialogBodyComponent } from '../../components/DialogBodyComponent';
import { DialogHistoryComponent } from '../../components/DialogHistoryComponent';
import { AssociateEditor } from '../../components/AssociateEditor';
import { FormControl, MenuItem, useTheme } from '@mui/material';
import { usePusher } from '../../hooks/PusherContext';

interface GetState {
  id: number;
}
interface AssociateData{
  id?: number;
  name?: string;
  motherName?: string;
  phone?: string;
  cpf?: string;
}
interface CostumerDetail {
  id: number
  nome: string,
  cpf: string,
  rg: string,
  dataCriacao: Date
  dataNascimento: Date,
  nomeMae: string,
  profissao: string,
  estadoCivil: string,
  tempoProfissao: number,
  valorPatrimonio: number,
  telefone: string,
  faturamentoMensal: number,
  valorEntrada: number,
  valorTotal: number,
  carencia: number,
  email: string,
  mediaContaLuz: number,
  potenciaInstalada: number,
  company_link: boolean,
  cnpj: string,
  dataAbertura: Date,
  razaoSocial: string,
  nomeFantasia: string,
  emailEmpresa: string,
  telefoneComercial: string,
  faturamentoMensalEmpresa: number,
  projectPotency: number,
  capitalSocial: number,
  associates: AssociateData[],
  electricityBillFamiliarType: number,
  electricityBillOwnership: boolean,
  contaLuzRecente: string,
  documentoIdentificacao: string,
  iptu: string,
  comprovanteRenda: string,
  notaFiscalEquipamentos: string,
  proposal: string,
  commercialProposal: string,
  invoice: string,
  bankDeal: {
    name: string,
  },
  accountDeposit: string,
  dateDeposit: Date,
  enderecoInstalacao: {
    city : {
      name : string,
      state : {
        name: string
      }
    },
    number: number,
    street1: string,
    street2: string,
    zip: string
  },
  status: string
}

interface CreditOption{
  id?: number;
  parcelas: number;
  banco: string;
  bancoId: number;
  valorParcela: number;
  taxa: number
  choosed: boolean
  bancoDoc: any
}
interface Banco{
  id: number;
  name: string;
  email: string;
}
interface Message {
  userID: number,
  senderName: string,
  receiverName: string,
  message: string,
  isFile: boolean,
  fileTitle: string,
  filePath: string,
  created_at: string,
  direction: string,
  messageFile: any
}
interface Consult {
  IdentityNumber: string,
  POS: string,
  LegalEntity: string
}
interface User{
  name: string,
}
interface ProjectsUpdates {
  id: number,
  created_at: Date,
  changed_at: Date,
  old_Status: String,
  new_Status: String,
  user: User,
}

export function DetalheCotacaoOld() {
  pdfjs.GlobalWorkerOptions.workerSrc = `https://cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.min.js`;

  const canvasRef = useRef(null);
  const taxaRef = useRef(null);
  const valorParcelaRef = useRef(null);

  const pusher = usePusher();

  enum TipoDocumento {
    comprovanteRenda,
    contaLuzRecente,
    documentoIdentificacao,
    iptu,
    notaFiscalEquipamentos,
    proposal,
    invoice,
    commercialProposal,
  }

  const getProjectId = () => {
    const { 'nextauth.p': cprojId } = parseCookies();
    return parseInt(cprojId, 10);
  };
  const getUserId = () => {
    const { 'nextauth.tag': userId } = parseCookies();
    return parseInt(userId, 10);
  };
  const getUserRole = () => {
    const { 'nextauth.roles': role } = parseCookies();
    return role;
  };
  const [loadedFiles, setLoadedFiles] = useState({
    proposal: undefined,
    invoice: undefined,
    receipt: undefined,
  });
  const history = useHistory();
  const ref = useRef<LoadingBarRef>(null);
  const projId = getProjectId();
  const userID = getUserId();
  const role = getUserRole();
  const [dateValue, onChange] = useState(new Date());
  const theme = useTheme();

  const [documentUrl, setDocumentUrl] = useState<string>('');
  const [pageNumber, setPageNumber] = useState<number>(1);
  const [numPages, setNumPages] = useState<number>(0);
  const [bancoDoc, setBancoDoc] = useState<any>([]);
  const [isOpen, setIsOpen] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isHistoryOpen, setIsHistoryOpen] = useState(false);
  const [allowEdit, setAllowEdit] = useState(false);
  const [loadingText, setLoadingText] = useState('');
  const [receipt, setReceipt] = useState<any>();
  const [isLoading, setIsLoading] = useState(false);

  const [creditOptions, setCreditOptions] = useState<CreditOption[]>([]);
  const [messages, setMessages] = useState<Message[]>([]);
  const [associates, setAssociates] = useState<AssociateData[]>([]);
  const [projectsUpdates, setProjectsUpdates] = useState<ProjectsUpdates[]>([]);
  const [user, setUser] = useState('');
  // const [posts, setPosts] = useState<Posts[]>([]);
  const [messageFile, setMessageFile] = useState<any>(null);
  const [costumer, setCostumer] = useState<CostumerDetail | null>(null);
  const [state, setState] = useState<GetState | null>(null);
  const [chosen, setChosen] = useState(0);
  const sstate: GetState = history.location.state as GetState;

  const [parcelas, setParcelas] = useState(0);
  const [banco, setBanco] = useState<Banco | null>(null);
  const [bankDeal, setBankDeal] = useState<Banco | null>(null);
  const [valorParcela, setValorParcela] = useState<number>(0);
  const [taxa, setTaxa] = useState<number>(0);
  const [choosed, setChoosed] = useState<boolean>(false);
  const [bancos, setBancos] = useState<Banco[]>([]);


  const [checkDeposit, setCheckDeposit] = useState(false);
  const [accountDeposit, setAccountDeposit] = useState('');
  const [dateDeposit, setDateDeposit] = useState('');

  const [message, setMessage] = useState('');
  const [isFile, setIsFile] = useState('');
  const [fileTitle, setFileTitle] = useState('');

  const [consult, setConsult] = useState<Consult>({
    IdentityNumber: '',
    POS: '',
    LegalEntity: ''
  });
  const [formLoading, setFormLoading] = useState(false);

  const integrators = [
    {
      value: 'EXBRA_SA_Fortaleza_CE',
      label: 'EXBRA_SA_Fortaleza_CE'
    },
    {
      value: 'EXBRA_SA_Paulo_SP',
      label: 'EXBRA_SA_Paulo_SP'
    },
    {
      value: 'EXBRA_SA_Rio_de_Janeiro_RJ',
      label: 'EXBRA_SA_Rio_de_Janeiro_RJ'
    }
  ];

  const closeModal = (value:boolean) => {
    setConsult({IdentityNumber: '', POS: '', LegalEntity: ''})
    setIsModalOpen(value);
  }

  const closeHistoryModal = (value:boolean) => {
    setIsHistoryOpen(value);
  }

  const validateDealForm = () => {
    const messages: any[] = [];

    if (!(checkDeposit)) {
      toast.warn('Para informar a contratação do projeto, você deve marcar que o deposito foi realizado.')
      messages.push(0);
    }

    return messages.length === 0;
  }

  const setAttribute = async (id: string, attrib: string, value: any) => {
    const object = document.getElementById(id);
    object?.setAttribute(attrib, value);
    // object?.setAttribute('disabled', 'disabled');
  };

  useEffect(
    () => {
      setLoadingText('carregando dados...');
      setIsOpen(true);
      // @ts-ignore
      valorParcelaRef!.current!.value = '0';
      // @ts-ignore
      taxaRef!.current!.value = '0';
      const getToday = () => {
        const date = new Date();
        const day = date.getDate()
          .toString();
        let month = ((date.getMonth() + 1).toString());
        if (month.length === 1) month = `0${month}`;
        const hour = date.getHours();
        const min = date.getMinutes();
        const wish = `Good ${(hour < 12 && 'Morning') || (hour < 17 && 'Afternoon') || 'Evening'}, `;
        const year = date.getFullYear()
          .toString();

        // check if date is after today
        return `${day}/${month}/${year} - ${hour}:${min}`;
      };
      const getCostumer = async () => {
        const resp: Promise<AxiosResponse<any, any>> = api.post('project/costumer/get', {
          projectId: sstate.id,
        });
        resp.then((resolved) => {
          if (resolved.data === 'COULD_NOT_FIND_COSTUMER') {
            setIsLoading(true);
          } else {
            const item: CostumerDetail = resolved.data;
            setCostumer(item);
            if (item.associates.length > 0) {
              setAssociates(item.associates);
            }
          }
          setState(sstate);
          setIsOpen(false);
          return state;
        });
      };
      const handleDownloadReceipt = async () => {
        if (loadedFiles.receipt) {
          const file = await getFile(loadedFiles.receipt);
          window.open(file);
        }
      };
      const getUser = async () => {
        const resp: Promise<AxiosResponse<any, any>> = api.post('/user/get', {
          userId: getUserId(),
        });

        resp.then((resolved) => {
          setUser(resolved.data.name);
        });
      };
      const getCreditOptions = async () => {
        const resp: Promise<AxiosResponse<any, any>> = api.post('project/credit/get', {
          projectId: sstate.id,
        });

        resp.then((resolved) => {
          setCreditOptions(resolved.data);
        });
      };
      const getProjectData = async () => {
        const respo: Promise<AxiosResponse<any, any>> = api.post(
          'project/single/get',
          { projectId: sstate.id },
        );
        respo.then((resolved) => {
          const { data } = resolved;
          if (data !== 'COULD_NOT_LOCATE_PROJECT') {
          // whether filled already
            setProjectsUpdates(data.projectsUpdates);
            if (data.nome.length !== 0) {
              setChosen(data.chosen_analysisId);
            }
            setLoadedFiles({
              proposal: data.proposal || 'Sem documentos',
              invoice: data.invoice || 'Sem documentos',
              receipt: data.receipt || 'Sem documentos',
            });
          }
          setAllowEdit(data.status !== 'Projeto contratado' && data.status !== 'Inativo');
          setChosen(data.chosen_analysisId);
        });
      };
      let userName = '';
      const getMessages = async () => {
        const response: Promise<AxiosResponse<any, any>> = api.post(
          '/message/get',
          { projectId: sstate.id },
        );

        response.then(async (resolved) => {
          const { data } = resolved;
          const newMessages: Message[] = [];
          for (let i = 0; i < data.length; i++) {
            // eslint-disable-next-line no-await-in-loop
            const resp = await api.post('/user/get', {
              userId: data[i].userID,
            });
            // eslint-disable-next-line no-loop-func
            userName = resp.data.name;
            newMessages.unshift({
              userID: data[i].userID,
              message: data[i].message,
              senderName: userName,
              filePath: data[i].filePath,
              fileTitle: data[i].fileTitle,
              messageFile: '',
              receiverName: `Quem recebeu${2}`,
              direction: '',
              created_at: timeStampToDate(data[i].created_at),
              isFile: data[i].isFile,
            });
          }
          setMessages(newMessages);
        });
      };

      const channel = pusher.subscribe(sstate.id.toString());
      channel.bind('update_messages_operator', (data: any) => {
        getMessages();
      });

      const getBancos = async () => {
        const response: Promise<AxiosResponse<any, any>> = api.post('/bank/get');
        response.then((resolved) => {
          const items: Banco[] = resolved.data;
          setBancos(items);
          return items;
        });
      };


      getBancos();
      getUser();
      getMessages();
      getProjectData();
      getCreditOptions();
      getCostumer();

    },
    [sstate.id],
  );

  const getToday = () => {
    const date = new Date();
    const day = date.getDate().toString();
    let month = ((date.getMonth() + 1).toString());
    if (month.length === 1) month = `0${month}`;
    const hour = date.getHours();
    const min = date.getMinutes();
    const wish = `Good ${(hour < 12 && 'Morning') || (hour < 17 && 'Afternoon') || 'Evening'}, `;
    const year = date.getFullYear().toString();

    // check if date is after today
    return `${day}/${month}/${year} - ${hour}:${min}`;
  };
  const applyCurrencyMask = (n: string) => {
    const value : string = n.toString().split('').reverse().join('');

    let newValue = '';
    for (let x = 0; x < value.length; x++) {
      newValue += value[x];
      if (x === 1) {
        if (x < value.length - 1) { newValue += ','; }
      }

      if (x === 4 || x === 7 ) {
        if (x < value.length - 1) { newValue += '.'; }
      }
    }

    newValue = newValue.split('').reverse().join('');
    newValue = `R$ ${newValue}`;
    return newValue;
  };

  const applyCurrencyMaskUndefined = (n: any) => {
    if (n === undefined) {
      return 'R$ 0';
    }
    const value : string = n.toString().split('').reverse().join('');

    let newValue = '';
    for (let x = 0; x < value.length; x++) {
      newValue += value[x];
      if (x === 1) {
        if (x < value.length - 1) { newValue += ','; }
      }

      if (x === 4 || x === 7) {
        if (x < value.length - 1) { newValue += '.'; }
      }
    }

    newValue = newValue.split('').reverse().join('');
    newValue = `R$ ${newValue}`;
    return newValue;
  };
  const getFile = async (urlx: String) => {
    const respo = await api.post('file/get', { data: urlx });

    if (respo.status === 403) {
      toast.error('Não foi possível acessar o arquivo.');
    }
    return respo.data;
  };

  const handleVoltarClick = () => {
    history.goBack();
  };

  const handleEnviarFinanceira = async () => {


    // eslint-disable-next-line no-restricted-globals
    const option = confirm('Deseja marcar o projeto como `Enviado à Instituição Financeira`? Esta operação não poderá ser desfeita.');
    if (option) {
      setLoadingText('Enviando para financeira...');
      setIsOpen(true);
      const respo = await api.post('project/status/put', {
        data: {
          projectId: sstate.id,
          status: 'Análise - Bancos',
          userId: getUserId(),
        },
      });
      setIsOpen(false);
      window.location.reload()
    }

    return 0;
  };

  const handleHistorico = async () => {
    setIsHistoryOpen(true);
  };

  const handleReprovarProjeto = async () => {


    // eslint-disable-next-line no-restricted-globals
    const option = confirm('Deseja informar que o projeto teve seu financiamento recusado?');
    if (option) {
      setLoadingText('Alterando status do projeto...');
      setIsOpen(true);
      const respo = await api.post('project/status/put', {
        data: {
          projectId: sstate.id,
          status: 'Financiamento recusado',
          userId: getUserId(),
        },
      });
      setIsOpen(false);
      window.location.reload()
    }

    return 0;
  };
  const handleAprovarProjeto = async () => {


    // eslint-disable-next-line no-restricted-globals
    const option = confirm('Deseja informar que o projeto teve seu financiamento aprovado?');
    if (option) {
      setLoadingText('Alterando status do projeto...');
      setIsOpen(true);
      const respo = await api.post('project/status/put', {
        data: {
          projectId: sstate.id,
          status: 'Financiamento aprovado',
          userId: getUserId(),
        },
      });
      setIsOpen(false);
      window.location.reload()
    }

    return 0;
  };

  const handleSubmitDeal = async () => {


    // eslint-disable-next-line no-restricted-globals
    if(validateDealForm()){
    const option = confirm('Deseja informar que o projeto foi contratado com sucesso?');
    if (option) {
      setLoadingText('Alterando status do projeto...');
      setIsOpen(true);
      const respo = await api.post('project/deal/put', {
        data: {
          projectId: sstate.id,
          tag: userID,
          status: 'Projeto contratado',
          checkDeposit,
          bankDealId: bankDeal?.id,
          dateDeposit,
          accountDeposit,
        },
      });
      setIsOpen(false);
      window.location.reload()
    }
    }
    return 0;
  };

  const openFile = async (file: string) => {
    if (costumer) {
      const furl = await getFile(file);
      window.open(furl, '_blank');
    }
  };
  function randomNumberInRange(min: number, max: number) {
    // 👇️ get number between min (inclusive) and max (inclusive)
    return Math.floor(Math.random() * (max - min + 1)) + min;
  }
  const handleDownloadContaLuz = async () => ((costumer) ? openFile(costumer?.contaLuzRecente) : '');
  const handleDownloadDocumentoIdentificacao = async () => ((costumer) ? openFile(costumer?.documentoIdentificacao) : '');
  const handleDownloadNotaFiscal = async () => ((costumer) ? openFile(costumer?.notaFiscalEquipamentos) : '');
  const handleDownloadIptu = async () => ((costumer) ? openFile(costumer?.iptu) : '');
  const handleDownloadRenda = async () => ((costumer) ? openFile(costumer?.comprovanteRenda) : '');

  const handleAdicionarMensagem = (e: ChangeEvent<HTMLInputElement>) => {
    setMessage(e.target.value);
    setMessageFile(null);
  };

  const sendFile = async (label: string, file: any) => {
    const body = new FormData();
    body.append('filefield', file, label);
    toast.success('Enviando arquivo, aguarde...');
    const response = await api.post('/upload', body, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    });

    return response.data;
  };

  const sendFileMessage = async (label: string, file: any, type: string) => {
    const body = new FormData();
    body.append('filefield', file, label);
    toast.success('Enviando arquivo, aguarde...');
    const response = await api.post('/upload/fileMessage', body,{
      headers: {
        'Content-Type': 'multipart/form-data',
      },
      data: {
        // @ts-ignore
        type: type,
      }
    });

    return response.data;
  };

  const handleAdicionarArquivo = async (e: ChangeEvent<HTMLInputElement>) => {
    const fileList = e.target.files;
    if (!fileList) return;
    // setMessageFile(fileList[0]);

    // @ts-ignore
    const type = extensionFile(e.target.files.item(0).name);
    const filePath2 = await sendFileMessage('messageFile', fileList[0], type[0]);

    const sendMessage = await api.post('/message/send', {
      data: {
        projectId: sstate.id,
        message,
        userID,
        isFile: true,
        fileTitle: fileList[0].name,
        filePath: filePath2,
      },
    });

    if (sendMessage.data === 'CREATED_OK') {
      messages.push({
        message,
        userID: getUserId(),
        senderName: user,
        filePath: filePath2,
        messageFile: fileList[0],
        fileTitle: fileList[0].name,
        receiverName: 'Ele',
        created_at: getToday(),
        direction: '',
        isFile: true,
      });
      setMessageFile({ ...messageFile, file: fileList[0] });
    } else if (sendMessage.data === 'COULD_NOT_SEND_MESSAGE') {
      toast.warning('Não foi possível enviar o arquivo!');
    }
    setMessage('');
    setMessageFile(null);
  };

  const tal = document.getElementById('upload_contrato');

  const changeValue = () => {
    tal?.setAttribute('value', '');
  };

  const submitMessage = async () => {
    if (message.length > 0) {
      const sendMessage = await api.post('/message/send', {
        data: {
          projectId: sstate.id,
          message,
          userID,
          isFile: false,
          fileTitle: '',
          filePath: '',
        },
      });


      if (sendMessage.data === 'CREATED_OK') {
        messages.push({
          message,
          userID: getUserId(),
          senderName: user,
          filePath: '',
          messageFile: '',
          fileTitle: '',
          receiverName: 'Ele',
          direction: '',
          created_at: getToday(),
          isFile: false,
        });

        setMessage('');
        // setMessageFile(null);
      } else if (sendMessage.data === 'COULD_NOT_SEND_MESSAGE') {
      }
    }
  };

  const handleAdicionarEntrada = async () => {
    const newCreditOptions : CreditOption[] = [];
    if (parcelas === 0) {
      toast.warning('Adicione uma parcela maior que 0.');
      return;
    }
    if (banco === null) {
      toast.warning('Adicione o banco.');
      return;
    }
    if (valorParcela === 0) {
      toast.warning('Adicione um valor de parcela maior que 0.');
      return;
    }
    for (let i = 0; i < creditOptions.length; i++) { newCreditOptions.push(creditOptions[i]); }

    newCreditOptions.push({
      parcelas,
      banco: banco.name,
      bancoId: banco.id,
      valorParcela,
      taxa,
      bancoDoc,
      choosed,
    });

    setBancoDoc('');
    setCreditOptions(newCreditOptions);
    // setBanco(null);
    setParcelas(0);
    setValorParcela(parseInt('0', 10));
    setTaxa(parseInt('0', 10));
    // setAttribute('banco', 'value', '');
    // setAttribute('taxa', 'value', '');
    // setAttribute('valorParcela', 'value', '');
    // @ts-ignore
    valorParcelaRef!.current!.value = '0';
    // @ts-ignore
    taxaRef!.current!.value = '0';
  };

  const handleLimparEntradas = async () => {
    // eslint-disable-next-line no-restricted-globals
    const option = confirm('Deseja remover todas as opções de crédito preenchidas?');
    if (option) {
      setCreditOptions([]);
    }
  };

  const handleSalvarEntradas = async () => {
    // eslint-disable-next-line no-restricted-globals
    const option = confirm('Deseja adicionar as todas opções de crédito abaixo ao perfil do cliente?' +
      ' Essa operação não poderá ser desfeita. As opções cadastradas estarão disponíveis para contratação por parte do cliente.');


    if (option) {
      const data = {
        projectId: sstate.id,
        creditOptions,
        userId: userID,
      };
      setLoadingText('Enviando cotações...');
      setIsOpen(true);


      for (const creditOption of data.creditOptions) {
        if (creditOption.bancoDoc !== null) {
          if (creditOption.bancoDoc.length > 0) {
            creditOption.bancoDoc = await sendFile('docBanco', creditOption.bancoDoc);
          } else {
            creditOption.bancoDoc = null;
          }
        }
      }
      const putResponse = await api.post('project/credit/put', {
        data,
      });

      if (putResponse.data !== 'CREDIT_OPTIONS_UPDATED') {
        setIsOpen(false);
        toast.error(`Falha ao inserir opções de crédito. ${putResponse.data}`);
      } else {
        setIsOpen(false);
        window.location.reload();
      }
      setIsOpen(false);
    }
  };

  const removeNonNumeric = (n: string) => {
    const mask = '0123456789';

    // remove unwanted characters (including recently pressed ones)
    let value = '';
    for (let i = 0; i < n.length; ++i) {
      if (mask.includes(n[i])) {
        value += n[i];
      }
    }
    //return (value.length === 0) ? '0' : value;
    return (value.length === 0) ? '0' : parseInt(value, 10).toString();
  };

  const handleTaxaChange = (e: ChangeEvent<HTMLInputElement>) => {
    const mask = '0123456789.';

    // remove unwanted characters (including recently pressed ones)
    let value = '';
    for (let i = 0; i < e.target.value.length; ++i) {
      if (mask.includes(e.target.value[i])) {
        value += e.target.value[i];
      }
    }

    setTaxa(parseFloat(value));
    e.target.value = percentageValue(value);
  };

  const handleValorParcelaChange = (e: ChangeEvent<HTMLInputElement>) => {
    const value = removeNonNumeric(e.target.value);
    setValorParcela(parseInt(value, 10));
    e.target.value = applyCurrencyMask(value);
  };

  const handleParcelasChange = (e: ChangeEvent<HTMLInputElement>) => {
    const value = removeNonNumeric(e.target.value);

    // if (parseInt(value, 10) === 0) value = '1';

    setParcelas(parseInt(value, 10));
    // e.target.value = applyCurrencyMask(value);
    e.target.value = value;
  };

  const handleBancoChange =  (a : any) => {
    setBanco(a);
  };
  const handleBankDealChange =  (a : any) => {
    setBankDeal(a);
  };
  const accountDepositChange =  (e: ChangeEvent<HTMLInputElement>) => {
    e.target.value = nonMonetaryDecimalValue(e);
    setAccountDeposit(e.target.value);
  };

  const handleEditEntrada = (idx: number) => {
    if (creditOptions.length > 0) {
      const creditOptionsList : CreditOption[] = [];
      for (let i = 0; i < creditOptions.length; i++) {
        if (i !== idx) creditOptionsList.push(creditOptions[i]);
      }
      setCreditOptions(creditOptionsList);
    }
  };
  const handleDownloadReceipt = async () => {
    if (loadedFiles.receipt) {
      const file = await getFile(loadedFiles.receipt);
      window.open(file);
    }
  };
  async function loadPdfToCanvas(canvasRef: any, file: String, pageNumber : number) {

    const pdf = await pdfjs.getDocument(file).promise;

    // Prepare canvas using PDF page dimensions.
    const canvas = canvasRef.current;
    const canvasContext = canvas.getContext('2d');

    const page = await pdf.getPage(pageNumber);
    const viewport = await page.getViewport({ scale: 2.0 });

    canvas.height = viewport.height;
    canvas.width = viewport.width;

    // Render PDF page into canvas context.
    const renderContext = { canvasContext, viewport };
    page.render(renderContext);

    return pdf.numPages;
  }

  const handleNextPageClick = async () => {
    if (pageNumber < numPages) {
      setPageNumber(pageNumber + 1);
      loadPdfToCanvas(canvasRef, documentUrl, pageNumber + 1);
    }
  };

  const handlePreviousPageClick = async () => {
    if (pageNumber > 1) {
      setPageNumber(pageNumber - 1);
      loadPdfToCanvas(canvasRef, documentUrl, pageNumber - 1);
    }
  };

  const handlePdfButtonClick = async (t: TipoDocumento) => {
    ref?.current?.continuousStart(0, 500);

    if (costumer) {
      let docUrl: String;

      switch (t) {

        case TipoDocumento.comprovanteRenda:
          docUrl = costumer.comprovanteRenda;
          break;
        case TipoDocumento.contaLuzRecente:
          docUrl = costumer.contaLuzRecente;
          break;
        case TipoDocumento.documentoIdentificacao:
          docUrl = costumer.documentoIdentificacao;
          break;
        case TipoDocumento.iptu:
          docUrl = costumer.iptu;
          break;
        case TipoDocumento.notaFiscalEquipamentos:
          docUrl = costumer.notaFiscalEquipamentos;
          break;
        case TipoDocumento.proposal:
          docUrl = costumer.proposal;
          break;
        case TipoDocumento.invoice:
          docUrl = costumer.invoice;
          break;
        case TipoDocumento.commercialProposal:
          docUrl = costumer.commercialProposal;
          break;
      }
      const promise = getFile(docUrl);

      promise.then((resolved) => {
        setPageNumber(1);
        setDocumentUrl(resolved as string);
        const nPages = loadPdfToCanvas(canvasRef, resolved, pageNumber);

        nPages.then((num) => {
          setNumPages(num);
        });

        const element = document.getElementById('canvasPdf');
        element?.scrollIntoView({
          behavior: 'smooth',
          block: 'center',
          inline: 'center',
        });
        ref?.current?.complete();
      });
    }
  };

  const extensionFile = (fileName: string)=>{
    let dots =[''];
    return dots = fileName.split('.');
  }
  const TestFileType = (fileName: string, fileTypes : [string]) => {
    if (!fileName) return;

    const dots = fileName.split('.');
    // get the part AFTER the LAST period.
    const fileType = `.${dots[dots.length - 1]}`;

    // eslint-disable-next-line consistent-return
    if (fileTypes.join('.').indexOf(fileType) !== -1) {
      // eslint-disable-next-line consistent-return
      return true;
    }
    // eslint-disable-next-line consistent-return
    return false;
  };
  const checkDepositChange = (e: ChangeEvent<HTMLInputElement>) => {
    setCheckDeposit(e.target.checked);
  };
  const handleBancoDocChange = (e: ChangeEvent<HTMLInputElement>) => {
    // @ts-ignore
    if (TestFileType(e.target.files.item(0).name, ['.pdf'])) {
      const fileList = e.target.files;
      if (!fileList) return;
      if (fileList[0].size > 10e6) {
        toast.warning('Por favor, é permitido enviar arquivos de no máximo 10MB!');
        return;
      }
      setBancoDoc(fileList[0]);
      toast.success('Arquivo selecionado!');
    } else {
      toast.info('Por favor, faça o upload apenas de arquivos com a extensão .pdf!');
    }
  };
  const buscaParentesco = (n: number) => {

    switch (n) {
      case 1:
        return 'Mãe';
      case 2:
        return 'Pai';
      case 3:
        return 'Esposo(a)';
      case 4:
        return 'Filho(a)';
      case 5:
        return 'Irmão/Irmã';
    }

  };
  const handleClickAbrirChamado = () => {

    history.push({pathname: '/AbrirChamado', state: { id: sstate.id },});

  };
  const updateCreditOptions = async (creditOption: CreditOption, idx: number) => {
    setLoadingText('Atualizando condição...');
    setIsOpen(true);
    const request = {
      headers: { 'Content-Type': 'application/json' },
      data: {
        id: creditOption.id,
        taxa: creditOption.taxa,
        parcelas: creditOption.parcelas,
        valorParcela: creditOption.valorParcela,
      },
    };

    const responseStatus = await api.post('/project/credit/update', request);

    switch (responseStatus.data) {
      case 'CREDIT_UPDATED':
        toast.success('Condição alterada com sucesso!');
        creditOptions[idx] = creditOption;
        setIsOpen(false);
        // window.location.reload();
        break;
      default:
        setIsOpen(false);
        toast.error(`Falha ao alterar a condição.${responseStatus.data}`);
        break;
    }
  }

  const customStyles = {
    //@ts-ignore
    option: (provided, state) => ({
      ...provided,
      borderBottom: '1px dotted pink',
      color: state.isSelected ? 'white' : 'blue',
      fontSize:12,
      padding: 20,
      width: 250,
    }),
    control: () => ({
      // none of react-select's styles are passed to <Control />
      width: 250,
      height:50,
    }),
    //@ts-ignore
    singleValue: (provided, state) => {
      const opacity = state.isDisabled ? 0.5 : 1;
      const transition = 'opacity 300ms';

      return {
        ...provided,
        opacity,
        transition
      };
    }
  }


  const handleDateDepositChange = (e: ChangeEvent<HTMLInputElement>) => {
    let value = '';
    const mask = '0123456789';
    const date = new Date();

    // remove unwanted characters (including recently pressed ones)
    for (let i = 0; i < e.target.value.length; ++i) {
      if (mask.includes(e.target.value[i])) {
        value += e.target.value[i];
      }
    }

    // apply the "__/__/____" mask
    let newValue = '';

    for (let i = 0; i < value.length; ++i) {
      if (i === 2 || i === 4) {
        newValue += '/';
      }
      newValue += value[i];
    }

    if (newValue.length >= 10) {
      newValue = newValue.slice(0, 10);
    }

    // forcefully limit character value
    if (newValue.slice(0, 2) > '31') {
      newValue = '31';
    }

    if (newValue.slice(3, 5) > '12') {
      newValue = newValue.slice(0, 3);
      newValue += '12';
    }

    if (newValue.slice(6, 10) > date.getFullYear().toString()) {
      newValue = newValue.slice(0, 6);
      newValue += date.getFullYear().toString();
    }

    e.target.value = newValue;
    setDateDeposit(e.target.value);
  };

  const handleConsultChange = (e: ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    setConsult({
      ...consult,
      [name]: value,
    });
  };

  const handleConsultConfirm = async () => {

    if (validateConsultForm()) {
      // setLoadingText('Procurando usuário...');
      // setIsOpen(true);
      setFormLoading(true);
      const data = consult;
      const responseStatus = await api.post('/getContractEnelX', { data });

      const consultResponse = responseStatus.data;
      // setIsOpen(false);
      setFormLoading(false);
      if (consultResponse.Result.ErrorCode === '0') {

        if (consultResponse.Contract[0].Status === 'Activated') {
          toast.success('Cliente está ativo!');
          history.push({pathname: '/CobrancaEmConta', state: { id: sstate.id, empresa: consult.LegalEntity },});
        }
      } else {
        toast.warning('Cliente não encontrado!')
      }
    }
  };
  const handleReceiptChange = (e: ChangeEvent<HTMLInputElement>) => {
    // @ts-ignore
    if (TestFileType(e.target.files.item(0).name, ['.pdf'])) {
      const fileList = e.target.files;
      if (!fileList) return;
      setReceipt(fileList[0]);
      toast.success('Arquivo selecionado!');
    } else {
      toast.info('Por favor, faça o upload apenas de arquivos com a extensão .pdf!');
    }
  };
  const validateLegalEntity = (m: string) => m.length > 0;
  const validatePOS = (m: string) => m.length > 0;

  const validateConsultForm = () => {
    if (!validateLegalEntity(consult.POS)) {
      toast.warning('O código do cliente é necessário!');
      return false;
    }

    if (!validatePOS(consult.LegalEntity)) {
      toast.warning('A distribuidora é necessário!');
      return false;
    }

    return true;
  }
  const validateReceipt = () => {
    const messages: any[] = [];
    if (!receipt) {
      toast.info('Não foi realizado o upload da "Comprovante de Depósito".');
      messages.push(1);
    }
    return messages.length === 0;
  };
  const handleSubmitReceipt = async () => {
    if (validateReceipt()) {
      setLoadingText('Enviando comprovante...');
      setIsOpen(true);
      const freceipt = await sendFile('receipt', receipt);
      const request = {
        headers: { 'Content-Type': 'application/json' },
        data: {
          projectId: sstate.id,
          receipt: freceipt,
        },
      };

      const response = await api.post('project/sendReceiptDoc', request);

      switch (response.data) {
        case 'UNABLE_TO_FIND_PROJECT':
          setIsOpen(false);
          toast.warn(
            'Houve um problema e o projeto não foi localizado, tente novamente mais tarde ou entre em contato com a Soltech.',
          );
          break;
        case 'PROJECT_DOCS_SENT':
          setIsOpen(false);
          toast.success('Comprovante enviado com sucesso.');
          window.location.reload()
          break;
        default:
          setIsOpen(false);
          toast.error(`Falha ao enviar o comprovante.${response.data}`);
          break;
      }
    }
  };
  // @ts-ignore
  // @ts-ignore
  return (
    <div>
      <LoadingBar color={theme.palette.primary.main} ref={ref} />
      <FormPage>
        <br />
        <form className={style.detalheCadastroSplitter}>
          <span className={style.detalheCadastroSplitterSection}>
            <h1>
              Pedido de Cotação #
              {costumer?.id|| '--'}
            </h1>
             <h1>
              Projeto #
               {sstate.id || '--'}
            </h1>
            <h1>
              Data de criação -
               {costumer?.dataCriacao.toString().split('T')[0].split('-').reverse().join('/') || '--' || '--'}
            </h1>
            <h1>
              Vendedor -
              {
                // @ts-ignore
                costumer?.user.name || '--'
              }
            </h1>
            <div>
              <label>
                Nome Completo
                <br />
                <span className={style.formarea_readonlydata}>
                { isLoading ? '--' : costumer?.nome || <LoadingIcon active={!costumer}/> }
                </span>
              </label>
              <label>
                CPF
                <br />
                <span className={style.formarea_readonlydata}>
                  {costumer?.cpf || '--'}
                </span>
              </label>
              <label>
                RG
                <br />
                <span className={style.formarea_readonlydata}>
                  {costumer?.rg || '--'}
                </span>
              </label>
            </div>
            <hr />
            <div>
              <label>
                Nome da Mãe
                <br />
                <span className={style.formarea_readonlydata}>
                {costumer?.nomeMae || '--'}
                </span>
              </label>
              <label>
                Data de Nascimento
                <br />
                <span className={style.formarea_readonlydata}>
                {costumer?.dataNascimento.toString().split('T')[0].split('-').reverse().join('/') || '--'}
                </span>
              </label>
              <label>
                Estado Civil
                <br />
                <span className={style.formarea_readonlydata}>
                {costumer?.estadoCivil || '--'}
                </span>
              </label>
            </div>
            <hr />
            <div>
              <label>
                Faturamento Mensal
                <br />
                {/* R$&nbsp; */}
                <span className={style.formarea_readonlydata}>
                {applyCurrencyMaskUndefined(costumer?.faturamentoMensal) || '--'}
                </span>
              </label>
              <label>
                Média da Conta de Luz
                <br />
                <span className={style.formarea_readonlydata}>
                {applyCurrencyMaskUndefined(costumer?.mediaContaLuz) || '--'}
                </span>
              </label>
              <label>
                Patrimônio
                <br />
                {/* R$&nbsp; */}
                <span className={style.formarea_readonlydata}>
                {applyCurrencyMaskUndefined(costumer?.valorPatrimonio) || '--'}
                </span>
              </label>
            </div>
            <hr />
            <div>
              <label>
                Profissão
                <br />
                <span className={style.formarea_readonlydata}>
                {costumer?.profissao || '--'}
                </span>
              </label>
              <label>
                Tempo de Profissão
                <br />
                <span className={style.formarea_readonlydata}>
                {costumer?.tempoProfissao || '--'}
                </span>
              </label>

            </div>
            <hr />
            <div>
              <label>
                Valor de Entrada do Projeto
                <br />
                {/* R$&nbsp; */}
                <span className={style.formarea_readonlydata}>
                {applyCurrencyMaskUndefined(costumer?.valorEntrada) || '--'}
                </span>
              </label>
              <label>
                Valor Total do Projeto
                <br />
                <span className={style.formarea_readonlydata}>
                {applyCurrencyMaskUndefined(costumer?.valorTotal) || '--'}
                </span>
              </label>
              <label>
                Carência
                <br />
                <span className={style.formarea_readonlydata}>
                {costumer?.carencia + ' mês' || '--'}
                </span>
              </label>
               <label>
                Potência do Projeto
                <br />
                <span className={style.formarea_readonlydata}>
                {costumer?.projectPotency + ' (KW)' || '--'}
                </span>
              </label>
            </div>
            <h1>Projeto é vinculado a empresa? {(costumer?.company_link) ? 'Sim' : 'Não'}</h1>
            {costumer?.company_link
              ? (
            <div>
              <label>
                CNPJ
                <br />
                {/* R$&nbsp; */}
                <span className={style.formarea_readonlydata}>
                {costumer?.cnpj || '--'}
                </span>
              </label>
              <label>
                Razão Social
                <br />
                <span className={style.formarea_readonlydata}>
                {costumer?.razaoSocial || '--'}
                </span>
              </label>
              <br />
              <label>
                Nome Fantasia
                <br />
                {/* R$&nbsp; */}
                <span className={style.formarea_readonlydata}>
                {costumer?.nomeFantasia || '--'}
                </span>
              </label>
              <label>
                Email
                <br />
                <span className={style.formarea_readonlydata}>
                {costumer?.emailEmpresa || '--'}
                </span>
              </label>
              <label>
                Telefone Comercial
                <br />
                <span className={style.formarea_readonlydata}>
                {costumer?.telefoneComercial || '--'}
                </span>
              </label>
              <br />
              <label>
                Data de Fundação
                <br />
                <span className={style.formarea_readonlydata}>
                {costumer?.dataAbertura.toString().split('T')[0].split('-').reverse().join('/') || '--'}
                </span>
              </label>
              <label>
                Faturamento Mensal
                <br />
                <span className={style.formarea_readonlydata}>
                {applyCurrencyMaskUndefined(costumer?.faturamentoMensalEmpresa) || '--'}
                </span>
              </label>
              <label>
                Capital Social
                <br />
                <span className={style.formarea_readonlydata}>
                {applyCurrencyMaskUndefined(costumer?.capitalSocial) || '--'}
                </span>
              </label>
              <br />
              <br />
            </div>
              ) : ''}
            <form style={{ display: ((costumer?.company_link) ? 'block' : 'none') }}>

            {(associates.length > 0)
                ? ((associates.map((x) => (
                  <div>
                <i>
                  Sócios
                </i>
                  <AssociateEditor
                    idx={(associates.indexOf(x))}
                    key={(associates.indexOf(x))}
                    data={x}
                    onClickParam={handleEditEntrada}
                    showRemove={false}
                  />
                  </div>
               ))) ) : (
                  <i>
                    Nenhum sócio adicionada.
                  </i>
               )}

              </form>
            <h1>Endereço</h1>
            <div>
              <label>
                Rua
                <br />
                <span className={style.formarea_readonlydata}>
                {costumer?.enderecoInstalacao.street1 || '--'}
                </span>
              </label>
              <label>
                Número
                <br />
                <span className={style.formarea_readonlydata}>
                {costumer?.enderecoInstalacao.number || '--'}
                </span>
              </label>
              <label>
                Complemento
                <br />
                <span className={style.formarea_readonlydata}>
                {costumer?.enderecoInstalacao.street2 || '--'}
                </span>
              </label>
              <br />
              <label>
                <b>CEP</b>
                <br />
                <span className={style.formarea_readonlydata}>
                {costumer?.enderecoInstalacao.zip || '--'}
                </span>
              </label>
              <label>
                Estado
                <br />
                <span className={style.formarea_readonlydata}>
                {costumer?.enderecoInstalacao.city.state.name || '--'}
                </span>
              </label>
              <label>
                Cidade
                <br />
                <span className={style.formarea_readonlydata}>
                {costumer?.enderecoInstalacao.city.name || '--'}
                </span>
              </label>
            </div>
            <br />
            <br />
          </span>
          <span className={style.detalheCadastroSplitterSection}>
            <h1>Documentação</h1>
            <table className={style.table}>
              <tbody>
                <tr>
                  <td className={style.detalheCadastroDescrCelula}>
                    Comprovante de Renda
                  </td>
                  <td className={style.detalheCadastroBtnAbrir}>
                    {costumer?.comprovanteRenda !== 'Sem documentos'
                      ? (
                    <button
                      className={style.detalheCadastroBtn}
                      type="button"
                      onClick={() => {
                        handlePdfButtonClick(TipoDocumento.comprovanteRenda);
                      }}
                    >
                      <img
                        className={style.detalheCadastroIcon}
                        src={pdfIcon}
                        alt="Abrir documento: Comprovante de Renda"
                      />
                    </button>
                      ) : ('Não enviado')}
                  </td>
                </tr>
                <tr>
                  <td className={style.detalheCadastroDescrCelula}>
                    Conta de Luz Recente
                  </td>
                  <td className={style.detalheCadastroBtnAbrir}>
                      {costumer?.contaLuzRecente !== 'Sem documentos'
                        ? (
                    <button
                      className={style.detalheCadastroBtn}
                      type="button"
                      onClick={() => {
                        handlePdfButtonClick(TipoDocumento.contaLuzRecente);
                      }}
                    >
                      <img
                        className={style.detalheCadastroIcon}
                        src={pdfIcon}
                        alt="Abrir documento: Conta de Luz Recente"
                      />
                    </button>
                        )   : ('Não enviado')}
                  </td>
                </tr>
                {costumer?.contaLuzRecente !== 'Sem documentos'
                  ? (
                <tr>
                    <tr>
                      <td className={style.electricity_bill_ownership}>
                        <label style={{fontSize: '8pt' }}>
                          Conta de luz está no nome do cliente?
                        </label>
                      </td>
                    </tr>
                    <tr>
                      <td className={style.electricity_bill_ownership} >
                        <label style={{fontSize: '8pt' }}>
                          <input
                            type="radio"
                            name="radioClientOwner"
                            checked={costumer?.electricityBillOwnership}
                          />
                          Sim
                        </label>
                        <label style={{fontSize: '8pt' }}>
                          <input
                            type="radio"
                            checked={!costumer?.electricityBillOwnership}
                            name="radioClientOwner"
                          />
                          Não
                        </label>
                      </td>
                      {!costumer?.electricityBillOwnership && (
                        <td style={{ fontSize: '8pt' }}
                            className={style.detalheCadastroBtnAbrir}>Parentesco: {buscaParentesco(costumer?.electricityBillFamiliarType as number)}</td>
                      )}
                    </tr>
                  </tr>
                  ) : '' }
                <tr>
                  <td className={style.detalheCadastroDescrCelula}>
                    Documento de Identificação
                  </td>
                  <td className={style.detalheCadastroBtnAbrir}>
                     {costumer?.documentoIdentificacao !== 'Sem documentos'
                       ? (
                    <button
                      className={style.detalheCadastroBtn}
                      type="button"
                      onClick={() => {
                        handlePdfButtonClick(TipoDocumento.documentoIdentificacao);
                      }}
                    >
                      <img
                        className={style.detalheCadastroIcon}
                        src={pdfIcon}
                        alt="Abrir documento: Documento de Identificação"
                      />
                    </button>
                       ) : ('Não enviado')}
                  </td>
                </tr>
                <tr>
                  <td className={style.detalheCadastroDescrCelula}>
                    IPTU
                  </td>
                  <td className={style.detalheCadastroBtnAbrir}>
                    {costumer?.iptu !== 'Sem documentos'
                      ? (
                    <button
                      className={style.detalheCadastroBtn}
                      type="button"
                      onClick={() => {
                        handlePdfButtonClick(TipoDocumento.iptu);
                      }}
                    >
                      <img
                        className={style.detalheCadastroIcon}
                        src={pdfIcon}
                        alt="Abrir documento: IPTU"
                      />
                    </button>
                      ) : ('Não enviado')}
                  </td>
                </tr>
                <tr>
                  <td className={style.detalheCadastroDescrCelula}>
                    Nota Fiscal do Equipamento
                  </td>
                  <td className={style.detalheCadastroBtnAbrir}>
                     {costumer?.notaFiscalEquipamentos !== 'Sem documentos'
                       ? (
                    <button
                      className={style.detalheCadastroBtn}
                      type="button"
                      onClick={() => {
                        handlePdfButtonClick(TipoDocumento.notaFiscalEquipamentos);
                      }}
                    >
                      <img
                        className={style.detalheCadastroIcon}
                        src={pdfIcon}
                        alt="Abrir documento: Nota Fiscal do Equipamento"
                      />
                    </button>
                       ) : ('Não enviado')}
                  </td>
                </tr>
              <tr>
                  <td className={style.detalheCadastroDescrCelula}>
                    Proposta comercial
                  </td>
                  <td className={style.detalheCadastroBtnAbrir}>
                     {costumer?.commercialProposal !== 'Sem documentos'
                       ? (
                         <button
                           className={style.detalheCadastroBtn}
                           type="button"
                           onClick={() => {
                             handlePdfButtonClick(TipoDocumento.commercialProposal);
                           }}
                         >
                           <img
                             className={style.detalheCadastroIcon}
                             src={pdfIcon}
                             alt="Abrir documento: Proposta comercial"
                           />
                         </button>
                       ) : ('Não enviado')}
                  </td>
                </tr>
              </tbody>
            </table>
            <h1>Contato</h1>
            <div>
              <label>
                Telefone
                <br />
                <span className={style.formarea_readonlydata}>
                {costumer?.telefone || '--'}
                  </span>
              </label>
              <br />
              <label>
                E-mail
                <br />
                <a href={`mailto:${costumer?.email}`}>
                  {costumer?.email || '--'}
                </a>
              </label>
            </div>
            <br />
            <br />
            {costumer?.status === 'Condições escolhidas' &&
              <div>
                <tr>
                  <td className={style.detalheProposalCelula}>
                    Proposta
                  </td>
                  <td className={style.detalheCadastroBtnAbrir}>
                    {costumer?.proposal
                      ? (
                        <button
                          className={style.detalheCadastroBtn}
                          type="button"
                          onClick={() => {
                            handlePdfButtonClick(TipoDocumento.proposal);
                          }}
                        >
                          <img
                            className={style.detalheCadastroIcon}
                            src={pdfIcon}
                            alt="Abrir documento: Proposta"
                          />
                        </button>
                      ) : ('Não enviada')}
                  </td>
                  <td className={style.detalheProposalCelula}>
                    Nota fiscal
                  </td>
                  <td className={style.detalheCadastroBtnAbrir}>
                    {costumer?.invoice
                      ? (
                        <button
                          className={style.detalheCadastroBtn}
                          type="button"
                          onClick={() => {
                            handlePdfButtonClick(TipoDocumento.invoice);
                          }}
                        >
                          <img
                            className={style.detalheCadastroIcon}
                            src={pdfIcon}
                            alt="Abrir documento: Nota fiscal"
                          />
                        </button>
                      ) : ('Não enviada')}
                  </td>
                  <tr>
                    <td className={style.detalheProposalCelula}>
                      Informar deposito do dinheiro?
                    </td>
                    <td>
                      <input
                        type="checkbox"
                        id="checkDeposit"
                        name="checkDeposit"
                        checked={checkDeposit}
                        onChange={checkDepositChange}
                      />
                    </td>
                  </tr>
                  {checkDeposit &&
                    <span>
                      <tr>
                        <td style={{ fontSize: '8pt' }}>
                          Número da conta (opcional)
                        </td>
                        <td>
                          <input
                            type="text"
                            placeholder={'Apenas números'}
                            id="accountDeposit"
                            name="accountDeposit"
                            style={{
                              width: '10px',
                              height: '10px'
                            }}
                            onChange={accountDepositChange}
                          />
                        </td>
                      </tr>
                      <tr>
                        <td style={{ fontSize: '8pt' }}>
                          Banco  (opcional)
                        </td>
                        <td colSpan={10}>
                          <Select options={bancos}
                                  className="basic-single"
                                  value={bankDeal}
                                  getOptionLabel={(option) => `${option.name}`}
                                  onChange={handleBankDealChange}
                                  theme={(theme) => ({ ...theme, borderRadius: 10})}
                          />
                        </td>
                      </tr>
                      <tr>
                        <td style={{ fontSize: '8pt' }}>
                          Data do depósito  (opcional)
                        </td>
                        <td>
                          <input
                            type="text"
                            style={{ width: '50px' }}
                            id="f_dateDeposit"
                            placeholder="00/00/0000"
                            onChange={handleDateDepositChange}
                          />
                        </td>
                      </tr>
                    </span>
                  }
                </tr>
              </div>
            }
            <br />
            <br />
            {costumer?.status === 'Projeto contratado'
              && (
            <span>
            <h1>Dados da contratação</h1>
            <div className={style.detalheCotacaoFiles}>
              <label>
                Proposta
                <br />
                <span className={style.formarea_readonlydata}>
                  {costumer?.proposal
                    ? (
                      <button
                        className={style.detalheCadastroBtn}
                        type="button"
                        onClick={() => {
                          handlePdfButtonClick(TipoDocumento.proposal);
                        }}
                      >
                        <img
                          className={style.detalheCadastroIcon}
                          src={pdfIcon}
                          alt="Abrir documento: Proposta"
                        />
                      </button>
                    ) : ('Não enviada')}
                </span>
              </label>
              <label>
              Nota Fiscal
              <br />
                <span className={style.formarea_readonlydata}>
                {costumer?.invoice
                  ? (
                    <button
                      className={style.detalheCadastroBtn}
                      type="button"
                      onClick={() => {
                        handlePdfButtonClick(TipoDocumento.invoice);
                      }}
                    >
                      <img
                        className={style.detalheCadastroIcon}
                        src={pdfIcon}
                        alt="Abrir documento: Nota fiscal"
                      />
                    </button>
                  ) : ('Não enviada')}
                </span>
              </label>
            </div>
            <div>
              <label>
                Data do depósito
                <br />
                <span className={style.formarea_readonlydata}>
                {costumer?.dateDeposit ? costumer?.dateDeposit.toString().split('T')[0].split('-').reverse().join('/') : 'Não informado'}
                  </span>
              </label>
              <br />
              <label>
                Conta
                <br />
                <span className={style.formarea_readonlydata}>
                  {costumer?.accountDeposit || 'Não informado'}
                </span>
              </label>
              <label>
                Banco
                <br />
                <span className={style.formarea_readonlydata}>
                  {costumer?.bankDeal.name || 'Não informado'}
                </span>
              </label>
            </div>
            </span>
            )}
            <br />
            <br />
            &nbsp;
            {costumer?.status === 'Condições escolhidas'
              && (
                <button
                  type="button"
                  onClick={handleSubmitDeal}
                >
                  Informar Contratação
                </button>
              )}
            <br />
            {costumer?.status === 'Projeto contratado'
              && (
                <button
                  type="button"
                  onClick={() => setIsModalOpen(true)}
                >
                  Cobrança de conta de energia
                </button>
              )}
            <br />
            <br />
            <button
              type="button"
              onClick={handleClickAbrirChamado}
            >
              Abrir Chamado
            </button>
            <br />
            <br />
            <button
              type="button"
              onClick={handleVoltarClick}
            >
              Voltar
            </button>
            &nbsp;
            {costumer?.status !== 'Análise - Soltech'
              ? (
                <h3>
                  {
                    costumer?.status
                  }
                </h3>
              )
              : (
                <button
                  type="button"
                  onClick={handleEnviarFinanceira}
                >
                  Enviar a Financeira
                </button>
              )}
            &nbsp;
            {costumer?.status === 'Análise - Bancos'
              && (
                <form className={style.detalheCadastroSplitter}>
                <button
                  type="button"
                  onClick={handleReprovarProjeto}
                >
                  Informar recusa de financiamento
                </button>
                  &nbsp;
                <button
                type="button"
                onClick={handleAprovarProjeto}
                >
                Informar aprovação de financiamento
                </button>
                </form>
              )}
            <button
              type="button"
              onClick={handleHistorico}
            >
                Ver histórico
            </button>
            {(costumer?.status === 'Projeto contratado')
              ? (
                <tr>
                  <td>
                    {(!loadedFiles.receipt || loadedFiles.receipt === 'Sem documentos') ? (
                        <label htmlFor="upload_equipamento">
                          Enviar Comprovante de Depósito
                        </label>
                      )
                      : (
                        <label htmlFor="upload_equipamento">
                          Comprovante de Depósito
                        </label>
                      )}
                    <label>
                      <input
                        id="upload_receipt"
                        name="upload_receipt"
                        onChange={handleReceiptChange}
                        type="file"
                      />
                      <img
                        src={uploadIcon}
                        alt="upload receipt"
                        style={{ width: '30px' }}
                        className={style.uploadIcon}
                      />
                      <td>{receipt?.name}</td>
                    </label>
                  </td>
                  <td>
                    {
                      // eslint-disable-next-line no-nested-ternary
                      (loadedFiles.receipt && loadedFiles.receipt !== 'Sem documentos')
                      && (
                        <td>
                          <label>
                            <label>
                              {/* Abrir arquivo enviado anteriormente */}
                            </label>
                            <button type="button" onClick={handleDownloadReceipt}>abrir</button>
                          </label>
                        </td>
                      )
                    }
                  </td>
                </tr>
              ) : (
                ''
              )}
            <br />
                  <br />
            {(costumer?.status === 'Projeto contratado')
              ? (
                <button type="button" onClick={handleSubmitReceipt}>
                  Enviar comprovante
                </button>
              ) : (
                ''
              )}
          </span>
        </form>
        <form
        style={{marginTop: '20px'}}
        className={
        documentUrl === ''
          ? style.detalheCotacaoSecondFrameDisabled
          : style.detalheCotacaoSecondFrame
      }
        >
        <div style={{
          background: '#eee',
          width: '100%',
          padding: '3px',
          color: '#666',
          fontSize: '11px',
        }}
        >
          <button type="button" onClick={handlePreviousPageClick}>Anter.</button>
          &nbsp;
          <button type="button" onClick={handleNextPageClick}>Próx.</button>
          &nbsp;&nbsp;
          {pageNumber}
          &nbsp;/&nbsp;
          {numPages}
        </div>
        <br />
        <canvas
          ref={canvasRef}
          id="canvasPdf"
          style={{ border: '1px solid #eee', width: '100%' }}
        />
      </form>

        <br />
        <form style={{ }}>
          <h1>Análise de Crédito (Cotações enviadas para o vendedor)</h1>
          {(creditOptions.length > 0) ? creditOptions.map((x) => (
            <AnaliseCreditoEditor
              idx={creditOptions.indexOf(x)}
              key={creditOptions.indexOf(x)}
              allowEdit={allowEdit}
              updateAnaliseCredito={updateCreditOptions}
              data={x}
              onClickParam={handleEditEntrada}
              showRemove={(false)}
            />
          )) : '' }
        </form>
        <br />
        <form style={{ display: ((costumer?.status !== 'Projeto contratado') ? 'block' : 'none') }}>

          <h1>Cotação escolhida</h1>
          {(creditOptions.length > 0) ? creditOptions.map((x) => (
            x.id === chosen && (
              <AnaliseCreditoEditor
                allowEdit={false}
                idx={creditOptions.indexOf(x)}
                key={creditOptions.indexOf(x)}
                updateAnaliseCredito={updateCreditOptions}
                data={x}
                onClickParam={handleEditEntrada}
                showRemove={(false)}
              />
            )
          )) : '' }
        </form>
        <br />
        <form style={{ display: ((costumer?.status === 'Projeto contratado') ? 'none' : 'block') }}>
          <h1>Análise de Crédito</h1>
&nbsp;
          <button
            type="button"
            onClick={handleLimparEntradas}
            disabled={(costumer?.status !== 'Financiamento aprovado'
              || creditOptions.length === 0)}
          >
            Limpar Entradas
          </button>
&nbsp;
          <button
            type="button"
            onClick={handleSalvarEntradas}
            disabled={(costumer?.status === 'Projeto contratado'
              || costumer?.status === 'Inativo'
              || creditOptions.length === 0)}
          >
            Enviar Cotações
          </button>
          <br />
          <br />

          {((creditOptions.length > 0)
            ? (creditOptions.map((x) => (
              <AnaliseCreditoEditor
                allowEdit={costumer?.status === 'Financiamento aprovado' ? true : false}
                idx={creditOptions.indexOf(x)}
                updateAnaliseCredito={updateCreditOptions}
                key={creditOptions.indexOf(x)}
                data={x}
                onClickParam={handleEditEntrada}
                showRemove={costumer?.status === 'Financiamento aprovado' ? true : false}
              />
            )))
            : (
              <i>
                Nenhuma entrada adicionada. Preencha os campos abaixo e pressione
                (+) para adicionar uma nova cotação. Todos os campos poderão ser
                revisados antes do envio.
              </i>
            ))}
          <br />
          <br />
          <div>
            <label>
              Banco
              <br />
              <Select options={bancos}
                      value={banco}
                      getOptionLabel={(option) => `${option.name}`}
                      onChange={handleBancoChange}
              />
            </label>
            <label>
              Número de Parcelas
              <input
                type="text"
                id="parcelas"
                value={parcelas}
                style={{ width: '20px' }}
                onChange={handleParcelasChange}
              />
            </label>
            <label>
              Valor da Parcela
              <input
                type="text"
                id="valorParcela"
                ref={valorParcelaRef}
                placeholder="R$3.000,00"
                style={{ width: '30px' }}
                onChange={handleValorParcelaChange}
              />
            </label>
            <label>
              Taxa (%)
              <input
                style={{width: '15px' }}
                type="text"
                ref={taxaRef}
                onChange={handleTaxaChange}
                onFocus={e => {
                  if (e.target.value === '0') e.target.value = '';
                }}
                id="taxa"
                min={0}
              />
              {/* <input */}
              {/*   type="text" */}
              {/*   style={{ width: '40px' }} */}
              {/*   onChange={handleTaxaChange} */}
              {/* /> */}
            </label>
            <label>
              <input
                id="upload_banco"
                name="upload_banco"
                onChange={handleBancoDocChange}
                type="file"
              />
              <img
                src={uploadIcon}
                alt="upload Banco"
                className={style.uploadIcon}
              />
            </label>
            <label>
              &nbsp;
              <button
                type="button"
                style={{ display: 'block' }}
                onClick={handleAdicionarEntrada}
                disabled={(costumer?.status === 'Projeto contratado'
                  || costumer?.status === 'Inativo'
                  || !costumer)}
              >
                +
              </button>
            </label>
          </div>
        </form>
        <br />
        <div className={style.detalheCadastroMensagemContainer}>
          <h1>
            Mensagens
          </h1>
          <br />
          <div className={style.detalheCadastroListaMensagem}>
            {(messages.length > 0) ? messages.map((m) => (
              <Post dataProp={
                {
                  userID: m.userID,
                  senderName: m.senderName,
                  filePath: m.filePath,
                  fileTitle: m.fileTitle,
                  isFile: m.isFile,
                  message: m.message,
                  receiverName: m.receiverName,
                  direction: m.direction,
                  created_at: m.created_at,
                }
              }
              />
            )) : '' }
          </div>

          <div className={style.detalheCadastroInputMensagem}>
            <input
              type="textarea"
              placeholder="Nova mensagem"
              id="messageTextArea"
              value={message}
              className={style.textareaDefaultStyle}
              onChange={handleAdicionarMensagem}
              onKeyDown={(e) => { if (e.key === 'Enter') submitMessage(); }}
            />
            <button
              type="button"
              className={style.detalheCadastroMensagemButton}
              onClick={submitMessage}
            >
              Enviar mensagem
            </button>
            <label htmlFor="upload_contrato">
              <input
                id="upload_contrato"
                name="upload_contrato"
                type="file"
                onInput={handleAdicionarArquivo}
                onClick={changeValue}
              />
              <img
                src={uploadIcon}
                alt="upload certidão de registro profissional"
                className={style.uploadIcon}
              />
            </label>
          </div>
        </div>
      </FormPage>
      <DialogBodyComponent
        title="Cobrança em Conta"
        helperText="Pesquise o usuário"
        cancelButtonText="Cancelar"
        confirmButtonText="Consultar"
        isOpen={isModalOpen}
        close={closeModal}
        confirm={handleConsultConfirm}
      >
        <FormControl style={{ display: 'flex' }}>
          <TextField
          autoFocus
          margin="dense"
          label="Código do Cliente"
          disabled={formLoading}
          value={formLoading ? 'Consultando...' : consult.POS}
          name={"POS"}
          type="text"
          fullWidth
          variant="standard"
          onChange={handleConsultChange}
          />

          {/* this Textfield is a select */}
          <TextField
          select={!formLoading}
          margin="dense"
          id="code"
          label="Distribuidora"
          disabled={formLoading}
          value={formLoading ? 'Consultando...' : consult.LegalEntity}
          name={"LegalEntity"}
          type="text"
          fullWidth
          variant="standard"
          onChange={handleConsultChange}
          >
            {integrators.map((integ) => (
              <MenuItem key={integ.value} value={integ.value}>
                {integ.label}
              </MenuItem>
            ))}
          </TextField>
        </FormControl>
      </DialogBodyComponent>
      <DialogComponent isOpen={isOpen} labelText={loadingText} />
      {/* <DialogHistoryComponent isHistoryOpen={isHistoryOpen} close={closeHistoryModal} projectsUpdates={projectsUpdates}/> */}
    </div>
  );
}
