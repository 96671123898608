import React, {
  ChangeEvent, useRef, useState,
} from 'react';
import {
  TableCell,
  TableHead,
  Paper,
  TableContainer,
  TableRow,
  TableBody,
  Table,
  TablePagination,
  Typography,
  Box, useTheme, alpha,
} from '@mui/material';
import { useHistory } from 'react-router-dom';
import LoadingBar, { LoadingBarRef } from 'react-top-loading-bar';
import Button from '@mui/material/Button';
import { toast } from 'react-toastify';
import { api } from '../../services/api';
import { DialogComponent } from '../DialogComponent';
import { VendorData } from '../../pages/ListaVendedor';

interface VendorsTableProps {
  vendors: Array<VendorData>
  sortVendors: Function
}
interface Column {
  id: 'id' | 'nome' | 'email' | 'status' | 'firstAccess' | 'resendNotification' | 'actions'
  label: string
  minWidth?: number
  align?: 'right' | 'center' | 'left'
  format?: (value: number) => string
}

const VendorsTable = (props: VendorsTableProps) => {
  const {
    vendors,
    sortVendors,
  }: VendorsTableProps = props;
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const history = useHistory();
  const theme = useTheme();
  const [loadingText, setLoadingText] = useState('');
  const [isOpen, setIsOpen] = useState(false);

  const handleClickEmail = async (vendor: VendorData) => {
    const urlv = window.location.href.split('/');
    const urlc = `${urlv[0]}//${urlv[2]}`;
    const msg = 'Deseja reenviar um email avisando ao usuário para que ela realize seu primeiro login?';

    // eslint-disable-next-line no-alert
    if (window.confirm(msg)) {
      setLoadingText('Enviando email..');
      setIsOpen(true);
      const sendEmail = await api.post('email/vendor/send', {
        data: {
          userId: vendor.id,
          returnUrl: urlc,
        },
      });

      if (sendEmail.data === 'EMAIL_SENT') {
        setIsOpen(false);
        toast.success('Email enviado!');
      } else {
        setIsOpen(false);
        toast.error('Houve um erro ao enviar o email!');
      }
    }
  };

  const handleClickActivate = async (vendor: VendorData) => {
    const msg = 'Deseja alterar o status deste vendedor?';

    // eslint-disable-next-line no-alert
    if (window.confirm(msg)) {
      if (vendor.status.toLowerCase() === 'Ativo'.toLowerCase()) {
        setLoadingText('Desativando vendedor...');
      } else {
        setLoadingText('Ativando vendedor...');
      }

      setIsOpen(true);
      const changeStatus = await api.post('/user/vendor/status/put', {
        data: {
          userId: vendor.id,
          status: vendor.status,
        },

      });

      if (changeStatus.data === 'STATUS_CHANGED') {
        toast.success(vendor.status.toLowerCase() === 'Ativo'.toLowerCase()
          ? 'Projeto Desativado' : 'Projeto Ativado');
        setTimeout(() => {
          window.location.reload();
        }, 2000);
      } else {
        toast.error('Houve um erro com sua solicitação, tente mais tarde!');
      }
    }
  };

  const handleChangePage = (event: unknown, newPage: number) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event: ChangeEvent<HTMLInputElement>) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  const columns: readonly Column[] = [
    { id: 'id', label: 'Código', align: 'left' },
    { id: 'nome', label: 'Vendedor', align: 'left' },
    { id: 'email', label: 'E-mail', align: 'left' },
    { id: 'status', label: 'Status', align: 'left' },
    { id: 'firstAccess', label: 'Primeiro acesso', align: 'left' },
    { id: 'resendNotification', label: 'Reenviar notificações', align: 'left' },
    { id: 'actions', label: '', align: 'left' },
  ];

  const ref = useRef<LoadingBarRef>(null);

  const handleRegVendor = () => {
    history.push('/CadastroVendedor');
  };

  return (
    <div>
      <LoadingBar color={theme.palette.primary.main} ref={ref} />
      <Paper sx={{
        width: '100%',
        overflow: 'hidden',
        boxSizing: 'border-box',
        boxShadow: 'none',
      }}
      >
        <TableContainer sx={{ maxHeight: 490 }}>
          <Table>
            <TableHead>
              <TableRow>
                {columns.map((column) => (
                  <TableCell
                    key={column.id}
                    align={column.align}
                    onClick={() => sortVendors(column.id)}
                    sx={{
                      userSelect: 'none',
                      cursor: (
                        column.id === 'id'
                        || column.id === 'nome'
                        || column.id === 'email'
                        || column.id === 'status'
                      ) ? 'pointer' : 'arrow',
                    }}
                  >
                    {column.label}
                  </TableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              {vendors && (
                vendors.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                  .map((row: VendorData) => (
                    <TableRow
                      hover
                      role="checkbox"
                      tabIndex={-1}
                      key={row.id}
                    >
                      <TableCell>
                        {row.id}
                      </TableCell>
                      <TableCell>
                        {row.nome}
                      </TableCell>
                      <TableCell>
                        {row.email}
                      </TableCell>
                      <TableCell
                        sx={{ color: row.status === 'Ativo' ? 'green' : 'red' }}
                      >
                        {row.status}
                      </TableCell>
                      <TableCell>
                        {row.passwordChanged ? 'Sim' : 'Não'}
                      </TableCell>
                      <TableCell>
                        <Typography
                          onClick={() => handleClickEmail(row)}
                          sx={{
                            fontSize: '12px',
                            fontFamily: 'Poppins',
                            color: theme.palette.primary.main,
                            fontWeight: '700',
                            cursor: 'pointer',
                            userSelect: 'none',
                            '&:hover': {
                              textShadow: '1px 1px 1px #0000005A',
                            },
                          }}
                        >
                          Enviar
                        </Typography>
                      </TableCell>
                      <TableCell
                        align="right"
                        sx={{
                          display: 'flex',
                          alignItems: 'center',
                          gap: '1rem',
                        }}
                      >
                        <Typography
                          onClick={() => handleClickActivate(row)}
                          sx={{
                            fontSize: '12px',
                            fontFamily: 'Poppins',
                            color: row.status.toLowerCase() === 'Inativo'.toLowerCase() ? 'green' : 'red',
                            fontWeight: '700',
                            cursor: 'pointer',
                            userSelect: 'none',
                            '&:hover': {
                              textShadow: '1px 1px 1px #0000005A',
                            },
                          }}
                        >
                          {row.status.toLowerCase() === 'Inativo'.toLowerCase() ? 'Ativar' : 'Desativar'}
                        </Typography>
                      </TableCell>
                    </TableRow>
                  ))
              )}
              {(!vendors || vendors.length === 0) && (
                <TableRow
                  hover
                  role="checkbox"
                >
                  <TableCell>
                    Nenhum projeto cadastrado!
                  </TableCell>
                  <TableCell />
                  <TableCell />
                  <TableCell />
                  <TableCell />
                  <TableCell />
                  <TableCell />
                </TableRow>
              )}
            </TableBody>
          </Table>
        </TableContainer>
        <Box sx={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
        }}
        >
          <Button
            variant="contained"
            sx={{
              borderRadius: '2rem',
              backgroundColor: theme.palette.primary.main,
              marginBottom: '0.5rem',
              marginTop: '1rem',
              textTransform: 'none',
              '&:hover': {
                backgroundColor: alpha(theme.palette.primary.main, 0.8),
              },
            }}
            onClick={handleRegVendor}
          >
            Cadastrar vendedor
          </Button>
          <TablePagination
            rowsPerPageOptions={[10, 25, 100]}
            component="div"
            count={vendors.length}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
            labelRowsPerPage="Linhas por página"
          />
        </Box>
      </Paper>
      <DialogComponent isOpen={isOpen} labelText={loadingText} />
    </div>
  );
};
export default VendorsTable;
