import LoadingBar, { LoadingBarRef } from 'react-top-loading-bar';
import React, {
  ChangeEvent, useEffect, useRef, useState,
} from 'react';
import {
  alpha,
  Autocomplete,
  Box,
  Button,
  Checkbox,
  FormControlLabel,
  IconButton,
  InputAdornment,
  Stack,
  TextField,
  ThemeProvider,
  Typography, useMediaQuery, useTheme,
} from '@mui/material';
import Grid from '@mui/material/Unstable_Grid2';
import { parseCookies } from 'nookies';
import { useHistory } from 'react-router-dom';
import { toast, ToastContainer } from 'react-toastify';
import UploadFileIcon from '@mui/icons-material/UploadFile';
import ClearIcon from '@mui/icons-material/Clear';
import { CloudUpload as CloudUploadIcon } from '@mui/icons-material';
import style from './detalhecotacao.module.scss';
import { api } from '../../services/api';
import {
  applyCurrencyMask,
  applyDecimal,
  applyNoSymbolCurrencyMask,
  calcKWPCost,
  convertToNumber,
  formatAccount,
  handleFileName,
  percentageValue,
  sendDocument,
  timeStampToDateOnly,
} from '../../utils/util';
import { DialogHistoryComponent } from '../../components/DialogHistoryComponent';
import {
  Address, Associate, Bank, City, CreditOption, Customer, Project, ProjectUpdate, State, User,
} from '../../contexts/@types';
import {
  defaultAddress, defaultBank,
  defaultCity, defaultCreditOption,
  defaultCustomer,
  defaultProject, defaultState, defaultUser,
} from '../../contexts/@defaultValues';
import { DialogBodyComponent } from '../../components/DialogBodyComponent';
import { DialogComponent } from '../../components/DialogComponent';
import BaseDatePicker from '../../components/BaseDatePicker';
import { useButtonThemes } from '../../themes/buttonTheme';
import { SectionTitle } from '../../components/elements/SectionTitle';
import { DataDisplay } from '../../components/elements/DataDisplay';
import { DocumentDisplay } from '../../components/elements/DocumentDisplay';
import { CreditOptionEditor } from '../../components/CreditOptionEditor';
import { Chat } from '../../components/Chat';
import { Index } from '../../components/AssociateDisplay';
import { CobrancaEmContaDialog } from '../../components/Dialogs/CobrancaEmContaDialog';
import SingleBarGraph from '../../components/SingleBarGraph';

interface EditedCredit {
  id: number,
  parcelas: number,
  valorParcela: number | string,
  taxa: number,
}

interface Error {
  parcelas: boolean,
  valorParcela: boolean,
  taxa: boolean,
  banco: boolean,
}

export function DetalheCotacao() {
  const { primaryButton, secondaryButton } = useButtonThemes();
  const ref = useRef<LoadingBarRef>(null);
  const getUserId = () => {
    const { 'nextauth.tag': userId } = parseCookies();
    return parseInt(userId, 10);
  };
  const getProjectId = () => {
    const { 'nextauth.p': cprojId } = parseCookies();
    return parseInt(cprojId, 10);
  };
  const history = useHistory();
  const theme = useTheme();
  const isXs = useMediaQuery(theme.breakpoints.only('xs'));

  const [project, setProject] = useState<Project>(defaultProject);
  const [historicPrice, setHistoricPrice] = useState<number>(0);
  const [customer, setCustomer] = useState<Customer>(defaultCustomer);
  const [user, setUser] = useState<User>(defaultUser);
  const [seller, setSeller] = useState<User>(defaultUser);
  const [address, setAddress] = useState<Address>(defaultAddress);
  const [city, setCity] = useState<City>(defaultCity);
  const [state, setState] = useState<State>(defaultState);
  const [associates, setAssociates] = useState<Array<Associate>>([]);
  const [projectsUpdates, setProjectsUpdates] = useState<ProjectUpdate[]>([]);
  const [bank, setBank] = useState<Bank>(defaultBank);
  const [projectBank, setProjectBank] = useState<Bank>(defaultBank);
  const [banks, setBanks] = useState<Array<Bank>>([defaultBank]);
  const [creditOptions, setCreditOptions] = useState<Array<CreditOption>>([]);
  const [tempCreditOptions, setTempCreditOptions] = useState<Array<CreditOption>>([]);
  const [creditOption, setCreditOption] = useState<CreditOption>(defaultCreditOption);

  const [loadingText, setLoadingText] = useState('');
  const [useEffectRefresh, setUseEffectRefresh] = useState(false);
  const [error, setError] = useState<Error>({
    parcelas: false,
    valorParcela: false,
    taxa: false,
    banco: false,
  });

  // Dialog booleans
  const [isOpen, setIsOpen] = useState(false);
  const [isHistoryOpen, setIsHistoryOpen] = useState(false);
  const [isSendFinanceOpen, setIsSendFinanceOpen] = useState(false);
  const [isApproveFinanceOpen, setIsApproveFinanceOpen] = useState(false);
  const [isRefuseFinanceOpen, setIsRefuseFinanceOpen] = useState(false);
  const [isRejectDocsOpen, setIsRejectDocsOpen] = useState(false);
  const [isApproveContractOpen, setIsApproveContractOpen] = useState(false);
  const [isSendCreditOpen, setIsSendCreditOpen] = useState(false);
  const [isCobrancaOpen, setIsCobrancaOpen] = useState(false);
  const [isApproveDocsOpen, setIsApproveDocsOpen] = useState(false);

  const cardStyle = {
    backgroundColor: '#fff',
    boxShadow: '0px 2px 2px 0px #0000001A',
    borderRadius: '8px',
    boxSizing: 'border-box',
    padding: '1rem',
    minHeight: 'fit-content',
  };

  useEffect(() => {
    const getCostumer = async () => {
      setLoadingText('carregando dados...');
      setIsOpen(true);
      api.post('project/costumer/get', {
        projectId: getProjectId(),
      }).then((resolved) => {
        const { data } = resolved;
        if (data === 'COULD_NOT_FIND_COSTUMER') return setIsOpen(false);
        setCustomer({
          ...customer,
          ...data,
        });
        setAssociates(data.associates);
        setAddress({
          ...address,
          ...data.enderecoInstalacao,
        });
        setCity({
          ...city,
          ...data.enderecoInstalacao.city,
        });
        setState({
          ...state,
          ...data.enderecoInstalacao.city.state,
        });
        setProjectBank({
          ...projectBank,
          ...data.bankDeal,
        });

        return setIsOpen(false);
      });
    };
    const getProjectData = async () => {
      api.post(
        'project/single/get',
        { projectId: getProjectId() },
      ).then((resolved) => {
        const { data } = resolved;
        if (data !== 'COULD_NOT_LOCATE_PROJECT') {
          // whether filled already
          setProjectsUpdates(data.projectsUpdates);
          setHistoricPrice(data.historicPrice);
          setProject({
            ...project,
            ...data,
          });
          setSeller({
            ...seller,
            ...data.user,
          });
        }
      });
    };

    const getCreditOptions = async () => {
      api.post('project/credit/get', {
        projectId: getProjectId(),
      }).then((resolved) => {
        const { data } = resolved;
        setCreditOptions(data);
      });
    };

    const getBancos = async () => {
      api.post('/bank/get')
        .then((resolved) => {
          const { data } = resolved;
          // set an array of banks
          setBanks(data);
        });
    };

    const getUser = async () => {
      api.post('/user/get', {
        userId: getUserId(),
      }).then((resolved) => {
        const { data } = resolved;
        setUser({
          ...user,
          ...data,
        });
      });
    };

    getCreditOptions();
    getProjectData();
    getCostumer();
    getBancos();
    getUser();
    // If Use-effect is changed run without the disabled eslint to check
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [useEffectRefresh, getProjectId()]);

  const isCompanyLink = (link: boolean | undefined) => {
    if (link === undefined) return link;
    if (link) return 'Sim';

    return 'Não';
  };

  const buscaParentesco = (n: number) => {
    let parentesco: string = '';
    // eslint-disable-next-line default-case
    switch (n) {
      case 1:
        parentesco = 'Mãe';
        break;
      case 2:
        parentesco = 'Pai';
        break;
      case 3:
        parentesco = 'Esposo(a)';
        break;
      case 4:
        parentesco = 'Filho(a)';
        break;
      case 5:
        parentesco = 'Irmão/Irmã';
        break;
    }

    return parentesco;
  };

  const handleChangeBank = (event: React.ChangeEvent<{}>, value: Bank) => {
    setProject({
      ...project,
      bankDealId: value.id,
    });
  };

  const handleChangeCreditBank = (event: React.ChangeEvent<{}>, value: Bank) => {
    setCreditOption({
      ...creditOption,
      banco: value.name,
      bancoId: value.id,
    });
    setBank(value);
  };

  const fileInputRef = useRef<HTMLInputElement>(null);
  const handleChangeProject = (key: keyof Project) => (e: ChangeEvent<HTMLInputElement>) => {
    const { value, checked } = e.target;
    let input: string | boolean | File | null = '';
    if (key === 'checkDeposit') input = checked;
    if (key === 'accountDeposit') input = formatAccount(value);
    if (key !== 'checkDeposit' && key !== 'accountDeposit') input = value;
    if (key === 'receipt') {
      if (e.target.files) {
        const [file] = Array.from(e.target.files);
        if (file.type === 'application/pdf') input = file;
      } else {
        if (fileInputRef.current) {
          fileInputRef.current.value = '';
        }
        input = null;
      }
    }
    const newProject = {
      ...project,
      [key]: input,
    };
    setProject(newProject);
  };

  const handleClearTempCredit = () => {
    setCreditOption(defaultCreditOption);
    setBank(defaultBank);
  };

  function formatCurrency(value: string) {
    if (value === '0,0') return '';
    let input = value.replace(/\D/g, '');
    input = (parseInt(input, 10) / 100).toFixed(2).replace(/\./g, ',');
    // if (value === 'NaN') input = '0,0';
    return input;
  }

  const handleChangeTempCredit = (key:
    keyof CreditOption) => (e:
    ChangeEvent<HTMLInputElement>) => {
    const { value, files } = e.target;
    let input: string | boolean | File = '';
    if (key === 'parcelas') input = value.replace(/\D/g, '');
    if (key === 'valorParcela') input = formatCurrency(value);
    if (key === 'taxa') input = percentageValue(value);
    if (key === 'bancoDoc') {
      if (files) {
        const [fileItem] = Array.from(files);
        if (fileItem.type === 'application/pdf') input = fileItem;
      }
    }

    setCreditOption({ ...creditOption, [key]: input });
  };

  const handleIncludeTempCredit = () => {
    const newError = {
      ...error,
      parcelas: creditOption.parcelas <= 0,
      valorParcela: Number(creditOption.valorParcela.toString().replace(/,/g, '.')) <= 0,
      taxa: creditOption.taxa < 0,
      banco: bank.id === 0,
    };

    setError(newError);

    const erroArray = Object.values(newError);
    const isValid = !erroArray.includes(true);

    if (!isValid) return '';

    setTempCreditOptions([...tempCreditOptions, creditOption]);
    return handleClearTempCredit();
  };

  const handleRemoveTempCredit = (credit: CreditOption) => {
    const arrIndex = tempCreditOptions.indexOf(credit);

    // remove array state by index
    setTempCreditOptions((item) => item.filter((_, i) => i !== arrIndex));
  };

  const handleEditTempCredit = (credit: CreditOption, editedIndex: number) => {
    // Make a copy of the state array so you can mutate it
    const newArr = tempCreditOptions;
    // Substitute the old item with the edited item, based on stored index
    newArr.splice(editedIndex, 1, credit);
    // Replace state with the copied array
    setTempCreditOptions([...newArr]);
  };

  const handleBackClick = () => {
    history.goBack();
  };

  const handleClickOpenCall = () => {
    history.push({ pathname: '/AbrirChamado', state: { id: getProjectId() } });
  };

  const closeModal = () => {
    setIsSendFinanceOpen(false);
    setIsApproveFinanceOpen(false);
    setIsRefuseFinanceOpen(false);
    setIsRejectDocsOpen(false);
    setIsApproveContractOpen(false);
    setIsHistoryOpen(false);
    setIsSendCreditOpen(false);
    setIsCobrancaOpen(false);
    setIsApproveDocsOpen(false);
  };

  const handleEnviarFinanceira = async () => {
    setLoadingText('Enviando para financeira...');
    setIsOpen(true);
    await api.post('project/status/put', {
      data: {
        projectId: getProjectId(),
        status: 'Análise - Bancos',
        userId: getUserId(),
      },
    }).then(() => {
      setUseEffectRefresh(!useEffectRefresh);
      setIsOpen(false);
    });
  };

  const handleRecusarFinanciamento = async () => {
    setLoadingText('Recusando financiamento...');
    setIsOpen(true);
    await api.post('project/status/put', {
      data: {
        projectId: getProjectId(),
        status: 'Financiamento recusado',
        userId: getUserId(),
      },
    }).then(() => {
      setUseEffectRefresh(!useEffectRefresh);
      setIsOpen(false);
    });
  };

  const handleAprovarFinanciamento = async () => {
    setLoadingText('Aprovando financiamento...');
    setIsOpen(true);
    await api.post('project/status/put', {
      data: {
        projectId: getProjectId(),
        status: 'Financiamento aprovado',
        userId: getUserId(),
      },
    }).then(() => {
      setUseEffectRefresh(!useEffectRefresh);
      setIsOpen(false);
    });
  };

  const handleRecusarDocumentos = async () => {
    setLoadingText('Recusa de documentos...');
    setIsOpen(true);
    await api.post('project/status/put', {
      data: {
        projectId: getProjectId(),
        status: 'Documentos recusados',
        userId: getUserId(),
      },
    }).then(() => {
      setUseEffectRefresh(!useEffectRefresh);
      setIsOpen(false);
    });
  };

  const handleAprovarDocumentos = async () => {
    setLoadingText('Aprovar documentos...');
    setIsOpen(true);
    await api.post('project/status/put', {
      data: {
        projectId: getProjectId(),
        status: 'Aguardando NF',
        userId: getUserId(),
      },
    }).then(() => {
      setUseEffectRefresh(!useEffectRefresh);
      setIsOpen(false);
    });
  };

  const validateBank = () => project.checkDeposit;

  const handleClickApprove = () => {
    if (!validateBank()) {
      return toast
        .warn('Para informar a contratação do projeto, você deve marcar que o deposito foi realizado.');
    }
    if (!project.dateDeposit
      || project.dateDeposit.length <= 0
      || project.dateDeposit.toLowerCase() === 'invalid date') {
      return toast.warn('Preencha a data do depósito');
    }

    return setIsApproveContractOpen(true);
  };

  const handleAprovarContrato = async () => {
    let receiptURL = '';
    if (project.receipt instanceof File) {
      receiptURL = await sendDocument('receipt', project.receipt);
      if (receiptURL === 'Sem documentos') {
        toast.error('Ocorreu um erro ao salvar o comprovante de depósito.');
        return;
      }
    }

    // Mantém somente os números da conta
    let formatedAccount;
    let accountNumber = project.accountDeposit;
    if (project.accountDeposit) {
      formatedAccount = project.accountDeposit.toString().replace(/\D/g, '');
      accountNumber = parseInt(formatedAccount, 10);
    }

    await api.post('project/deal/put', {
      data: {
        projectId: getProjectId(),
        tag: getUserId(),
        status: 'Projeto contratado',
        checkDeposit: project.checkDeposit,
        bankDealId: project.bankDealId,
        dateDeposit: project.dateDeposit,
        accountDeposit: accountNumber,
        depositReceipt: receiptURL,
      },
    }).then(() => {
      setUseEffectRefresh(!useEffectRefresh);
      setIsOpen(false);
    });
  };

  const handleEditCredit = async (editedItem: EditedCredit) => {
    await api.post('/project/credit/update', {
      data: {
        id: editedItem.id,
        taxa: Number(editedItem.taxa),
        parcelas: Number(editedItem.parcelas),
        valorParcela: Number(editedItem.valorParcela.toString().replace(/,/g, '')),
      },
    }).then((response) => {
      if (response.data !== 'CREDIT_UPDATED') return toast.error('Falha ao alterar a condição.');
      toast.success('Condição de crédito atualizada!');
      return setUseEffectRefresh(!useEffectRefresh);
    });
  };

  const fixCurrencyToSubmit = (value: string) => {
    const currency = value.replace(/,/g, '');
    return Number(currency);
  };

  const handleSendCredit = async () => {
    setIsOpen(true);
    for (let i = 0; i < tempCreditOptions.length; i++) {
      // Enviando arquivos
      if (tempCreditOptions[i].bancoDoc && tempCreditOptions[i].bancoDoc.toString().length > 0) {
        const fileName = handleFileName(tempCreditOptions[i].bancoDoc);
        // eslint-disable-next-line no-await-in-loop
        tempCreditOptions[i].bancoDoc = await sendDocument('docBanco', tempCreditOptions[i].bancoDoc);
        toast.success(`Documento ${fileName} enviado.`);
      } else {
        tempCreditOptions[i].bancoDoc = 'Sem documentos';
      }
      tempCreditOptions[i].valorParcela = fixCurrencyToSubmit(
        tempCreditOptions[i].valorParcela.toString(),
      );
    }

    const data = {
      projectId: getProjectId(),
      creditOptions: tempCreditOptions,
      userId: getUserId(),
    };

    api.post('project/credit/put', {
      data,
    }).then((response) => {
      if (response.data !== 'CREDIT_OPTIONS_UPDATED') {
        setIsOpen(false);
        toast.error(`Falha ao inserir opções de crédito. ${response.data}`);
      } else {
        setTempCreditOptions([]);
        setUseEffectRefresh(!useEffectRefresh);
        setIsOpen(false);
      }
      setIsOpen(false);
    });
  };

  const confirmDialog = async (type: string) => {
    await closeModal();

    if (type === 'sendFinance') {
      return handleEnviarFinanceira();
    }
    if (type === 'recuseFinance') {
      return handleRecusarFinanciamento();
    }
    if (type === 'approveFinance') {
      return handleAprovarFinanciamento();
    }
    if (type === 'rejectDocs') {
      return handleRecusarDocumentos();
    }
    if (type === 'sendCredit') {
      return handleSendCredit();
    }
    if (type === 'approveDocs') {
      return handleAprovarDocumentos();
    }

    return handleAprovarContrato();
  };

  const handleChangeDate = (date: string) => {
    const fakeEvent = {
      target: {
        value: date,
      },
    } as React.ChangeEvent<HTMLInputElement>;
    handleChangeProject('dateDeposit')(fakeEvent);
  };

  return (
    <>
      <Box sx={cardStyle}>
        <LoadingBar color={theme.palette.primary.main} ref={ref} />
        <Button
          variant="contained"
          type="submit"
          disableElevation
          sx={{
            minWidth: 'fit-content',
            borderRadius: '2rem',
            backgroundColor: theme.palette.primary.main,
            marginBottom: '0.5rem',
            '&:hover': {
              backgroundColor: alpha(theme.palette.primary.main, 0.8),
            },
          }}
          onClick={handleBackClick}
        >
          Voltar
        </Button>
        {/* primeiro grid-container */}
        <Grid
          container
          spacing={3}
        >
          {/* Primeiro item-grid */}
          <Grid xs={12} lg={8}>
            <Box>
              <SectionTitle title={`Pedido de cotação #${getProjectId()}`} status={project.status} />
              <Stack
                direction={isXs ? 'column' : 'row'}
                justifyContent="space-between"
              >
                <DataDisplay title="Projeto" data={project.nome || '-----'} />
                <DataDisplay title="Vendedor" data={seller.name || '-----'} />
                <DataDisplay
                  title="Data de Criação"
                  data={project.dataCriacao
                    ? timeStampToDateOnly(project.dataCriacao.toString())
                    : '-----'}
                />
              </Stack>
              <hr className={style.subDivisor} />
              <Stack
                direction={isXs ? 'column' : 'row'}
                justifyContent="space-between"
              >
                <DataDisplay title="Cliente" data={customer.nome || '-----'} />
                <DataDisplay title="CPF" data={customer.cpf || '-----'} />
                <DataDisplay title="RG" data={customer.rg || '-----'} />
              </Stack>
              <Stack
                direction={isXs ? 'column' : 'row'}
                justifyContent="space-between"
              >
                <DataDisplay title="Nome da mãe" data={customer.nomeMae || '-----'} />
                <DataDisplay
                  title="Data de nascimento"
                  data={customer.dataNascimento
                    ? timeStampToDateOnly(customer.dataNascimento.toString())
                    : '-----'}
                />
                <DataDisplay title="Estado civil" data={customer.estadoCivil || '-----'} />
              </Stack>
              <hr className={style.subDivisor} />
              <Stack
                direction={isXs ? 'column' : 'row'}
                justifyContent="space-between"
              >
                <DataDisplay title="Faturamento mensal" data={applyCurrencyMask(customer?.faturamentoMensal.toString() || '0')} />
                <DataDisplay title="Média da conta de luz" data={applyCurrencyMask(customer?.mediaContaLuz.toString() || '0')} />
                <DataDisplay title="Patrimônio" data={applyCurrencyMask(customer?.valorPatrimonio.toString() || '0')} />
              </Stack>
              <Stack
                direction={isXs ? 'column' : 'row'}
                justifyContent="space-between"
              >
                <DataDisplay title="Profissão" data={customer?.profissao || '-----'} />
                <DataDisplay title="Tempo de profissão" data={`${customer?.tempoProfissao} anos` || '-----'} />
                <DataDisplay title="" data="" />
              </Stack>
              <hr className={style.subDivisor} />
              <Stack
                direction={isXs ? 'column' : 'row'}
                justifyContent="space-between"
              >
                <DataDisplay title="Valor de entrada do projeto" data={applyCurrencyMask(project.valorEntrada.toString() || '0')} />
                <DataDisplay title="Valor total do projeto" data={applyCurrencyMask(project.valorTotal.toString() || '0')} />
                <DataDisplay title="Carência" data={`${project.carencia} meses` || '-----'} />
              </Stack>
              <Stack
                direction={isXs ? 'column' : 'row'}
                justifyContent="space-between"
              >
                <DataDisplay title="Potência do projeto" data={`${project.potencia} Kw` || '-----'} />
                <DataDisplay title="Custo do kWp" data={project.powerCost && project.powerCost > 0 ? applyCurrencyMask(project.powerCost) : `R$${calcKWPCost(convertToNumber(applyNoSymbolCurrencyMask(project.valorTotal)), project.potencia).brl}` || '-----'} />
                <DataDisplay title="O projeto é vinculado a empresa?" data={isCompanyLink(customer.company_link) || '-----'} />
              </Stack>
              {!!state.id && (
                <Stack
                  mt="1rem"
                  alignItems="center"
                  justifyContent="center"
                >
                  <SingleBarGraph
                    title="Comparação do histórico do preço por região"
                    kWpCost={project.powerCost && project.powerCost > 0
                      ? convertToNumber(applyNoSymbolCurrencyMask(project.powerCost))
                      : calcKWPCost(convertToNumber(
                        applyNoSymbolCurrencyMask(project.valorTotal),
                      ), project.potencia).value}
                    reference={{
                      value: applyDecimal(historicPrice),
                      label: `Média da região ${applyCurrencyMask(historicPrice || 0)}`,
                    }}
                    width="70%"
                  />
                </Stack>
              )}
            </Box>
            {customer.company_link
              && (
              <Box>
                <SectionTitle title="Dados da Empresa" />
                <Stack
                  direction={isXs ? 'column' : 'row'}
                  justifyContent="space-between"
                >
                  <DataDisplay title="CNPJ" data={customer.cnpj || '-----'} />
                  <DataDisplay title="Razão Social" data={customer.razaoSocial || '-----'} />
                  <DataDisplay title="Nome Fantasia" data={customer.nomeFantasia || '-----'} />
                </Stack>
                <Stack
                  direction={isXs ? 'column' : 'row'}
                  justifyContent="space-between"
                >
                  <DataDisplay title="Email" data={customer.emailEmpresa || '-----'} />
                  <DataDisplay
                    title="Telefone Comercial"
                    data={customer.telefoneComercial || '-----'}
                  />
                  <DataDisplay
                    title="Data de Fundação"
                    data={customer.dataAbertura
                      ? timeStampToDateOnly(customer.dataAbertura.toString())
                      : '-----'}
                  />
                </Stack>
                <Stack
                  direction={isXs ? 'column' : 'row'}
                  justifyContent="space-between"
                >
                  <DataDisplay
                    title="Faturamento Mensal"
                    data={applyCurrencyMask(customer.faturamentoMensalEmpresa.toString() || '0')}
                  />
                  <DataDisplay
                    title="Capital Social"
                    data={applyCurrencyMask(customer.capitalSocial.toString() || '0')}
                  />
                  <DataDisplay title="" data="" />
                </Stack>
                {associates && associates.length > 0
                  && (
                    <Stack
                      sx={{ mt: 1 }}
                      flexDirection="column"
                      justifyContent="center"
                      alignContent="center"
                      alignItems="center"
                    >
                      <Typography
                        sx={{ width: 'fit-content' }}
                        fontWeight={700}
                      >
                        Sócios
                      </Typography>
                      <Index
                        associates={associates}
                      />
                    </Stack>
                  )}
              </Box>
              )}
            <Box>
              <SectionTitle title="Endereço" />
              <Stack
                direction={isXs ? 'column' : 'row'}
                justifyContent="space-between"
              >
                <DataDisplay title="Logradouro" data={address.street1 || '-----'} />
                <DataDisplay title="Nº" data={address.number || '-----'} />
                <DataDisplay title="Bairro/Setor" data={address.district || '-----'} />
              </Stack>
              <Stack
                direction={isXs ? 'column' : 'row'}
                justifyContent="space-between"
              >
                <DataDisplay title="Cidade" data={city.name || '-----'} />
                <DataDisplay title="Estado" data={state.name || '-----'} />
                <DataDisplay title="CEP" data={address.zip || '-----'} />
                <DataDisplay title="Complemento" data={address.street2 || '-----'} />
              </Stack>
            </Box>
            <Box>
              <SectionTitle title="Contato" />
              <Stack
                direction={isXs ? 'column' : 'row'}
                justifyContent="space-between"
              >
                <DataDisplay title="Telefone" data={customer.telefone || '-----'} />
                <DataDisplay title="E-mail" data={customer.email || '-----'} />
              </Stack>
            </Box>
          </Grid>
          {/* Segundo item-grid */}
          <Grid
            xs={12}
            lg={4}
            sx={{
              display: 'flex', flexDirection: 'column', justifyContent: 'space-between',
            }}
          >
            <div
              style={{ marginBottom: '1rem' }}
            >
              <Box>
                <SectionTitle title="Documentação" />
                <Stack>
                  <DocumentDisplay title="Comprovante de renda" file={customer.comprovanteRenda} />
                  <DocumentDisplay title="Conta de luz recente" file={customer.contaLuzRecente} />
                  {(customer.contaLuzRecente && customer.contaLuzRecente !== 'Sem documentos') && (
                    <Typography
                      fontSize="12px"
                    >
                      { 'Conta de luz está no nome do cliente? ' }
                      {' '}
                      { customer.electricityBillOwnership ? 'Sim' : 'Não' }
                    </Typography>
                  )}
                  {!customer.electricityBillOwnership && (
                    <Typography
                      fontSize="12px"
                    >
                      { buscaParentesco(customer.electricityBillFamiliarType as number) }
                    </Typography>
                  )}
                  <DocumentDisplay title="Documento de identificação" file={customer.documentoIdentificacao} />
                  <DocumentDisplay title="IPTU" file={customer.iptu} />
                  <DocumentDisplay title="Nota fiscal do equipamento" file={customer.notaFiscalEquipamentos} />
                  <DocumentDisplay title="Proposta comercial" file={customer.proposal} />
                </Stack>
              </Box>
              <Box>
                {(project.status === 'Condições escolhidas' || project.status === 'Projeto contratado') && (
                  <SectionTitle title="Dados da contratação" />
                )}
                {project.status === 'Condições escolhidas' && (
                <>
                  <FormControlLabel
                    label="Informar depósito do dinheiro?"
                    control={<Checkbox checked={project.checkDeposit} onChange={handleChangeProject('checkDeposit')} />}
                  />
                  { project.checkDeposit
                && (
                  <Stack
                    direction="row"
                    useFlexGap
                    flexWrap="wrap"
                    spacing={2}
                  >
                    <TextField
                      fullWidth
                      label="Número da conta"
                      variant="outlined"
                      value={project.accountDeposit}
                      onChange={handleChangeProject('accountDeposit')}
                      helperText="Opcional"
                    />
                    <Autocomplete
                      sx={{ flexGrow: 1 }}
                      disableClearable
                      options={banks}
                      getOptionLabel={(option: Bank) => option.name}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          label="Banco"
                          helperText="Opcional"
                        />
                      )}
                      onChange={handleChangeBank}
                    />
                    <BaseDatePicker
                      value={project.dateDeposit}
                      label="Data do depósito"
                      handleChange={handleChangeDate}
                    />
                    <TextField
                      label="Enviar comprovante de depósito"
                      fullWidth
                      value={handleFileName(project.receipt)}
                      InputProps={{
                        readOnly: true,
                        endAdornment: (
                          <InputAdornment position="end">
                            <>
                              {project.receipt && project.receipt !== '' && (
                                <IconButton
                                  component="label"
                                  onClick={() => {
                                    const fakeEvent = {
                                      target: {
                                        value: 'clear',
                                      },
                                    } as React.ChangeEvent<HTMLInputElement>;
                                    handleChangeProject('receipt')(fakeEvent);
                                  }}
                                >
                                  <ClearIcon />
                                </IconButton>
                              )}
                              <IconButton component="label">
                                <CloudUploadIcon sx={{ color: '#EB5A12' }} />
                                <input
                                  type="file"
                                  accept=".pdf"
                                  hidden
                                  ref={fileInputRef}
                                  onChange={handleChangeProject('receipt')}
                                />
                              </IconButton>
                            </>
                          </InputAdornment>
                        ),
                      }}
                      InputLabelProps={{
                        shrink: !!project.receipt,
                      }}
                      variant="outlined"
                      disabled
                      helperText="Opcional"
                    />
                  </Stack>
                )}
                </>
                )}
                {project.status === 'Projeto contratado' && (
                  <>
                    <Stack
                      direction={isXs ? 'column' : 'row'}
                      justifyContent="space-between"
                      marginBottom={2}
                    >
                      <DocumentDisplay title="Proposta" file={project.proposal} sx={{ mr: { xs: 0, sm: 1 } }} />
                      <DocumentDisplay title="Nota fiscal" file={project.invoice} />
                    </Stack>
                    <Stack
                      direction="row"
                      justifyContent="space-between"
                    >
                      <DataDisplay
                        title="Data do depósito"
                        data={(project && project.dateDeposit) ? timeStampToDateOnly(project.dateDeposit.toString()) : 'Não informado'}
                      />
                      <DataDisplay title="Conta" data={project.accountDeposit || 'Não informado'} />
                      <DataDisplay title="Banco" data={projectBank.name || 'Não informado'} />
                    </Stack>
                  </>
                )}
              </Box>
            </div>
            <Box>
              <Stack
                direction="column"
                alignItems="center"
                justifyContent="flex-end"
              >
                {project.status === 'Análise - Soltech' && (
                <ThemeProvider theme={primaryButton}>
                  <Button
                    onClick={() => setIsSendFinanceOpen(true)}
                    sx={{
                      maxWidth: '300px',
                    }}
                  >
                    Enviar à financeira
                  </Button>
                </ThemeProvider>
                )}
                {project.status === 'Análise - Bancos' && (
                <Stack
                  sx={{
                    marginBottom: '0.55rem',
                  }}
                  direction="row"
                  spacing={2}
                >
                  <ThemeProvider theme={secondaryButton}>
                    <Button
                      size="small"
                      sx={{
                        maxWidth: '300px',
                        fontSize: {
                          lg: '12px', xl: '14px',
                        },
                      }}
                      onClick={() => setIsRefuseFinanceOpen(true)}
                    >
                      Informar recusa de financiamento
                    </Button>
                  </ThemeProvider>
                  <ThemeProvider theme={primaryButton}>
                    <Button
                      size="small"
                      sx={{
                        maxWidth: '300px',
                        fontSize: {
                          lg: '12px', xl: '14px',
                        },
                      }}
                      onClick={() => setIsApproveFinanceOpen(true)}
                    >
                      Informar aprovação de financiamento
                    </Button>
                  </ThemeProvider>
                </Stack>
                )}
                {project.status === 'Análise - Documentos' && (
                <Stack
                  sx={{
                    marginBottom: '0.55rem',
                  }}
                  direction="row"
                  spacing={2}
                >
                  <ThemeProvider theme={secondaryButton}>
                    <Button
                      sx={{
                        maxWidth: '300px',
                        fontSize: { lg: '12px', xl: '14px' },
                      }}
                      size="small"
                      onClick={() => setIsRejectDocsOpen(true)}
                    >
                      Recusar documentos
                    </Button>
                  </ThemeProvider>
                  <ThemeProvider theme={primaryButton}>
                    <Button
                      sx={{
                        maxWidth: '300px',
                        fontSize: { lg: '12px', xl: '14px' },
                      }}
                      size="small"
                      onClick={() => setIsApproveDocsOpen(true)}
                    >
                      Aprovar Documentos
                    </Button>
                  </ThemeProvider>
                </Stack>
                )}
                {project.status === 'Aguardando NF' && (
                  <Typography
                    fontSize="18px"
                    fontWeight="500"
                    textAlign="center"
                    sx={{
                      mb: 4,
                    }}
                  >
                    Aguardando o envio da nota fiscal!
                  </Typography>
                )}
                {project.status === 'Condições escolhidas' && (
                  <ThemeProvider theme={primaryButton}>
                    <Button
                      sx={{
                        maxWidth: '300px',
                      }}
                      onClick={handleClickApprove}
                    >
                      Informar Contratação
                    </Button>
                  </ThemeProvider>
                )}
                {project.status === 'Projeto contratado' && (
                <ThemeProvider theme={secondaryButton}>
                  <Button
                    sx={{
                      maxWidth: '300px',
                    }}
                    onClick={() => setIsCobrancaOpen(true)}
                  >
                    Cobrança em conta de energia
                  </Button>
                </ThemeProvider>
                )}
                {project.status === 'Financiamento aprovado' && (
                  <Typography
                    fontSize="18px"
                    fontWeight="500"
                    textAlign="center"
                    sx={{
                      mb: 4,
                    }}
                  >
                    Preencha as opções de crédito abaixo!
                  </Typography>
                )}
                <ThemeProvider theme={primaryButton}>
                  <Button
                    onClick={handleClickOpenCall}
                    sx={{
                      maxWidth: '300px',
                    }}
                  >
                    Abrir chamado
                  </Button>
                </ThemeProvider>
                <ThemeProvider theme={primaryButton}>
                  <Button
                    onClick={() => setIsHistoryOpen(true)}
                    sx={{
                      maxWidth: '300px',
                    }}
                  >
                    Ver histórico
                  </Button>
                </ThemeProvider>
              </Stack>
            </Box>
          </Grid>
        </Grid>
      </Box>
      {(project.status !== 'Cadastro'
        && project.status !== 'Inativo'
        && project.status !== 'Análise - Soltech'
        && project.status !== 'Análise - Bancos'
        && project.status !== 'Financiamento recusado') && (
        <>
          <hr className={style.subDivisor} />
          {/* segundo grid-container */}
          <Box sx={cardStyle}>
            <Grid
              container
            >
              {/* primeiro grid-item 2 */}
              <Grid
                xs={12}
              >
                <Box
                  marginBottom="2rem"
                >
                  <SectionTitle title="Análise de Crédito" />
                  <Stack
                    direction={isXs ? 'column' : 'row'}
                    alignItems="center"
                    columnGap={2}
                  >
                    <ThemeProvider theme={secondaryButton}>
                      <Button
                        sx={{
                          maxWidth: '200px',
                        }}
                        onClick={handleClearTempCredit}
                      >
                        Limpar entradas
                      </Button>
                      <Button
                        sx={{
                          maxWidth: '200px',
                        }}
                        onClick={() => (tempCreditOptions.length > 0 ? setIsSendCreditOpen(true) : toast.warning('Sem opções de crédito para enviar.'))}
                      >
                        Enviar cotações
                      </Button>
                    </ThemeProvider>
                  </Stack>
                  <Stack>
                    <hr className={style.subDivisor} />
                    {tempCreditOptions.length > 0 && (tempCreditOptions.map((tempCredit, index) => (
                      <>
                        <CreditOptionEditor
                          creditOption={tempCredit}
                          allowRemove
                          removeCredit={handleRemoveTempCredit}
                          allowEdit
                          saveCredit={handleEditTempCredit}
                          storedIndex={index}
                        />
                        <hr className={style.subDivisor} />
                      </>
                    )))}
                    {tempCreditOptions.length === 0 && (
                      <Stack
                        alignItems="center"
                        justifyContent="center"
                      >
                        <Typography
                          fontSize="18px"
                          fontWeight="500"
                          textAlign="center"
                        >
                          Sem novos créditos!
                        </Typography>
                      </Stack>
                    )}
                  </Stack>
                  <hr className={style.subDivisor} />
                  <Stack
                    direction={isXs ? 'column' : 'row'}
                    justifyContent="space-between"
                    alignItems="center"
                    columnGap={2}
                  >
                    <Autocomplete
                      sx={{ flexGrow: 1 }}
                      fullWidth
                      disableClearable
                      options={banks}
                      getOptionLabel={(option: Bank) => option.name}
                      value={bank}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          label="Banco"
                          error={error.banco}
                          helperText={error.banco ? 'Obrigatório' : ' '}
                        />
                      )}
                      onChange={handleChangeCreditBank}
                    />
                    <TextField
                      fullWidth
                      error={error.parcelas}
                      helperText={error.parcelas ? 'Obrigatório' : ' '}
                      label="Quantidade de parcelas"
                      variant="outlined"
                      value={creditOption.parcelas}
                      onChange={handleChangeTempCredit('parcelas')}
                    />
                    <TextField
                      fullWidth
                      error={error.valorParcela}
                      helperText={error.valorParcela ? 'Obrigatório' : ' '}
                      label="Valor da parcela"
                      variant="outlined"
                      value={creditOption.valorParcela}
                      onChange={handleChangeTempCredit('valorParcela')}
                    />
                    <TextField
                      fullWidth
                      error={error.taxa}
                      helperText={error.taxa ? 'Valor inválido' : ' '}
                      label="Taxa(%)"
                      variant="outlined"
                      value={creditOption.taxa}
                      onChange={handleChangeTempCredit('taxa')}
                    />
                    <IconButton
                      component="label"
                      sx={{
                        color: theme.palette.primary.main,
                        border: `${theme.palette.primary.main} solid 1px`,
                        height: 'fit-content',
                        borderRadius: 1,
                      }}
                    >
                      <UploadFileIcon
                        color="inherit"
                      />
                      <input
                        type="file"
                        accept=".pdf"
                        hidden
                        onChange={handleChangeTempCredit('bancoDoc')}
                      />
                    </IconButton>
                    <ThemeProvider theme={primaryButton}>
                      <Button
                        sx={{
                          width: 'fit-content',
                          padding: '0.25rem 3rem',
                          marginBottom: 0,
                          marginTop: { xs: '15px', md: '0' },
                        }}
                        onClick={handleIncludeTempCredit}
                      >
                        Incluir
                      </Button>
                    </ThemeProvider>
                  </Stack>
                  <Stack>
                    <Typography
                      fontSize="18px"
                      fontWeight="500"
                      sx={{
                        mt: 2,
                      }}
                    >
                      Arquivo escolhido:
                      {' '}
                      {handleFileName(creditOption.bancoDoc)}
                    </Typography>
                  </Stack>
                </Box>
              </Grid>
            </Grid>
          </Box>
        </>
      )}
      {project.status !== 'Cadastro'
        && project.status !== 'Inativo'
        && project.status !== 'Análise - Soltech'
        && project.status !== 'Análise - Bancos'
        && project.status !== 'Financiamento recusado' && (
        <>
          <hr className={style.subDivisor} />
          {/* segundo grid-container */}
          <Box sx={cardStyle}>
            <Grid
              container
            >
              {/* segundo grid-item 2 */}

              <Grid
                xs={12}
              >
                <Box
                  marginBottom="2rem"
                >
                  <SectionTitle title="Cotação Escolhida" />
                  {(creditOptions.length > 0) && (creditOptions.map((creditOptionItem) => (
                    project.chosen_analysisId === creditOptionItem.id && (
                    <CreditOptionEditor
                      creditOption={creditOptionItem}
                    />
                    ))))}
                  {(project.chosen_analysisId === 0 || !project.chosen_analysisId) && (
                    <Typography
                      fontSize="18px"
                      fontWeight="500"
                      textAlign="center"
                    >
                      Sem análises enviadas!
                    </Typography>
                  )}
                </Box>
              </Grid>
            </Grid>
          </Box>
        </>
      )}
      {(project.status !== 'Cadastro'
        && project.status !== 'Inativo'
        && project.status !== 'Análise - Soltech'
        && project.status !== 'Análise - Bancos'
        && project.status !== 'Financiamento recusado') && (
        <>
          <hr className={style.subDivisor} />
          {/* segundo grid-container */}
          <Box sx={cardStyle}>
            <Grid
              container
            >
              {/* terceiro grid-item 2 */}
              <Grid
                xs={12}
              >
                <Box
                  marginBottom="2rem"
                >
                  <SectionTitle title="Análise de crédito (Cotações enviadas para o vendedor)" />
                  {(creditOptions.length > 0) && (creditOptions.map((creditOptionItem) => (
                    <CreditOptionEditor
                      creditOption={creditOptionItem}
                      allowEdit
                      saveCredit={handleEditCredit}
                    />
                  )))}
                  {(creditOptions.length === 0) && (
                    <Typography
                      fontSize="18px"
                      fontWeight="500"
                      textAlign="center"
                    >
                      Sem análises enviadas!
                    </Typography>
                  )}
                </Box>
              </Grid>
            </Grid>
          </Box>
        </>
      )}
      <hr className={style.subDivisor} />
      <Box sx={cardStyle}>
        <Chat
          projectId={getProjectId()}
          user={user}
        />
      </Box>
      <DialogComponent isOpen={isOpen} labelText={loadingText} />
      <DialogHistoryComponent
        isHistoryOpen={isHistoryOpen}
        close={closeModal}
        projectsUpdates={projectsUpdates}
      />
      <CobrancaEmContaDialog
        isModalOpen={isCobrancaOpen}
        localClose={closeModal}
        projectId={getProjectId()}
      />
      <DialogBodyComponent
        title="Enviar à financeira"
        helperText="Altere o status do projeto"
        cancelButtonText="Cancelar"
        confirmButtonText="Confirmar"
        isOpen={isSendFinanceOpen}
        close={closeModal}
        confirm={() => confirmDialog('sendFinance')}
      >
        <Box
          paddingX="2rem"
          paddingY="2rem"
        >
          <Typography
            fontSize="18px"
            fontWeight="500"
            textAlign="center"
          >
            Deseja confirmar que o projeto foi enviado para a financeira?
          </Typography>
        </Box>
      </DialogBodyComponent>
      <DialogBodyComponent
        title="Recusar financiamento"
        helperText="Altere o status do projeto"
        cancelButtonText="Cancelar"
        confirmButtonText="Confirmar"
        isOpen={isRefuseFinanceOpen}
        close={closeModal}
        confirm={() => confirmDialog('recuseFinance')}
      >
        <Box
          paddingX="2rem"
          paddingY="2rem"
        >
          <Typography
            fontSize="18px"
            fontWeight="500"
            textAlign="center"
          >
            Deseja informar que o projeto teve seu financiamento recusado?
          </Typography>
        </Box>
      </DialogBodyComponent>
      <DialogBodyComponent
        title="Aprovar financiamento"
        helperText="Altere o status do projeto"
        cancelButtonText="Cancelar"
        confirmButtonText="Confirmar"
        isOpen={isApproveFinanceOpen}
        close={closeModal}
        confirm={() => confirmDialog('approveFinance')}
      >
        <Box
          paddingX="2rem"
          paddingY="2rem"
        >
          <Typography
            fontSize="18px"
            fontWeight="500"
            textAlign="center"
          >
            Deseja informar que o projeto teve seu financiamento aprovado?
          </Typography>
        </Box>
      </DialogBodyComponent>
      <DialogBodyComponent
        title="Aprovar a documentação do cliente"
        helperText="Altere o status do projeto"
        cancelButtonText="Cancelar"
        confirmButtonText="Confirmar"
        isOpen={isApproveDocsOpen}
        close={closeModal}
        confirm={() => confirmDialog('approveDocs')}
      >
        <Box
          paddingX="2rem"
          paddingY="2rem"
        >
          <Typography
            fontSize="18px"
            fontWeight="500"
            textAlign="center"
          >
            Deseja informar que o projeto teve os documentos aprovados?
          </Typography>
        </Box>
      </DialogBodyComponent>
      <DialogBodyComponent
        title="Recusar documentos"
        helperText="Altere o status do projeto"
        cancelButtonText="Cancelar"
        confirmButtonText="Confirmar"
        isOpen={isRejectDocsOpen}
        close={closeModal}
        confirm={() => confirmDialog('rejectDocs')}
      >
        <Box
          paddingX="2rem"
          paddingY="2rem"
        >
          <Typography
            fontSize="18px"
            fontWeight="500"
            textAlign="center"
          >
            Deseja informar que o projeto teve os documentos recusados?
          </Typography>
        </Box>
      </DialogBodyComponent>
      <DialogBodyComponent
        title="Informar contratação"
        helperText="Altere o status do projeto"
        cancelButtonText="Cancelar"
        confirmButtonText="Confirmar"
        isOpen={isApproveContractOpen}
        close={closeModal}
        confirm={() => confirmDialog('approveContract')}
      >
        <Box
          paddingX="2rem"
          paddingY="2rem"
        >
          <Typography
            fontSize="18px"
            fontWeight="500"
            textAlign="center"
          >
            Deseja informar que o projeto foi contratado com sucesso?
          </Typography>
        </Box>
      </DialogBodyComponent>
      <DialogBodyComponent
        title="Adicionar as todas opções de crédito"
        helperText="Altere o status do projeto"
        cancelButtonText="Cancelar"
        confirmButtonText="Confirmar"
        isOpen={isSendCreditOpen}
        close={closeModal}
        confirm={() => confirmDialog('sendCredit')}
      >
        <Box
          paddingX="2rem"
          paddingY="2rem"
        >
          <Typography
            fontSize="18px"
            fontWeight="500"
            textAlign="center"
          >
            Essa operação não poderá ser desfeita. As opções cadastradas
            estarão disponíveis para contratação por parte do cliente.
          </Typography>
        </Box>
      </DialogBodyComponent>
    </>
  );
}
