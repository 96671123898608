import React, {
  ChangeEvent, useEffect, useState,
} from 'react';
import Grid from '@mui/material/Unstable_Grid2';

import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import { useHistory } from 'react-router-dom';
import { TextField } from '@mui/material';
import { parseCookies } from 'nookies';
import Card from '../elements/Card';
import { SectionTitle } from '../elements/SectionTitle';
import Button from '../elements/Button';
import { Bank } from '../../contexts/@types';
import { defaultBank } from '../../contexts/@defaultValues';
import { DialogComponent } from '../DialogComponent';
import { api } from '../../services/api';

interface BankFormProps {
  bankInfo?: Bank
}

const BankForm = (props: BankFormProps) => {
  const { bankInfo }: BankFormProps = props;
  const [newBank, setNewBank] = useState<Bank>(bankInfo || defaultBank);
  const [fieldsError, setFieldsError] = useState({
    name: false,
    email: false,
    code: false,
  });
  const emailRegex = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

  const [loading, setLoading] = useState(false);
  const [loadingText, setLoadingText] = useState('');

  const { 'nextauth.tag': tag } = parseCookies();
  const history = useHistory();

  useEffect(() => {
    if (bankInfo) setNewBank(bankInfo);
  }, [bankInfo]);

  const handleChangeBank = (key: keyof Bank) => (e: ChangeEvent<HTMLInputElement>) => {
    const { value } = e.target;
    setNewBank({
      ...newBank,
      [key]: value,
    });
  };

  const validateBankForm = async () => {
    const errors = {
      name: !newBank.name,
      email: !newBank.email || !emailRegex.test(newBank.email),
      code: !newBank.cod,
    };

    setFieldsError(errors);

    const fieldNames = {
      name: 'Nome do banco',
      email: 'E-mail do banco',
      code: 'Código do banco',
    };

    const emptyFields = Object.keys(errors)
      .filter((key) => errors[key as keyof typeof errors])
      .map((key) => fieldNames[key as keyof typeof fieldNames])
      .join(';; ');

    if (emptyFields.length > 0) {
      toast.error(
        <>
          O(s) campo(s) a seguir devem ser preenchidos:
          {emptyFields.split('; ').map((e) => (
            <React.Fragment key={e}>
              <br />
              {e}
            </React.Fragment>
          ))}
        </>,
      );

      return false;
    }
    return true;
  };

  const submitBank = async () => {
    setLoadingText(bankInfo ? 'Atualizando banco...' : 'Cadastrando banco...');

    const request = {
      headers: { 'Content-Type': 'application/json' },
      data: {
        name: newBank.name,
        email: newBank.email,
        cod: newBank.cod,
        operadorId: tag,
        bancoId: newBank.id,
      },
    };

    const route = bankInfo ? '/bank/put' : '/bank/insert';

    const response = await api.post(route, request);

    switch (response.data) {
      case 'EMAIL_REGISTERED_ALREADY':
        setLoading(false);
        toast.error(
          'E-mail já cadastrado no sistema. Não foi possível inserir o banco.',
        );
        break;

      case 'NAME_REGISTERED_ALREADY':
        setLoading(false);
        toast.error(
          'Um banco com este nome já foi cadastrado no sistema. Não foi possível inserir o banco.',
        );
        break;
      case 'BANK_CREATED':
        setLoading(false);
        toast.success('Banco inserido com sucesso!');
        history.push('/listaBancos');
        break;
      case 'BANK_UPDATE':
        setLoading(false);
        toast.success('Banco atualizado com sucesso!');
        history.push('/listaBancos');
        break;
      default:
        setLoading(false);
        toast.error(`Falha ao inserir banco no sistema.${response.data}`);
        break;
    }
  };

  const handleSubmit = async () => {
    setLoading(true);
    setLoadingText('Validando campos...');
    const validation = await validateBankForm();
    if (!validation) {
      setLoading(false);
      return;
    }
    await submitBank().then(() => { setLoading(false); });
  };

  const handleCancel = () => {
    history.push('/listaBancos');
  };

  return (
    <>
      <DialogComponent isOpen={loading} labelText={loadingText} />
      <Card>
        {bankInfo ? <SectionTitle title="Editar banco" /> : (
          <SectionTitle
            title="Cadastrar banco"
            helperText="Preencha o formulário abaixo para cadastrar um novo banco no sistema.
           Os bancos são utilizados na análise de crédito."
          />
        )}
        <Grid container spacing={2}>
          <Grid xs={12} lg={4}>
            <TextField
              error={fieldsError.name}
              label="Nome do banco"
              required
              fullWidth
              margin="normal"
              value={newBank.name}
              onChange={handleChangeBank('name')}
            />
          </Grid>
          <Grid xs={12} lg={4}>
            <TextField
              error={fieldsError.email}
              label="E-mail do banco"
              required
              fullWidth
              margin="normal"
              value={newBank.email}
              onChange={handleChangeBank('email')}
            />
          </Grid>
          <Grid xs={12} lg={4}>
            <TextField
              error={fieldsError.code}
              label="Código do banco"
              required
              fullWidth
              margin="normal"
              value={newBank.cod}
              onChange={handleChangeBank('cod')}
            />
          </Grid>
        </Grid>
        <Grid
          xs={12}
          display="flex"
          gap="1rem"
          justifyContent="flex-end"
          mt="1rem"
        >
          <Button
            variant="outlined"
            sx={{ width: '10rem' }}
            onClick={handleCancel}
          >
            Cancelar
          </Button>
          <Button
            variant="contained"
            sx={{ width: '10rem' }}
            onClick={handleSubmit}
          >
            Salvar
          </Button>
        </Grid>
      </Card>
    </>
  );
};
export default BankForm;
