import * as React from 'react';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import {
  Box, Typography, useMediaQuery, useTheme,
} from '@mui/material';
import background from '../../assets/images/background.jpeg';
import logo from '../../assets/images/bold_white_logo.png';

interface CardMediaProps {
  children : React.ReactNode,
  smallCard?: boolean,
}

export default function MediaCard(props: CardMediaProps) {
  const {
    children,
    smallCard = false,
  } = props;
  const date = new Date();
  const currentYear = date.getFullYear();
  const theme = useTheme();
  const isMobileScreen = useMediaQuery(theme.breakpoints.down('sm'));
  return (
    <>
      <Typography
        sx={{
          position: 'absolute',
          width: { xs: '100%', md: '50vw' },
          textAlign: 'center',
          marginTop: '50vh',
          color: '#fff',
          fontFamily: 'Poppins',
          fontSize: { xs: '12px', md: '16px' },
          bottom: { md: 0 },
        }}
      >
        BoldEnergy©
        {currentYear}
        . Todos os direitos reservados.
      </Typography>
      <Card
        sx={{
          display: { md: 'flex' },
          flexDirection: { md: 'row' },
          width: '100vw',
          height: '100vh',
        }}
      >
        <CardMedia
          sx={{
            height: smallCard ? { xs: '55vh', md: '100vh' } : { xs: '30vh', md: '100vh' },
            width: { md: '55vw' },
            borderTopRightRadius: { md: '1rem' },
            borderBottomRightRadius: { md: '1rem' },
            backgroundImage: `url(${background})`,
            backgroundSize: 'cover', // Certifique-se de que a imagem cubra todo o espaço
            backgroundPosition: 'center 90%', // Ajuste a posição do fundo
          }}
          title="backgroundWorker"
        />
        <Box sx={{
          display: 'flex',
          position: 'absolute',
          width: { xs: '100vw', md: '55vw' },
          justifyContent: 'center',
          top: 0,
          height: smallCard ? { xs: '55vh', md: '80vh' } : { xs: '15vh', md: '80vh' },
          alignItems: 'center',
        }}
        >
          <img
            src={logo}
            alt="logo"
            style={{
              width: isMobileScreen ? '60vw' : '20vw',
            }}
          />
        </Box>

        <CardContent
          sx={{
            backgroundColor: '#fff',
            borderTopLeftRadius: '1rem',
            borderTopRightRadius: '1rem',
            display: 'flex',
            flexDirection: 'column',
            marginTop: { xs: '-1rem' },
            alignSelf: { md: 'center' },
            justifySelf: { md: 'center' },
            margin: { md: '1rem' },
            width: { md: '45vw' },
            height: { xs: '45vh', md: 'auto' },
          }}
        >
          {children}
        </CardContent>
      </Card>
    </>
  );
}
