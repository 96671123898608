import { toast, ToastContainer } from 'react-toastify';
import React, {
  ChangeEvent, SyntheticEvent, useEffect, useState,
} from 'react';
import { useHistory } from 'react-router-dom';
import {
  Box, Button, IconButton, TextField, ThemeProvider, Typography, useTheme,
} from '@mui/material';
import Grid from '@mui/material/Unstable_Grid2';
import UploadFileIcon from '@mui/icons-material/UploadFile';
import style from './cobrancaemconta.module.scss';
import { DialogComponent } from '../../components/DialogComponent';
import {
  applyCEPMask,
  applyCPFMask, applyCurrencyMask, applyDateMask, applyTelefoneMask,
  onlyLetters, removeNonNumeric_2, searchCEP,
  timeStampToDateOnly,
} from '../../utils/util';
import { api } from '../../services/api';
import { useButtonThemes } from '../../themes/buttonTheme';
import { Address, City, Customer } from '../../contexts/@types';
import { OrderFileTable } from '../../components/OrderFileTable';
import { checkAllRules, rules } from '../../utils/validationRules';
import BaseDatePicker from '../../components/BaseDatePicker';
import { ConfirmCobranca } from '../../components/Dialogs/ConfirmCobranca';
import { SectionTitle } from '../../components/elements/SectionTitle';

interface GetState {
  id: number;
  empresa: string;
}
interface Anexos {
  tipoAnexo: string,
  conteudoBase64: string,
  nomeArquivo: string,
  extensao: string
}
interface OrderSale {
  Nome: string,
  Sobrenome: string,
  dtNascimento: any,
  CPF: string,
  Telefone: string,
  Email: string,
  Endereco: string,
  EnderecoNumero: string,
  Bairro: string,
  Cidade: string,
  CEP: string,
  empresa: string,
  numeroCliente: string,
  numParcelas: number,
  valorParcela: string,
  codProduto: string,
  dataOcorrencia: any,
  codCanalVenda: string,
  numeroClienteEmpPar: string,
  anexos: Anexos[]
}
interface SingleError {
  value: boolean,
  label: string,
}
interface OrderSaleFieldError {
  Nome: SingleError
  Sobrenome: SingleError
  dtNascimento: SingleError
  CPF: SingleError
  Telefone: SingleError
  Email: SingleError
  Endereco: SingleError
  EnderecoNumero: SingleError
  Bairro: SingleError
  Cidade: SingleError
  CEP: SingleError
  empresa: SingleError
  numeroCliente: SingleError
  numParcelas: SingleError
  valorParcela: SingleError
  codProduto: SingleError
  dataOcorrencia?: SingleError
  codCanalVenda: SingleError
  numeroClienteEmpPar: SingleError
  anexo: SingleError
}

export function CobrancaEmConta() {
  const { primaryButton, secondaryButton } = useButtonThemes();
  const theme = useTheme();
  const history = useHistory();
  const [isOpen, setIsOpen] = useState(false);
  const [isCepOpen, setIsCepOpen] = useState(false);
  const [loadingText, setLoadingText] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [confirm, setConfirm] = useState(false);
  const [changeCep, setChangeCep] = useState<boolean>(false);

  const [orderSale, setOrderSale] = useState<OrderSale>({
    Bairro: '',
    CEP: '',
    CPF: '',
    Cidade: '',
    Email: '',
    Endereco: '',
    EnderecoNumero: '',
    Nome: '',
    Sobrenome: '',
    Telefone: '',
    anexos: [],
    codCanalVenda: '',
    codProduto: '',
    dataOcorrencia: '',
    dtNascimento: '',
    empresa: '',
    numParcelas: 0,
    numeroCliente: '',
    numeroClienteEmpPar: '',
    valorParcela: '',
  });
  const [anexos, setAnexos] = useState<Anexos[]>([]);
  const defaultFieldError = {
    Nome: {
      value: false,
      label: 'Nome',
    },
    Sobrenome: {
      value: false,
      label: 'Sobrenome',
    },
    dtNascimento: {
      value: false,
      label: 'Data de nascimento',
    },
    CPF: {
      value: false,
      label: 'CPF',
    },
    Telefone: {
      value: false,
      label: 'Telefone',
    },
    Email: {
      value: false,
      label: 'E-mail do cliente',
    },
    Endereco: {
      value: false,
      label: 'Endereço',
    },
    EnderecoNumero: {
      value: false,
      label: 'N°',
    },
    Bairro: {
      value: false,
      label: 'Bairro',
    },
    Cidade: {
      value: false,
      label: 'Cidade',
    },
    CEP: {
      value: false,
      label: 'CEP',
    },
    empresa: {
      value: false,
      label: 'Empresa',
    },
    numeroCliente: {
      value: false,
      label: 'N° do cliente',
    },
    numParcelas: {
      value: false,
      label: 'N° de parcelas',
    },
    valorParcela: {
      value: false,
      label: 'Valor da parcela',
    },
    codProduto: {
      value: false,
      label: 'Código do produto',
    },
    dataOcorrencia: {
      value: false,
      label: 'Data da ocorrência',
    },
    codCanalVenda: {
      value: false,
      label: 'Código do canal de vendas',
    },
    numeroClienteEmpPar: {
      value: false,
      label: 'Nº do cliente da empresa parceira',
    },
    anexo: {
      value: false,
      label: 'Anexo',
    },
  };
  const [fieldsError, setFieldsError] = useState<OrderSaleFieldError>(defaultFieldError);

  const [state, setState] = useState<GetState | null>(null);
  const sstate: GetState = history.location.state as GetState;

  const cardStyle = {
    backgroundColor: '#fff',
    boxShadow: '0px 2px 2px 0px #0000001A',
    borderRadius: '8px',
    boxSizing: 'border-box',
    padding: '1rem',
    minHeight: '100%',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
  };

  const whichCompany = (companyCode: string) => {
    let chosen = '';
    switch (companyCode) {
      case 'EXBRA_SA_Fortaleza_CE':
        chosen = '2003';
        break;
      case 'EXBRA_SA_Paulo_SP':
        chosen = 'MT10';
        break;
      case 'EXBRA_SA_Rio_de_Janeiro_RJ':
        chosen = '2005';
        break;
      default:
    }

    return chosen;
  };

  useEffect(() => {
    setLoadingText('carregando dados...');
    setIsOpen(true);

    const getCostumer = async () => {
      api.post('project/costumer/get', {
        projectId: sstate.id,
      }).then(((resolved) => {
        const { data } = resolved;

        if (data === 'COULD_NOT_FIND_COSTUMER') {
          setIsLoading(true);
        } else {
          const customer: Customer = resolved.data;
          const endereco: Address = resolved.data.enderecoInstalacao;
          const cidade: City = resolved.data.enderecoInstalacao.city;

          const [primeiroNome, ...second] = customer.nome.split(' ');
          const sobrenome = second.join(' ');
          setOrderSale({
            ...orderSale,
            Nome: primeiroNome || customer.nome,
            Sobrenome: sobrenome || customer.nome,
            dtNascimento: timeStampToDateOnly(customer.dataNascimento.toString()),
            CPF: customer.cpf,
            Email: customer.email,
            Telefone: customer.telefone,
            empresa: whichCompany(sstate.empresa),
            CEP: endereco.zip,
            Endereco: endereco.street1,
            EnderecoNumero: endereco.number.toString(),
            Cidade: cidade.name,
          });
          setChangeCep(!changeCep);
        }
        setState(state);
        setIsOpen(false);
        return state;
      }));
    };
    getCostumer();
    // If Use-effect is changed run without the disabled eslint to check
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    const updateCep = async () => {
      if (orderSale.CEP) {
        setIsCepOpen(true);
        setLoadingText('Procurando CEP');
        const value = await searchCEP(orderSale.CEP);
        if (value.data.erro) {
          toast.warning('Cep Não encontrado!');
          setOrderSale({
            ...orderSale,
            Endereco: '',
            Bairro: '',
            Cidade: '',
          });
        } else {
          setOrderSale({
            ...orderSale,
            Endereco: value.data.rua,
            Bairro: value.data.bairro,
            Cidade: value.data.cidade,
          });
        }
      }
      setIsCepOpen(false);
    };
    updateCep();
    // If Use-effect is changed run without the disabled eslint to check
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [changeCep]);

  const handleVoltarClick = () => {
    history.goBack();
  };

  const handleOrderChange = async (e: ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;

    let newValue = '';
    let nonNumeric = '';
    switch (name) {
      case 'Nome':
      case 'Sobrenome':
        newValue = onlyLetters(e);
        break;
      case 'CPF':
        newValue = applyCPFMask(e);
        break;
      case 'dtNascimento':
      case 'dataOcorrencia':
        newValue = applyDateMask(e);
        break;
      case 'Telefone':
        newValue = applyTelefoneMask(value);
        break;
      case 'CEP':
        newValue = applyCEPMask(e);
        if (newValue.length === 9) {
          setChangeCep(!changeCep);
        }
        break;
      case 'EnderecoNumero':
      case 'numeroCliente':
      case 'numeroClienteEmpPar':
        newValue = removeNonNumeric_2(value);
        break;
      case 'valorParcela':
        nonNumeric = removeNonNumeric_2(value);
        newValue = applyCurrencyMask(nonNumeric);
        break;
      case 'numParcelas':
        newValue = removeNonNumeric_2(value);
        break;
      default:
        newValue = value;
    }

    setOrderSale({
      ...orderSale,
      [name]: newValue,
    });
  };

  const handleChangeDateNasc = (date: string) => {
    const fakeEvent = {
      target: {
        name: 'dtNascimento',
        value: date,
      },
    } as React.ChangeEvent<HTMLInputElement>;
    handleOrderChange(fakeEvent);
  };

  const handleChangeDateOcc = (date: string) => {
    const fakeEvent = {
      target: {
        name: 'dataOcorrencia',
        value: date,
      },
    } as React.ChangeEvent<HTMLInputElement>;
    handleOrderChange(fakeEvent);
  };

  const fieldList = (list: string[]) => (
    <Box>
      <Typography
        fontFamily="Poppins"
        fontSize="12px"
      >
        Preencha o(s) seguinte(s) campo(s) corretamente:
      </Typography>
      {
        list.map((item) => (
          <>
            {item}
            <br />
          </>
        ))
      }
    </Box>
  );

  const validateOrderForm = () => {
    const errors = JSON.parse(JSON.stringify(defaultFieldError));
    errors.Nome.value = rules.required(orderSale.Nome);
    errors.Sobrenome.value = rules.required(orderSale.Sobrenome);
    errors.dtNascimento.value = checkAllRules(
      [rules.required(orderSale.dtNascimento), rules.date(orderSale.dtNascimento)],
    );
    errors.Telefone.value = checkAllRules(
      [rules.required(orderSale.Telefone), rules.phone(orderSale.Telefone)],
    );
    errors.Email.value = rules.optionalEmail(orderSale.Email);
    errors.Endereco.value = rules.required(orderSale.Endereco);
    errors.EnderecoNumero.value = rules.required(orderSale.EnderecoNumero);
    errors.Bairro.value = rules.required(orderSale.Bairro);
    errors.Cidade.value = rules.required(orderSale.Cidade);
    errors.CEP.value = checkAllRules([rules.required(orderSale.CEP), rules.cep(orderSale.CEP)]);
    errors.empresa.value = rules.required(orderSale.empresa);
    errors.numeroCliente.value = rules.required(orderSale.numeroCliente);
    errors.CPF.value = checkAllRules([rules.required(orderSale.CPF), rules.cpf(orderSale.CPF)]);
    errors.numParcelas.value = rules.required(orderSale.numParcelas);
    errors.valorParcela.value = rules.required(orderSale.valorParcela.toString()
      .replace('R$ ', ''));
    errors.codProduto.value = rules.required(orderSale.codProduto);
    // errors.dataOcorrencia.value = rules.required(orderSale.dataOcorrencia);
    // errors.codCanalVenda.value = rules.required(orderSale.codCanalVenda);
    // errors.numeroClienteEmpPar.value = rules.required(orderSale.numeroClienteEmpPar);
    errors.anexo.value = rules.required(orderSale.anexos);

    let newFieldsError = fieldsError;
    newFieldsError = { ...errors };

    setFieldsError(newFieldsError);

    const erroArray = Object.values(newFieldsError);
    const fieldNames: string[] = [];
    if (erroArray.some((item) => item.value)) {
      for (let i = 0; i < erroArray.length; i++) {
        if (erroArray[i].value) fieldNames.push(erroArray[i].label);
      }

      toast.warning(fieldList(fieldNames));
      return false;
    }

    return true;
  };

  const formatDateRequest = (date: string) => {
    const [a, b, c] = date.split('/');
    return `${c}-${b}-${a}`;
  };

  const openDialogSubmit = async (event: SyntheticEvent) => {
    event.preventDefault();

    if (validateOrderForm()) setConfirm(true);
  };

  const handleSubmit = async () => {
    const requestData: OrderSale = JSON.parse(JSON.stringify(orderSale));

    // criando requisição para a API da ENEL
    requestData.CEP = requestData.CEP?.replace('-', '');
    requestData.CPF = requestData.CPF?.replace(/(\.)|(-)/g, '');
    requestData.Telefone = requestData.Telefone?.replace(/(-)|(\()|(\))|(\s)/g, '');
    requestData.dtNascimento = formatDateRequest(requestData.dtNascimento);
    requestData.dataOcorrencia = formatDateRequest(requestData.dataOcorrencia);

    const valorParcelaString = requestData.valorParcela?.toString()
      .replace('R$ ', '')
      .replace(/\./g, '')
      .replace(/,/g, '.');
    requestData.valorParcela = parseFloat(valorParcelaString).toFixed(2);

    requestData.numParcelas = requestData.numParcelas
      ? parseInt(requestData.numParcelas.toString(), 10)
      : 0;

    const response = await api.post('/order', { data: requestData });
    const { data } = response;

    if (data) {
      if (data.result && data.result.error_code === 0) {
        toast.success('Cobrança criada com sucesso!');
      } else {
        toast.error('Não foi possível concluir a operação!');
      }
    }
  };

  const convertFileToBase64 = (file: File) => new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result);
    // reader.onerror = error => reject(error);
  });

  const handleAdicionarArquivo = async (e: ChangeEvent<HTMLInputElement>) => {
    e.preventDefault();
    const fileList = e.target.files;
    if (fileList) {
      anexos.push({
        nomeArquivo: fileList[0].name,
        extensao: fileList[0].name.split('.').pop(),
        tipoAnexo: 'Documento',
        conteudoBase64: await convertFileToBase64(fileList[0]),
      } as Anexos);

      setOrderSale({ ...orderSale, anexos });
    }
  };

  const handleTipoChange = (event: any, index: number) => {
    // Copy the state to temp array
    const newArr = [...anexos];
    // Update the value on the temp array
    newArr[index].tipoAnexo = event.target.value;
    // Update state using the updated temp array
    setAnexos(newArr);
  };

  const handleDeletarAnexo = (event: any, index: number) => {
    const newArr = [...anexos];
    newArr.splice(index, 1);
    setAnexos(newArr);
    setOrderSale({ ...orderSale, anexos: newArr });
  };

  return (
    <>
      <Box sx={cardStyle}>
        <Grid
          container
          spacing={2}
        >
          <Grid
            xs={12}
          >
            <SectionTitle title={`Editar cobrança em conta do projeto #${sstate.id}`} />
          </Grid>
          <Grid
            xs={12}
            sm={6}
            md={4}
          >
            <TextField
              fullWidth
              required
              label="Nome"
              variant="outlined"
              name="Nome"
              value={orderSale.Nome}
              InputLabelProps={{
                shrink: !!orderSale.Sobrenome,
              }}
              error={fieldsError.Nome.value}
              onChange={handleOrderChange}
            />
          </Grid>
          <Grid
            xs={12}
            sm={6}
            md={4}
          >
            <TextField
              fullWidth
              required
              label="Sobrenome"
              variant="outlined"
              value={orderSale.Sobrenome}
              name="Sobrenome"
              InputLabelProps={{
                shrink: !!orderSale.Sobrenome,
              }}
              error={fieldsError.Sobrenome.value}
              onChange={handleOrderChange}
            />
          </Grid>
          <Grid
            xs={12}
            sm={6}
            md={4}
          >
            <BaseDatePicker
              value={orderSale.dtNascimento}
              label="Data do depósito"
              handleChange={handleChangeDateNasc}
              customError={fieldsError.dtNascimento.value}
            />
          </Grid>
          <Grid
            xs={12}
            sm={6}
            md={4}
          >
            <TextField
              fullWidth
              required
              label="CPF"
              variant="outlined"
              value={orderSale.CPF}
              name="CPF"
              InputLabelProps={{
                shrink: !!orderSale.CPF,
              }}
              error={fieldsError.CPF.value}
              onChange={handleOrderChange}
            />
          </Grid>
          <Grid
            xs={12}
            sm={6}
            md={4}
          >
            <TextField
              fullWidth
              required
              label="Telefone"
              variant="outlined"
              value={orderSale.Telefone}
              name="Telefone"
              InputLabelProps={{
                shrink: !!orderSale.Telefone,
              }}
              error={fieldsError.Telefone.value}
              onChange={handleOrderChange}
            />
          </Grid>
          <Grid
            xs={12}
            sm={6}
            md={4}
          >
            <TextField
              fullWidth
              label="E-mail do cliente"
              variant="outlined"
              value={orderSale.Email}
              name="Email"
              InputLabelProps={{
                shrink: !!orderSale.Email,
              }}
              error={fieldsError.Email.value}
              onChange={handleOrderChange}
            />
          </Grid>
        </Grid>
        <Grid
          container
          spacing={2}
        >
          <Grid
            xs={12}
          >
            <SectionTitle title="Endereço" />
          </Grid>
          <Grid
            xs={12}
            md={2}
          >
            <TextField
              fullWidth
              required
              label="CEP"
              variant="outlined"
              value={orderSale.CEP}
              name="CEP"
              InputLabelProps={{
                shrink: !!orderSale.CEP,
              }}
              error={fieldsError.CEP.value}
              onChange={handleOrderChange}
            />
          </Grid>
          <Grid
            xs={12}
            md={3}
          >
            <TextField
              fullWidth
              required
              label="Endereco"
              variant="outlined"
              value={orderSale.Endereco}
              name="Endereco"
              InputLabelProps={{
                shrink: !!orderSale.Endereco,
              }}
              error={fieldsError.Endereco.value}
              onChange={handleOrderChange}
            />
          </Grid>
          <Grid
            xs={12}
            md={3}
          >
            <TextField
              fullWidth
              required
              label="Bairro"
              variant="outlined"
              value={orderSale.Bairro}
              name="Bairro"
              InputLabelProps={{
                shrink: !!orderSale.Bairro,
              }}
              error={fieldsError.Bairro.value}
              onChange={handleOrderChange}
            />
          </Grid>
          <Grid
            xs={12}
            md={1}
          >
            <TextField
              fullWidth
              required
              label="N°"
              variant="outlined"
              value={orderSale.EnderecoNumero}
              name="EnderecoNumero"
              InputLabelProps={{
                shrink: !!orderSale.EnderecoNumero,
              }}
              error={fieldsError.EnderecoNumero.value}
              onChange={handleOrderChange}
            />
          </Grid>
          <Grid
            xs={12}
            md={3}
          >
            <TextField
              fullWidth
              required
              label="Cidade"
              variant="outlined"
              value={orderSale.Cidade}
              name="Cidade"
              InputLabelProps={{
                shrink: !!orderSale.Cidade,
              }}
              error={fieldsError.Cidade.value}
              onChange={handleOrderChange}
            />
          </Grid>
        </Grid>
        <Grid
          container
          spacing={2}
        >
          <Grid
            xs={12}
          >
            <SectionTitle title="Dados de empresa " />
          </Grid>
          <Grid
            xs={12}
            sm={6}
            md={4}
          >
            <TextField
              fullWidth
              required
              disabled
              label="Empresa"
              variant="outlined"
              name="empresa"
              value={orderSale.empresa}
              InputLabelProps={{
                shrink: !!orderSale.empresa,
              }}
              error={fieldsError.empresa.value}
              onChange={handleOrderChange}
            />
          </Grid>
          <Grid
            xs={12}
            sm={6}
            md={4}
          >
            <TextField
              fullWidth
              required
              label="N° do cliente"
              variant="outlined"
              name="numeroCliente"
              value={orderSale.numeroCliente}
              InputLabelProps={{
                shrink: !!orderSale.numeroCliente,
              }}
              error={fieldsError.numeroCliente.value}
              onChange={handleOrderChange}
            />
          </Grid>
          <Grid
            xs={12}
            sm={6}
            md={4}
          >
            <TextField
              fullWidth
              required
              label="N° de parcelas"
              variant="outlined"
              name="numParcelas"
              value={orderSale.numParcelas}
              InputLabelProps={{
                shrink: !!orderSale.numParcelas || orderSale.numParcelas === 0,
              }}
              error={fieldsError.numParcelas.value}
              onChange={handleOrderChange}
            />
          </Grid>
          <Grid
            xs={12}
            sm={6}
            md={4}
          >
            <TextField
              fullWidth
              required
              label="Valor da parcela"
              variant="outlined"
              name="valorParcela"
              value={orderSale.valorParcela}
              InputLabelProps={{
                shrink: !!orderSale.valorParcela,
              }}
              error={fieldsError.valorParcela.value}
              onChange={handleOrderChange}
            />
          </Grid>
          <Grid
            xs={12}
            sm={6}
            md={4}
          >
            <TextField
              fullWidth
              required
              label="Código do produto"
              variant="outlined"
              name="codProduto"
              value={orderSale.codProduto}
              InputLabelProps={{
                shrink: !!orderSale.codProduto,
              }}
              error={fieldsError.codProduto.value}
              onChange={handleOrderChange}
            />
          </Grid>
          <Grid
            xs={12}
            sm={6}
            md={4}
          >
            <BaseDatePicker
              value={orderSale.dataOcorrencia}
              label="Data da ocorrência"
              handleChange={handleChangeDateOcc}
            />
          </Grid>
          <Grid
            xs={12}
            sm={6}
          >
            <TextField
              fullWidth
              label="Código do canal de vendas"
              variant="outlined"
              name="codCanalVenda"
              value={orderSale.codCanalVenda}
              InputLabelProps={{
                shrink: !!orderSale.codCanalVenda,
              }}
              error={fieldsError.codCanalVenda.value}
              onChange={handleOrderChange}
            />
          </Grid>
          <Grid
            xs={12}
            sm={6}
          >
            <TextField
              fullWidth
              label="Nº do cliente da empresa parceira"
              variant="outlined"
              name="numeroClienteEmpPar"
              value={orderSale.numeroClienteEmpPar}
              InputLabelProps={{
                shrink: !!orderSale.numeroClienteEmpPar,
              }}
              error={fieldsError.numeroClienteEmpPar.value}
              onChange={handleOrderChange}
            />
          </Grid>
        </Grid>
        <Grid
          container
          spacing={2}
        >
          <Grid
            xs={12}
          >
            <SectionTitle title="Anexos" />
          </Grid>
          <Grid
            display="flex"
            alignItems="center"
            gap={1}
            xs={12}
          >
            <label className={style.inputLabel} htmlFor="input-anexo">
              <Typography>
                Selecione arquivos
              </Typography>
            </label>
            <IconButton
              id="inputfile"
              component="label"
              sx={{
                color: theme.palette.primary.main,
                border: `${theme.palette.primary.main} solid 1px`,
                height: 'fit-content',
                borderRadius: 1,
              }}
            >
              <UploadFileIcon
                color="inherit"
              />
              <input
                id="input-anexo"
                type="file"
                hidden
                onChange={handleAdicionarArquivo}
              />
            </IconButton>
          </Grid>
          <Grid
            xs={12}
          >
            <OrderFileTable
              anexosList={anexos}
              changeFile={handleTipoChange}
              deleteFile={handleDeletarAnexo}
            />
          </Grid>
        </Grid>
        <div>
          <hr className={style.divisor} />
          <Box
            display="flex"
            flexDirection="row"
            justifyContent="end"
          >
            <Box
              display="flex"
              flexDirection="row"
              justifyContent="end"
              gap="1rem"
              sx={{
                width: {
                  xs: '50vw',
                  md: '30vw',
                },
              }}
            >
              <ThemeProvider theme={secondaryButton}>
                <Button
                  onClick={handleVoltarClick}
                >
                  Voltar
                </Button>
              </ThemeProvider>
              <ThemeProvider theme={primaryButton}>
                <Button
                  onClick={openDialogSubmit}
                >
                  Confirmar
                </Button>
              </ThemeProvider>
            </Box>
          </Box>
        </div>
      </Box>
      <DialogComponent isOpen={isOpen} labelText={loadingText} />
      <DialogComponent isOpen={isCepOpen} labelText={loadingText} />
      <ConfirmCobranca
        isModalOpen={confirm}
        localClose={() => setConfirm(false)}
        localConfirm={() => handleSubmit()}
      />
    </>
  );
}
