import React, {
  useEffect, useState,
} from 'react';

import 'react-toastify/dist/ReactToastify.css';

import { useHistory } from 'react-router-dom';
import { AxiosResponse } from 'axios';
import { api } from '../../services/api';
import BankForm from '../../components/BankForm';
import { Bank } from '../../contexts/@types';
import { defaultBank } from '../../contexts/@defaultValues';

interface BancoData {
  id: number;
  name: string;
  email: string;
  active: string;
  cod: string;
}

export function EditarBanco() {
  const [bankInfo, setBankInfo] = useState<Bank>(defaultBank);

  const history = useHistory();

  const bankId: number = history.location.state as number;

  useEffect(() => {
    const getBank = async () => {
      const bancoId = bankId;
      const response: Promise<AxiosResponse<any, any>> = api.post(
        'bank/single/get',
        { bancoId },
      );
      response.then((resolved) => {
        const item: BancoData = resolved.data;
        setBankInfo({
          id: item.id,
          name: item.name,
          email: item.email,
          active: Boolean(item.active),
          cod: item.cod,
        });
        return item;
      });
    };
    getBank();
    // If Use-effect is changed run without the disabled eslint to check
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return <BankForm bankInfo={bankInfo} />;
}
