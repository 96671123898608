import {
  Box,
  Typography, useTheme,
} from '@mui/material';
import Grid from '@mui/material/Unstable_Grid2';
import React from 'react';
import { parseCookies } from 'nookies';
import { applyCurrencyMask, applyNoSymbolCurrencyMask } from '../../../utils/util';

export interface BottomCardsProps {
  totalProjects: number
  approvedFinancialProjectsMonth: number
  totalProjectsValue: number
  totalAprovedProjectsValue: number
  totalPower: number
  totalAprovePower: number
}

export const defaultBottomCardsProps = {
  totalProjects: 0,
  approvedFinancialProjectsMonth: 0,
  totalProjectsValue: 0,
  totalAprovedProjectsValue: 0,
  totalPower: 0,
  totalAprovePower: 0,
};

interface BottomChartsProps {
  bottomCardsData: BottomCardsProps,
}
function BottomCharts(props: BottomChartsProps) {
  const {
    bottomCardsData,
  }: BottomChartsProps = props;
  const theme = useTheme();
  const { 'nextauth.roles': cookieRoles } = parseCookies();

  const desktopResponsivity = (cookieRoles === 'Vendedor' && 6)
    || (cookieRoles === 'Integradora' && 3)
    || 2;

  const cardStyle = {
    display: 'flex',
    flexDirection: 'column',
    backgroundColor: '#fff',
    boxShadow: '0px 2px 2px 0px #0000001A',
    borderRadius: '8px',
    padding: { xs: '0.5rem', lg: '1rem' },
    textAlign: 'center',
    justifyContent: 'space-around',
    gap: { xs: '1rem' },
    minHeight: { xs: '5rem', md: cookieRoles === 'Vendedor' ? '6rem' : '7rem' },
  };

  return (
    <Grid
      container
      spacing={2}
      rowSpacing={2}
      sx={{ alignItems: 'flex-end' }}
    >
      <Grid xs={6} md={desktopResponsivity}>
        <Box sx={cardStyle}>
          <Typography sx={{
            fontFamily: 'Poppins',
            fontSize: { xs: '12px', md: '16px' },
            fontWeight: '700',
            maxWidth: { lg: '250px' },
            margin: '0 auto',
          }}
          >
            Quantidade de projetos
          </Typography>
          <Typography sx={{
            fontSize: { xs: '16px', md: '20px' },
            fontWeight: 'bolder',
            color: theme.palette.primary.main,
          }}
          >
            {bottomCardsData.totalProjects}
          </Typography>
        </Box>
      </Grid>
      <Grid xs={6} md={desktopResponsivity}>
        <Box sx={cardStyle}>
          <Typography sx={{
            fontFamily: 'Poppins',
            fontSize: { xs: '12px', md: '16px' },
            fontWeight: '700',
            maxWidth: { lg: '250px' },
            margin: '0 auto',
          }}
          >
            Financiamento aprovado
          </Typography>
          <Typography sx={{
            fontSize: { xs: '16px', md: '20px' },
            fontWeight: 'bolder',
            color: theme.palette.primary.main,
          }}
          >
            {`${bottomCardsData.approvedFinancialProjectsMonth}%`}
          </Typography>
        </Box>
      </Grid>
      <Grid xs={6} md={desktopResponsivity}>
        <Box sx={cardStyle}>
          <Typography sx={{
            fontFamily: 'Poppins',
            fontSize: { xs: '12px', md: '16px' },
            fontWeight: '700',
            maxWidth: { lg: '250px' },
            margin: '0 auto',
          }}
          >
            Valor total dos projetos
            {' '}
            {(cookieRoles === 'Vendedor' || cookieRoles === 'Integradora') && 'contratados'}
          </Typography>
          <Typography sx={{
            fontSize: { xs: '16px', md: '20px' },
            fontWeight: 'bolder',
            color: theme.palette.primary.main,
          }}
          >
            {applyCurrencyMask(bottomCardsData.totalProjectsValue)}
          </Typography>
        </Box>
      </Grid>
      {cookieRoles !== 'Vendedor' && cookieRoles !== 'Integradora' && (
        <>
          <Grid xs={6} md={desktopResponsivity}>
            <Box sx={cardStyle}>
              <Typography sx={{
                fontFamily: 'Poppins',
                fontSize: { xs: '12px', md: '16px' },
                fontWeight: '700',
                maxWidth: { lg: '250px' },
                margin: '0 auto',
              }}
              >
                Valor total dos projetos contratados
              </Typography>
              <Typography sx={{
                fontSize: { xs: '16px', md: '20px' },
                fontWeight: 'bolder',
                color: theme.palette.primary.main,
              }}
              >
                {applyCurrencyMask(bottomCardsData.totalAprovedProjectsValue)}
              </Typography>
            </Box>
          </Grid>
          <Grid xs={6} md={2}>
            <Box sx={cardStyle}>
              <Typography sx={{
                fontFamily: 'Poppins',
                fontSize: {
                  xs: '12px',
                  md: '16px',
                },
                fontWeight: '700',
                maxWidth: { lg: '250px' },
                margin: '0 auto',
              }}
              >
                Potência total dos projetos
              </Typography>
              <Typography sx={{
                fontSize: {
                  xs: '16px',
                  md: '20px',
                },
                fontWeight: 'bolder',
                color: theme.palette.primary.main,
              }}
              >
                {/* O '+' antes do valor vai permitir que só exiba 2 decimais caso necessário */}
                {String(+bottomCardsData.totalPower.toFixed(2)).replace(/\./g, ',')}
                {' '}
                kWp
              </Typography>
            </Box>
          </Grid>
        </>
      )}
      <Grid xs={6} md={desktopResponsivity}>
        <Box sx={cardStyle}>
          <Typography sx={{
            fontFamily: 'Poppins',
            fontSize: { xs: '12px', md: '16px' },
            fontWeight: '700',
            maxWidth: { lg: '250px' },
            margin: '0 auto',
          }}
          >
            Potência total dos projetos contratados
          </Typography>
          <Typography sx={{
            fontSize: { xs: '16px', md: '20px' },
            fontWeight: 'bolder',
            color: theme.palette.primary.main,
          }}
          >
            {/* O '+' antes do valor vai permitir que só exiba 2 decimais caso necessário */}
            {String(+bottomCardsData.totalAprovePower.toFixed(2)).replace(/\./g, ',')}
            {' '}
            kWp
          </Typography>
        </Box>
      </Grid>
    </Grid>
  );
}
export default BottomCharts;
