import React, { useEffect, useRef, useState } from 'react';
import LoadingBar, { LoadingBarRef } from 'react-top-loading-bar';
import { useHistory } from 'react-router-dom';
import { AxiosResponse } from 'axios';
import SortIcon from '@mui/icons-material/ArrowUpward';
import DataTable from 'react-data-table-component';
import { parseCookies } from 'nookies';
import { ToastContainer } from 'react-toastify';
import { useTheme } from '@mui/material';
import { FormPage } from '../../components/FormPage';
import style from './logssistema.module.scss';
import { api } from '../../services/api';
import { DialogComponent } from '../../components/DialogComponent';
import { timeStampToDate } from '../../utils/util';
import { LoadingIcon } from '../../components/LoadingIcon';

interface LogData {
  id: number;
  usuario: string;
  descricao: string;
  date: string;
}

export function LogsSistema() {
  const ref = useRef<LoadingBarRef>(null);
  const [logs, setLogs] = useState<LogData[]>([]);
  const theme = useTheme();

  const history = useHistory();
  const { 'nextauth.tag': tag } = parseCookies();
  const [isOpen, setIsOpen] = useState(false);
  const [loadingText, setLoadingText] = useState('');

  useEffect(() => {
    const getLogs = async () => {
      const response: Promise<AxiosResponse<any, any>> = api.post('/logs/get');
      response.then((resolved) => {
        const items: LogData[] = resolved.data;
        setLogs(items);
        return items;
      });
    };
    getLogs();
  }, [tag]);

  const handleClick = () => {
    history.push('/CadastroVendedor');
  };

  // @ts-ignore
  // const handleClickActivate = async (state) => {
  //   const msg = 'Deseja alterar o status deste log?';
  //   // eslint-disable-next-line no-alert
  //
  //   let statusToSend = '';
  //   let value = 0;
  //
  //   for (let i = 0; i < logs.length; i++) {
  //     if (logs[i].id.toString() === state.target.id) {
  //       statusToSend = logs[i].status;
  //       value = logs[i].id;
  //     }
  //   }
  //   // eslint-disable-next-line no-alert
  //   if (window.confirm(msg)) {
  //     if (statusToSend.toLowerCase() === 'Ativo'.toLowerCase()) {
  //       setLoadingText('Desativando log...');
  //     } else {
  //       setLoadingText('Ativando log...');
  //     }
  //
  //     setIsOpen(true);
  //     const changeStatus = await api.post('/user/vendor/changeStatus', {
  //       data: {
  //         userId: state.target.id,
  //         status: statusToSend,
  //       },
  //
  //     });
  //
  //     if (changeStatus.data === 'STATUS_CHANGED') {
  //       toast.success(statusToSend.toLowerCase() === 'Ativo'.toLowerCase()
  //         ? 'Vendedor Desativado' : 'Vendedor Ativado');
  //       setTimeout(() => {
  //         window.location.reload();
  //       }, 2000);
  //     } else {
  //       toast.error('Houve um erro com sua solicitação, tente mais tarde!');
  //     }
  //   }
  // };

  // @ts-ignore
  const columns = [
    // {
    // name: 'Código',
    {
      name: 'Código', selector: 'id', sortable: true, width: '100px',
    },
    // },
    {
      name: 'Usuário',
      selector: (row: { [x: string]: any; }) => row['userId'],
      sortable: true,
      right: false,
      center: false,
      minWidth: '200px',
      width: '200px',
    },
    {
      name: 'Descricao',
      selector: (row: { [x: string]: any; }) => row['descricao'],
      sortable: true,
      right: false,
      center: false,
      minWidth: '600px',
      width: '600px',
    },
    {
      // name: 'Código', selector: 'id', sortable: true, width: '100px',
      name: 'Data',
      // @ts-ignore
      selector: (row) => timeStampToDate(row.data),
      width: '200px',
      sortable: true,
      conditionalCellStyles: [
        {
          when: (row: { [x: string]: any; }) => row['data'],
          style: {
            color: 'green',
            '&:hover': {
              cursor: 'pointer',
            },
          },
        },
      ],
    },
  ];
  const paginationComponentOptions = {
    selectAllRowsItem: true,
    selectAllRowsItemText: 'Todos',
    rowsPerPageText: 'Registros por página',
  };
  return (
    <div>
      <LoadingBar color={theme.palette.primary.main} ref={ref} />
      <FormPage>
        <br />
        <div className={style.listaVendedorContainer}>
          <h1>Logs</h1>
          {/* <button type="button" onClick={handleClick}> */}
          {/*   Cadastrar Vendedor */}
          {/* </button> */}
          {logs.length === 0 ? <LoadingIcon active />
            : (
              <DataTable
                title=""
                key="id"
            // @ts-ignore
                columns={columns}
            // @ts-ignore
            // paginationComponent={BootyPagination}
                paginationComponentOptions={paginationComponentOptions}
                data={logs}
            // paginationPerPage={30}
            // @ts-ignore
                defaultSortField="nome"
                noDataComponent="Nenhum log registrado"
                sortIcon={<SortIcon />}
                pagination
              />
            )}
        </div>
        <br />
      </FormPage>
      <DialogComponent isOpen={isOpen} labelText={loadingText} />
    </div>
  );
}
