import React, {
  ChangeEvent,
  useRef,
  useState,
} from 'react';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import LoadingBar, { LoadingBarRef } from 'react-top-loading-bar';
import { parseCookies } from 'nookies';
import { useHistory } from 'react-router-dom';
import {
  alpha,
  Box, Button, TextField, Typography, useTheme,
} from '@mui/material';
import Grid from '@mui/material/Unstable_Grid2';
import InputMask from 'react-input-mask';
import style from './cadastrovendedor.module.scss';
import { api } from '../../services/api';
import { Vendor } from '../../contexts/@types';
import { defaultVendor } from '../../contexts/@defaultValues';
import { DialogComponent } from '../../components/DialogComponent';

export type VendorError = {
  name: boolean,
  email: boolean,
  cpf: boolean,
  phone: boolean,
}

export function CadastroVendedor() {
  const completeURL = window.location.href.split('/');
  const formatedURL = `${completeURL[0]}//${completeURL[2]}`;
  const history = useHistory();
  const ref = useRef<LoadingBarRef>(null);
  const theme = useTheme();

  const { 'nextauth.tag': tag } = parseCookies();

  const [isOpen, setIsOpen] = useState(false);
  const [loadingText, setLoadingText] = useState('');

  const [vendor, setVendor] = useState<Vendor>(defaultVendor);
  const [fieldsError, setFieldsError] = useState<VendorError>({
    name: false,
    email: false,
    cpf: false,
    phone: false,
  });

  const handleChange = (prop: keyof Vendor) => (event: ChangeEvent<HTMLInputElement>) => {
    setVendor({ ...vendor, [prop]: event.target.value });
  };

  const validateVendorForm = async () => {
    const errors = {
      name: !vendor.name,
      email: !vendor.email,
      cpf: !vendor.cpf || vendor.cpf.includes('_'),
      phone: !vendor.phone || vendor.phone.includes('_'),
    };

    setFieldsError(errors);

    const fieldNames = {
      name: 'Nome completo',
      email: 'E-mail',
      cpf: 'CPF',
      phone: 'Telefone',
    };

    const emptyFields = Object.keys(errors)
      .filter((key) => errors[key as keyof typeof errors])
      .map((key) => fieldNames[key as keyof typeof fieldNames])
      .join(';; ');

    if (emptyFields.length > 0) {
      toast.error(
        <>
          O(s) campo(s) a seguir devem ser preenchidos:
          {emptyFields.split('; ').map((e) => (
            <React.Fragment key={e}>
              <br />
              {e}
            </React.Fragment>
          ))}
        </>,
      );

      return true;
    }
    return false;
  };

  const handleSubmitVendor = async () => {
    if (await validateVendorForm()) return;

    setLoadingText('Cadastrando vendedor...');
    setIsOpen(true);
    const request = {
      headers: { 'Content-Type': 'application/json' },
      data: {
        nome: vendor.name,
        email: vendor.email,
        cpf: vendor.cpf,
        telefone: vendor.phone,
        currentUserId: parseInt(tag, 10),
        returnURL: formatedURL,
      },
    };

    const response = await api.post('user/vendor/insert', request);

    switch (response.data) {
      case 'EMAIL_REGISTERED_ALREADY':
        setIsOpen(false);
        toast.error(
          'E-mail já cadastrado no sistema.',
        );
        break;

      case 'CREATED_OK':
        setIsOpen(false);
        toast.success('Vendedor cadastrado com sucesso.');
        history.push('/ListaVendedor');
        break;
      default:
        setIsOpen(false);
        toast.error(`Falha ao inserir usuário no sistema.${response.data}`);
        break;
    }
  };

  const handleCancel = () => {
    history.push('/ListaVendedor');
  };

  const cardStyle = {
    minHeight: '100%',
    backgroundColor: '#fff',
    boxShadow: '0px 2px 2px 0px #0000001A',
    borderRadius: '8px',
    boxSizing: 'border-box',
    padding: '0.5rem 1.5rem',
    gap: '1rem',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
  };
  return (
    <>
      <LoadingBar color={theme.palette.primary.main} ref={ref} />
      <Box sx={cardStyle}>
        <Grid container spacing={2} rowSpacing={2}>
          <Grid xs={12}>
            <Typography sx={{
              fontSize: { xs: '16px', md: '24px' },
              fontFamily: 'Poppins',
              color: theme.palette.primary.main,
              fontWeight: '700',
            }}
            >
              Cadastro de vendedor
            </Typography>
            <hr className={style.titleDivisor} />
            <Typography sx={{
              fontSize: {
                xs: '12px',
                md: '16px',
              },
              fontFamily: 'Poppins',
              fontWeight: '400',
            }}
            >
              Preencha o formulário abaixo para cadastrar um novo vendedor para sua integradora.
              Vendedores poderão fazer lançamento de novos projetos no sistema da Soltech.
              Você poderá acompanhar o andamento de todos os projetos associados aos vendedores
              da sua integradora.
            </Typography>
          </Grid>
          <Grid xs={12} lg={3}>
            <TextField
              error={fieldsError.name}
              fullWidth
              required
              label="Nome completo"
              variant="outlined"
              onChange={handleChange('name')}
            />
          </Grid>
          <Grid xs={12} lg={3}>
            <TextField
              error={fieldsError.email}
              fullWidth
              required
              label="E-mail"
              variant="outlined"
              onChange={handleChange('email')}
            />
          </Grid>
          <Grid xs={12} lg={3}>
            <InputMask
              mask="999.999.999-99"
              maskPlaceholder="_"
              onChange={handleChange('cpf')}
            >
              <TextField
                error={fieldsError.cpf}
                fullWidth
                required
                label="CPF"
                variant="outlined"
              />
            </InputMask>
          </Grid>
          <Grid xs={12} lg={3}>
            <InputMask
              mask="(99) 9 9999-9999"
              maskPlaceholder="_"
              onChange={handleChange('phone')}
            >
              <TextField
                error={fieldsError.phone}
                fullWidth
                required
                label="Telefone"
                variant="outlined"
              />
            </InputMask>
          </Grid>
        </Grid>
        <div>
          <hr className={style.bottomDivisor} />
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'end',
              gap: '1rem',
            }}
          >
            <Button
              variant="outlined"
              onClick={() => handleCancel()}
              sx={{
                width: '10rem',
                borderRadius: '2rem',
                borderColor: theme.palette.primary.main,
                color: theme.palette.primary.main,
                marginBottom: '0.5rem',
                marginTop: '1rem',
                textTransform: 'none',
                '&:hover': {
                  borderColor: theme.palette.primary.main,
                  backgroundColor: alpha(theme.palette.primary.main, 0.2),
                },
              }}
            >
              Cancelar
            </Button>
            <Button
              variant="contained"
              onClick={() => handleSubmitVendor()}
              sx={{
                width: '10rem',
                borderRadius: '2rem',
                backgroundColor: theme.palette.primary.main,
                marginBottom: '0.5rem',
                marginTop: '1rem',
                textTransform: 'none',
                '&:hover': {
                  backgroundColor: alpha(theme.palette.primary.main, 0.8),
                },
              }}
            >
              Cadastrar
            </Button>
          </Box>
        </div>
      </Box>
      <DialogComponent isOpen={isOpen} labelText={loadingText} />
    </>
  );
}
