import React from 'react';
import { toast } from 'react-toastify';
import { api } from '../services/api';
import { getFile } from '../lib/download';
import { sendFile } from '../lib/upload';

// used to check user inputs
export const onlyLetters = (e: React.FormEvent<HTMLInputElement>) => {
  const mask = /[a-zA-Z-áàâãéèêíïóôõöúçñÁÀÂÃÉÈÍÏÓÔÕÖÚÇÑ\s]/;

  let value = '';

  for (let i = 0; i < e.currentTarget.value.length; ++i) {
    if (e.currentTarget.value[i].match(mask)) {
      value += e.currentTarget.value[i];
    }
  }

  return value;
};

// used to check user inputs
export const nonMonetaryDecimalValue = (e: React.ChangeEvent<HTMLInputElement>) => {
  let value = '';
  const mask = /^\d*(\.?|,?)\d{0,2}$/;

  if (e.target.value.substring(0) === '.' || e.target.value.substring(0) === ',') {
    e.target.value = '';
  }

  let valueWithoutComma = '';

  for (let i = 0; i < e.target.value.length; ++i) {
    if (e.target.value.match(mask)) {
      valueWithoutComma = e.target.value.replace(/,/, '.');
      value = valueWithoutComma;
    } else {
      value = e.target.value.substring(0, e.target.value.length - 1);
    }
  }

  return value;
};

export const percentageValue = (valueFinal: string) => {
  let value = '';
  const mask2 = /(^100(\.0{1,2})?$)|(^([1-9]([0-9])?|0|[0-9]\.|[1-9]([0-9])?\.)(\.[0-9]{1,2})?$)/;

  if (valueFinal.substring(0) === '.' || valueFinal.substring(0) === ',') {
    return '';
  }

  let valueWithoutComma = '';

  for (let i = 0; i < valueFinal.length; ++i) {
    if (valueFinal.match(mask2)) {
      valueWithoutComma = valueFinal.replace(',', '.');
      value = valueWithoutComma;
    } else {
      value = valueFinal.substring(0, valueFinal.length - 1);
    }
  }

  return value;
};

export const timeStampToDate = (dateS : string) => {
  const formatedDate = dateS.replace('Z', '')
    .split('T')[0]
    .replace(/-/, '/').replace(/-/, '/')
    .split('/').reverse()
    .join('/');
  const formatedHour = dateS.replace('Z', '').split('T')[1].split(':')[0];
  const hour = parseInt(formatedHour, 10) - 3;
  const formatedMinute = dateS.replace('Z', '').split('T')[1].split(':')[1];
  return `${formatedDate} - ${hour.toString()}:${formatedMinute}`;
};

export const timeStampToDateOnly = (dateS : string | null) => {
  if (dateS) {
    const formatedDate = dateS.replace('Z', '')
      .split('T')[0]
      .replace(/-/, '/').replace(/-/, '/')
      .split('/').reverse()
      .join('/');
    return `${formatedDate}`;
  }
  return null;
};

export const emailMask = (value: string) => {
  const mask = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

  return mask.test(value);
};

export const removeNonNumeric_2 = (n: string) => {
  const mask = '0123456789';

  // remove unwanted characters (including recently pressed ones)
  let value = '';
  for (let i = 0; i < n.length; ++i) {
    if (mask.includes(n[i])) {
      value += n[i];
    }
  }
  return (value.length === 0) ? '0' : parseInt(value, 10).toString();
};

export const applyCurrencyMask = (n: string | number) => {
  const value : string = n.toString()
    .split('')
    .reverse()
    .join('');
  let newValue = '';
  if (Number(value) >= 0) {
    for (let x = 0; x < value.length; x++) {
      newValue += value[x];
      if (x === 1) {
        if (x < value.length - 1) {
          newValue += ',';
        }
      }

      if (x === 4 || x === 7 || x === 10 || x === 13) {
        if (x < value.length - 1) {
          newValue += '.';
        }
      }
    }
  } else {
    const negative : string = value.replace(/(?!^)-/g, '')
      .replace(/^,/, '')
      .replace('-', '');
    for (let x = 0; x < negative.length; x++) {
      newValue += negative[x];
      if (x === 1) {
        if (x < negative.length - 1) {
          newValue += ',';
        }
      }

      if (x === 4 || x === 7 || x === 10 || x === 13) {
        if (x < negative.length - 1) {
          newValue += '.';
        }
      }
    }
    newValue = newValue.split('').reverse().join('');
    newValue = `-${newValue}`;
    newValue = newValue.split('').reverse().join('');
  }
  newValue = newValue.split('').reverse().join('');
  newValue = `R$ ${newValue}`;
  return newValue;
};

export const applyNoSymbolCurrencyMask = (n: string | number) => {
  const value = n.toString().split('').reverse().join('');
  let newValue = '';
  const processValue = (val: string) => {
    for (let x = 0; x < val.length; x++) {
      newValue += val[x];
      if ((x === 1 || x === 4 || x === 7 || x === 10 || x === 13) && x < val.length - 1) {
        newValue += x === 1 ? ',' : '.';
      }
    }
  };
  if (Number(value) >= 0) {
    processValue(value);
  } else {
    const negative = value.replace(/(?!^)-/g, '').replace(/^,/, '').replace('-', '');
    processValue(negative);
    newValue = `-${newValue.split('').reverse().join('')}`;
  }
  return newValue.split('').reverse().join('');
};

export const applyCPFMask = (e: React.ChangeEvent<HTMLInputElement>) => {
  let value = '';
  const mask = '0123456789';

  // remove unwanted characters (including recently pressed ones)
  for (let i = 0; i < e.target.value.length; ++i) {
    if (mask.includes(e.target.value[i])) {
      value += e.target.value[i];
    }
  }

  // apply the "___.___.___-__" mask
  let newValue = '';

  for (let i = 0; i < value.length; ++i) {
    newValue += value[i];
    if ((i === 2 && value.length > 3) || (i === 5 && value.length > 6)) {
      newValue += '.';
    }
    if (i === 8 && value.length > 9) {
      newValue += '-';
    }
  }

  // forcefully limit characters count
  if (newValue.length > 14) {
    newValue = newValue.substring(0, 14);
  }

  return newValue;
};

export const applyDateMask = (e: React.ChangeEvent<HTMLInputElement>) => {
  let value = '';
  const mask = '0123456789';
  const date = new Date();

  // remove unwanted characters (including recently pressed ones)
  for (let i = 0; i < e.target.value.length; ++i) {
    if (mask.includes(e.target.value[i])) {
      value += e.target.value[i];
    }
  }

  // apply the "__/__/____" mask
  let newValue = '';

  for (let i = 0; i < value.length; ++i) {
    if (i === 2 || i === 4) {
      newValue += '/';
    }
    newValue += value[i];
  }

  if (newValue.length >= 10) {
    newValue = newValue.slice(0, 10);
  }

  // forcefully limit character value
  if (newValue.slice(0, 2) > '31') {
    newValue = '31';
  }

  if (newValue.slice(3, 5) > '12') {
    newValue = newValue.slice(0, 3);
    newValue += '12';
  }

  if (newValue.slice(6, 10) > date.getFullYear().toString()) {
    newValue = newValue.slice(0, 6);
    newValue += date.getFullYear().toString();
  }

  return newValue;
};

export const applyCEPMask = (e: React.ChangeEvent<HTMLInputElement>) => {
  let value = '';
  const mask = '0123456789';

  // remove unwanted characters (including recently pressed ones)
  for (let i = 0; i < e.target.value.length; ++i) {
    // prevent field to go over 9 characters
    if (i === 9) break;

    if (i === 5) {
      value += '-';
    }

    if (mask.includes(e.target.value[i])) {
      value += e.target.value[i];
    }
  }

  return value;
};

export const applyTelefoneMask = (targetValue: string) => {
  let value = '';
  const mask = '0123456789';

  // remove unwanted characters (including recently pressed ones)
  for (let i = 0; i < targetValue.length; ++i) {
    if (mask.includes(targetValue[i])) {
      value += targetValue[i];
    }
  }

  // apply the "(__)_____-____" mask
  let newValue = '';

  for (let i = 0; i < value.length; ++i) {
    if (i === 0) {
      newValue += '(';
    }
    if (i === 2) {
      newValue += ') ';
    }
    if (i === 7) {
      newValue += '-';
    }

    newValue += value[i];
  }

  // forcefully limit characters count
  if (newValue.length > 15) {
    newValue = newValue.substring(0, 15);
  }

  return newValue;
};

export const checkValidDate = (date: string, isEmptyValid: boolean) => {
  const dateMask = /^(?:(?:31(\/|-|\.)(?:0?[13578]|1[02]))\1|(?:(?:29|30)(\/|-|\.)(?:0?[13-9]|1[0-2])\2))(?:(?:1[6-9]|[2-9]\d)?\d{2})$|^(?:29(\/|-|\.)0?2\3?:(?:(?:1[6-9]|[2-9]\d)?(?:0[48]|[2468][048]|[13579][26])|(?:(?:16|[2468][048]|[3579][26])00)))$|^(?:0?[1-9]|1\d|2[0-8])(\/|-|\.)(?:(?:0?[1-9])|(?:1[0-2]))\4(?:(?:1[6-9]|[2-9]\d)?\d{2})$/;

  const getToday = (m: string) => {
    const newDate = new Date();
    const day = newDate.getDate().toString();
    let month = ((newDate.getMonth() + 1).toString());
    if (month.length === 1) month = `0${month}`;
    const year = newDate.getFullYear().toString();

    // check if date is after today
    return !(m.slice(6, 10) > year
      || (m.slice(6, 10) >= year
        && m.slice(3, 5) > month)
      || (m.slice(0, 2) > day
        && m.slice(3, 5) >= month
        && m.slice(6, 10) >= year));
  };

  if (!date && isEmptyValid) return true;
  let checkDate = false;
  if (date) {
    const leapDay = date.slice(0, 2);
    const leapMonth = date.slice(3, 5);
    const leapYear = date.slice(6, date.length);

    if (getToday(date) && dateMask.test(date)) {
      return true;
    }
    checkDate = (parseInt(leapYear, 10) % 4 === 0) && leapMonth === '02' && leapDay === '29';
  }
  return checkDate;
};

export const searchCEP = async (cepVal: string | undefined) => {
  const value = cepVal?.replace('-', '');
  const request = {
    data: {
      ref: value,
    },
  };

  return api.post('/address/cep/get', request);
};

export const TestFileType = (fileName: string, fileTypes : [string]) => {
  if (!fileName) return;

  const dots = fileName.split('.');
  // get the part AFTER the LAST period.
  const fileType = `.${dots[dots.length - 1]}`;

  // eslint-disable-next-line consistent-return
  if (fileTypes.join('.').indexOf(fileType) !== -1) {
    // eslint-disable-next-line consistent-return
    return true;
  }
  // eslint-disable-next-line consistent-return
  return false;
};

export function isValidUrl(string: File | null | string) {
  if (typeof string !== 'string') return false;
  try {
    const url = new URL(string);
  } catch (_) {
    return false;
  }
  return true;
}

export const openPdf = async (docUrl : string) => {
  const file = await getFile(docUrl);
  window.open(file);
};

export const handleFileName = (value: File | null | string) => {
  if (value instanceof File) return value.name;

  return '';
};

export const sendDocument = async (type: string, document: File | string | null) => {
  if (document && typeof document !== 'string') {
    const resp = await sendFile(type, document);
    if (resp === 'UPLOAD_FAILED') {
      toast.error(`Ocorreu um erro ao tentar salvar o documento ${document.name}`);
      return 'Sem documentos';
    }
    return resp;
  }
  if (!document) return 'Sem documentos';
  return document;
};

export const handleFixCurrency = (value: number | string) => {
  // recebe o número inteiro 00000 e passa para float 000,00
  let strValue = String(value);
  if (strValue.length > 2) {
    strValue = `${strValue.slice(0, -2)},${strValue.slice(-2)}`;
  } else {
    strValue = `0,${strValue.padStart(2, '0')}`;
  }
  return strValue;
};

export const formatAccount = (originalValue: string) => {
  let newValue = '';
  const mask = '0123456789';

  // remove unwanted characters (including recently pressed ones)
  for (let i = 0; i < originalValue.length; ++i) {
    // prevent field to go over 9 characters
    if (i === 11) break;

    if (i === originalValue.length - 1) {
      newValue += '-';
    }

    if (mask.includes(originalValue[i])) {
      newValue += originalValue[i];
    }
  }

  return newValue;
};

// sortArray(array, attribute)
export function sortArray<T>(array: T[], attribute: keyof T): T[] {
  const isSorted = array.every((val, i, arr) => !i || (arr[i - 1][attribute] <= val[attribute]));

  return [...array].sort((a, b) => {
    const aValue: T[keyof T] = a[attribute];
    const bValue: T[keyof T] = b[attribute];

    if (aValue instanceof Date && bValue instanceof Date) {
      return isSorted ? bValue.getTime() - aValue.getTime() : aValue.getTime() - bValue.getTime();
    }
    if (aValue < bValue) {
      return isSorted ? 1 : -1;
    }
    if (aValue > bValue) {
      return isSorted ? -1 : 1;
    }
    return 0;
  });
}

export function currencyToSubmit(value: string) {
  if (value === '0,0') return '';
  return value.replace(/\D/g, '');
}

export function convertToNumber(value: string) {
  const noDot = value.replace(/\./g, '');
  const numberStr = noDot.replace(/,/g, '.');
  return Number(numberStr);
}

export function applyDecimal(value: number) {
  const str = String(value);
  return Number(`${str.slice(0, -2)}.${str.slice(-2)}`);
}

export function calcKWPCost(totalCost: number, potency: number) {
  let kWpCost = totalCost / potency;
  if (totalCost < 1 || potency < 1) kWpCost = 0;
  return {
    brl: kWpCost.toLocaleString('pt-BR', {
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    }),
    value: kWpCost,
  };
}

export function applyZipMask(value: string | number) {
  let zip: string;
  if (typeof value === 'number') {
    zip = String(value);
  } else {
    zip = value;
  }
  zip = zip.replace(/\D/g, '');
  return zip.replace(/(\d{2})(\d{3})(\d{3})/, '$1.$2-$3');
}

export const applyNumberMask = (input: string) => {
  if (!input) return input;

  let value = input;

  // Remove caracteres não numéricos
  value = value.replace(/\D/g, '');

  // Remove os zeros excedentes à esquerda dos inteiros
  value = value.replace(/^0+/, '');

  // Se o valor tem apenas decimais
  if (value.length <= 2) {
    // Adiciona zeros à esquerda para completar os decimais
    value = value.padStart(3, '0');
  }

  // Divide o valor em inteiros e decimais
  const integers = value.substring(0, value.length - 2);
  const decimals = value.substring(value.length - 2);

  // Adiciona o ponto entre inteiros e decimais
  return `${integers}.${decimals}`;
};

export const applyStrNumberMask = (input: number) => {
  if (!input) return input;

  let value = String(input);

  // Substitui o ponto por vírgula
  value = value.replace(/\./g, ',');

  // // Adiciona pontos de milhar
  // const thousandsRegex = /(\d)(?=(\d{3})+(?!\d))/g;
  // value = value.replace(thousandsRegex, '$1.');

  return value;
};

export const convertToNumberFormat = (input: string) => {
  let value = input;
  value = value.replace(/\./g, '');
  value = value.replace(/,/g, '.');
  return value;
};
