import Grid from '@mui/material/Unstable_Grid2';
import {
  alpha,
  Button,
  FormControl,
  IconButton,
  InputAdornment,
  InputLabel,
  OutlinedInput,
  useTheme,
} from '@mui/material';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import { useHistory } from 'react-router-dom';
import React, { useEffect, useState, ChangeEvent } from 'react';
import { parseCookies } from 'nookies';
import { toast, ToastContainer } from 'react-toastify';
import { VisibilityOffOutlined, VisibilityOutlined } from '@mui/icons-material';

interface ChangePassword {
  oldPassword: string
  newPassword: string
  confirmPassword: string
  showOldPassword: boolean
  showNewPassword: boolean
  showConfirmPassword: boolean
}

interface Props {
  isOpen: boolean;
  updateUser: Function;
  setDialogClose: Function;
}

const DialogNewPasswordComponent = (props: Props) => {
  const {
    isOpen, updateUser, setDialogClose,
  } = props;
  const [isOpenDialog, setIsOpenDialog] = useState(false);
  const [errorField, setErrorField] = useState(false);

  const theme = useTheme();

  useEffect(() => {
    setIsOpenDialog(isOpen);
    // If Use-effect is changed run without the disabled eslint to check
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  const [values, setValues] = React.useState<ChangePassword>({
    oldPassword: '',
    newPassword: '',
    confirmPassword: '',
    showOldPassword: false,
    showNewPassword: false,
    showConfirmPassword: false,
  });
  const handleChange = (prop: keyof ChangePassword) => (event:
    React.ChangeEvent<HTMLInputElement>) => {
    setValues({ ...values, [prop]: event.target.value });
  };

  const handleClickShowOldPassword = () => {
    setValues({
      ...values,
      showOldPassword: !values.showOldPassword,
    });
  }; const handleClickShowNewPassword = () => {
    setValues({
      ...values,
      showNewPassword: !values.showNewPassword,
    });
  };
  const handleClickShowConfirmPassword = () => {
    setValues({
      ...values,
      showConfirmPassword: !values.showConfirmPassword,
    });
  };
  const validateOldPassword = (e: string) => e.length > 0;
  const validateNewPassword = (e: string) => e.length >= 6;
  const validateConfirmPassword = (e: string) => e === values.newPassword;

  const validateForm = () => {
    if (!validateOldPassword(values.oldPassword)) {
      toast.error('Você precisa digitar sua senha atual.');
      setErrorField(true);
      return false;
    }
    if (!validateNewPassword(values.newPassword)) {
      toast.error('A senha precisa ter ao menos 6 caracters.');
      setErrorField(true);
      return false;
    }
    if (!validateConfirmPassword(values.confirmPassword)) {
      toast.error('A nova senha e a confirmação digitada não conferem.');
      setErrorField(true);
      return false;
    }
    return true;
  };
  const clearFields = () => {
    setValues({
      oldPassword: '',
      newPassword: '',
      confirmPassword: '',
      showOldPassword: false,
      showNewPassword: false,
      showConfirmPassword: false,
    });
  };
  const handleClose = () => {
    clearFields();
    setDialogClose();
  };
  const updateUserProp = async () => {
    if (validateForm()) {
      setErrorField(false);
      updateUser(values.oldPassword, values.newPassword);
      clearFields();
    }
  };

  return (
    <div>
      <Dialog
        open={isOpenDialog}
        onClose={handleClose}
        sx={{
          '& .MuiDialog-paper': {
            padding: '1rem',
            borderRadius: '1rem',
          },
        }}
      >
        <DialogTitle sx={{
          fontSize: { xs: '16px', md: '24px' },
          fontFamily: 'Poppins',
          color: theme.palette.primary.main,
          fontWeight: '700',
        }}
        >
          Troca de senha
        </DialogTitle>
        <DialogContent>
          <DialogContentText>
            Altera a senha utilizada para logar no sistema.
          </DialogContentText>
          <Grid container spacing={2} rowSpacing={2} sx={{ marginTop: '1rem' }}>
            <Grid xs={12} sm={12} md={12} lg={12}>
              <FormControl
                variant="outlined"
                fullWidth
                error={errorField}
              >
                <InputLabel htmlFor="outlined-adornment-password">Digite a senha atual</InputLabel>
                <OutlinedInput
                  id="outlined-adornment-password"
                  type={values.showOldPassword ? 'text' : 'password'}
                  value={values.oldPassword}
                  onChange={handleChange('oldPassword')}
                  endAdornment={(
                    <InputAdornment position="end">
                      <IconButton
                        aria-label="toggle password visibility"
                        onClick={handleClickShowOldPassword}
                      >
                        {values.showOldPassword
                          ? <VisibilityOutlined /> : <VisibilityOffOutlined />}
                      </IconButton>
                    </InputAdornment>
                  )}
                  label="Digite a senha atual"
                />
              </FormControl>
            </Grid>
            <Grid xs={12} sm={12} md={12} lg={12}>
              <FormControl
                variant="outlined"
                fullWidth
                error={errorField}
              >
                <InputLabel htmlFor="outlined-adornment-password">Digite a nova senha</InputLabel>
                <OutlinedInput
                  id="outlined-adornment-password"
                  type={values.showNewPassword ? 'text' : 'password'}
                  value={values.newPassword}
                  onChange={handleChange('newPassword')}
                  endAdornment={(
                    <InputAdornment position="end">
                      <IconButton
                        aria-label="toggle password visibility"
                        onClick={handleClickShowNewPassword}
                      >
                        {values.showNewPassword
                          ? <VisibilityOutlined /> : <VisibilityOffOutlined />}
                      </IconButton>
                    </InputAdornment>
                  )}
                  label="Digite a nova senha"
                />
              </FormControl>
            </Grid>
            <Grid xs={12} sm={12} md={12} lg={12}>
              <FormControl
                variant="outlined"
                fullWidth
                error={errorField}
              >
                <InputLabel htmlFor="outlined-adornment-password">Confirme a nova senha</InputLabel>
                <OutlinedInput
                  id="outlined-adornment-password"
                  type={values.showConfirmPassword ? 'text' : 'password'}
                  value={values.confirmPassword}
                  onChange={handleChange('confirmPassword')}
                  endAdornment={(
                    <InputAdornment position="end">
                      <IconButton
                        aria-label="toggle password visibility"
                        onClick={handleClickShowConfirmPassword}
                      >
                        {values.showConfirmPassword
                          ? <VisibilityOutlined /> : <VisibilityOffOutlined />}
                      </IconButton>
                    </InputAdornment>
                  )}
                  label="Confirme a nova senha"
                />
              </FormControl>
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions sx={{ justifyContent: 'space-around' }}>
          <Grid container spacing={2} rowSpacing={2} sx={{ width: '100%' }}>
            <Grid xs={6}>
              <Button
                variant="outlined"
                sx={{
                  width: '100%',
                  borderRadius: '2rem',
                  borderColor: theme.palette.primary.main,
                  color: theme.palette.primary.main,
                  marginBottom: '0.5rem',
                  marginTop: '1rem',
                  '&:hover': {
                    borderColor: theme.palette.primary.main,
                    backgroundColor: alpha(theme.palette.primary.main, 0.2),
                  },
                }}
                onClick={handleClose}
              >
                Cancelar
              </Button>
            </Grid>
            <Grid xs={6}>
              <Button
                variant="contained"
                sx={{
                  width: '100%',
                  borderRadius: '2rem',
                  backgroundColor: theme.palette.primary.main,
                  marginBottom: '0.5rem',
                  marginTop: '1rem',
                  textTransform: 'none',
                  '&:hover': {
                    backgroundColor: alpha(theme.palette.primary.main, 0.8),
                  },
                }}
                onClick={updateUserProp}
              >
                Salvar
              </Button>
            </Grid>
          </Grid>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export { DialogNewPasswordComponent };
