import {
  Button, Stack, TextField, ThemeProvider, useMediaQuery, useTheme,
} from '@mui/material';
import React, { ChangeEvent, useState } from 'react';
import { DataDisplay } from '../elements/DataDisplay';
import { applyCurrencyMask, percentageValue } from '../../utils/util';
import { CreditOption } from '../../contexts/@types';
import { useButtonThemes } from '../../themes/buttonTheme';
import { DialogBodyComponent } from '../DialogBodyComponent';
import { defaultCreditOption } from '../../contexts/@defaultValues';

interface Props {
  creditOption: CreditOption
  saveCredit?: Function
  allowEdit?: boolean
  allowRemove?: boolean
  removeCredit?: Function
  storedIndex?: number
}

interface Error {
  parcelas: boolean,
  valorParcela: boolean,
  taxa: boolean,
}

const CreditOptionEditor = (props: Props) => {
  const {
    creditOption,
    allowEdit,
    saveCredit,
    allowRemove,
    removeCredit,
    storedIndex,
  } = props;

  const { primaryButton, secondaryButton } = useButtonThemes();

  const theme = useTheme();
  const isXs = useMediaQuery(theme.breakpoints.only('xs'));

  const [openDialog, setOpenDialog] = useState(false);
  const [editedItem, setEditedItem] = useState<CreditOption>(defaultCreditOption);
  const [error, setError] = useState<Error>({
    parcelas: false,
    valorParcela: false,
    taxa: false,
  });

  const remove = () => {
    if (!removeCredit) return '';
    return removeCredit(creditOption);
  };

  const open = () => {
    setOpenDialog(true);
    setEditedItem(creditOption);
  };

  const editCredit = async () => {
    if (!saveCredit) return '';

    const newError = {
      ...error,
      parcelas: editedItem.parcelas <= 0,
      valorParcela: Number(editedItem.valorParcela.toString().replace(/,/g, '.')) <= 0,
      taxa: editedItem.taxa < 0,
    };

    setError(newError);

    const erroArray = Object.values(newError);
    const isValid = !erroArray.includes(true);

    if (!isValid) return '';

    setOpenDialog(false);
    return saveCredit(editedItem, storedIndex);
  };

  function formatCurrency(value: string) {
    if (value === '0,0') return '';
    let input = value.replace(/\D/g, '');
    input = (parseInt(input, 10) / 100).toFixed(2).replace(/\./g, ',');
    // if (value === 'NaN') input = '0,0';
    return input;
  }

  const handleChangeEdited = (key: keyof CreditOption) => (e: ChangeEvent<HTMLInputElement>) => {
    const { value, checked } = e.target;
    let input: string | boolean = '';
    if (key === 'parcelas') input = value.replace(/\D/g, '');
    if (key === 'valorParcela') input = formatCurrency(value);
    if (key === 'taxa') input = percentageValue(value);

    setEditedItem({ ...editedItem, [key]: input });
  };
  return (
    <>
      <Stack
        direction={isXs ? 'column' : 'row'}
        justifyContent="space-between"
        alignItems="center"
      >
        <DataDisplay title="Banco" data={creditOption.banco || '-----'} />
        <DataDisplay title="Quantidade de Parcelas" data={creditOption.parcelas || '-----'} />
        <DataDisplay
          title="Valor da Parcela"
          data={creditOption.valorParcela.toString().includes(',')
            ? creditOption.valorParcela.toString()
            : applyCurrencyMask(creditOption.valorParcela.toString() || '0')}
        />
        <DataDisplay title="Taxa" data={creditOption.taxa ? `${creditOption.taxa} %` : '-----'} />
        <Stack
          flexDirection="column"
          justifyContent="space-between"
          alignItems="center"
        >
          {allowEdit && (
          <ThemeProvider theme={primaryButton}>
            <Button
              sx={{
                width: 'fit-content',
                padding: '0.2rem 2rem',
              }}
              onClick={open}
            >
              Editar
            </Button>
          </ThemeProvider>
          )}
          {allowRemove && (
            <ThemeProvider theme={secondaryButton}>
              <Button
                sx={{
                  width: 'fit-content',
                  padding: '0.2rem 2rem',
                }}
                onClick={remove}
              >
                Remover
              </Button>
            </ThemeProvider>
          )}
        </Stack>
      </Stack>
      <DialogBodyComponent
        title="Editar condição de crédito"
        helperText="Altere os dados financeiros da condição de crédito selecionada."
        cancelButtonText="Cancelar"
        confirmButtonText="Salvar"
        isOpen={openDialog}
        close={() => setOpenDialog(false)}
        confirm={editCredit}
      >
        <Stack
          direction="column"
          spacing={4}
          marginTop={2}
        >
          <DataDisplay
            title="Banco"
            data={creditOption.banco}
          />
          <TextField
            fullWidth
            required
            error={error.parcelas}
            helperText={error.parcelas ? 'Obrigatório' : ''}
            label="Número de parcelas"
            variant="outlined"
            value={editedItem.parcelas}
            onChange={handleChangeEdited('parcelas')}
          />
          <TextField
            fullWidth
            required
            error={error.valorParcela}
            helperText={error.valorParcela ? 'Obrigatório' : ''}
            label="Valor da parcela"
            variant="outlined"
            prefix="R$"
            value={formatCurrency(editedItem.valorParcela.toString())}
            onChange={handleChangeEdited('valorParcela')}
          />
          <TextField
            fullWidth
            error={error.taxa}
            helperText={error.taxa ? 'Valor inválido' : ''}
            label="Taxa(%)"
            variant="outlined"
            value={editedItem.taxa}
            onChange={handleChangeEdited('taxa')}
          />
        </Stack>
      </DialogBodyComponent>
    </>
  );
};

export { CreditOptionEditor };
