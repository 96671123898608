import { useHistory } from 'react-router-dom';
import LoadingBar, { LoadingBarRef } from 'react-top-loading-bar';
import React, {
  SyntheticEvent, useRef, useState,
} from 'react';
import { ToastContainer, toast } from 'react-toastify';
import {
  VisibilityOffOutlined,
  VisibilityOutlined,
} from '@mui/icons-material';
import {
  alpha,
  Button,
  FormControl,
  IconButton,
  InputAdornment,
  InputLabel,
  OutlinedInput,
  Typography, useTheme,
} from '@mui/material';
import Grid from '@mui/material/Unstable_Grid2';

import { api } from '../../services/api';
import MediaCard from '../../components/MediaCard';

interface GetState {
  token: string;
}

export function TrocaSenha() {
  const ref = useRef<LoadingBarRef>(null);
  const history = useHistory();
  const theme = useTheme();
  const query = new URLSearchParams(window.location.search);

  const [errorMessage, setErrorMessage] = useState<string[]>([]);
  const [newPassword, setNewPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');

  const [showPassword, setShowPassword] = useState(false);
  const [passwordShownConfirm, setPasswordShownConfirm] = useState(false);

  const warnMsg = 'URL de definição de senha inválida. ';
  const [passwordShown, setPasswordShown] = useState(false);
  const [errorField, setErrorField] = useState(false);

  const handleSubmit = async (event: SyntheticEvent) => {
    event.preventDefault();

    const param = query.get('token');

    if (param === null || param === undefined || param.length === 0) {
      toast.warn(warnMsg);
    }

    const validateNewPassword = (e: string) => e.length >= 6;
    const validateConfirmPassword = (e: string) => e === newPassword;

    const validateForm = () => {
      if (!validateNewPassword(newPassword)) {
        toast.error(
          <div style={{ fontWeight: '8px' }}>
            <strong>Entrada inválida.</strong>
            {' '}
            <br />
            {' '}
            A senha precisa ter ao menos 6 caracters!
          </div>,
        );
        setErrorField(true);
      }
      if (!validateConfirmPassword(confirmPassword)) {
        toast.error(
          <div style={{ fontWeight: '8px' }}>
            <strong>As senhas não correspondem.</strong>
            {' '}
            <br />
            {' '}
            Tente novamente!
          </div>,
        );
        setErrorField(true);
      }
      return true;
    };

    if (validateForm()) {
      const request = {
        token: param,
        password: newPassword,
      };

      const response = await api.post('user/password', request);

      switch (response.data) {
        case 'PASSWORD_UPDATED_SUCCEFULLY':
          setErrorField(false);
          toast.success(
            <div style={{ fontWeight: '8px' }}>
              <strong>Senha alterada com sucesso.</strong>
            </div>,
          );
          history.push('/Login');
          break;
        case 'INVALID_TOKEN':
          setErrorField(false);
          toast.error(
            <div style={{ fontWeight: '8px' }}>
              <strong>Não foi possível alterar a senha.</strong>
              {' '}
              <br />
              {' '}
              Verifique se o link acessado foi o ultimo enviado por email!
            </div>,
          );
          history.push('/Login');
          break;
        default:
          setErrorField(true);
          toast.error(
            <div style={{ fontWeight: '8px' }}>
              <strong>Não foi possível alterar a senha.</strong>
              {' '}
              <br />
              {' '}
              {response.data}
            </div>,
          );
      }
    } else {
      setErrorField(true);
      toast.error(
        <div style={{ fontWeight: '8px' }}>
          <strong>Não foi possível alterar a senha.</strong>
          {' '}
          <br />
          {' '}
          Alguns campos estão preenchidos incorretamente!
        </div>,
      );
    }
  };
  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };
  const togglePasswordConfirm = () => {
    setPasswordShownConfirm(!passwordShownConfirm);
  };

  return (
    <>
      <LoadingBar color={theme.palette.primary.main} ref={ref} />
      <form onSubmit={handleSubmit}>
        <MediaCard smallCard>
          <Grid container spacing={2} rowSpacing={2}>
            <Grid xs={12} sm={12} md={12} lg={12}>
              <h5 style={{ marginBottom: '0.5rem' }}>Nova Senha</h5>
              <Typography sx={{ fontSize: { xs: '12px', md: '16px' }, fontFamily: 'Poppins' }}>Insira uma nova senha.</Typography>
            </Grid>
            <Grid xs={12} sm={12} md={12} lg={12}>
              <FormControl variant="outlined" fullWidth error={errorField}>
                <InputLabel htmlFor="outlined-adornment-password">Senha</InputLabel>
                <OutlinedInput
                  id="outlined-adornment-password"
                  type={showPassword ? 'text' : 'password'}
                  onChange={(e) => setNewPassword(e.target.value)}
                  endAdornment={(
                    <InputAdornment position="end">
                      <IconButton
                        aria-label="toggle password visibility"
                        onClick={togglePasswordVisibility}
                      >
                        {showPassword ? <VisibilityOutlined /> : <VisibilityOffOutlined />}
                      </IconButton>
                    </InputAdornment>
                  )}
                  label="Senha"
                />
              </FormControl>
            </Grid>
            <Grid xs={12} sm={12} md={12} lg={12}>
              <FormControl variant="outlined" fullWidth error={errorField}>
                <InputLabel htmlFor="outlined-adornment-password">Confirmar senha</InputLabel>
                <OutlinedInput
                  id="outlined-adornment-password"
                  type={passwordShownConfirm ? 'text' : 'password'}
                  onChange={(e) => setConfirmPassword(e.target.value)}
                  endAdornment={(
                    <InputAdornment position="end">
                      <IconButton
                        aria-label="toggle password visibility"
                        onClick={() => togglePasswordConfirm()}
                      >
                        {passwordShownConfirm ? <VisibilityOutlined /> : <VisibilityOffOutlined />}
                      </IconButton>
                    </InputAdornment>
                  )}
                  label="Confirmar senha"
                />
              </FormControl>
            </Grid>
            <Grid xs={12} sm={12} md={12} lg={12}>
              <Button
                variant="contained"
                fullWidth
                type="submit"
                sx={{
                  borderRadius: '2rem',
                  backgroundColor: theme.palette.primary.main,
                  marginBottom: '0.5rem',
                  '&:hover': {
                    backgroundColor: alpha(theme.palette.primary.main, 0.8),
                  },
                }}
              >
                Alterar senha
              </Button>
            </Grid>
          </Grid>
        </MediaCard>
      </form>
    </>
  );
}
