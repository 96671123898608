import React, { ReactNode } from 'react';
import { alpha, createTheme, ThemeProvider } from '@mui/material';

// Defina sua cor hexadecimal aqui
const primary = '#0068FF';
const light = '#00d4ff';
const dark = '#020024';

const theme = createTheme({
  palette: {
    primary: {
      main: primary,
      light,
      dark,
    },
  },
});

interface SystemColorProps {
  children: ReactNode
}

export default function SystemColors(props: SystemColorProps) {
  const { children }: SystemColorProps = props;
  return (
    <ThemeProvider theme={theme}>
      {children}
    </ThemeProvider>
  );
}
