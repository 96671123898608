import React, { useEffect, useRef, useState } from 'react';
import LoadingBar, { LoadingBarRef } from 'react-top-loading-bar';
import { useHistory } from 'react-router-dom';
import { AxiosResponse } from 'axios';
import SortIcon from '@mui/icons-material/ArrowUpward';
import DataTable from 'react-data-table-component';
import { parseCookies } from 'nookies';
import { toast, ToastContainer } from 'react-toastify';
import { useTheme } from '@mui/material';
import { FormPage } from '../../components/FormPage';
import style from './listagerente.module.scss';
import { api } from '../../services/api';
import { DialogComponent } from '../../components/DialogComponent';
import { LoadingIcon } from '../../components/LoadingIcon';

interface GerenteData {
  id: number;
  nome: string;
  email: string;
  status: string;
}

export function ListaGerente() {
  const ref = useRef<LoadingBarRef>(null);
  const [gerentes, setGerentes] = useState<GerenteData[]>([]);
  const theme = useTheme();

  const history = useHistory();
  const { 'nextauth.tag': tag } = parseCookies();
  const [isOpen, setIsOpen] = useState(false);
  const [loadingText, setLoadingText] = useState('');

  useEffect(() => {
    const getGerentes = async () => {
      const response: Promise<AxiosResponse<any, any>> = api.post('/user/manager/all/get');
      response.then((resolved) => {
        const items: GerenteData[] = resolved.data;
        setGerentes(items);
        return items;
      });
    };
    getGerentes();
  }, []);

  const handleClick = () => {
    history.push('/CadastroGerente');
  };

  // @ts-ignore
  const handleClickActivate = async (state) => {
    const msg = 'Deseja alterar o status deste gerente?';
    // eslint-disable-next-line no-alert

    let statusToSend = '';
    let value = 0;

    for (let i = 0; i < gerentes.length; i++) {
      if (gerentes[i].id.toString() === state.target.id) {
        statusToSend = gerentes[i].status;
        value = gerentes[i].id;
      }
    }
    // eslint-disable-next-line no-alert
    if (window.confirm(msg)) {
      if (statusToSend.toLowerCase() === 'Ativo'.toLowerCase()) {
        setLoadingText('Desativando gerente...');
      } else {
        setLoadingText('Ativando gerente...');
      }

      setIsOpen(true);
      const changeStatus = await api.post('/user/operator/status/put', {
        data: {
          userId: state.target.id,
          status: statusToSend,
          operadorId: tag,
        },

      });

      if (changeStatus.data === 'STATUS_CHANGED') {
        toast.success(statusToSend.toLowerCase() === 'Ativo'.toLowerCase()
          ? 'Gerente Desativado' : 'Gerente Ativado');
        setTimeout(() => {
          window.location.reload();
        }, 2000);
      } else {
        toast.error('Houve um erro com sua solicitação, tente mais tarde!');
      }
    }
  };

  const columns = [
    // {
    // name: 'Código',
    {
      name: 'Código', selector: 'id', sortable: true, width: '100px',
    },
    // },
    {
      name: 'Gerente',
      selector: (row: { [x: string]: any; }) => row['nome'],
      sortable: true,
      right: false,
      center: false,
      minWidth: '200',
      width: '200',
    },
    {
      name: 'Email',
      selector: (row: { [x: string]: any; }) => row['email'],
      sortable: true,
      right: false,
      center: false,
      minWidth: '200',
      width: '200',
    },
    {
      // name: 'Código', selector: 'id', sortable: true, width: '100px',
      name: 'Status',
      selector: 'status',
      width: '100px',
      sortable: true,
      conditionalCellStyles: [
        {
          when: (row: { [x: string]: any; }) => row['status'] === 'Ativo',
          style: {
            color: 'green',
            '&:hover': {
              cursor: 'pointer',
            },
          },
        },
        {
          when: (row: { [x: string]: any; }) => row['status'] === 'Inativo',
          style: {
            color: 'red',
            '&:hover': {
              cursor: 'pointer',
            },
          },
        },
      ],
    },
    {
      button: true,
      // eslint-disable-next-line react/no-unstable-nested-components
      cell: (row: { [x: string]: any; }) => (
        <button
          style={{ width: '80px', backgroundColor: row.status === 'Ativo' ? 'red' : 'darkgreen', cursor: 'pointer' }}
          type="button"
          id={row.id}
          // @ts-ignore
          onClick={handleClickActivate}
        >
          {row.status.toLowerCase() === 'Ativo'.toLowerCase() ? 'Desativar' : 'Ativar'}
        </button>
      ),
    },
  ];
  const paginationComponentOptions = {
    selectAllRowsItem: true,
    selectAllRowsItemText: 'Todos',
    rowsPerPageText: 'Registros por página',
  };
  return (
    <div>
      <LoadingBar color={theme.palette.primary.main} ref={ref} />
      <FormPage>
        <br />
        <div className={style.listaGerenteContainer}>
          <h1>Meus Gerentes</h1>
          <button type="button" onClick={handleClick}>
            Cadastrar Gerente
          </button>
          {gerentes.length === 0 ? <LoadingIcon active />
            : (
              <DataTable
                title=""
                key="id"
            // @ts-ignore
                columns={columns}
            // @ts-ignore
            // paginationComponent={BootyPagination}
                paginationComponentOptions={paginationComponentOptions}
                data={gerentes}
            // paginationPerPage={30}
            // @ts-ignore
                defaultSortField="nome"
                noDataComponent="Nenhum gerente cadastrado"
                sortIcon={<SortIcon />}
                pagination
              />
            )}
        </div>
        <br />
      </FormPage>
      <DialogComponent isOpen={isOpen} labelText={loadingText} />
    </div>
  );
}
