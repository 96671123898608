import React, { ChangeEvent, useRef, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { setCookie } from 'nookies';
import LoadingBar, { LoadingBarRef } from 'react-top-loading-bar';
import {
  Box,
  InputAdornment,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  TextField,
  Typography,
  useTheme,
  Button as MuiButton,
} from '@mui/material';
import Grid from '@mui/material/Unstable_Grid2';
import { toast } from 'react-toastify';
import SearchOutlinedIcon from '@mui/icons-material/SearchOutlined';
import style from '../../pages/MeusProjetos/meusprojetos.module.scss';
import { api } from '../../services/api';
import { DialogComponent } from '../DialogComponent';
import Button from '../elements/Button';
import { integratorStatusList } from '../../utils/Status';

interface User {
  passwordChanged: boolean;
  id: number
}

interface Integrator {
  id: number;
  razaoSocial: string;
  cnpj: string;
  telefone: string;
  cidade: string;
  estado: string;
  ativo: string;
  email: string;
  user: User;
}

interface IntegratorStatus {
  ativo: number;
  pendente: number;
  recusado: number;
  semDocumentos: number;
}

interface IntegratorsTableProps {
  integrators: Array<Integrator>;
  integratorsQt: IntegratorStatus;
}
interface Column {
  id: 'code' | 'socialReason' | 'email' | 'cnpj'| 'city' | 'firstAccess' | 'status' | 'actions'
  label: string
  minWidth?: number
  align?: 'right' | 'center' | 'left'
  format?: (value: number) => string
}

interface FilterInput {
  input: string
}
const IntegratorsTable = (props: IntegratorsTableProps) => {
  const {
    integrators,
    integratorsQt,
  }: IntegratorsTableProps = props;
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const history = useHistory();
  const [isOpen, setIsOpen] = useState(false);
  const [loadingText, setLoadingText] = useState('');

  const theme = useTheme();

  const [filterText, setFilterText] = React.useState<string[]>(integratorStatusList);
  const [filterInput, setFilterInput] = useState<Partial<FilterInput>>({});

  const projectFilter = () => {
    if (filterInput.input) {
      return integrators.filter(
        (item) => item.razaoSocial
          && filterInput.input
          && item.razaoSocial.toLowerCase().includes(filterInput.input.toLowerCase()),
      );
    }
    return integrators.filter((e) => e.ativo && filterText?.includes(e.ativo));
  };

  const filteredItems = projectFilter();

  const columns: readonly Column[] = [
    { id: 'code', label: 'Código', align: 'left' },
    { id: 'socialReason', label: 'Razão Social', align: 'left' },
    { id: 'email', label: 'Email', align: 'left' },
    { id: 'cnpj', label: 'CNPJ', align: 'left' },
    { id: 'city', label: 'Cidade', align: 'left' },
    { id: 'firstAccess', label: 'Primeiro Acesso', align: 'left' },
    { id: 'status', label: 'Status', align: 'left' },
    { id: 'actions', label: '', align: 'left' },
  ];

  const handleChangePage = (event: unknown, newPage: number) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event: ChangeEvent<HTMLInputElement>) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  const ref = useRef<LoadingBarRef>(null);

  const handleClickDetails = (row: Integrator) => {
    setCookie(undefined, 'nextauth.p', String(row.id), {
      maxAge: 60 * 60 * 24 * 30, // 30 days,
      path: '/',
      sameSite: 'none',
      secure: true,
    });
    history.push({
      pathname: '/DetalheCadastro',
    });
  };

  const integratorStatusColor = (status: string) => {
    if (status === 'Recusado') {
      return 'red';
    } if (status === 'Sem documentos') {
      return '#000';
    } if (status === 'Pendente') {
      return 'blue';
    } if (status === 'Ativo') {
      return 'green';
    }
    return '#000';
  };

  const filterCardStyle = {
    padding: '0.5rem 2rem 0.5rem 1rem',
    height: '4rem',
    backgroundColor: '#fff',
    color: '#000',
    cursor: 'pointer',
    transitionDuration: '0.1s',
    justifyContent: 'space-between',
  };

  const cardTextStyle = {
    fontSize: '12px',
    textAlign: 'left',
    fontWeight: '700',
    fontFamily: 'Poppins',
  };

  const counterStyle = {
    display: 'flex',
    height: '100%',
    gap: '1rem',
    alignItems: 'center',
  };

  const filterCard = (title: string, filter: string[], counter: Function | number) => {
    const clickedCardTest = (filterText[0] === filter[0] && filterText.length < 3 && title !== 'Todos') || (filterText.length > 2 && title === 'Todos');
    return (
      <MuiButton
        sx={{
          ...filterCardStyle,
          boxShadow: clickedCardTest ? 'black 0.75px 0.75px 3px' : 'lightgray 0.25px 0.25px 3px',
        }}
        fullWidth
        type="button"
        onClick={() => setFilterText(filter)}
      >
        <Typography sx={cardTextStyle}>{title}</Typography>
        <Box sx={counterStyle}>
          <div
            style={{ border: clickedCardTest ? `solid ${theme.palette.primary.main} 1px` : 'solid lightgray 1px' }}
            className={style.filterButtonDivider}
          />
          <Typography sx={cardTextStyle}>{counter}</Typography>
        </Box>
      </MuiButton>
    );
  };

  const handleClickEmail = async (row: Integrator) => {
    const urlv = window.location.href.split('/');
    const urlc = `${urlv[0]}//${urlv[2]}`;
    const msg = 'Deseja reenviar um email avisando a integradora para que ela realize seu primeiro login?';
    // eslint-disable-next-line no-alert
    if (window.confirm(msg)) {
      setLoadingText('Enviando email..');
      setIsOpen(true);
      const sendEmail = await api.post('email/integrator/send', {
        data: {
          integratorId: row.user.id,
          returnUrl: urlc,
        },
      });
      if (sendEmail.data === 'EMAIL_SEND') {
        setIsOpen(false);
        toast.success('Email enviado!');
      } else {
        setIsOpen(false);
        toast.error('Houve um erro ao enviar o email!');
      }
    }
  };

  const handleFilterChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const {
      value,
    } = e.target;

    setFilterInput({
      ...filterInput,
      input: value,
    });
  };

  return (
    <div>
      <LoadingBar color={theme.palette.primary.main} ref={ref} />
      {/* <Box */}
      {/*   sx={{ */}
      {/*     backgroundColor: '#F2F5F9', */}
      {/*     padding: '1rem', */}
      {/*     borderRadius: '0.5rem', */}
      {/*   }} */}
      {/* > */}
      {/*   <Grid */}
      {/*     container */}
      {/*     spacing={2} */}
      {/*     rowSpacing={2} */}
      {/*   > */}
      {/*     <Grid lg={2.4}> */}
      {/* {filterCard( */}
      {/*   'Todos', */}
      {/*   integratorStatusList, */}
      {/*   (integratorsQt.ativo */}
      {/*            + integratorsQt.recusado */}
      {/*            + integratorsQt.pendente */}
      {/*            + integratorsQt.semDocumentos), */}
      {/* )} */}
      {/*     </Grid> */}
      {/*     <Grid lg={2.4}> */}
      {/*       {filterCard('Ativos', ['Ativo'], integratorsQt.ativo)} */}
      {/*     </Grid> */}
      {/*     <Grid lg={2.4}> */}
      {/*       {filterCard('Recusados', ['Recusado'], integratorsQt.recusado)} */}
      {/*     </Grid> */}
      {/*     <Grid lg={2.4}> */}
      {/*       {filterCard('Pendentes', ['Pendente'], integratorsQt.pendente)} */}
      {/*     </Grid> */}
      {/*     <Grid lg={2.4}> */}
      {/*       {filterCard('Sem documentos', ['Sem documentos'], integratorsQt.semDocumentos)} */}
      {/*     </Grid> */}
      {/*   </Grid> */}
      {/* </Box> */}
      <TextField
        fullWidth
        label="Filtre pela razão social da integradora"
        variant="outlined"
        onChange={handleFilterChange}
        InputProps={{
          endAdornment: (
            <InputAdornment position="end">
              <SearchOutlinedIcon />
            </InputAdornment>
          ),
        }}
        // sx={{ margin: '1rem 0 1rem 0' }}
        sx={{ margin: '0 0 1rem 0' }}
      />
      <Paper sx={{
        width: '100%',
        overflow: 'hidden',
        boxSizing: 'border-box',
        boxShadow: 'none',
      }}
      >
        <TableContainer sx={{ maxHeight: 490 }}>
          <Table>
            <TableHead>
              <TableRow>
                {columns.map((column) => (
                  <TableCell
                    key={column.id}
                    align={column.align}
                  >
                    {column.label}
                  </TableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              {filteredItems && (
                filteredItems.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                  .map((row: Integrator) => (
                    <TableRow
                      hover
                      role="checkbox"
                      tabIndex={-1}
                      key={row.id}
                    >
                      <TableCell>
                        {row.id}
                      </TableCell>
                      <TableCell>
                        {row.razaoSocial}
                      </TableCell>
                      <TableCell>
                        {row.email}
                      </TableCell>
                      <TableCell>
                        {row.cnpj}
                      </TableCell>
                      <TableCell>
                        {row.cidade}
                      </TableCell>
                      <TableCell>
                        {row.user.passwordChanged ? 'Sim' : 'Não'}
                      </TableCell>
                      <TableCell
                        sx={{
                          color: integratorStatusColor(row.ativo),
                        }}
                      >
                        {row.ativo}
                      </TableCell>
                      {row.ativo === 'Sem documentos' && row.user && !row.user.passwordChanged ? (
                        <TableCell align="right" onClick={() => handleClickEmail(row)}>
                          <Typography sx={{
                            fontSize: '12px',
                            fontFamily: 'Poppins',
                            color: theme.palette.primary.main,
                            fontWeight: '700',
                            cursor: 'pointer',
                            userSelect: 'none',
                          }}
                          >
                            Reenviar E-mail
                          </Typography>
                        </TableCell>
                      ) : (
                        // <TableCell align="right" onClick={row.ativo
                        // === 'Sem documentos' ? () => {} : () => handleClickDetails(row)}>
                        <TableCell align="right" onClick={() => handleClickDetails(row)}>
                          <Typography sx={{
                            fontSize: '12px',
                            fontFamily: 'Poppins',
                            // color: row.ativo === 'Sem documentos'
                            // ? 'lightGray' : theme.palette.primary.main,
                            color: theme.palette.primary.main,
                            fontWeight: '700',
                            // cursor: row.ativo === 'Sem documentos' ? 'arrow' : 'pointer',
                            cursor: 'pointer',
                            userSelect: 'none',
                          }}
                          >
                            Detalhes
                          </Typography>
                        </TableCell>
                      )}
                    </TableRow>
                  ))
              )}
              {(!filteredItems || filteredItems.length === 0) && (
                <TableRow
                  hover
                  role="checkbox"
                >
                  <TableCell colSpan={columns.length}>
                    Nenhum projeto cadastrado!
                  </TableCell>
                </TableRow>
              )}
            </TableBody>
          </Table>
        </TableContainer>
        <Box sx={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
        }}
        >
          <Button variant="contained" onClick={() => history.push('/CadastroIntegradora')}>
            Adicionar integradora
          </Button>
          <TablePagination
            rowsPerPageOptions={[10, 25, 100]}
            component="div"
            count={filteredItems.length}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
            labelRowsPerPage="Linhas por página"
          />
        </Box>
      </Paper>
      <DialogComponent isOpen={isOpen} labelText={loadingText} />
    </div>
  );
};

export default IntegratorsTable;
