import React from 'react';
import { Box, Modal } from '@mui/material';
import style from './dialog.module.scss';
import { LoadingIcon } from '../LoadingIcon';

interface Props {
  isOpen: boolean;
  labelText: string;
}

const DialogComponent = (props: Props) => {
  const {
    isOpen, labelText,
  } = props;

  return (
    <Modal
      open={isOpen}
      sx={{
        position: 'absolute',
        width: '100vw',
        height: '100vh',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        '& .MuiBackdrop-root': {
          backgroundColor: 'rgba(255, 255, 255, 0.7)',
        },
      }}
    >
      <Box sx={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        gap: '1rem',
      }}
      >
        <LoadingIcon active={isOpen} />
        <div>{labelText}</div>
      </Box>
    </Modal>
  );
};

export { DialogComponent };
